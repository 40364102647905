import { useEffect, useState } from 'react';
import { useWhiteboards } from '../api/get-whiteboards.ts';
import { useSetWhiteboardOwner } from '../api/set-whiteboard-owner.ts';
import { clearPublicWhiteboardUuids, getPublicWhiteboardUuids } from '../helpers/public-whiteboard-uuid.ts';
import { Whiteboard } from '../types.ts';

export function useSetupWhiteboards(projectId: number) {
  const { setWhiteboardOwner } = useSetWhiteboardOwner();
  const { whiteboards } = useWhiteboards(Number(projectId));

  const [allWhiteboards, setAllWhiteboards] = useState<Whiteboard[]>([]);

  useEffect(() => {
    if (whiteboards && projectId) {
      setupWhiteboards();
      setAllWhiteboards(whiteboards);
    }
  }, [whiteboards, projectId]);

  async function setupWhiteboards() {
    const publicWhiteboardUuids = getPublicWhiteboardUuids();
    if (publicWhiteboardUuids.length > 0) {
      try {
        const newWhiteboards = await setWhiteboardOwner({ uuids: publicWhiteboardUuids, projectId });
        clearPublicWhiteboardUuids();
        setAllWhiteboards((w) => [...newWhiteboards, ...w]);
      } catch (error) {
        console.error('Failed to set public whiteboard owner:', error);
      }
    }
  }

  return {
    whiteboards: allWhiteboards,
  };
}

import { ToolsContext } from '../../libs/tools/ToolsContext.tsx';
import { BubbleMenu, Editor, EditorContent, useEditor } from '@tiptap/react';
import {
  AiTools,
  CharacterCount,
  Color,
  Column,
  Columns,
  Document,
  Emoji,
  emojiSuggestion,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  Link,
  Selection,
  StarterKit,
  Subscript,
  Superscript,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
} from '@/components/tiptap/extensions';
import History from '@tiptap/extension-history';
import Iframe from '@/components/tiptap/extensions/Iframe/iframe.ts';
import { memo, useRef } from 'react';
import { Toolbar } from '@/components/tiptap/components/ui/Toolbar.tsx';
import { ColorPicker } from '@/components/tiptap/components/panels';
import { FontFamilyPicker } from '@/components/tiptap/components/menus/TextMenu/components/FontFamilyPicker.tsx';
import { FontSizePicker } from '@/components/tiptap/components/menus/TextMenu/components/FontSizePicker.tsx';
import { ContentTypePicker } from '@/components/tiptap/components/menus/TextMenu/components/ContentTypePicker.tsx';
import { useTextmenuCommands } from '@/components/tiptap/components/menus/TextMenu/hooks/useTextmenuCommands.ts';
import { useTextmenuStates } from '@/components/tiptap/components/menus/TextMenu/hooks/useTextmenuStates.ts';
import { useTextmenuContentTypes } from '@/components/tiptap/components/menus/TextMenu/hooks/useTextmenuContentTypes.ts';
import { AIDropdown } from '@/components/tiptap/components/menus/TextMenu/components/AIDropdown.tsx';
import { Icon } from '@/components/tiptap/components/ui/Icon.tsx';
import { icons } from 'lucide-react';
import { EditLinkPopover } from '@/components/tiptap/components/menus/TextMenu/components/EditLinkPopover.tsx';
import * as Popover from '@radix-ui/react-popover';
import { Surface } from '@/components/tiptap/components/ui/Surface.tsx';
import { useReactFlowIfExists } from '@/hooks/useReactFlowIfExists.ts';
import { ToolConfig } from '../../libs/tools/type.model.ts';

export interface PlainTiptapEditorProps {
  initialContent?: string;
  onUpdate?: (content: string) => void;
  toolConfigs: ToolConfig[];
}

export const PlainTiptapEditor = (props: PlainTiptapEditorProps) => {
  return (
    <ToolsContext.Provider value={{ tools: props.toolConfigs }}>
      <PlainTiptapEditorBody {...props} />
    </ToolsContext.Provider>
  );
};

const PlainTiptapEditorBody = ({ initialContent, onUpdate }: PlainTiptapEditorProps) => {
  const editor = useEditor(
    {
      extensions: [
        Document,
        Columns,
        Iframe,
        AiTools(),
        Column,
        Selection,
        Heading.configure({
          levels: [1, 2, 3, 4, 5, 6],
        }),
        StarterKit.configure({
          document: false,
          dropcursor: false,
          heading: false,
          blockquote: false,
          history: false,
          codeBlock: false,
        }),
        TextStyle,
        FontSize,
        FontFamily,
        Color,
        TrailingNode,
        Link.configure({
          openOnClick: false,
        }),
        Highlight.configure({ multicolor: true }),
        Underline,
        CharacterCount.configure({ limit: 500000 }),
        Emoji.configure({
          enableEmoticons: true,
          suggestion: emojiSuggestion,
        }),
        TextAlign.extend({
          addKeyboardShortcuts() {
            return {};
          },
        }).configure({
          types: ['heading', 'paragraph'],
        }),
        Subscript,
        Superscript,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        Typography,
        Focus,
        History,
      ],
      content: initialContent,
      injectCSS: false,
      onUpdate: ({ editor }) => {
        onUpdate?.(editor.getHTML());
      },
    },
    [],
  );

  if (!editor) return null;

  return (
    <>
      <PlainTiptapEditorMenu editor={editor} />
      <EditorContent editor={editor} />
    </>
  );
};

const MemoButton = memo(Toolbar.Button);
const MemoColorPicker = memo(ColorPicker);
const MemoFontFamilyPicker = memo(FontFamilyPicker);
const MemoFontSizePicker = memo(FontSizePicker);
const MemoContentTypePicker = memo(ContentTypePicker);

const PlainTiptapEditorMenu = ({ editor }: { editor: Editor }) => {
  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  const blockOptions = useTextmenuContentTypes(editor);

  const reactFlow = useReactFlowIfExists();
  const menuRef = useRef<HTMLDivElement>(null);

  return (
    <div
      style={
        reactFlow
          ? {
              transform: `scale(${1 / reactFlow.getZoom()})`,
              transformOrigin: 'center',
            }
          : {}
      }
      className="fixed z-50"
      ref={menuRef}
      id="asdf"
    >
      <BubbleMenu
        tippyOptions={{
          appendTo: () => menuRef.current!,
          popperOptions: {
            strategy: 'fixed',
            placement: 'top-start',
          },
        }}
        editor={editor}
        pluginKey="textMenu"
        shouldShow={states.shouldShow}
        updateDelay={100}
      >
        <Toolbar.Wrapper>
          <AIDropdown
            onCompleteSentence={commands.onCompleteSentence}
            onEmojify={commands.onEmojify}
            onFixSpelling={commands.onFixSpelling}
            onMakeLonger={commands.onMakeLonger}
            onMakeShorter={commands.onMakeShorter}
            onSimplify={commands.onSimplify}
            onTldr={commands.onTldr}
            onTone={commands.onTone}
            onTranslate={commands.onTranslate}
          />

          <Toolbar.Divider />
          <MemoContentTypePicker options={blockOptions} />
          <MemoFontFamilyPicker
            onChange={commands.onSetFont}
            value={states.currentFont || ''}
          />
          <MemoFontSizePicker
            onChange={commands.onSetFontSize}
            value={states.currentSize || ''}
          />
          <Toolbar.Divider />
          <MemoButton
            tooltip="Bold"
            tooltipShortcut={['Mod', 'B']}
            onClick={commands.onBold}
            active={states.isBold}
          >
            <Icon name="Bold" />
          </MemoButton>
          <MemoButton
            tooltip="Italic"
            tooltipShortcut={['Mod', 'I']}
            onClick={commands.onItalic}
            active={states.isItalic}
          >
            <Icon name="Italic" />
          </MemoButton>
          <MemoButton
            tooltip="Underline"
            tooltipShortcut={['Mod', 'U']}
            onClick={commands.onUnderline}
            active={states.isUnderline}
          >
            <Icon name="Underline" />
          </MemoButton>
          <MemoButton
            tooltip="Strikehrough"
            tooltipShortcut={['Mod', 'X']}
            onClick={commands.onStrike}
            active={states.isStrike}
          >
            <Icon name="Strikethrough" />
          </MemoButton>
          <MemoButton
            tooltip="Code"
            tooltipShortcut={['Mod', 'E']}
            onClick={commands.onCode}
            active={states.isCode}
          >
            <Icon name="Code" />
          </MemoButton>
          <MemoButton
            tooltip="Code block"
            onClick={commands.onCodeBlock}
          >
            <Icon name={'Code2' as keyof typeof icons} />
          </MemoButton>
          <EditLinkPopover onSetLink={commands.onLink} />
          <Popover.Root>
            <Popover.Trigger asChild>
              <MemoButton
                active={!!states.currentHighlight}
                tooltip="Highlight text"
              >
                <Icon name="Highlighter" />
              </MemoButton>
            </Popover.Trigger>
            <Popover.Content
              side="top"
              sideOffset={8}
              asChild
            >
              <Surface className="p-1">
                <MemoColorPicker
                  color={states.currentHighlight}
                  onChange={commands.onChangeHighlight}
                  onClear={commands.onClearHighlight}
                />
              </Surface>
            </Popover.Content>
          </Popover.Root>
          <Popover.Root>
            <Popover.Trigger asChild>
              <MemoButton
                active={!!states.currentColor}
                tooltip="Text color"
              >
                <Icon name="Palette" />
              </MemoButton>
            </Popover.Trigger>
            <Popover.Content
              side="top"
              sideOffset={8}
              asChild
            >
              <Surface className="p-1">
                <MemoColorPicker
                  color={states.currentColor}
                  onChange={commands.onChangeColor}
                  onClear={commands.onClearColor}
                />
              </Surface>
            </Popover.Content>
          </Popover.Root>
          <Popover.Root>
            <Popover.Trigger asChild>
              <MemoButton tooltip="More options">
                <Icon name={'MoreVertical' as keyof typeof icons} />
              </MemoButton>
            </Popover.Trigger>
            <Popover.Content
              side="top"
              asChild
            >
              <Toolbar.Wrapper>
                <MemoButton
                  tooltip="Subscript"
                  tooltipShortcut={['Mod', '.']}
                  onClick={commands.onSubscript}
                  active={states.isSubscript}
                >
                  <Icon name="Subscript" />
                </MemoButton>
                <MemoButton
                  tooltip="Superscript"
                  tooltipShortcut={['Mod', ',']}
                  onClick={commands.onSuperscript}
                  active={states.isSuperscript}
                >
                  <Icon name="Superscript" />
                </MemoButton>
                <Toolbar.Divider />
                <MemoButton
                  tooltip="Align left"
                  tooltipShortcut={['Shift', 'Mod', 'L']}
                  onClick={commands.onAlignLeft}
                  active={states.isAlignLeft}
                >
                  <Icon name="AlignLeft" />
                </MemoButton>
                <MemoButton
                  tooltip="Align center"
                  tooltipShortcut={['Shift', 'Mod', 'E']}
                  onClick={commands.onAlignCenter}
                  active={states.isAlignCenter}
                >
                  <Icon name="AlignCenter" />
                </MemoButton>
                <MemoButton
                  tooltip="Align right"
                  tooltipShortcut={['Shift', 'Mod', 'R']}
                  onClick={commands.onAlignRight}
                  active={states.isAlignRight}
                >
                  <Icon name="AlignRight" />
                </MemoButton>
                <MemoButton
                  tooltip="Justify"
                  tooltipShortcut={['Shift', 'Mod', 'J']}
                  onClick={commands.onAlignJustify}
                  active={states.isAlignJustify}
                >
                  <Icon name="AlignJustify" />
                </MemoButton>
              </Toolbar.Wrapper>
            </Popover.Content>
          </Popover.Root>
        </Toolbar.Wrapper>
      </BubbleMenu>
    </div>
  );
};

import { cn } from '@/helpers/cn';

type ColorSelectorProps = {
  onChange: (color: string) => void;
  selectedColor: string;
  colors: string[];
};

export function ColorSelector({ onChange, selectedColor, colors }: ColorSelectorProps) {
  return (
    <div className="flex gap-2 px-2">
      {colors.map((color) => (
        <button
          type="button"
          key={color}
          className={cn(
            'size-6 rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2',
            color,
            {
              'ring-2 ring-primary-500 ring-offset-2': selectedColor === color,
            },
          )}
          onClick={() => onChange(color)}
        />
      ))}
    </div>
  );
}

import { DfTooltip } from '@/components/DfTooltip/DfTooltip';
import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { useEffect, useRef, useState } from 'react';
import { ToolConfig } from 'src/libs/tools/type.model';
import { getTemplateTools } from '../../helpers/template-helper';
import { getToolIcon } from '../../helpers/tool-helpers';

export function TemplateToolsIcons({ template }: { template: WhiteboardTemplate }) {
  const ref = useRef<HTMLDivElement>(null);
  const [visibleTools, setVisibleTools] = useState<ToolConfig[]>([]);
  const [restTools, setRestTools] = useState<ToolConfig[]>([]);

  const templateTools = getTemplateTools(template);
  const uniqueTemplateTools = templateTools.filter(
    (toolConfig, index, self) => index === self.findIndex((t) => t.name === toolConfig.name),
  );
  const gap = 6;
  const toolIconWidth = 40;

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = ref.current?.clientWidth ?? 0;
      const maxVisibleTools = Math.floor((containerWidth + gap) / (toolIconWidth + gap));
      const sliceIndex = maxVisibleTools >= uniqueTemplateTools.length ? maxVisibleTools : maxVisibleTools - 1;
      setVisibleTools(uniqueTemplateTools.slice(0, sliceIndex));
      setRestTools(uniqueTemplateTools.slice(sliceIndex));
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="flex gap-1.5 py-2"
      ref={ref}
    >
      {visibleTools.map((toolConfig) => (
        <TemplateToolIcon
          key={toolConfig.name}
          toolConfig={toolConfig}
        />
      ))}
      {restTools.length > 0 && (
        <div
          className="size-10 rounded-lg bg-neutrals-100 p-2.5 text-body-sm"
          data-tooltip-id={`rest-tools-${template.id}`}
        >
          +{restTools.length}
        </div>
      )}
      {restTools.length > 0 && (
        <div className="relative z-10">
          <DfTooltip
            id={`rest-tools-${template.id}`}
            content={restTools.map((tool) => tool.displayName).join(', ')}
            place="bottom"
          />
        </div>
      )}
    </div>
  );
}

function TemplateToolIcon({ toolConfig }: { toolConfig: ToolConfig }) {
  const ToolIcon = getToolIcon(toolConfig);
  return (
    <div
      className="size-10 rounded-lg bg-neutrals-100 p-2.5"
      data-tooltip-id={`tooltip-tool-${toolConfig.name}`}
    >
      <ToolIcon className="size-5" />
      <div className="relative z-10">
        <DfTooltip
          id={`tooltip-tool-${toolConfig.name}`}
          content={toolConfig.displayName}
          place="bottom"
        />
      </div>
    </div>
  );
}

import { useApi } from '../api';

export const useWhiteboardToXlsx = () => {
  const { getFetcher } = useApi();

  const whiteboardToXlsx = async (whiteboardId: number) => {
    return getFetcher(`/for-client-features/whiteboard-to-xlsx/${whiteboardId}`);
  };

  return {
    whiteboardToXlsx,
  };
};

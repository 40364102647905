import { useKeyPress } from '@xyflow/react';
import { useEffect } from 'react';
import { useWhiteboardStore } from '../store/whiteboard.store';

export function useSetLayout() {
  const cmdLPressed = useKeyPress(['Meta+l', 'Strg+l']);
  const setLayout = useWhiteboardStore((state) => state.setLayout);

  useEffect(() => {
    if (cmdLPressed) {
      setLayout();
    }
  }, [cmdLPressed]);

  return null;
}

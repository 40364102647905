import ResizeIcon from '@assets/resize.svg';
import { NodeResizeControl } from '@xyflow/react';
import { NODE_RESIZE_CONSTRAINTS } from '../consts/whiteboard.const.ts';

export function NodeResizeWrapper({
  selected = false,
  resizeConstraints = NODE_RESIZE_CONSTRAINTS,
}: {
  selected?: boolean;
  resizeConstraints?: typeof NODE_RESIZE_CONSTRAINTS;
}) {
  return (
    <NodeResizeControl
      style={{
        background: 'transparent',
        border: 'none',
      }}
      {...resizeConstraints}
      className={selected ? 'visible' : 'hidden'}
    >
      <ResizeIcon className="size-5 text-neutrals-600 hover:text-primary-600" />
    </NodeResizeControl>
  );
}

import { usePublicApi } from '@/api/public-api';
import { WhiteboardTemplate } from '@/models/whiteboard-template';
import useSWR from 'swr';

export const usePublicWhiteboardTemplate = (uuid?: string) => {
  const { publicGetFetcher } = usePublicApi();

  const { data, ...rest } = useSWR<WhiteboardTemplate>(uuid ? `/public-template/${uuid}` : null, publicGetFetcher);

  return {
    publicWhiteboardTemplate: data,
    ...rest,
  };
};

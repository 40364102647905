import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { Toggle } from '@/components/Toggle';
import CopyIcon from '@assets/copy.svg';
import LinkIcon from '@assets/link.svg';
import { Button } from 'flowbite-react';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { mutate } from 'swr';
import { useCreateWhiteboardShareToken } from '../api/create-share-token';
import { useShareWhiteboard } from '../api/share-whiteboard';
import { Whiteboard } from '../types';

export function ShareWhiteboardModal() {
  const { context } = useGlobalModal<Whiteboard>();
  const [whiteboard, setWhiteboard] = useState<Whiteboard>(context);
  const { createShareToken } = useCreateWhiteboardShareToken();
  const { shareWhiteboard } = useShareWhiteboard();
  const [isLoading, setIsLoading] = useState(false);

  const publicUrl = `${window.location.origin}/public-app/whiteboard/${whiteboard.uuid}`;
  const editUrl = `${window.location.origin}/whiteboard/${whiteboard.uuid}/${whiteboard.shareToken}`;

  const handleAction = async (action: () => Promise<Whiteboard>) => {
    setIsLoading(true);
    try {
      const updatedWhiteboard = await action();
      setWhiteboard(updatedWhiteboard);
      setIsLoading(false);
      mutate(`/whiteboard/${whiteboard.id}`);
    } catch (error) {
      console.error('Update share settings failed:', error);
      setIsLoading(false);
    }
  };

  async function handleRegenerateEditLink() {
    await handleAction(() => createShareToken(whiteboard.id));
    toast.success('Share link regenerated');
  }

  async function handleShare(isPublic: boolean, isEditable: boolean) {
    await handleAction(() => shareWhiteboard(whiteboard.id, { isPublic, isEditable }));
    toast.success('Share settings updated');
  }

  async function handleSharePublic(isPublic: boolean) {
    handleShare(isPublic, !!whiteboard.isEditable);
  }

  async function handleShareEdit(isEditable: boolean) {
    handleShare(!!whiteboard.isPublic, isEditable);
  }

  return (
    <div>
      <Toggle
        checked={!!whiteboard.isPublic}
        onChange={handleSharePublic}
        label="Share public link with anyone"
        subLabel="Allow users to view the flow in view only mode."
        disabled={isLoading}
      />
      {whiteboard.isPublic && (
        <ShareUrlInput
          url={publicUrl}
          disabled={isLoading}
        />
      )}
      <div className="modal-divider my-6"></div>

      <Toggle
        checked={!!whiteboard.isEditable}
        onChange={handleShareEdit}
        label="Share edit link"
        subLabel="Allow users to view and edit the whiteboard."
        disabled={isLoading}
      />
      {whiteboard.isEditable && (
        <div>
          <ShareUrlInput
            url={editUrl}
            disabled={isLoading}
          />
          <div className="pt-3">
            <Button
              color="secondary"
              size="sm"
              onClick={handleRegenerateEditLink}
              disabled={isLoading}
            >
              <LinkIcon className="size-4 path:fill-neutrals-1000" />
              Regenerate link
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

function ShareUrlInput({ url, disabled }: { url: string; disabled?: boolean }) {
  return (
    <div className="flex items-center gap-2 pt-5">
      <input
        type="text"
        value={!disabled ? url : ''}
        readOnly
        disabled
        className="w-full rounded-lg border border-neutrals-300 p-2 text-sm"
      />
      <Button
        color="secondary"
        size="sm"
        onClick={() => {
          navigator.clipboard.writeText(url);
          toast.success('Link copied to clipboard');
        }}
        disabled={disabled}
      >
        <CopyIcon className="size-4 path:fill-neutrals-1000" />
        Copy
      </Button>
    </div>
  );
}

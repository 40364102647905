import { usePublicWhiteboardTemplates } from './api/usePublicWhiteboardTemplates.ts';
import { PublicTemplates } from './PublicTemplates';

export function PublicTemplatesPage() {
  const { publicWhiteboardTemplates } = usePublicWhiteboardTemplates();

  if (!publicWhiteboardTemplates) return null;

  return <PublicTemplates publicTemplates={publicWhiteboardTemplates} />;
}

import { useEffect } from 'react';
import { io } from 'socket.io-client';

const socket = io(import.meta.env.VITE_WEBSOCKET_URL, {
  transports: ['websocket'],
});

socket.connect();

export const useOnWebSocketMessage = <T>(channel: string, callback: (data: T) => void) => {
  useEffect(() => {
    socket.emit('subscribe', channel);
    socket.on(channel, async (data: T) => {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch (e) {
          console.error(e);
        }
      }
      callback(data);
    });

    return () => {
      socket.emit('unsubscribe', channel);
      socket.off(channel);
    };
  }, [callback, channel]);
};

export const useWebSocketMessageListener = () => {
  const listenerOn = <T>(channel: string, callback: (data: T) => void) => {
    socket.emit('subscribe', channel);
    socket.on(channel, (data: T) => {
      if (typeof data === 'string') {
        try {
          data = JSON.parse(data);
        } catch {
          // silent
        }
      }
      callback(data);
    });

    return {
      listenerOff: () => {
        socket.emit('unsubscribe', channel);
        socket.off(channel);
      },
    };
  };

  return {
    listenerOn,
  };
};

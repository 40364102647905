import { useApi } from '@/api/api.ts';
import { Whiteboard } from '../types.ts';

export const useCreateWhiteboardFromTemplate = () => {
  const { postFetcher } = useApi();

  const createWhiteboardFromTemplate = async (templateUuid: string, projectId: number): Promise<Whiteboard> => {
    return postFetcher(`/whiteboard/from-template`, {
      arg: {
        templateUuid,
        projectId,
      },
    });
  };

  return { createWhiteboardFromTemplate };
};

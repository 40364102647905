import { Link, useParams } from 'react-router-dom';
import { IconButton } from '@/components/IconButton.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Card } from 'flowbite-react';
import { dateToRelativeString } from '@/helpers/date-to-relative-string.ts';
import { DropdownWhiteboardOptions } from './DropdownWhiteboardOptions.tsx';
import { Whiteboard } from '../../../../whiteboards/types.ts';

export const WhiteboardCard = ({ whiteboard }: { whiteboard: Whiteboard }) => {
  const { projectId } = useParams<{ projectId: string }>();

  if (!projectId) {
    return null;
  }

  return (
    <>
      <div className="relative">
        <div className="absolute right-4 top-[calc(50%-12px)]">
          <DropdownWhiteboardOptions whiteboard={whiteboard}>
            <IconButton
              icon="/three-dots-gray.svg"
              size={24}
            />
          </DropdownWhiteboardOptions>
        </div>
        <Link
          className="block h-full max-h-full min-h-[110px] min-w-0 grow"
          to={buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.WHITEBOARD], {
            whiteboardId: whiteboard.id,
            projectId: projectId,
          })}
        >
          <Card
            className="h-full max-h-full hover:bg-gray-bg active:bg-gray-ultra-light"
            key={whiteboard.id}
          >
            <div className="flex w-full gap-4">
              <div className="flex min-w-0 grow flex-col gap-2">
                <div className="flex items-center gap-2">
                  <span className="overflow-hidden text-ellipsis text-nowrap text-button-lg">{whiteboard.name}</span>
                  <img
                    src="/generate-black.svg"
                    alt=""
                  />
                </div>
                <div className="overflow-hidden text-ellipsis text-nowrap text-body-sm">
                  Edited {dateToRelativeString(whiteboard.modifiedAt)}
                </div>
              </div>
              <div className="flex w-[24px] shrink-0 items-center"></div>
              {/* place for an absolute button */}
            </div>
          </Card>
        </Link>
      </div>
    </>
  );
};

import { Button, Label, Select } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { getValidationSchema } from '../../../../libs/tools/getValidationSchema.ts';
import { Tool, ToolConfig, ToolValue } from '../../../../libs/tools/type.model';
import { ReaderFileItem } from '../../helpers/reader-file-helpers.ts';
import { useWhiteboardFormSubmit } from '../../hooks/useWhiteboardFormSubmit.ts';

interface ReaderFileFormProps {
  reactFlowNodeId: string;
  toolConfig: ToolConfig;
  items: ReaderFileItem[];
  label: string;
  tool?: Tool;
}

export const ReaderFileForm = ({ reactFlowNodeId, toolConfig, items, label, tool }: ReaderFileFormProps) => {
  const onSubmit = useWhiteboardFormSubmit(reactFlowNodeId);

  const valueKey = Object.keys(toolConfig.config.input)[0];
  const toolValue = tool?.input.find((item) => item.key === valueKey)?.value;
  const valueType = toolConfig.config.input[valueKey].type;

  const formik = useFormik<{ [valueKey: string]: ToolValue }>({
    initialValues: {
      [valueKey]: toolValue ?? '',
    },
    validationSchema: getValidationSchema(toolConfig.config.input),
    onSubmit: async (values) => {
      await onSubmit([
        {
          key: valueKey,
          value: valueType === 'number' ? Number(values[valueKey]) : values[valueKey],
        },
      ]);
    },
  });

  if (!items) return null;

  return (
    <FormikProvider value={formik}>
      <form
        onSubmit={formik.handleSubmit}
        className="flex min-h-0 grow flex-col"
      >
        <div className="grow overflow-y-scroll p-6">
          <Label htmlFor={valueKey}>{label}</Label>
          <Select
            id={valueKey}
            name={valueKey}
            onChange={formik.handleChange}
            value={formik.values[valueKey] as string}
          >
            <option value="">Select {label}</option>
            {items.map((item: ReaderFileItem) => (
              <option
                key={item.id}
                value={item.id}
              >
                {item.name}
              </option>
            ))}
          </Select>
          {formik.errors[valueKey] && formik.touched[valueKey] ? (
            <div className="text-red-500">{formik.errors[valueKey]}</div>
          ) : null}
        </div>

        <div className="flex flex-col rounded-b-2xl p-6">
          <Button
            color="primary"
            className="mt-4 w-full"
            type="submit"
            isProcessing={formik.isSubmitting}
            disabled={formik.isSubmitting}
          >
            Save
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

import { cn } from '@/helpers/cn.ts';
import { Handle, Position } from '@xyflow/react';
import { ToolOutputType } from 'src/libs/tools/type.model.ts';
import { NodeType } from '../../types.ts';
import { socketColors } from './socketColors.ts';
import { SOCKET_PADDING } from '../../consts/whiteboard.const.ts';
import { useWhiteboardStore } from '../../store/whiteboard.store.ts';

type NodeInputSocketProps = {
  name: string;
  node: NodeType;
  socketType: ToolOutputType;
  isOneSocket: boolean;
};

export function NodeInputSocket({ name, node, socketType, isOneSocket }: NodeInputSocketProps) {
  const isEdgeConnecting = useWhiteboardStore((state) => state.isEdgeConnecting);
  const connectionOnWholeNode = isEdgeConnecting && isOneSocket;

  return (
    <div className="relative">
      <div
        className={cn(
          'rounded bg-[rgba(255,255,255,0.3)] p-0.5 text-button-md opacity-0 shadow-[0px_0px_5px_rgba(255,255,255,0.3)] transition-all',
          {
            'opacity-100': isEdgeConnecting,
          },
        )}
      >
        {name}
      </div>
      <Handle
        className="absolute top-1/2 flex items-center justify-center !rounded-none !border-0 !bg-transparent"
        type="target"
        id={name}
        position={Position.Left}
        style={{
          padding: connectionOnWholeNode
            ? `${node.measured!.height! / 2}px ${node.measured!.width!}px ${node.measured!.height! / 2}px 0`
            : isEdgeConnecting
              ? 80
              : SOCKET_PADDING,
          margin: connectionOnWholeNode ? `0 0 0 ${node.measured!.width! / 2 - 16}px` : '0 0 0 -17px',
        }}
      >
        <div className={cn('pointer-events-none absolute size-3 rounded-full', socketColors[socketType])}></div>
      </Handle>
    </div>
  );
}

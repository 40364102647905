import { PlainTiptapEditor } from '@/components/PlainTiptapEditor.tsx';
import './ToolTextOutputEditor.scss';
import { Button } from 'flowbite-react';
import { useState } from 'react';
import { ToolConfig } from './type.model.ts';

interface ToolTextOutputEditorProps {
  initialOutput: string;
  onFinishEditing: (output: string) => void;
  onCancelEditing: () => void;
  toolConfigs: ToolConfig[];
}

export const ToolTextOutputEditor = ({
  initialOutput,
  onFinishEditing,
  onCancelEditing,
  toolConfigs,
}: ToolTextOutputEditorProps) => {
  const [editingOutput, setEditingOutput] = useState(initialOutput);

  return (
    <div className="nopan nodrag">
      <div className="tool-output-editor p-4">
        <PlainTiptapEditor
          initialContent={initialOutput}
          onUpdate={(content) => setEditingOutput(content)}
          toolConfigs={toolConfigs}
        />
      </div>

      {/* fixed position because safari can't handle sticky on bottom, 2px offset for border */}
      <div className="fixed bottom-[2px] left-[2px] z-10 flex w-[calc(100%-4px)] justify-end gap-2 rounded-b-xl border-t-2 bg-white p-2">
        <Button
          color="secondary"
          size="sm"
          onClick={onCancelEditing}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          size="sm"
          onClick={() => onFinishEditing(editingOutput)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

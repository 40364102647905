import { useApi } from '@/api/api';

export const useDeleteExecutionEngineNodes = () => {
  const { deleteFetcher } = useApi();

  const deleteExecutionEngineNodes = (reactFlowNodeIds: string[]) => {
    return deleteFetcher('/execution-engine-node', {
      arg: {
        reactFlowNodeIds,
      },
    });
  };

  return {
    deleteExecutionEngineNodes,
  };
};

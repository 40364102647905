import MinusIcon from '@assets/minus.svg';
import PlusIcon from '@assets/plus.svg';
import { useReactFlow } from '@xyflow/react';
import { PanelButton, PanelCard } from '../components/Panels/PanelUIComponents';

export function PreviewZoomPanel() {
  const { zoomIn, zoomOut } = useReactFlow();
  return (
    <PanelCard>
      <PanelButton
        onClick={() => {
          zoomOut();
        }}
      >
        <MinusIcon className="size-5 path:fill-neutrals-700" />
      </PanelButton>

      <PanelButton
        onClick={() => {
          zoomIn();
        }}
      >
        <PlusIcon className="size-5 path:fill-neutrals-700" />
      </PanelButton>
    </PanelCard>
  );
}

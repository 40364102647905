import TemplateIcon from '@assets/template.svg';
import { ToolboxButton } from './ToolboxButton';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';

export function ToolboxTemplates() {
  const { openModal } = useGlobalModal();

  const handleClick = () => {
    openModal(MODALS.WhiteboardTemplatesModal);
  };

  return (
    <ToolboxButton
      label="Workflow Templates"
      onClick={handleClick}
      Icon={TemplateIcon}
      iconClassName="path:fill-blue-800"
    />
  );
}

import { useToolConfigs } from '@/api/tools/get-tool-configs';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { PlainTiptapEditor } from '@/components/PlainTiptapEditor';
import { DfFeature } from '@/enums/df-feature.enum';
import { Button, Label, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { turndownService } from '@/components/tiptap/extensions/DigitalFirst/turndown/turndown.service';
import { useAddResource } from '@/api/data-room/add-resource';
import { InputType } from '@/models/add-resource-payload.interface';

export const DataRoomAddTextModalBody = () => {
  const { toolConfigs } = useToolConfigs(DfFeature.FLOW);
  const { closeModal } = useGlobalModal();
  const { addResource } = useAddResource();

  const [doc, setDoc] = useState('');
  const [fileName, setFileName] = useState('note');

  const onUpdate = async () => {
    const markdownText = turndownService.turndown(doc);
    await addResource({
      type: InputType.File,
      file: btoa(unescape(encodeURIComponent(markdownText))),
      fileExtension: 'md',
      fileName: `${fileName}.md`,
    });

    closeModal();
  };

  return (
    toolConfigs && (
      <div className="modal-body">
        <div className="flex w-full">
          <Label className="w-full">
            <span>Your file name</span>
            <TextInput
              id="fileName"
              type="text"
              name="fileName"
              className="mt-1"
              onChange={(e) => setFileName(e.target.value)}
              defaultValue={fileName}
              placeholder="Enter file name"
            />
          </Label>
        </div>

        <Label className="w-full">
          <span>Your note</span>
        </Label>

        <div className="cursor-text rounded-lg border border-solid border-gray-divider">
          <PlainTiptapEditor
            onUpdate={(content) => setDoc(content)}
            toolConfigs={toolConfigs}
          />
        </div>

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
            onClick={() => onUpdate()}
          >
            Add
          </Button>
        </div>
      </div>
    )
  );
};

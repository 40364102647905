import { useState } from 'react';
import { finishEditingTool } from 'src/libs/tools/tool-output-editor.store';
import { ToolTextOutputEditor } from 'src/libs/tools/ToolTextOutputEditor';
import { ToolConfig, ToolField } from 'src/libs/tools/type.model';
import { mutateWhiteboardNodes } from 'src/routes/whiteboards/api/get-whiteboard-nodes';
import { useUpdateExecutionEngineNode } from 'src/routes/whiteboards/api/update-execution-engine-node';
import { useWhiteboardStore } from 'src/routes/whiteboards/store/whiteboard.store';
import { NodeData } from 'src/routes/whiteboards/types';

type ReaderTextFormProps = {
  reactFlowNodeId: string;
  nodeData: NodeData;
  toolConfigs?: ToolConfig[];
};

export function ReaderTextForm({ reactFlowNodeId, nodeData, toolConfigs }: ReaderTextFormProps) {
  const { updateFields } = useUpdateExecutionEngineNode();
  const setNodeValid = useWhiteboardStore((store) => store.setNodeValid);
  const whiteboardId = useWhiteboardStore((store) => store.whiteboardId);

  const [content, setContent] = useState(nodeData.output ? (nodeData.output as string) : '');

  const finishEditingOutput = async (newOutput: string) => {
    setContent(newOutput);

    const fields: ToolField[] = [
      {
        key: 'content',
        value: newOutput,
      },
    ];
    await updateFields({
      reactFlowNodeId,
      fields,
    });

    setNodeValid(reactFlowNodeId, true);
    await mutateWhiteboardNodes(whiteboardId);

    finishEditingTool();
  };

  const cancelEditingOutput = () => {
    setContent(nodeData.output as string);
    finishEditingTool();
  };

  if (!toolConfigs) return null;

  return (
    <div>
      <ToolTextOutputEditor
        initialOutput={content}
        onFinishEditing={finishEditingOutput}
        onCancelEditing={cancelEditingOutput}
        toolConfigs={toolConfigs}
      />
    </div>
  );
}

import GroupIcon from '@assets/group.svg';
import React from 'react';
import { generateId } from '../../helpers/node-helpers.ts';
import { DATA_TRANSFER_KEYS } from '../../hooks/useDropNodeCreator.ts';
import { useWhiteboardStore } from '../../store/whiteboard.store';
import { NodeTypesEnum } from '../../types.ts';
import { ToolboxItem } from './ToolboxItem';

export function ToolboxGroupNode() {
  const addGroupNode = useWhiteboardStore((state) => state.addGroupNode);
  const calculateNewNodePosition = useWhiteboardStore((state) => state.calculateNewNodePosition);
  const fitView = useWhiteboardStore((state) => state.fitView);

  const onDragStart = (event: React.DragEvent) => {
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.nodeType, NodeTypesEnum.Group);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleAdd = async () => {
    const id = generateId(NodeTypesEnum.Group);
    addGroupNode({ position: calculateNewNodePosition(), id });
    fitView([{ id }]);
  };

  return (
    <ToolboxItem
      onClick={handleAdd}
      onDragStart={onDragStart}
      icon={<GroupIcon className="path:stroke-neutrals-1000 rect:stroke-neutrals-1000" />}
      label="Group"
    />
  );
}

import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { useMemo, useRef } from 'react';
import DropdownIcon from '@assets/dropdown.svg';
import { useTypewriter } from '@/hooks/useTypewriter.ts';
import { AutoSizingTextarea } from '@/components/AutoSizingTextarea/AutoSizingTextarea.tsx';
import { buildUrl } from '@/helpers/build-url.ts';

export const OnboardingStarter = () => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const placeholder = useTypewriter({
    words: ['a marketing campaign for...', 'a creative idea for...', 'a persona canvas for...'],
    prefix: 'Ask DigitalFist AI for ',
  });
  // const [whiteboardLibrary, setWhiteboardLibrary] = useState<'featured' | 'newest' | 'df'>('featured');
  // const { publicWhiteboardTemplates } = usePublicWhiteboardTemplates();

  const envFrontendHostname = useMemo(() => {
    switch (import.meta.env.VITE_NODE_ENV) {
      case 'production':
        return 'https://v2.digitalfirst.ai';
      case 'uat':
        return 'https://uat-v2.digitalfirst.ai';
      default:
        return 'http://localhost:3005';
    }
  }, []);

  const formik = useFormik<{
    search: string;
    access: 'public' | 'private';
  }>({
    initialValues: {
      search: '',
      access: 'public',
    },
    validationSchema: object({
      search: string().required(),
    }),
    onSubmit: async ({ search }) => {
      window.open(envFrontendHostname + buildUrl([''], undefined, { whiteboardAgentPrompt: search! }), '_blank');
    },
  });

  const quickPrompts = [
    {
      title: 'Creative campaign',
      prompt: 'Create a creative campaign for Zalando',
    },
    {
      title: 'Marketing plan',
      prompt: 'Create a marketing plan to find first 10 customers for a marketing agency',
    },
    {
      title: 'Create a personas',
      prompt: 'Create 3 marketing personas for Spotify',
    },
    {
      title: 'Market trends',
      prompt: 'Analyze trends in electromobility industry',
    },
  ];

  return (
    <div className="h-svh w-full overflow-y-auto bg-[#090c1e] text-white">
      <div className="mx-auto mt-4 max-w-[600px]">
        <FormikProvider value={formik}>
          <div
            className="cursor-text flex-col gap-2 rounded-xl border border-neutrals-700 bg-neutrals-900 px-5 py-3"
            onClick={() => textareaRef.current?.focus()}
          >
            <AutoSizingTextarea
              id="search"
              value={formik.values.search}
              onChange={formik.handleChange}
              autoFocus
              className="reset-input block w-full flex-1 resize-none !border-none bg-transparent p-0 text-body-lg !outline-none outline-none !ring-0 placeholder:text-neutrals-500"
              autoComplete="off"
              placeholder={placeholder}
              ref={textareaRef}
              maxRows={3}
            />

            <div className="flex items-center justify-end gap-2">
              {/*<DropdownMenu*/}
              {/*  DropdownControl={*/}
              {/*    <DropdownMenuPanel className="w-[300px] border border-neutrals-700 !bg-neutrals-900">*/}
              {/*      <div className="flex flex-col gap-3">*/}
              {/*        <div>*/}
              {/*          <CheckboxWithLabel*/}
              {/*            name="accessPublic"*/}
              {/*            label={*/}
              {/*              <div className="flex items-center gap-1.5">*/}
              {/*                <div className="rounded bg-neutrals-800 p-1">*/}
              {/*                  <WebIcon className="size-2.5" />*/}
              {/*                </div>*/}
              {/*                <div className="mt-[1px] text-caption-sm text-white">Public</div>*/}
              {/*              </div>*/}
              {/*            }*/}
              {/*            labelClassName="!mb-1"*/}
              {/*            checked={formik.values.access === 'public'}*/}
              {/*            onChange={() => formik.setFieldValue('access', 'public')}*/}
              {/*            checkboxSize="size-4"*/}
              {/*          />*/}
              {/*          <div className="ml-[3.2rem] text-caption-sm text-neutrals-500">*/}
              {/*            Anyone can view and use template*/}
              {/*          </div>*/}
              {/*        </div>*/}

              {/*        <div>*/}
              {/*          <CheckboxWithLabel*/}
              {/*            name="accessPrivate"*/}
              {/*            label={*/}
              {/*              <div className="flex items-center gap-1.5">*/}
              {/*                <div className="rounded bg-neutrals-800 p-1">*/}
              {/*                  <PlayIcon className="size-2.5" />*/}
              {/*                </div>*/}
              {/*                <div className="mt-[1px] text-caption-sm text-white">Private</div>*/}
              {/*                <div className="rounded-md bg-neutrals-200 px-2 py-1 text-button-sm text-neutrals-1000">*/}
              {/*                  STARTER*/}
              {/*                </div>*/}
              {/*              </div>*/}
              {/*            }*/}
              {/*            labelClassName="!mb-1"*/}
              {/*            checked={formik.values.access === 'private'}*/}
              {/*            onChange={() => formik.setFieldValue('access', 'private')}*/}
              {/*            checkboxSize="size-4"*/}
              {/*          />*/}
              {/*          <div className="ml-[3.2rem] text-caption-sm text-neutrals-500">*/}
              {/*            Only you and collaborators can access*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </div>*/}
              {/*    </DropdownMenuPanel>*/}
              {/*  }*/}
              {/*  placement="bottom-start"*/}
              {/*>*/}
              {/*  {(props) => (*/}
              {/*    <button*/}
              {/*      className="flex min-w-[73px] gap-1 rounded-lg p-2 text-caption-sm transition hover:bg-neutrals-800 active:bg-neutrals-700"*/}
              {/*      {...props}*/}
              {/*    >*/}
              {/*      {formik.values.access === 'public' ? (*/}
              {/*        <>*/}
              {/*          <WebIcon className="size-3" /> Public*/}
              {/*        </>*/}
              {/*      ) : (*/}
              {/*        <>*/}
              {/*          <PlayIcon className="size-3" /> Private*/}
              {/*        </>*/}
              {/*      )}*/}
              {/*    </button>*/}
              {/*  )}*/}
              {/*</DropdownMenu>*/}

              <button
                onClick={formik.submitForm}
                disabled={!formik.isValid}
                className="group flex size-[34px] cursor-pointer items-center justify-center rounded-xl bg-primary-600"
              >
                <img
                  src="/arrow-right-white.svg"
                  alt=""
                  className="size-4 transition-transform group-hover:translate-x-1"
                />
              </button>
            </div>
          </div>
        </FormikProvider>

        <div className="mt-5 flex flex-wrap justify-center gap-3 text-caption-sm">
          {quickPrompts.map((quickPrompt, i) => (
            <button
              key={i}
              onClick={() => {
                formik.setFieldValue('search', quickPrompt.prompt);
                textareaRef.current?.focus();
              }}
              className="flex items-center gap-0.5 rounded-full border border-neutrals-700 bg-neutrals-900 py-1 pl-3 pr-2 transition hover:bg-neutrals-800 active:bg-neutrals-700"
            >
              <span>{quickPrompt.title}</span>
              <DropdownIcon className="size-4 -rotate-90 stroke-white" />
            </button>
          ))}
        </div>
      </div>

      {/*{!!publicWhiteboardTemplates && (*/}
      {/*  <>*/}
      {/*    <div className="mx-auto mt-[130px] flex w-fit max-w-full rounded-lg border border-neutrals-700 p-[3px] text-caption-md">*/}
      {/*      <button*/}
      {/*        className={cn(*/}
      {/*          'flex w-[8.25rem] items-center justify-center gap-2 rounded-md py-1.5 transition hover:bg-neutrals-900 active:bg-neutrals-800',*/}
      {/*          { 'bg-neutrals-900': whiteboardLibrary === 'featured' },*/}
      {/*        )}*/}
      {/*        onClick={() => setWhiteboardLibrary('featured')}*/}
      {/*      >*/}
      {/*        <StarIcon />*/}
      {/*        <div>Featured</div>*/}
      {/*      </button>*/}

      {/*      <button*/}
      {/*        className={cn(*/}
      {/*          'flex w-[8.25rem] items-center justify-center gap-2 rounded-md py-1.5 transition hover:bg-neutrals-900 active:bg-neutrals-800',*/}
      {/*          { 'bg-neutrals-900': whiteboardLibrary === 'newest' },*/}
      {/*        )}*/}
      {/*        onClick={() => setWhiteboardLibrary('newest')}*/}
      {/*      >*/}
      {/*        <RunIcon className="size-4 *:fill-white" />*/}
      {/*        <div>Newest</div>*/}
      {/*      </button>*/}

      {/*      <button*/}
      {/*        className={cn(*/}
      {/*          'flex w-[8.25rem] items-center justify-center gap-2 rounded-md py-1.5 transition hover:bg-neutrals-900 active:bg-neutrals-800',*/}
      {/*          { 'bg-neutrals-900': whiteboardLibrary === 'df' },*/}
      {/*        )}*/}
      {/*        onClick={() => setWhiteboardLibrary('df')}*/}
      {/*      >*/}
      {/*        <GenerateIcon className="size-4" />*/}
      {/*        <div>DF Templates</div>*/}
      {/*      </button>*/}
      {/*    </div>*/}

      {/*    <div className="mx-auto mt-5 grid max-w-[1000px] grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4">*/}
      {/*      {publicWhiteboardTemplates.map((template, index) => (*/}
      {/*        <OnboardingWhiteboardTemplateCard*/}
      {/*          template={template}*/}
      {/*          key={index}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  );
};

import EditIcon from '@assets/edit.svg';
import { setCurrentlyEditingToolId } from 'src/libs/tools/tool-output-editor.store.ts';
import { ExecutionEngineNode, NodeData, NodeType } from '../../../types.ts';
import { OutputHistorySwitcher } from '../../OutputHistorySwitcher.tsx';

interface RenderTextFooterProps {
  nodeId: NodeType['id'];
  nodeData: NodeData;
  executionEngineNode: ExecutionEngineNode;
}

export const RenderTextFooter = ({ nodeId, nodeData, executionEngineNode }: RenderTextFooterProps) => {
  function enableEditMode() {
    setCurrentlyEditingToolId(executionEngineNode.tool.id);
  }

  return (
    <div className="flex justify-end p-2">
      <OutputHistorySwitcher
        executionEngineNode={executionEngineNode}
        nodeId={nodeId}
        nodeData={nodeData}
      />
      <button
        onClick={enableEditMode}
        className="rounded-lg p-2 hover:bg-neutrals-100"
      >
        <EditIcon className="size-5 path:fill-neutrals-600" />
      </button>
    </div>
  );
};

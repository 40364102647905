import { SideModalBodyWithHeader } from '@/components/SideModalBodyWithHeader.tsx';
import { TileCheckbox } from '@/components/TileCheckbox.tsx';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { useEffect } from 'react';
import { Button } from 'flowbite-react';

interface GenericCanvasGenerationContextSideModalBodyProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  dataRoomItems: DataRoomItem[];
  submitButtonText: string;
}

export const GenericCanvasGenerationContextSideModalBody = ({
  formik,
  dataRoomItems,
}: GenericCanvasGenerationContextSideModalBodyProps) => {
  useEffect(() => {
    // set all data room items as selected by default
    if (formik.values.generationContextItemsIds.length === 0) {
      formik.setFieldValue(
        'generationContextItemsIds',
        dataRoomItems.map((item) => item.id),
      );
    }
  }, [dataRoomItems]);

  const areAllItemsSelected = formik.values.generationContextItemsIds.length === dataRoomItems.length;

  return (
    <>
      <SideModalBodyWithHeader
        header="Context"
        subheader="Define what data you want to use for canvas generation"
      >
        <div className="grow overflow-y-scroll p-6">
          <button
            type="button"
            onClick={() => {
              if (areAllItemsSelected) {
                formik.setFieldValue('generationContextItemsIds', []);
              } else {
                formik.setFieldValue(
                  'generationContextItemsIds',
                  dataRoomItems.map((item) => item.id),
                );
              }
            }}
            className="link link--md mb-4 ml-auto hover:underline"
          >
            {areAllItemsSelected ? 'Deselect all' : 'Select all'}
          </button>

          <div className="flex flex-col gap-3">
            {dataRoomItems
              .filter((item) => item.isParsed)
              .map((dataRoomItem) => (
                <TileCheckbox
                  key={dataRoomItem.id}
                  checked={formik.values.generationContextItemsIds.includes(dataRoomItem.id)}
                  size="sm"
                  onClick={() => {
                    if (formik.values.generationContextItemsIds.includes(dataRoomItem.id)) {
                      formik.setFieldValue(
                        'generationContextItemsIds',
                        formik.values.generationContextItemsIds.filter((itemId: string) => itemId !== dataRoomItem.id),
                      );
                    } else {
                      formik.setFieldValue('generationContextItemsIds', [
                        ...formik.values.generationContextItemsIds,
                        dataRoomItem.id,
                      ]);
                    }
                  }}
                >
                  <img
                    src={dataRoomItem.icon}
                    alt=""
                  />
                  <span className="text-subtitle-sm">{dataRoomItem.name}</span>
                </TileCheckbox>
              ))}
          </div>
        </div>

        <div className="flex flex-col rounded-b-2xl p-6">
          <Button
            color="primary"
            className="mt-8 w-full"
            type="submit"
            onClick={formik.submitForm}
            isProcessing={formik.isSubmitting}
            disabled={formik.isSubmitting || formik.values.generationContextItemsIds.length === 0}
          >
            Save changes
          </Button>
        </div>
      </SideModalBodyWithHeader>
    </>
  );
};

import { WebSearcherPanel } from './components/WebSearcherPanel.tsx';
import { WebSearcherNewSearchForm } from './components/WebSearcherNewSearchForm.tsx';
import { useWebSearcherRooms } from '@/api/websearcher/get-web-searcher-rooms.ts';
import { useEffect } from 'react';
import { confettiBoom } from '@/components/GlobalConfetti/global-confetti.store.ts';
import { useWebSearcherMenuStore } from './web-searcher.store.ts';
import { WebSearcherPageHeader } from '@/components/WebSearcherPageHeader.tsx';
import { cn } from '@/helpers/cn.ts';
import { inIframe } from '@/components/tiptap/extensions/DigitalFirst/helpers/inIframe.ts';

export function WebSearcherPage({ onboardingMode = false }: { onboardingMode?: boolean }) {
  const { webSearcherRooms } = useWebSearcherRooms();
  const { setIsMenuOpen } = useWebSearcherMenuStore();

  useEffect(() => {
    if (webSearcherRooms && onboardingMode) {
      confettiBoom();
    }

    if (!onboardingMode) {
      setIsMenuOpen(true);
    }
  }, [webSearcherRooms]);

  if (!webSearcherRooms) {
    return;
  }

  return (
    <>
      <title>Digitalfirst.ai | WebSearcher</title>
      <main
        className={cn('flex h-svh w-full flex-col', {
          'bg-neutrals-200': !inIframe(),
        })}
      >
        <WebSearcherPageHeader />
        <div className="flex h-[calc(100svh-66px)] pb-3 pr-3">
          {webSearcherRooms.length ? (
            <WebSearcherPanel
              webSearcherRooms={webSearcherRooms}
              onboardingMode={onboardingMode}
            />
          ) : (
            <WebSearcherNewSearchForm />
          )}
        </div>
      </main>
    </>
  );
}

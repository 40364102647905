import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Table from './Table';
import { DataColumn, TableViewConfig } from './table.inferfaces';
import {
  DynamicRowData,
  useRefillTableRows,
  useTableDefinitionApi,
  useTableLoadData,
  useUpdateTableColumns,
  useUpdateTableView,
} from '../api/tables';
import Loader from '@/components/Loader';
import { useDebouncedCallback } from 'use-debounce';
import { useDataSync } from './useDataSync';

export default function SmartTable({
  tableId,
  onRowSelectionChange,
}: {
  tableId: string;
  onRowSelectionChange?: (rows: DynamicRowData[]) => void;
}) {
  const [data, setData] = useState<DynamicRowData[]>([]);

  const loadTableDefinition = useTableDefinitionApi();
  const loadTableData = useTableLoadData();
  const updateViewConfig = useUpdateTableView();
  const updateColumns = useUpdateTableColumns();
  const refillAllRows = useRefillTableRows();
  const [loading, setLoading] = useState(false);

  const tableIdRef = useRef<string | undefined>(undefined);
  const dataSync = useDataSync(tableId, 800);

  const debouncedUpdateViewConfig = useDebouncedCallback((value, id) => {
    updateViewConfig(id, value);
  }, 800);
  const debouncedUpdateColumns = useDebouncedCallback((value, id) => {
    updateColumns(id, value);
  }, 800);

  const [columns, setColumns] = useState<DataColumn[]>([]);
  const [viewConfig, setViewConfig] = useState<TableViewConfig>({
    sorting: [],
    columnSizing: {},
    columnOrder: [],
    columnVisibility: {},
    columnFilters: [],
    rowSelection: {},
  });

  const handleUpdateViewConfig = useCallback(
    (value: TableViewConfig) => {
      const selectedRowsIndexes = Object.keys(value.rowSelection!);
      if (onRowSelectionChange && selectedRowsIndexes.length) {
        onRowSelectionChange(selectedRowsIndexes.map((index) => data[parseInt(index, 10)]));
      }

      setViewConfig(value);
      debouncedUpdateViewConfig(value, tableId);
    },
    [debouncedUpdateViewConfig, tableId, data, onRowSelectionChange],
  );

  const handleUpdateColumns = useCallback(
    (value: SetStateAction<DataColumn[]>) => {
      if (value instanceof Function) {
        setColumns((oldColumns) => {
          const newColumns = value(oldColumns);
          debouncedUpdateColumns(newColumns, tableId);
          return newColumns;
        });
      } else {
        setColumns(value);
        debouncedUpdateColumns(value, tableId);
      }
    },
    [debouncedUpdateColumns, tableId],
  );

  const reloadTableDefinition = useCallback(
    async (tableId: string) => {
      setLoading(true);

      if (tableId) {
        const tableDefinition = await loadTableDefinition(tableId);
        setColumns(tableDefinition?.columnsDef || []);

        const reloadedViewConfig = {
          sorting: [],
          columnSizing: {},
          columnOrder: [],
          columnVisibility: {},
          columnFilters: [],
          rowSelection: {},
          ...(tableDefinition?.viewConfig || {}),
        };

        for (const column of columns) {
          reloadedViewConfig.columnSizing[column.id] = reloadedViewConfig.columnSizing[column.id] || 100;
        }

        setViewConfig(reloadedViewConfig);

        tableIdRef.current = tableId;

        setData((await loadTableData(tableId)) || []);

        setLoading(false);
      }
    },
    [setViewConfig, setLoading, setColumns, loadTableDefinition],
  );

  useEffect(() => {
    if (tableIdRef.current !== tableId) {
      if (tableIdRef.current) {
        debouncedUpdateViewConfig.flush();
        debouncedUpdateColumns.flush();
        dataSync.flush();
      }

      reloadTableDefinition(tableId);
    }

    return () => {
      if (tableIdRef.current) {
        debouncedUpdateViewConfig.flush();
        debouncedUpdateColumns.flush();
        dataSync.flush();
      }
    };
  }, [tableId]);

  const handleRowsDelete = useCallback(
    (rows: DynamicRowData[]) => {
      for (const row of rows) {
        dataSync.removeRow(row);
      }
    },
    [dataSync],
  );

  const handleRowUpdate = useCallback(
    (row: DynamicRowData) => {
      dataSync.updateRow(row);
    },
    [dataSync],
  );

  const handleRefillAllRows = useCallback(
    (rows: DynamicRowData[]) => {
      refillAllRows(tableId, rows);
    },
    [dataSync],
  );

  return (
    viewConfig !== undefined &&
    columns !== undefined && (
      <Loader isLoading={loading}>
        <Table
          data={data}
          columns={columns}
          viewConfig={viewConfig}
          onViewConfigChange={handleUpdateViewConfig}
          onColumnsChange={handleUpdateColumns}
          onRowsDelete={handleRowsDelete}
          onRowUpdate={handleRowUpdate}
          handleRefillAllRows={handleRefillAllRows}
          onDataChange={setData}
        />
      </Loader>
    )
  );
}

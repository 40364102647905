import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';
import { useCallback, useContext, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { v4 as uuid } from 'uuid';

import * as Dropdown from '@radix-ui/react-dropdown-menu';

import { Button } from '@/components/tiptap/components/ui/Button';
import { Loader } from '@/components/tiptap/components/ui/Loader';
import { Panel, PanelHeadline } from '@/components/tiptap/components/ui/Panel';
import { Textarea } from '@/components/tiptap/components/ui/Textarea';
import { Icon } from '@/components/tiptap/components/ui/Icon';
import { Surface } from '@/components/tiptap/components/ui/Surface';
import { DropdownButton } from '@/components/tiptap/components/ui/Dropdown';
import { Toolbar } from '@/components/tiptap/components/ui/Toolbar';
import { ToolsContext } from 'src/libs/tools/ToolsContext';
import { ToolName } from 'src/libs/tools/type.model';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { getToolConfigsByCategory } from '../../../../../../libs/tools/getToolConfigsByCategory.ts';
import { useExecuteTool } from '@/api/tools/execute-tool.ts';

const imageStyles = [
  { name: 'photorealistic', label: 'Photorealistic', value: 'photorealistic' },
  { name: 'digital-art', label: 'Digital art', value: 'digital_art' },
  { name: 'comic-book', label: 'Comic book', value: 'comic_book' },
  { name: 'neon-punk', label: 'Neon punk', value: 'neon_punk' },
  { name: 'isometric', label: 'Isometric', value: 'isometric' },
  { name: 'line-art', label: 'Line art', value: 'line_art' },
  { name: '3d-model', label: '3D model', value: '3d_model' },
];

interface Data {
  text: string;
  imageStyle?: string;
}

export const AiImageView = ({ editor, getPos, deleteNode }: NodeViewWrapperProps) => {
  const [data, setData] = useState<Data>({
    text: '',
    imageStyle: undefined,
  });
  const currentImageStyle = imageStyles.find((t) => t.value === data.imageStyle);
  const [previewImage, setPreviewImage] = useState<string | undefined>(undefined);
  const [isFetching, setIsFetching] = useState(false);
  const textareaId = useMemo(() => uuid(), []);

  const toolConfigs = useContext(ToolsContext);
  const toolConfigsByCategory = getToolConfigsByCategory(toolConfigs!.tools!);
  const { executeTool } = useExecuteTool();
  const { listenerOn } = useWebSocketMessageListener();

  const generateImage = useCallback(async () => {
    if (!data.text) {
      toast.error('Please enter a description for the image');

      return;
    }

    setIsFetching(true);

    try {
      const toolConfig = toolConfigsByCategory.IMAGEGEN.find((tool) => tool.name === ToolName.PROMPT_IMAGE_SD_MODELS);
      const wsChannel = `${toolConfig?.name}: ${uuid()}`;
      let prompt = data.text;

      if (data.imageStyle) {
        prompt += `Use style ${data.imageStyle}`;
      }

      try {
        await executeTool({
          toolName: toolConfig!.name,
          wsChannel,
          fields: [
            {
              key: 'prompt',
              value: prompt,
            },
            {
              key: 'model',
              value: 'sd3-medium',
            },
            {
              key: 'aspect_ratio',
              value: '1:1',
            },
          ],
        });
      } catch {
        return; // error is handled by WebSocketErrorListener
      }

      await new Promise((resolve) => {
        const { listenerOff } = listenerOn(wsChannel, (data: string[]) => {
          setPreviewImage((import.meta.env.VITE_ASSETS + data[0]) as string);
          listenerOff();
          setIsFetching(false);
          resolve(true);
        });
      });
    } catch (errPayload) {
      setIsFetching(false);
      toast.error(errPayload as string);
    }
  }, [data.imageStyle, data.text]);

  const insert = useCallback(() => {
    if (!previewImage?.length) {
      return;
    }

    editor
      .chain()
      .insertContent(`<img src="${previewImage}" alt="" />`)
      .deleteRange({ from: getPos(), to: getPos() })
      .focus()
      .run();

    setIsFetching(false);
  }, [editor, previewImage, getPos]);

  const discard = useCallback(() => {
    deleteNode();
  }, [deleteNode]);

  const handleTextareaChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => setData((prevData) => ({ ...prevData, text: e.target.value })),
    [],
  );

  const onUndoClick = useCallback(() => {
    setData((prevData) => ({ ...prevData, imageStyle: undefined }));
    setPreviewImage(undefined);
  }, []);

  const createItemClickHandler = useCallback((style: { name: string; label: string; value: string }) => {
    return () => setData((prevData) => ({ ...prevData, imageStyle: style.value }));
  }, []);

  return (
    <NodeViewWrapper data-drag-handle>
      <Panel
        noShadow
        className="w-full"
      >
        <div className="flex flex-col p-1">
          {isFetching && <Loader label="AI is now doing its job!" />}
          {previewImage && (
            <>
              <PanelHeadline>Preview</PanelHeadline>
              <div
                className="mb-4 aspect-square w-full rounded border border-black bg-white bg-contain bg-center bg-no-repeat dark:border-neutral-700"
                style={{ backgroundImage: `url(${previewImage})` }}
              />
            </>
          )}
          <div className="row flex items-center justify-between gap-2">
            <PanelHeadline asChild>
              <label htmlFor={textareaId}>Prompt</label>
            </PanelHeadline>
          </div>
          <Textarea
            id={textareaId}
            value={data.text}
            onChange={handleTextareaChange}
            placeholder={`Describe the image that you want me to generate.`}
            required
            className="mb-2"
          />
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="flex w-auto justify-between gap-1">
              <Dropdown.Root>
                <Dropdown.Trigger asChild>
                  <Button variant="tertiary">
                    <Icon name="Image" />
                    {currentImageStyle?.label || 'Image style'}
                    <Icon name="ChevronDown" />
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Portal>
                  <Dropdown.Content
                    side="bottom"
                    align="start"
                    asChild
                  >
                    <Surface className="min-w-[12rem] p-2">
                      {!!data.imageStyle && (
                        <>
                          <DropdownButton
                            isActive={data.imageStyle === undefined}
                            onClick={onUndoClick}
                          >
                            <Icon name="Undo2" />
                            Reset
                          </DropdownButton>
                          <Toolbar.Divider horizontal />
                        </>
                      )}
                      {imageStyles.map((style) => (
                        <DropdownButton
                          isActive={style.value === data.imageStyle}
                          key={style.value}
                          onClick={createItemClickHandler(style)}
                        >
                          {style.label}
                        </DropdownButton>
                      ))}
                    </Surface>
                  </Dropdown.Content>
                </Dropdown.Portal>
              </Dropdown.Root>
            </div>
            <div className="flex flex-row items-center justify-between gap-1">
              {previewImage && (
                <Button
                  variant="ghost"
                  className="text-red-500 hover:bg-red-500/10 hover:text-red-500"
                  onClick={discard}
                >
                  <Icon name="Trash" />
                  Discard
                </Button>
              )}
              {previewImage && (
                <Button
                  variant="ghost"
                  onClick={insert}
                >
                  <Icon name="Check" />
                  Insert
                </Button>
              )}
              <Button
                variant="primary"
                onClick={generateImage}
              >
                {previewImage ? <Icon name="Repeat" /> : <Icon name="Sparkles" />}
                {previewImage ? 'Regenerate' : 'Generate image'}
              </Button>
            </div>
          </div>
        </div>
      </Panel>
    </NodeViewWrapper>
  );
};

import { ReactElement } from 'react';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { Dropdown } from 'flowbite-react';
import { Asset } from '@/models/asset.model.ts';
import { useDeleteAsset } from '@/api/assets/useDeleteAsset.ts';

export function DropdownAssetCardOptions({ asset, children }: { asset: Asset; children: ReactElement }) {
  const { openDialog } = useGlobalDialog();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { deleteAsset } = useDeleteAsset();

  const deleteClick = () => {
    openDialog(DIALOGS.DeleteAsset, async () => {
      showLoader();
      await deleteAsset(asset.id);
      hideLoader();
    });
  };

  return (
    <Dropdown
      label=""
      onClick={(e) => e.stopPropagation()}
      dismissOnClick={false}
      renderTrigger={() => children}
      placement="bottom-start"
      className="!fixed"
    >
      <Dropdown.Item onClick={() => deleteClick()}>
        <img
          src="/archive-black.svg"
          alt=""
        />
        Delete
      </Dropdown.Item>
    </Dropdown>
  );
}

import { IconType } from '@/components/Icon';
import { cn } from '@/helpers/cn';
import SearchIcon from '@assets/search-check-color.svg';

type ToolboxButtonProps = {
  label: string;
  onClick: () => void;
  Icon?: IconType;
  iconClassName?: string;
};

export function ToolboxButton({ label, onClick, Icon, iconClassName }: ToolboxButtonProps) {
  return (
    <button
      className="mt-1.5 flex w-full items-center justify-between rounded-xl border border-neutrals-300 p-2 transition hover:bg-neutrals-100"
      onClick={onClick}
    >
      <div className="flex items-center justify-between gap-3">
        <div className="rounded-lg bg-neutrals-100 p-2.5">
          {Icon ? <Icon className={cn('size-4', iconClassName)} /> : <SearchIcon className="size-4" />}
        </div>
        <div className="text-caption-md">{label}</div>
      </div>
    </button>
  );
}

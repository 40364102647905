import { NodeData, NodeType } from '../../types.ts';
import { NodeInputSocket } from './NodeInputSocket.tsx';
import { useWhiteboardStore } from '../../store/whiteboard.store.ts';

type NodeInputSocketsProps = {
  nodeData: NodeData;
  nodeId: NodeType['id'];
};

export function NodeInputSockets({ nodeData, nodeId }: NodeInputSocketsProps) {
  const inputSockets = Object.entries(nodeData.toolConfig.config.input).filter(([, input]) => input.canBeGeneratedBy);
  const isOneSocket = inputSockets.length === 1;
  const node = useWhiteboardStore((state) => state.nodes).find((node) => node.id === nodeId)!;

  return (
    <div className="absolute left-0 top-0 ml-4 flex h-full flex-col justify-evenly">
      {inputSockets.map(([name, input]) => (
        <NodeInputSocket
          key={name}
          name={name}
          node={node}
          socketType={input.canBeGeneratedBy!}
          isOneSocket={isOneSocket}
        />
      ))}
    </div>
  );
}

import { useApi } from '@/api/api.ts';
import { ExecuteToolPayload } from '../../../libs/tools/type.model.ts';
import { mutate } from 'swr';

export const useExecuteTool = () => {
  const { postFetcher } = useApi({ closeModalOnError: false });

  const executeTool = async (data: ExecuteToolPayload): Promise<{ wsChannel: string; toolId: number }> => {
    const response = await postFetcher<ExecuteToolPayload>('/tools/execute', { arg: data });
    setTimeout(() => mutate('/plan-limit/quota'), 500);
    return response;
  };

  return {
    executeTool,
  };
};

import useSWR from 'swr';
import { useApi } from '../api';
import { TiptapDocument } from '@/models/tiptap-document.interface.ts';

export const useTiptapDocument = (
  id?: number,
): {
  tiptapDocument: TiptapDocument;
  isLoading: boolean;
  error: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, error, isLoading } = useSWR(`/tiptap-document/${id}`, getFetcher);

  return {
    tiptapDocument: data,
    isLoading,
    error,
  };
};

export const useGetTiptapDocument = () => {
  const { getFetcher } = useApi();
  const getTiptapDocument = async (id: number) => {
    return await getFetcher(`/tiptap-document/${id}`);
  };

  return {
    getTiptapDocument,
  };
};

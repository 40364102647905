import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { Tool, ToolConfig } from '../../../../../libs/tools/type.model';
import { ReaderFileForm } from '../RenderFileForm.tsx';

interface ReaderDataRoomFormProps {
  reactFlowNodeId: string;
  toolConfig: ToolConfig;
  tool?: Tool;
}

export const ReaderDataRoomForm = ({ reactFlowNodeId, toolConfig, tool }: ReaderDataRoomFormProps) => {
  const { dataRoomItems } = useDataRoomItems();

  return (
    <ReaderFileForm
      reactFlowNodeId={reactFlowNodeId}
      toolConfig={toolConfig}
      items={dataRoomItems.filter((item) => item.isParsed && !item.isErrored)}
      tool={tool}
      label="Data Room Item"
    />
  );
};

import DataRoomIcon from '@assets/data-room.svg';
import ImageIcon from '@assets/image.svg';
import ResearchIcon from '@assets/research.svg';
import SearchIcon from '@assets/search-check-color.svg';
import ToolTextIcon from '@assets/tool-text.svg';
import FacebookIcon from '@assets/facebook.svg';
import InstagramIcon from '@assets/instagram.svg';
import { ToolCategory, ToolConfig, ToolName } from 'src/libs/tools/type.model.ts';
import { AssetName } from '@/models/asset.model.ts';

const toolCategoryConfigs = {
  [ToolCategory.TEXTGEN]: {
    label: 'Text models',
    icon: ToolTextIcon,
  },
  [ToolCategory.IMAGEGEN]: {
    label: 'Image',
    icon: ImageIcon,
  },
  [ToolCategory.RESEARCHGEN]: {
    label: 'Research',
    icon: ResearchIcon,
  },
  [ToolCategory.OTHER]: {
    label: 'Integrations',
    icon: SearchIcon,
  },
  [ToolCategory.SEMRUSH]: {
    label: 'Semrush models',
    icon: SearchIcon,
  },
  [ToolCategory.ASSETS]: {
    label: 'Assets',
    icon: DataRoomIcon,
  },
} as const;

const toolIcons: Partial<Record<ToolName, typeof ImageIcon>> = {
  [ToolName.TEXT_BASE_MODELS]: ToolTextIcon,
  [ToolName.ASK_VISION_MODEL]: ImageIcon,
  [ToolName.ASK_INPUT]: DataRoomIcon,
};

const assetIcons: Record<AssetName, typeof ImageIcon> = {
  [AssetName.FACEBOOK_ADS]: FacebookIcon,
  [AssetName.INSTAGRAM_ADS]: InstagramIcon,
  [AssetName.FACEBOOK_CAROUSEL_ADS]: FacebookIcon,
};

export function getToolCategoryConfig(category: ToolCategory) {
  return toolCategoryConfigs[category];
}

export function getToolIcon(tool: ToolConfig) {
  return toolIcons[tool.name] || toolCategoryConfigs[tool.category].icon;
}

export function getAssetIconByAssetName(assetName: AssetName): typeof ImageIcon {
  return assetIcons[assetName];
}

import useSWR from 'swr';
import { useApi } from '../api.ts';
import { Permission } from '@/enums/permission.enum.ts';

export const usePermissions = () => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR('token-payload', getFetcher);

  const permissions: Permission[] = data?.permissions ?? [];
  const canCreateFlowsLibrary = permissions.includes(Permission.CAN_CREATE_FLOWS_LIBRARY);
  const canDeleteFlowsLibrary = permissions.includes(Permission.CAN_DELETE_FLOWS_LIBRARY);
  const canEditFlowsLibrary = permissions.includes(Permission.CAN_EDIT_FLOWS_LIBRARY);

  return {
    canCreateFlowsLibrary,
    canDeleteFlowsLibrary,
    canEditFlowsLibrary,
    isLoading,
    error,
  };
};

import { usePinFlowToProject } from '@/api/flow/pin-flow-to-project';
import { useCreateProject } from '@/api/projects/create-project.ts';
import { useProjects } from '@/api/projects/get-projects';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { setFlowInstance } from '@/helpers/flowInstance';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { FlowTemplate } from '@/models/flow-template.interface';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { Button, Label, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { WorkspaceProjectsSelector } from '../../project-details/components/WorkspaceProjectsSelector';

export function AddFlowToProjectModalBody() {
  const navigate = useNavigate();
  const { projects } = useProjects();
  const currentWorkspace = useCurrentWorkspace();
  const { createProject } = useCreateProject();
  const { pinFlowToProject } = usePinFlowToProject();
  const { closeModal, context } = useGlobalModal<FlowTemplate>();
  const { showLoader, hideLoader } = useGlobalLoader();

  async function handleSubmit(data: { name?: string; projectId?: number }) {
    if (!data.projectId && !data.name) {
      toast.error('Select project or create a new one');
      return;
    }
    showLoader();

    let projectId = data.projectId;
    if (data.name) {
      const project = await createProject(data.name);
      projectId = project.id;
    }

    const flow = await pinFlowToProject(context.id, projectId!);
    setFlowInstance(flow.id);
    closeModal();
    navigate(buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.FLOW_EDITOR], { projectId: projectId!, flowId: flow.id }));
    hideLoader();
  }

  const formik = useFormik<{ name?: string; projectId?: number }>({
    initialValues: {
      name: '',
      projectId: undefined,
    },
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <Label
          htmlFor="name"
          value="New project"
        />
        <TextInput
          {...getDfFlowbiteFieldProps('name', formik)}
          placeholder="Project name"
        />

        <div className="mt-6">
          <WorkspaceProjectsSelector
            currentWorkspace={currentWorkspace}
            projects={projects}
            formik={formik}
            fieldName="projectId"
          />
        </div>

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Add
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}

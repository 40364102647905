import { useSegmentPersonasForReaderTool } from 'src/routes/whiteboards/hooks/useSegmentPersonasForReaderTool.ts';
import { Tool, ToolConfig } from '../../../../../libs/tools/type.model';
import { ReaderFileForm } from '../RenderFileForm.tsx';

interface ReaderPersonaFormProps {
  reactFlowNodeId: string;
  toolConfig: ToolConfig;
  tool?: Tool;
}

export const ReaderPersonaForm = ({ reactFlowNodeId, toolConfig, tool }: ReaderPersonaFormProps) => {
  const { segmentsWithPersonas } = useSegmentPersonasForReaderTool();

  return (
    <ReaderFileForm
      reactFlowNodeId={reactFlowNodeId}
      toolConfig={toolConfig}
      items={segmentsWithPersonas.flatMap((segmentWithPersonas) => segmentWithPersonas.personas)}
      tool={tool}
      label="Persona"
    />
  );
};

import { useApi } from '@/api/api';
import { Whiteboard } from '../types';

export const useSetWhiteboardOwner = () => {
  const { patchFetcher } = useApi();

  const setWhiteboardOwner = async (arg: { uuids: string[]; projectId: number }): Promise<Whiteboard[]> => {
    return await patchFetcher('/whiteboard/set-owner', {
      arg: {
        uuids: arg.uuids,
        projectId: arg.projectId,
      },
    });
  };

  return {
    setWhiteboardOwner,
  };
};

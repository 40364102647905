export const convertToNewTool = (toolInput: unknown) => {
  if (Array.isArray(toolInput)) {
    return toolInput;
  }

  const input = [];

  for (const key in toolInput as object) {
    input.push({
      key,
      value: (toolInput as Record<string, string>)[key],
    });
  }

  return input;
};

export const URL_REGEXP =
  /^((https?):\/\/)?(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,3}(\/[a-zA-Z0-9#-_]*)*(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+(&[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+)*)?\/?$/;

export const YOUTUBE_URL_REGEXP =
  /^(https?:\/\/)?(www\.|m\.)?(youtube\.com\/(watch\?v=|shorts\/|embed\/|playlist\?list=)|youtu\.be\/)[a-zA-Z0-9_-]+(&[a-zA-Z0-9#?&%=._-]*)?$/;

export const IMAGE_URL_REGEXP = /\.(jpeg|jpg|png|gif|webp|bmp|svg)(\?.*)?$/i;

export const INSTAGRAM_URL_REGEXP = /^(https?:\/\/)?(www\.)?(m\.)?instagram\.com(\/|$)/i;

export const YOUTUBE_VIDEO_URL_REGEXP =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&].*)?$/;

import { ReactNode, useEffect } from 'react';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { useAuth } from '@/hooks/auth/useAuth';
import { useErrorComposer } from '@/api/error-composer.ts';

interface ErrorWithStatus extends Error {
  status: number;
  message: string;
}

export function WebSocketErrorListener({ children }: { children: ReactNode }) {
  const { listenerOn } = useWebSocketMessageListener();
  const { user } = useAuth();
  const { errorComposer } = useErrorComposer();

  useEffect(() => {
    if (!user?.sub) return;

    const { listenerOff } = listenerOn(`ws-error:${user.sub}`, (error: ErrorWithStatus) => {
      let status = 500;
      let message = 'Something went wrong. Please try again later.';

      if (error.status) {
        status = error.status;
      }

      if (error.message) {
        message = error.message;
      }

      errorComposer(status, message);
    });

    return () => {
      listenerOff();
    };
  }, [user?.sub]);

  return <>{children}</>;
}

import RedoIcon from '@assets/redo.svg';
import UndoIcon from '@assets/undo.svg';
import { useKeyPress } from '@xyflow/react';
import { useEffect } from 'react';
import { useWhiteboardStore } from '../../store/whiteboard.store';
import { PanelButton, PanelCard } from './PanelUIComponents';

export function UndoPanel() {
  const undo = useWhiteboardStore((state) => state.undo);
  const redo = useWhiteboardStore((state) => state.redo);
  const canUndo = useWhiteboardStore((state) => state.canUndo());
  const canRedo = useWhiteboardStore((state) => state.canRedo());
  const cmdZPressed = useKeyPress(['Meta+z', 'Strg+z']);
  const cmdYPressed = useKeyPress(['Meta+y', 'Strg+y']);

  useEffect(() => {
    if (cmdZPressed) {
      undo();
    }
    if (cmdYPressed) {
      redo();
    }
  }, [cmdZPressed, cmdYPressed]);

  return (
    <PanelCard>
      <PanelButton
        className="px-3"
        onClick={undo}
        disabled={!canUndo}
      >
        <UndoIcon className="size-5 path:fill-neutrals-700" />
      </PanelButton>
      <div className="h-8 w-px bg-neutrals-300" />
      <PanelButton
        className="px-3"
        onClick={redo}
        disabled={!canRedo}
      >
        <RedoIcon className="size-5 path:fill-neutrals-700" />
      </PanelButton>
    </PanelCard>
  );
}

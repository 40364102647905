import { cn } from '@/helpers/cn';
import DropdownIcon from '@assets/dropdown.svg';
import * as Collapsible from '@radix-ui/react-collapsible';
import { ReactNode, useEffect, useState } from 'react';
import { CategoryConfig } from '../../helpers/toolbox-helpers';

type ToolboxCategoryProps = {
  categoryConfig: CategoryConfig;
  children: ReactNode;
  open?: boolean;
};

const ToolboxCategory = ({ categoryConfig, children, open }: ToolboxCategoryProps) => {
  const [isOpen, setIsOpen] = useState(!!open);
  const CategoryIcon = categoryConfig.icon;

  useEffect(() => {
    if (!open) return;
    setIsOpen(!!open);
  }, [open]);

  return (
    <Collapsible.Root
      open={isOpen}
      onOpenChange={setIsOpen}
      className="rounded-xl border border-neutrals-300 p-2"
    >
      <Collapsible.Trigger asChild>
        <button className="flex w-full items-center justify-between">
          <div className="flex items-center justify-between gap-3">
            <div className="rounded-lg bg-neutrals-100 p-2.5">
              <CategoryIcon className="size-4" />
            </div>
            <div className="text-caption-md">{categoryConfig.label}</div>
          </div>
          <div className="rounded-3xl p-1 transition hover:bg-neutrals-300">
            <DropdownIcon className={cn('size-4 transition', { 'rotate-90': isOpen })} />
          </div>
        </button>
      </Collapsible.Trigger>

      <Collapsible.Content>
        <div className="flex flex-col gap-1.5 pt-4">{children}</div>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};

export default ToolboxCategory;

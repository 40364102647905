import { useToolConfigs } from '@/api/tools/get-tool-configs.ts';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { NodeConfig } from '../types.ts';

export function useNodeRegistry() {
  const { toolConfigs } = useToolConfigs(DfFeature.WHITEBOARD);

  const nodeConfigs: NodeConfig[] =
    toolConfigs?.map((toolConfig) => ({
      name: toolConfig.displayName,
      toolConfig,
    })) ?? [];

  return {
    nodeConfigs,
  };
}

import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { StateCreator } from 'zustand';
import { filterExecutionEngineNodes, hydrateNodeData } from '../helpers/node-helpers';
import { positionNodesWithDagre } from '../helpers/position-helpers';
import { ExecutionEngineNode, Whiteboard } from '../types';
import { ExecutionSlice, initialExecutionState } from './execution.slice';
import { HistorySlice, initialHistoryState } from './history.slice';
import { ReactFlowSlice, initialFlowState } from './react-flow.slice';

type WhiteboardState = {
  whiteboardId?: number;
  whiteboardName?: string;
  projectId?: number;
  isLoading: boolean;
  shouldSave: boolean;
  showToolbox: boolean;
  template?: WhiteboardTemplate;
  fullSync: boolean;
  stopped: boolean;
};

type WhiteboardAction = {
  setupFromServer: (whiteboard: Whiteboard, serverNodes: ExecutionEngineNode[], projectId?: number) => Promise<void>;
  setupEENodesFromServer: (serverNodes: ExecutionEngineNode[]) => void;
  clear: () => void;
  clearSave: () => void;
  save: () => void;
  setShowToolbox: (showToolbox: boolean) => void;
  setLayout: () => void;
  setFullSync: (fullSync: boolean) => void;
  getStopped: () => boolean;
  setStopped: (stopped: boolean) => void;
};

export type WhiteboardSlice = WhiteboardState & WhiteboardAction;

export const initialWhiteboardState: WhiteboardState = {
  whiteboardId: undefined,
  whiteboardName: undefined,
  projectId: undefined,
  isLoading: false,
  shouldSave: false,
  showToolbox: true,
  template: undefined,
  fullSync: false,
  stopped: false,
};

const initialState = {
  ...initialWhiteboardState,
  ...initialExecutionState,
  ...initialFlowState,
  ...initialHistoryState,
};

export const createWhiteboardSlice: StateCreator<
  WhiteboardSlice & ReactFlowSlice & ExecutionSlice & HistorySlice,
  [],
  [],
  WhiteboardSlice
> = (set, get) => ({
  ...initialWhiteboardState,
  setLayout: () => {
    const selectedNodes = get().nodes.filter((n) => n.selected);
    if (selectedNodes.length === 0) {
      return;
    }
    const rest = get().nodes.filter((n) => !n.selected);
    const nodesWithPositions = positionNodesWithDagre(selectedNodes, get().edges, selectedNodes[0]?.position, 'LR');
    set({ nodes: [...rest, ...nodesWithPositions] });
    get().addToHistoryStack();
  },
  setupFromServer: async (whiteboard, serverNodes, projectId) => {
    const { nodes = [], edges = [] } = whiteboard.reactFlow || {};
    const { executionEngineNodes, eeNodesToRemove } = filterExecutionEngineNodes(nodes, serverNodes);
    const nodesWithData = hydrateNodeData(nodes, executionEngineNodes);

    set({
      reactFlowId: whiteboard.reactFlow?.id,
      whiteboardId: whiteboard.id,
      whiteboardName: whiteboard.name,
      projectId,
      template: whiteboard.template,
      nodes: nodesWithData,
      edges,
      executionEngineNodes,
      historyState: {
        nodes: nodesWithData,
        edges,
      },
      lastSavedNodes: nodesWithData,
      lastSavedEdges: edges,
      eeNodesToRemove,
      fullSync: true,
    });
  },

  setupEENodesFromServer: async (executionEngineNodes) => {
    set((state) => ({
      executionEngineNodes,
      nodes: state.nodes.map((node) => {
        const executionEngineNode = executionEngineNodes.find((een) => een.reactFlowNodeId === node.id);
        if (!executionEngineNode) {
          return node;
        }
        return {
          ...node,
          data: {
            ...node.data,
            outputHistoryIndex: executionEngineNode.tool.outputHistory?.findIndex(
              (item) => JSON.stringify(item.output) === JSON.stringify(node.data.output),
            ),
            toolInput: executionEngineNode.tool.input,
          },
        };
      }),
    }));
  },
  clear: () => set(initialState),
  save: () => set({ shouldSave: true }),
  clearSave: () => set({ shouldSave: false }),
  setShowToolbox: (showToolbox: boolean) => set({ showToolbox }),
  setFullSync: (fullSync: boolean) => set({ fullSync }),
  getStopped: () => get().stopped,
  setStopped: (stopped: boolean) => set({ stopped }),
});

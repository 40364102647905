// import { usePublicTemplate } from './api/get-public-template';
import { WhiteboardPreview } from '../../whiteboards/preview/WhiteboardPreview';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicWhiteboardTemplate } from '../api/usePublicWhiteboardTemplate.ts';
import { Button } from 'flowbite-react';
import { buildUrl } from '@/helpers/build-url.ts';

export function PublicWhiteboardTemplatePreview() {
  const uuid = useParams().uuid;
  const { publicWhiteboardTemplate } = usePublicWhiteboardTemplate(uuid);
  const navigate = useNavigate();

  if (!publicWhiteboardTemplate) {
    return null;
  }

  const startWhiteboardOnboarding = () => {
    navigate(buildUrl([''], undefined, { whiteboardTemplateUuid: uuid! }));
  };

  return (
    <div className="relative h-svh w-full">
      <WhiteboardPreview reactFlow={publicWhiteboardTemplate.whiteboard.reactFlow} />

      <div className="absolute right-4 top-4">
        <Button onClick={startWhiteboardOnboarding}>Use this template</Button>
      </div>
    </div>
  );
}

import { cn } from '@/helpers/cn';
import { llmMarked } from '@/helpers/llmMarked';
import { NodeProps, Panel, ReactFlow } from '@xyflow/react';
import React from 'react';
import { ToolHtmlOutput } from 'src/libs/tools/ToolHtmlOutput';
import { ToolName, ToolOutputType } from 'src/libs/tools/type.model';
import Background from '../components/Background';
import { LoaderIcon } from '../components/ui/LoaderIcon';
import { GROUP_COLORS, STICKY_NOTE_COLORS } from '../consts/whiteboard.const';
import { getYouTubeVideoId } from '../helpers/node-output-helpers';
import { NodeInputSockets } from '../nodes/sockets/NodeInputSockets';
import { NodeOutputSocket } from '../nodes/sockets/NodeOutputSocket';
import { GenericToolNodeHeader } from '../nodes/tools/generic/GenericToolNodeHeader';
import { PaddingEdge } from '../PaddingEdge';
import { GroupNodeType, ReactFlow as ReactFlowType, StickyNoteNodeType, ToolNodeType } from '../types';
import { PreviewZoomPanel } from './PreviewZoomPanel';

const nodeTypes = {
  Tool: ToolNodePreview,
  StickyNote: StickyNoteNodePreview,
  Group: GroupNodePreview,
};

type WhiteboardPreviewProps = {
  reactFlow: ReactFlowType;
  badge?: string;
};

export function WhiteboardPreview({ reactFlow, badge }: WhiteboardPreviewProps) {
  return (
    <ReactFlow
      className="rounded-xl border border-neutrals-300"
      nodeTypes={nodeTypes}
      nodes={reactFlow.nodes}
      edges={reactFlow.edges}
      fitView
      minZoom={0.1}
      maxZoom={2}
      edgeTypes={{
        default: PaddingEdge,
      }}
    >
      {badge && (
        <Panel
          position="top-right"
          className="!mx-2 !my-1 text-body-sm text-text-secondary"
        >
          <span className="">{badge}</span>
        </Panel>
      )}
      <Panel position="bottom-right">
        <PreviewZoomPanel />
      </Panel>
      <Background />
    </ReactFlow>
  );
}

type ToolNodeProps = Pick<NodeProps<ToolNodeType>, 'id' | 'data' | 'height' | 'width'>;

function ToolNodePreview({ id, data, height, width }: ToolNodeProps) {
  const outputType = data.toolConfig.config.outputType;

  function renderOutput() {
    if (!data.output) return null;
    if (data.toolConfig.name === ToolName.READER_YOUTUBE) {
      const videoUrl = data.toolInput?.find((i) => i.key === 'url')?.value;
      if (!videoUrl) return;
      const videoId = videoUrl ? getYouTubeVideoId(videoUrl as string) : null;
      return (
        <div>
          <div className="mb-4">
            <iframe
              className="aspect-video w-full"
              src={`https://www.youtube.com/embed/${videoId}`}
              title="YouTube video player"
              allowFullScreen
            />
          </div>
          <h2 className="mb-2 text-h3-desktop">Transcript:</h2>
          <p
            className="ProseMirror cursor-text !p-0"
            dangerouslySetInnerHTML={{ __html: llmMarked(data.output as string) }}
          ></p>
        </div>
      );
    }

    if (outputType === 'text') {
      return (
        <p
          className="ProseMirror cursor-text !p-0"
          dangerouslySetInnerHTML={{ __html: llmMarked(data.output as string) }}
        ></p>
      );
    }
    if (outputType === 'image') {
      return (
        <img
          src={import.meta.env.VITE_ASSETS + data.output}
          alt=""
        />
      );
    }

    if (outputType === ToolOutputType.JSON && data.toolId) {
      return <ToolHtmlOutput toolId={data.toolId} />;
    }

    return JSON.stringify(data.output);
  }

  return (
    <div
      className={cn(
        'relative h-full rounded-xl border-2 bg-white text-body-sm shadow-card',
        'flex flex-col justify-between',
      )}
      style={{
        width,
        height,
      }}
    >
      <GenericToolNodeHeader
        nodeId={id}
        nodeData={data}
        canExecute={true}
        status={data.status}
        preview
      />

      <NodeInputSockets
        nodeData={data}
        nodeId={id}
      />

      <NodeOutputSocket
        nodeId={id}
        socketType={outputType}
      />

      <div className="relative h-auto grow overflow-auto rounded-b-xl border-t-2 p-4">
        {data.loading ? (
          <div className="flex h-full items-center justify-center">
            <LoaderIcon />
          </div>
        ) : (
          <div className="nopan nodrag nowheel flex items-center gap-2">{renderOutput()}</div>
        )}
      </div>
    </div>
  );
}

function StickyNoteNodePreview({ data }: NodeProps<StickyNoteNodeType>) {
  return (
    <div
      className={cn(
        'h-full overflow-hidden rounded-sm p-2.5 text-caption-sm italic shadow-[2px_2px_5px_rgba(0,0,0,0.15)]',
        data.backgroundColor ?? STICKY_NOTE_COLORS[0],
      )}
    >
      <p>{data.content?.split('\n').map((line, index) => <React.Fragment key={index}>{line}</React.Fragment>)}</p>
    </div>
  );
}

function GroupNodePreview({ data }: NodeProps<GroupNodeType>) {
  return (
    <div className="group h-full rounded-xl">
      <div className={cn('group-node h-full w-full rounded-xl opacity-50', data.backgroundColor ?? GROUP_COLORS[0])}>
        <div className="absolute -top-7 left-0">
          <h2 className="line-clamp-3 min-w-3 text-subtitle-md">{data.name}</h2>
        </div>
      </div>
    </div>
  );
}

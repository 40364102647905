import { Tool, ToolConfig, ToolValue } from './type.model.ts';

export function getToolFormInitialValues(toolConfig: ToolConfig, tool?: Tool) {
  const result: Record<string, ToolValue> = {};
  const instanceValues: Record<string, ToolValue> = {};

  tool?.input?.forEach(({ key, value }) => {
    if (value) instanceValues[key] = value;
  });

  for (const key in toolConfig?.config?.input) {
    result[key] = instanceValues[key] ?? toolConfig.config.input[key].defaultValue ?? '';
  }

  return result;
}

import { useProject } from '@/api/projects/get-project';
import { PageTitle } from '@/components/PageTitle';
import { Urls } from '@/consts/urls';
import { buildUrl } from '@/helpers/build-url';
import { useLeftSideNav } from '@/layouts/MainLayout/store.ts';
import { ReactFlowProvider } from '@xyflow/react';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useWhiteboard } from './api/get-whiteboard';
import { useWhiteboardNodes } from './api/get-whiteboard-nodes';
import WhiteboardHeader from './components/WhiteboardHeader';
import WhiteboardShell from './components/WhiteboardShell';
import { useNodeExecutionSubscriber } from './hooks/useNodeExecutionSubscriber';
import { useWhiteboardStore } from './store/whiteboard.store';
import { Whiteboard } from './types';
import './Whiteboard.scss';

function useBreadcrumbs(projectId?: string, whiteboard?: Whiteboard) {
  const { project } = useProject(projectId ? Number(projectId) : undefined);

  if (!project && whiteboard) {
    return [{ label: whiteboard.template?.name || whiteboard.name, href: '#' }];
  }

  return [
    { label: 'Projects', href: buildUrl([Urls.PROJECTS]) },
    {
      label: project?.name || '...',
      href: project ? buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: project.id }) : '#',
    },
    { label: whiteboard?.template?.name || whiteboard?.name || '...', href: '#' },
  ];
}

export function WhiteboardPage() {
  const { collapseNav } = useLeftSideNav();
  const { whiteboardId, projectId } = useParams();
  const { whiteboard } = useWhiteboard(whiteboardId);
  const { listenOnNodeExecution } = useNodeExecutionSubscriber();
  const { executionEngineNodes } = useWhiteboardNodes(whiteboard?.id);

  const breadcrumbs = useBreadcrumbs(projectId, whiteboard);

  const clearBoard = useWhiteboardStore((state) => state.clear);
  const storeWhiteboardId = useWhiteboardStore((state) => state.whiteboardId);
  const setupFromServer = useWhiteboardStore((state) => state.setupFromServer);
  const setupEENodesFromServer = useWhiteboardStore((state) => state.setupEENodesFromServer);

  const listenerOffRef = useRef(() => {});

  useEffect(() => {
    collapseNav();
  }, []);

  useEffect(() => {
    if (whiteboardId && whiteboard && executionEngineNodes && whiteboardId !== String(storeWhiteboardId)) {
      setupFromServer(whiteboard, executionEngineNodes, Number(projectId));
      listenerOffRef.current = listenOnNodeExecution(whiteboardId).listenerOff;
    }
    if (!whiteboard) {
      clearBoard();
    }
  }, [whiteboard, executionEngineNodes]);

  useEffect(() => {
    if (executionEngineNodes) {
      setupEENodesFromServer(executionEngineNodes);
    }
  }, [executionEngineNodes]);

  useEffect(() => {
    return () => {
      listenerOffRef.current();
    };
  }, []);

  return (
    <div className="h-screen w-full bg-gray-ultra-light">
      <WhiteboardHeader
        breadcrumbs={breadcrumbs}
        whiteboard={whiteboard}
      />
      <PageTitle title={`Digitalfirst.ai | ${whiteboard?.name}`} />

      <div className="relative h-[calc(100vh-66px)] overflow-hidden pb-3 pr-3">
        <ReactFlowProvider>
          <WhiteboardShell />
        </ReactFlowProvider>
      </div>
    </div>
  );
}

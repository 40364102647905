import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { ToolHistoryItem, ToolOutput } from '../../../libs/tools/type.model.ts';

export const useToolOutputHistory = (toolId: number) => {
  const { getFetcher } = useApi();

  const { data, ...rest } = useSWR<ToolHistoryItem[]>(`/tools/output-history/${toolId}`, getFetcher);
  const historyOutputs = (data?.map((i: { output: ToolOutput }) => i.output) as Array<ToolOutput>) ?? [];

  return {
    outputHistory: data,
    historyOutputs,
    ...rest,
  };
};

import { Loader2 } from 'lucide-react';
import { useGlobalModal } from '../GlobalModal/global-modal-store';
import { useState } from 'react';

export const WebsearchToolsModalBody = () => {
  const { closeModal, context } = useGlobalModal<{ callback: (data: string, title?: string) => void }>();
  const [loading, setLoading] = useState(true);

  window.onmessage = (event) => {
    if (event.origin !== location.origin) {
      return;
    }

    let message;

    try {
      message = JSON.parse(event.data);
    } catch {
      return;
    }

    if (message.type !== 'export') {
      return;
    }

    context.callback(message.data, message?.title);
    closeModal();
  };

  return (
    <>
      {loading && (
        <Loader2 className="absolute left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 animate-spin" />
      )}
      <iframe
        src="/no-menu-app/web-searcher"
        className="h-[85svh] w-full"
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

import { AutoSizingTextarea } from '@/components/AutoSizingTextarea/AutoSizingTextarea.tsx';
import { cn } from '@/helpers/cn.ts';
import { useEffect, useState } from 'react';
import { useSaveReactFlow } from '../hooks/useSaveReactFlow.ts';
import { useWhiteboardStore } from '../store/whiteboard.store.ts';
import { NodeData, NodeType } from '../types.ts';

type EditNodeNameProps = {
  nodeData: NodeData;
  nodeId: NodeType['id'];
  className?: string;
};

export function EditNodeName({ nodeData, nodeId, className }: EditNodeNameProps) {
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);
  const addToHistoryStack = useWhiteboardStore((state) => state.addToHistoryStack);
  const { saveReactFlow } = useSaveReactFlow();

  const [isEditingName, setIsEditingName] = useState(false);
  const [name, setName] = useState(nodeData.name);

  useEffect(() => {
    if (!isEditingName && nodeData.isNewlyAdded && !nodeData.loading) {
      updateNodeData(nodeId, { isNewlyAdded: false });
      setIsEditingName(true);
    }
  }, [nodeData.loading]);

  useEffect(() => {
    setName(nodeData.name);
  }, [nodeData.name]);

  function saveName() {
    if (!name || name === nodeData.name) {
      setName(nodeData.name);
      setIsEditingName(false);
      return;
    }
    updateNodeData(nodeId, { hasEditedNameByUser: true, name: name });
    addToHistoryStack();
    saveReactFlow();
    setIsEditingName(false);
  }

  return (
    <>
      {isEditingName ? (
        <AutoSizingTextarea
          className={cn(
            'nopan nodrag reset-input block w-full resize-none !border-none bg-transparent p-0 text-subtitle-md !outline-none !ring-0',
            className,
          )}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              setIsEditingName(false);
            }
            if (e.key === 'Enter') {
              saveName();
            }
          }}
          onBlur={() => {
            saveName();
          }}
          selectAllOnFocus={!nodeData.hasEditedNameByUser}
          autoFocus
          maxRows={3}
        />
      ) : (
        <h2
          className={cn('line-clamp-3 min-w-3 text-subtitle-md', className)}
          onDoubleClick={() => {
            setIsEditingName(true);
          }}
        >
          {nodeData.name}
        </h2>
      )}
    </>
  );
}

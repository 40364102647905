import { useApi } from '@/api/api.ts';
import { UpdateWhiteboardDto } from '../types.ts';
import { mutate } from 'swr';

export const useUpdateWhiteboard = (projectId: number) => {
  const { patchFetcher } = useApi();

  const updateWhiteboard = async (whiteboardId: number, payload: UpdateWhiteboardDto) => {
    await patchFetcher(`/whiteboard/${whiteboardId}`, { arg: payload });
    await mutate(`/whiteboard/by-project/${projectId}`);
  };

  return {
    updateWhiteboard,
  };
};

import { useToolOutputAsHTML } from '@/api/tools/get-tool-output-as-html';
import { llmMarked } from '@/helpers/llmMarked.ts';

export function ToolHtmlOutput({ toolId }: { toolId?: number }) {
  const { toolOutputAsHTML } = useToolOutputAsHTML(toolId);

  if (!toolOutputAsHTML) return null;

  return (
    <>
      <p
        className="ProseMirror !p-0"
        dangerouslySetInnerHTML={{ __html: llmMarked(toolOutputAsHTML) }}
      ></p>
    </>
  );
}

import {
  AiWriter,
  AiImage,
  BlockquoteFigure,
  Figcaption,
  FontSize,
  ImageBlock,
  SlashCommand,
  TrailingNode,
  Column,
  DfGenOutput,
  DFUserInput,
  Document,
  CharacterCount,
  Color,
  Dropcursor,
  Emoji,
  FileHandler,
  Focus,
  FontFamily,
  Heading,
  HorizontalRule,
  Highlight,
  Placeholder,
  Selection,
  StarterKit,
  Subscript,
  Superscript,
  Table,
  TableOfContents,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  TextStyle,
  Typography,
  Underline,
  Columns,
  TaskItem,
  TaskList,
  Link,
} from '@/components/tiptap/extensions';
import { Image } from '@tiptap/extension-image';
import { Extensions, getSchema, JSONContent } from '@tiptap/core';
import { prosemirrorJSONToYDoc } from 'y-prosemirror';
import { applyUpdate, Doc, encodeStateAsUpdate } from 'yjs';

export const TiptapSchema: Extensions = [
  AiWriter,
  Image,
  AiImage,
  BlockquoteFigure,
  CharacterCount,
  Color,
  Document,
  Dropcursor,
  Emoji,
  Figcaption,
  FileHandler,
  Focus,
  FontFamily,
  FontSize,
  Heading,
  Highlight,
  HorizontalRule,
  ImageBlock,
  Link,
  Placeholder,
  Selection,
  SlashCommand,
  StarterKit,
  Subscript,
  Superscript,
  Table,
  TableOfContents,
  TableCell,
  TableHeader,
  TableRow,
  TextAlign,
  TextStyle,
  TrailingNode,
  Typography,
  Underline,
  Columns,
  Column,
  TaskItem,
  TaskList,
  DfGenOutput,
  DFUserInput,
];

export const convertJsonToYdoc = (json: JSONContent) => {
  const ydoc = new Doc();
  const update = encodeStateAsUpdate(prosemirrorJSONToYDoc(getSchema(TiptapSchema), json, 'default'));
  applyUpdate(ydoc, update);
  return ydoc;
};

import { FormikProvider, useFormik } from 'formik';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { SettingsSubHeader } from './components/SettingsSubHeader';
import { Avatar, Button, FileInput, Label, TextInput } from 'flowbite-react';
import { useOrganization } from '@/api/organization/get-organization';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useUpdateOrganization } from '@/api/organization/update-organization';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { useEffect, useRef } from 'react';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store';
import { DIALOGS } from '@/consts/dialogs.const';
import { useDeleteOrganization } from '@/api/organization/delete-organization';
import { cn } from '@/helpers/cn';

export function OrganizationSettings() {
  const { organization } = useOrganization();
  const { deleteOrganization } = useDeleteOrganization();
  const { updateOrganization } = useUpdateOrganization();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { openDialog } = useGlobalDialog();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const formik = useFormik<{ displayName?: string; logoBase64?: string }>({
    initialValues: {},
    onSubmit: async ({ displayName, logoBase64 }) => {
      showLoader('Saving organization...');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let branding = { logoBase64 } as any;

      if (logoBase64 === 'no-img') {
        branding = {};
      } else if (!logoBase64) {
        branding = organization?.branding;
      }

      await updateOrganization({
        display_name: displayName,
        branding: branding,
      });
      hideLoader();
    },
  });

  useEffect(() => {
    formik.setValues({ displayName: organization?.display_name });
  }, [organization]);

  const organizationImage = formik.values.logoBase64 ?? organization?.branding?.logo_url;

  return (
    <PageWithHeaderLayout
      subHeader={<SettingsSubHeader />}
      header={{
        breadcrumbs: [{ label: 'Settings', href: '#' }],
        icon: '/settings-black.svg',
      }}
    >
      <title>Digitalfirst.ai | Settings</title>

      <div className="grid grid-cols-2 gap-6">
        <form
          className="h-fit rounded-2xl border p-6"
          onSubmit={formik.handleSubmit}
        >
          <FormikProvider value={formik}>
            <h4 className="text-h4-desktop">Organization</h4>
            <div className="my-6 flex items-center gap-4">
              {organizationImage ? (
                <Avatar
                  img={organizationImage}
                  size="lg"
                />
              ) : (
                <div className="flex size-20 shrink-0 items-center justify-center rounded-lg bg-pink-300 text-5xl font-bold">
                  {organization?.display_name.charAt(0).toUpperCase()}
                </div>
              )}
              <div
                className="flex cursor-pointer items-center gap-2 text-caption-md text-primary-600"
                onClick={() => fileInputRef?.current?.click()}
              >
                <img
                  src="/upload-blue.svg"
                  width={'16px'}
                  alt=""
                />
                <span>Upload</span>
                <FileInput
                  className="hidden"
                  ref={fileInputRef}
                  accept="image/png, image/gif, image/jpeg"
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (!file) {
                      return;
                    }

                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const base64String = reader.result as string;
                      formik.setFieldValue('logoBase64', base64String);
                    };
                    reader.readAsDataURL(file);
                  }}
                />
              </div>
              <div
                className={cn(
                  'flex cursor-pointer items-center gap-2 text-caption-md text-text-secondary',
                  (!formik.values.logoBase64 || formik.values.logoBase64 === 'no-img') &&
                    !organization?.branding?.logo_url
                    ? 'hidden'
                    : null,
                )}
                onClick={() => {
                  formik.setFieldValue('logoBase64', 'no-img');
                  if (fileInputRef.current) {
                    fileInputRef.current.files = new DataTransfer().files;
                  }
                }}
              >
                <img
                  src="/trash-gray.svg"
                  width={'16px'}
                  alt=""
                />
                Remove
              </div>
            </div>
            <div>
              <Label value="Organization name" />
              <TextInput {...getDfFlowbiteFieldProps('displayName', formik)} />
            </div>
            <div className="mt-6 flex justify-end">
              <Button
                color="primary"
                type="submit"
              >
                Save changes
              </Button>
            </div>
          </FormikProvider>
        </form>

        <div className="hidden h-fit rounded-2xl border p-6">
          <h4>Billing portal</h4>
          <div className="mt-6 flex justify-end">
            <Button
              color="primary"
              size="sm"
            >
              Open
              <img
                src="/open-in-new-tab-white.svg"
                alt=""
              />
            </Button>
          </div>
        </div>

        <div className="hidden h-fit rounded-2xl border p-6">
          <h4>Delete organization</h4>
          <div className="mt-6 flex gap-3 rounded-xl bg-notice-100 p-4">
            <img
              src="/warning-yellow-filled.svg"
              alt=""
            />
            <span className="text-body-md text-notice-1000">
              If you delete your organization, all workspaces, projects and other assets will be permanently deleted.
            </span>
          </div>
          <div className="mt-6 flex justify-end">
            <Button
              color="failure"
              size="sm"
              onClick={() =>
                openDialog(DIALOGS.DeleteOrganization, async () => {
                  showLoader('Deleting organization...');
                  await deleteOrganization();
                  hideLoader();
                })
              }
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </PageWithHeaderLayout>
  );
}

import { ReactElement } from 'react';
import { Tool, ToolCategory, ToolName } from './type.model.ts';
import { LangChainModel } from '@/enums/lang-chain-model.enum.ts';
import ToolTextIcon from '@assets/tool-text.svg';
import ImageIcon from '@assets/image.svg';
import ResearchIcon from '@assets/research.svg';
import SearchIcon from '@assets/search-check-color.svg';
import { Asset, AssetName } from '@/models/asset.model.ts';
import FacebookIcon from '@assets/facebook.svg';
import InstagramIcon from '@assets/instagram.svg';
import TextFile from '@assets/text-file.svg';
import { useToolConfigs } from '@/api/tools/get-tool-configs.ts';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { useAssets } from '@/api/assets/useAssets.ts';
import DataRoom from '@assets/data-room.svg';

interface ToolGraphics {
  icon: ReactElement;
  logo: ReactElement;
}

interface GetToolGraphicsParams {
  toolName: ToolName;
  tool?: Tool;
  asset?: Asset;
}

export const useToolGraphics = () => {
  const { assets } = useAssets();
  const { toolConfigs } = useToolConfigs(DfFeature.WHITEBOARD);

  const getToolGraphics = ({ toolName, tool, asset }: GetToolGraphicsParams): ToolGraphics => {
    const toolConfig = toolConfigs?.find((config) => config.name === toolName);

    if (!toolConfig || !assets) {
      return {
        icon: <></>,
        logo: <></>,
      };
    }

    const { category, name } = toolConfig;

    const textModel: LangChainModel | undefined = tool?.input.find((field) => field.key === 'model')
      ?.value as LangChainModel;

    const assetName: AssetName | undefined =
      assets?.find((asset) => asset.id === (tool?.input.find((field) => field.key === 'assetId')?.value as number))
        ?.name ?? asset?.name;

    switch (category) {
      case ToolCategory.TEXTGEN:
        switch (name) {
          case ToolName.TEXT_BASE_MODELS:
          case ToolName.ASK_VISION_MODEL:
            switch (textModel) {
              case LangChainModel.gpt4o:
              case LangChainModel.gpt4o20241120:
              case LangChainModel.gpt4Turbo:
              case LangChainModel.gpt4:
              case LangChainModel.gpt35Turbo16k:
              case LangChainModel.o1:
              case LangChainModel.o1Mini:
              case LangChainModel.o1Preview:
              case LangChainModel.o3Mini:
              case LangChainModel.gpt4oMini:
                return {
                  icon: (
                    <img
                      src="/logo-open-ai-icon.png"
                      alt="OpenAI logo"
                    />
                  ),
                  logo: (
                    <img
                      src="/logo-open-ai.png"
                      alt="OpenAI logo"
                    />
                  ),
                };
              case LangChainModel.claude3Haiku20240307:
              case LangChainModel.claude3Sonnet20240229:
              case LangChainModel.claude3Opus20240229:
              case LangChainModel.claude35Sonnet20240620:
              case LangChainModel.claude35HaikuLatest:
              case LangChainModel.claude37SonnetLatest:
                return {
                  icon: (
                    <img
                      src="/logo-claude-icon.png"
                      alt="Anthropic logo"
                    />
                  ),
                  logo: (
                    <img
                      src="/logo-claude.png"
                      alt="Anthropic logo"
                    />
                  ),
                };
              case LangChainModel.MistralSmallLatest:
              case LangChainModel.MistralMediumLatest:
              case LangChainModel.MistralLargeLatest:
              case LangChainModel.MistralEmbed:
              case LangChainModel.CodestralLatest:
              case LangChainModel.OpenMistral7b:
              case LangChainModel.OpenMixtral8x7b:
              case LangChainModel.OpenMixtral8x22b:
                return {
                  icon: (
                    <img
                      src="/logo-mistral-ai-icon.png"
                      alt="MistralAI logo"
                    />
                  ),
                  logo: (
                    <img
                      src="/logo-mistral-ai.png"
                      alt="MistralAI logo"
                    />
                  ),
                };
              case LangChainModel.Groq:
                return {
                  icon: (
                    <img
                      src="/logo-groq-icon.png"
                      alt="Groq logo"
                    />
                  ),
                  logo: (
                    <img
                      src="/logo-groq.png"
                      alt="Groq logo"
                    />
                  ),
                };
              default:
                switch (name) {
                  case ToolName.ASK_VISION_MODEL:
                    return {
                      icon: <ImageIcon />,
                      logo: <ImageIcon />,
                    };
                  case ToolName.TEXT_BASE_MODELS:
                  default:
                    return {
                      icon: <ToolTextIcon />,
                      logo: <ToolTextIcon />,
                    };
                }
            }
          case ToolName.ASK_INPUT:
            return {
              icon: <DataRoom />,
              logo: <DataRoom />,
            };
          default:
            return {
              icon: <ToolTextIcon />,
              logo: <ToolTextIcon />,
            };
        }

      case ToolCategory.IMAGEGEN:
        switch (name) {
          case ToolName.PROMPT_IMAGE_IDEOGRAM_MODELS:
            return {
              icon: (
                <img
                  src="/logo-ideogram-icon.png"
                  alt="Ideogram logo"
                />
              ),
              logo: (
                <img
                  src="/logo-ideogram.png"
                  alt="Ideogram logo"
                />
              ),
            };
          case ToolName.PROMPT_IMAGE_SD_MODELS:
          case ToolName.SD_ERASE:
          case ToolName.SD_INPAINT:
          case ToolName.SD_OUTPAINT:
          case ToolName.SD_SEARCH_AND_REPLACE:
          case ToolName.SD_REMOVE_BACKGROUND:
          case ToolName.SD_SKETCH:
          case ToolName.SD_STRUCTURE:
          case ToolName.SD_STYLE:
          case ToolName.IMAGE_IMAGE_SD_MODELS:
            return {
              icon: (
                <img
                  src="/logo-sd-icon.png"
                  alt="Stable Diffusion logo"
                />
              ),
              logo: (
                <img
                  src="/logo-sd.png"
                  alt="Stable Diffusion logo"
                />
              ),
            };
          case ToolName.FLUX:
            return {
              icon: <ImageIcon />,
              logo: <ImageIcon />,
            };
          case ToolName.PROMPT_IMAGE_DALLE3_MODELS:
            return {
              icon: (
                <img
                  src="/logo-open-ai-icon.png"
                  alt="DALL-E logo"
                />
              ),
              logo: (
                <img
                  src="/logo-dall-e.png"
                  alt="DALL-E logo"
                />
              ),
            };
          case ToolName.LUMA_IMAGE_TO_VIDEO:
          case ToolName.LUMA_PROMPT_TO_VIDEO:
            return {
              icon: (
                <img
                  src="/logo-luma-icon.png"
                  alt="Luma logo"
                />
              ),
              logo: (
                <img
                  src="/logo-luma.png"
                  alt="Luma logo"
                />
              ),
            };
          default:
            return {
              icon: <ImageIcon />,
              logo: <ImageIcon />,
            };
        }

      case ToolCategory.RESEARCHGEN:
        switch (name) {
          case ToolName.PERPLEXITY:
            return {
              icon: (
                <img
                  src="/logo-perplexity-icon.png"
                  alt="Perplexity logo"
                />
              ),
              logo: (
                <img
                  src="/logo-perplexity.png"
                  alt="Perplexity logo"
                />
              ),
            };
          case ToolName.TAVILY:
            return {
              icon: (
                <img
                  src="/logo-tavily-icon.png"
                  alt="Tavily logo"
                />
              ),
              logo: (
                <img
                  src="/logo-tavily.svg"
                  alt="Tavily logo"
                />
              ),
            };
          case ToolName.INSTAGRAM_SCRAPPER:
            return {
              icon: <InstagramIcon />,
              logo: <InstagramIcon />,
            };
          case ToolName.FACEBOOK_SCRAPPER:
            return {
              icon: <FacebookIcon />,
              logo: <FacebookIcon />,
            };
          case ToolName.LINKEDIN_PROFILE_SCRAPPER:
          case ToolName.LINKEDIN_COMPANY_SCRAPPER:
            return {
              icon: (
                <img
                  src="/logo-linkedin-icon.png"
                  alt="LinkedIn logo"
                />
              ),
              logo: (
                <img
                  src="/logo-linkedin-icon.png"
                  alt="LinkedIn logo"
                />
              ),
            };
          case ToolName.REDDIT_SCRAPPER:
            return {
              icon: (
                <img
                  src="/logo-reddit-icon.png"
                  alt="Reddit logo"
                />
              ),
              logo: (
                <img
                  src="/logo-reddit-icon.png"
                  alt="Reddit logo"
                />
              ),
            };
          case ToolName.TIKTOK_SCRAPPER:
            return {
              icon: (
                <img
                  src="/logo-tiktok-icon.png"
                  alt="TikTok logo"
                />
              ),
              logo: (
                <img
                  src="/logo-tiktok-icon.png"
                  alt="TikTok logo"
                />
              ),
            };
          case ToolName.YOUTUBE_SCRAPPER:
            return {
              icon: (
                <img
                  src="/youtube.svg"
                  alt="YouTube logo"
                />
              ),
              logo: (
                <img
                  src="/youtube.svg"
                  alt="YouTube logo"
                />
              ),
            };
          default:
            return {
              icon: <ResearchIcon />,
              logo: <ResearchIcon />,
            };
        }

      case ToolCategory.SEMRUSH:
        return {
          icon: (
            <img
              src="/logo-semrush-icon.svg"
              alt="SEMrush logo"
            />
          ),
          logo: (
            <img
              src="/logo-semrush.png"
              alt="SEMrush logo"
            />
          ),
        };

      case ToolCategory.OTHER:
        return {
          icon: <SearchIcon />,
          logo: <SearchIcon />,
        };

      case ToolCategory.ASSETS:
        switch (assetName) {
          case AssetName.FACEBOOK_ADS:
          case AssetName.FACEBOOK_CAROUSEL_ADS:
            return {
              icon: <FacebookIcon />,
              logo: <FacebookIcon />,
            };
          case AssetName.INSTAGRAM_ADS:
            return {
              icon: <InstagramIcon />,
              logo: <InstagramIcon />,
            };
          default:
            return {
              icon: <TextFile />,
              logo: <TextFile />,
            };
        }

      default:
        return {
          icon: <TextFile />,
          logo: <TextFile />,
        };
    }
  };

  return {
    getToolGraphics,
  };
};

import TextFileIcon from '@assets/text-file.svg';
import React from 'react';
import { generateId } from '../../helpers/node-helpers.ts';
import { DATA_TRANSFER_KEYS } from '../../hooks/useDropNodeCreator.ts';
import { useWhiteboardStore } from '../../store/whiteboard.store';
import { NodeTypesEnum } from '../../types.ts';
import { ToolboxItem } from './ToolboxItem';

export function ToolboxStickyNote() {
  const addStickyNote = useWhiteboardStore((state) => state.addStickyNote);
  const calculateNewNodePosition = useWhiteboardStore((state) => state.calculateNewNodePosition);
  const fitView = useWhiteboardStore((state) => state.fitView);

  const onDragStart = (event: React.DragEvent) => {
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.nodeType, NodeTypesEnum.StickyNote);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleAdd = async () => {
    const id = generateId(NodeTypesEnum.StickyNote);
    addStickyNote({ id, position: calculateNewNodePosition() });
    fitView([{ id }]);
  };

  return (
    <ToolboxItem
      onClick={handleAdd}
      onDragStart={onDragStart}
      icon={<TextFileIcon className="path:stroke-yellow-600" />}
      label="Sticky Note"
    />
  );
}

import axios from 'axios';
import { useAccessToken } from '@/api/get-access-token.ts';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { ApiOptions } from '@/api/api.ts';
import { useErrorComposer } from '@/api/error-composer.ts';

export const useCreateDfAxios = (options?: ApiOptions) => {
  const { getAccessToken } = useAccessToken();
  const { errorComposer } = useErrorComposer(options?.closeModalOnError ?? true);
  const workspaceId = useCurrentWorkspace()?.id;

  const createDfAxios = async () => {
    const accessToken = await getAccessToken();

    const dfAxios = axios.create({
      baseURL: import.meta.env.VITE_API_SERVER,
      timeout: 1000 * 60 * 5,
      headers: {
        accept: 'application/json',
        'Content-Type': options?.contentType ?? 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'DF-Workspace-Id': `${workspaceId}`,
      },
    });

    dfAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (!options?.silent) errorComposer(error?.response?.status ?? 500, error?.response?.data?.message);
        return Promise.reject(error);
      },
    );

    return dfAxios;
  };

  return {
    createDfAxios,
  };
};

export const useCreatePublicDFAxios = () => {
  const { errorComposer } = useErrorComposer();

  const createPublicDFAxios = () => {
    const dfAxios = axios.create({
      baseURL: import.meta.env.VITE_API_SERVER,
      timeout: 1000 * 60 * 5,
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    dfAxios.interceptors.response.use(
      (response) => response,
      (error) => {
        errorComposer(error?.response?.status ?? 500, error?.response?.data?.message);
        return Promise.reject(error);
      },
    );

    return dfAxios;
  };

  return {
    createPublicDFAxios,
  };
};

import './MentionAccordionBody.scss';
import { PromptEditorContext } from '@/components/tiptap/extensions/DigitalFirst/editing/prompt-editor.context.ts';
import { useContext, useMemo } from 'react';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export function SectionMentionAccordionBody() {
  const { editor, toc } = useContext(PromptEditorContext);

  const sections = useMemo(() => {
    return toc?.content.map((header) => ({
      id: header.id,
      name: header.dom.textContent,
      label: header.dom.textContent,
    }));
  }, [toc]);

  const handleAddSectionToPrompt = (section: { id: string; label: string }) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: MentionReferenceType.SECTION,
        attrs: {
          id: section.id,
          label: `${section.label}`,
        },
      })
      .insertContent(' ') // add an extra space after the mention
      .run();
  };

  return (
    <>
      <div className="mention-accordion">
        <div className="mention-accordion__header">Add to prompt</div>
        {!!sections?.length && (
          <div className="mention-accordion__list">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => {
                  handleAddSectionToPrompt(section);
                }}
                className="mention-accordion__data-room-item"
                type="button"
              >
                <img
                  src="/add-blue.svg"
                  width={16}
                  height={16}
                  alt=""
                />
                <span className="mention-accordion__data-room-item__name">{section.name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

import { StateCreator } from 'zustand';
import { isNodeLoading } from '../helpers/node-helpers';
import { ExecutionEngineNode } from '../types';
import { EeNodeToUpdateOutput } from './history.slice';
import { ReactFlowSlice } from './react-flow.slice';

type ExecutionState = {
  executionEngineNodes: ExecutionEngineNode[];
  eeNodesToRemove?: string[];
  eeNodesToUpdateOutput?: EeNodeToUpdateOutput[];
};

type ExecutionAction = {
  addExecutionEngineNodes: (executionEngineNodes: ExecutionEngineNode[]) => void;
  getExecutionEngineNode: (reactFlowNodeId: string) => ExecutionEngineNode | undefined;
  clearEeNodesToUpdateOutput: () => void;
  isProcessingOrQueued: () => boolean;
  clearEeNodesToRemove: () => void;
  updateExecutionEngineNode: (reactFlowNodeId: string, updatedNode: ExecutionEngineNode) => void;
};

export type ExecutionSlice = ExecutionState & ExecutionAction;

export const initialExecutionState: ExecutionState = {
  executionEngineNodes: [],
  eeNodesToRemove: [],
};

export const createExecutionSlice: StateCreator<ExecutionSlice & ReactFlowSlice, [], [], ExecutionSlice> = (
  set,
  get,
) => ({
  ...initialExecutionState,
  addExecutionEngineNodes: (executionEngineNodes) => {
    set((state) => ({
      executionEngineNodes: state.executionEngineNodes.concat(executionEngineNodes),
    }));
  },

  getExecutionEngineNode: (reactFlowNodeId) =>
    get().executionEngineNodes.find((node) => node.reactFlowNodeId === reactFlowNodeId),

  clearEeNodesToRemove: () => set({ eeNodesToRemove: [] }),
  clearEeNodesToUpdateOutput: () => set({ eeNodesToUpdateOutput: [] }),
  isProcessingOrQueued: () => get().nodes.some((node) => isNodeLoading(node)),

  updateExecutionEngineNode: (reactFlowNodeId, updatedNode) => {
    set((state) => ({
      executionEngineNodes: state.executionEngineNodes.map((node) => {
        if (node.reactFlowNodeId === reactFlowNodeId) {
          return updatedNode;
        }
        return node;
      }),
    }));
  },
});

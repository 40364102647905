import { Button } from 'flowbite-react';
import { CanvasDetails, useGenerateCanvas } from '@/api/canvases/api.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { ToolName } from '../../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { listenOnCanvasFillerMessage } from '../../../helpers/listen-on-canvas-filler-message.ts';
import { mutate } from 'swr';
import GenerateIcon from '@assets/generate.svg';
import RegenerateIcon from '@assets/regenerate.svg';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { ForcedCanvasGenerationContextSideModalBody } from './modals/ForcedCanvasGenerationContextSideModalBody.tsx';

export function GenerateStrategyCanvasButton({ canvasDetails }: { canvasDetails: CanvasDetails }) {
  const { generateCanvas } = useGenerateCanvas();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { isSmallDataRoom } = useDataRoomItems();

  const onGenerate = async () => {
    if (isSmallDataRoom || canvasDetails.generationContextItemsIds) {
      showLoader('Generating canvas...');
      const wsChannel = `${ToolName.CANVAS_FILLER}: ${uuid()}`;
      listenOnCanvasFillerMessage(wsChannel, () => {
        hideLoader();
        mutate(`/strategy-canvas/instance/${canvasDetails.id}`);
      });
      await generateCanvas(canvasDetails.id, wsChannel);
    } else {
      openSideModal(<ForcedCanvasGenerationContextSideModalBody />, { canvasDetails });
    }
  };

  const buttonBody = canvasDetails.positions.some((position) => position.value.length) ? (
    <>
      <RegenerateIcon className="path:fill-white" />
      Regenerate
    </>
  ) : (
    <>
      <GenerateIcon className="path:fill-white" />
      Generate
    </>
  );

  return (
    <Button
      color="primary"
      size="sm"
      onClick={onGenerate}
    >
      {buttonBody}
    </Button>
  );
}

import { FormikProvider, useFormik } from 'formik';
import { ToolFormikFields, ToolValue } from './type.model';
import { Button } from 'flowbite-react';
import { ReactNode, useRef, useState } from 'react';
import { FormikDynamicFormItem } from './FormikDynamicFormItem';
import { cn } from '@/helpers/cn';
import { TOC } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';

export interface FormicDynamicFormProps {
  initialValues: Record<string, ToolValue>;
  validationSchema: object;
  formikBaseFields: ToolFormikFields[];
  formikAdvancedFields: ToolFormikFields[];
  onSubmit: (values: Record<string, ToolValue>) => Promise<void>;
  enableReinitialize?: boolean;
  submitLabel?: string;
  tiptap?: {
    turnOnTiptapInputAsPromptInput: boolean;
    toc: TOC;
  };
  extraTopChildren?: ReactNode;
}

export const FormikDynamicForm = ({
  initialValues,
  validationSchema,
  formikBaseFields,
  formikAdvancedFields,
  submitLabel,
  onSubmit,
  tiptap,
  extraTopChildren,
}: FormicDynamicFormProps) => {
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      //TODO: why FormData? (FormData returns all values as strings, so BE validation failed)
      // const formValues = Object.fromEntries(new FormData(formRef.current!));
      await onSubmit(values);
    },
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <form
        ref={formRef}
        onSubmit={formik.handleSubmit}
        className="flex min-h-0 grow flex-col"
      >
        <div className="grow overflow-y-scroll p-6">
          {extraTopChildren}

          <div className="flex flex-col gap-5">
            {formikBaseFields.map((field) => (
              <FormikDynamicFormItem
                field={field}
                formik={formik}
                key={field.name}
                tiptap={tiptap}
              />
            ))}
          </div>

          {formikAdvancedFields.length > 0 && (
            <div className="mb-4 mt-5 flex justify-end">
              <button
                className="link link--lg"
                onClick={() => setShowAdvancedFields(!showAdvancedFields)}
                type="button"
              >
                Advanced options
                <img
                  src="/chevron-down-blue.svg"
                  className={cn('transition', { 'rotate-180': showAdvancedFields })}
                  alt=""
                />
              </button>
            </div>
          )}

          <div className="flex flex-col gap-5">
            {showAdvancedFields &&
              formikAdvancedFields.map((field) => (
                <FormikDynamicFormItem
                  field={field}
                  formik={formik}
                  key={field.name}
                />
              ))}
          </div>
        </div>

        <div className="flex flex-col rounded-b-2xl p-6">
          <Button
            color="primary"
            className="w-full"
            type="submit"
            isProcessing={formik.isSubmitting}
            disabled={formik.isSubmitting}
          >
            {submitLabel ?? 'Save'}
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

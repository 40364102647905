import { ToolName } from '../../../../libs/tools/type.model.ts';
import { ToolboxGroup } from './ToolboxGroup.tsx';
import { NodeConfig } from '../../types.ts';
import ToolboxCategory from './ToolboxCategory.tsx';
import { useAssets } from '@/api/assets/useAssets.ts';
import { ToolboxAssetItem } from './ToolboxAssetItem.tsx';
import { AssetCategory, CategoryAssetNames } from '@/models/asset.model.ts';
import { useMemo } from 'react';
import { getAssetIconByAssetName } from '../../helpers/tool-helpers.ts';

type ToolboxAssetsProps = {
  nodeConfigs: NodeConfig[];
  searchQuery: string;
  category: AssetCategory;
};

export const ToolboxAssetCategoryList = ({ nodeConfigs, searchQuery, category }: ToolboxAssetsProps) => {
  const { assets } = useAssets();
  const assetNodeConfig = nodeConfigs.find((nodeConfig) => nodeConfig.toolConfig.name === ToolName.ASSETS)!;

  const assetsByCategory = useMemo(
    () =>
      (assets?.filter((asset) => asset.category === category) ?? []).filter((asset) =>
        asset.format.toLowerCase().includes(searchQuery.toLowerCase()),
      ),
    [assets, category],
  );

  if (!assets || assetsByCategory.length === 0) {
    return null;
  }

  return (
    <ToolboxGroup label="Assets">
      {CategoryAssetNames[category].map((assetName) => {
        const assetsByAssetName = assetsByCategory.filter((asset) => asset.name === assetName);

        if (assetsByAssetName.length === 0) {
          return null;
        } else {
          return (
            <ToolboxCategory
              key={assetName}
              categoryConfig={{
                label: assetName,
                icon: getAssetIconByAssetName(assetName),
              }}
              open={!!searchQuery}
            >
              {assetsByCategory
                .filter((asset) => asset.name === assetName)
                .map((asset) => (
                  <ToolboxAssetItem
                    key={asset.id}
                    asset={asset}
                    assetNodeConfig={assetNodeConfig}
                  />
                ))}
            </ToolboxCategory>
          );
        }
      })}
    </ToolboxGroup>
  );
};

import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { Button } from 'flowbite-react';
import RegenerateIcon from '@assets/regenerate.svg';
import GenerateIcon from '@assets/generate.svg';
import { ToolName } from '../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { mutate } from 'swr';
import { useGeneratePersona } from '@/api/segments/persona/generate-persona.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { ForcedPersonaGenerationContextSideModalBody } from './modals/ForcedPersonaGenerationContextSideModalBody.tsx';

export const GeneratePersonaCanvasButton = ({ personaDetails }: { personaDetails: PersonaDetails }) => {
  const { generatePersona } = useGeneratePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { listenerOn } = useWebSocketMessageListener();
  const { isSmallDataRoom } = useDataRoomItems();

  const onGenerate = async () => {
    if (isSmallDataRoom || personaDetails.generationContextItemsIds) {
      showLoader('Generating persona...');
      const wsChannel = `${ToolName.CANVAS_FILLER}: ${uuid()}`;
      await generatePersona(personaDetails.id, wsChannel);

      const { listenerOff } = listenerOn(wsChannel, () => {
        mutate(`/persona-canvas/${personaDetails.id}`);
        hideLoader();
        listenerOff();
      });
    } else {
      openSideModal(<ForcedPersonaGenerationContextSideModalBody />, { personaDetails });
    }
  };

  const generateAllButtonBody = personaDetails.positions.some((position) => position.value.length) ? (
    <>
      <RegenerateIcon className="path:fill-white" />
      Regenerate
    </>
  ) : (
    <>
      <GenerateIcon className="path:fill-white" />
      Generate
    </>
  );

  return (
    <>
      <Button
        key={'1'}
        color="primary"
        onClick={onGenerate}
        size="sm"
      >
        {generateAllButtonBody}
      </Button>
      ,
    </>
  );
};

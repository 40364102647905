import dagre from '@dagrejs/dagre';
import { Edge } from '@xyflow/react';
import { NODE_DIMENSIONS, NODE_GAP } from '../consts/whiteboard.const';
import { NodeType } from '../types';

function findPositionFromCenter(width: number, height: number, nodes: NodeType[]) {
  function isPositionAvailable(posX: number, posY: number) {
    return !nodes.some((node) => {
      const nodeX = node.position.x;
      const nodeY = node.position.y;
      const nodeWidth = node.measured?.width ?? NODE_DIMENSIONS.width;
      const nodeHeight = node.measured?.height ?? NODE_DIMENSIONS.height;

      return (
        posX < nodeX + nodeWidth + NODE_GAP &&
        posX + width + NODE_GAP > nodeX &&
        posY < nodeY + nodeHeight + NODE_GAP &&
        posY + height + NODE_GAP > nodeY
      );
    });
  }

  let x = 0;
  let y = 0;

  const stepSize = 20;

  let layer = 1;
  const maxLayers = 1000;
  while (layer <= maxLayers) {
    if (isPositionAvailable(x, y)) {
      return { x, y };
    }

    // Move in a spiral pattern (right, down, left, up, right, ...)
    for (let i = 0; i < layer; i++) {
      x += stepSize;
      if (isPositionAvailable(x, y)) return { x, y };
    }

    for (let i = 0; i < layer; i++) {
      y += stepSize;
      if (isPositionAvailable(x, y)) return { x, y };
    }

    layer++;

    for (let i = 0; i < layer; i++) {
      x -= stepSize;
      if (isPositionAvailable(x, y)) return { x, y };
    }

    for (let i = 0; i < layer; i++) {
      y -= stepSize;
      if (isPositionAvailable(x, y)) return { x, y };
    }

    layer++;

    if (layer > maxLayers) {
      return { x: 0, y: 0 };
    }
  }
  return { x: 0, y: 0 };
}

function findPositionNextToNode(node: NodeType) {
  const nodeWidth = node.measured?.width ?? NODE_DIMENSIONS.width;
  const x = node.position.x + nodeWidth + NODE_GAP;
  return { x, y: node.position.y };
}

export function calculateGroupDimensions(nodes: NodeType[]) {
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;

  nodes.forEach((node) => {
    const nodeWidth = node.measured?.width ?? NODE_DIMENSIONS.width;
    const nodeHeight = node.measured?.height ?? NODE_DIMENSIONS.height;
    const nodeX = node.position.x;
    const nodeY = node.position.y;

    minX = Math.min(minX, nodeX);
    minY = Math.min(minY, nodeY);
    maxX = Math.max(maxX, nodeX + nodeWidth);
    maxY = Math.max(maxY, nodeY + nodeHeight);
  });

  return {
    width: maxX - minX,
    height: maxY - minY,
    minX,
    minY,
  };
}

export function positionTemplateNodes(templateNodes: NodeType[], existingNodes: NodeType[]) {
  const { width, height } = calculateGroupDimensions(templateNodes);
  const position = findPosition(existingNodes, width, height);

  const referenceNode = templateNodes.reduce((a, b) => (a.position.x < b.position.x ? a : b));

  const referenceNodeNewPosition = position;
  const offsetX = referenceNodeNewPosition.x - referenceNode.position.x;
  const offsetY = referenceNodeNewPosition.y - referenceNode.position.y;

  return templateNodes.map((node) => {
    return {
      ...node,
      position: {
        x: node.position.x + offsetX,
        y: node.position.y + offsetY,
      },
      selected: true,
    };
  });
}

const MAX_X_POSITION = 5000;

export function findPosition(
  nodes: NodeType[],
  width: number = NODE_DIMENSIONS.width,
  height: number = NODE_DIMENSIONS.height,
) {
  if (!nodes.length) {
    return { x: 0, y: 0 };
  }

  const nodeWithHighestX = nodes.reduce((max, node) => {
    return node.position.x > max.position.x ? node : max;
  }, nodes[0]);

  if (nodeWithHighestX && nodeWithHighestX.position.x < MAX_X_POSITION) {
    return findPositionNextToNode(nodeWithHighestX);
  }

  return findPositionFromCenter(width, height, nodes);
}

export function positionNodesWithDagre(
  nodes: NodeType[],
  edges: Edge[],
  position: { x: number; y: number } = { x: 0, y: 0 },
  direction: 'TB' | 'LR' = 'LR',
): NodeType[] {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setGraph({ rankdir: direction, nodesep: NODE_GAP, ranksep: NODE_GAP });
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, {
      width: node.measured?.width ?? NODE_DIMENSIONS.width,
      height: node.measured?.height ?? NODE_DIMENSIONS.height,
    });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  return nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    return {
      ...node,
      position: {
        x: nodeWithPosition.x - (node.measured?.width ?? NODE_DIMENSIONS.width) / 2 + position.x,
        y: nodeWithPosition.y - (node.measured?.height ?? NODE_DIMENSIONS.height) / 2 + position.y,
      },
    };
  });
}

import { Button } from 'flowbite-react';
import OpenInNewTabIcon from '@assets/open-in-new-tab.svg';
import CloseIcon from '@assets/close.svg';
import { cn } from '@/helpers/cn.ts';
import { IconButton } from '@/components/IconButton.tsx';
import { useState } from 'react';
import { useSemrush } from '@/state/semrush.store';

interface InfoCardProps {
  title: string;
  description: string;
  buttonUrl: string;
  imageUrl: string;
  className?: string;
}

export const InfoCard = ({ title, description, buttonUrl, imageUrl, className }: InfoCardProps) => {
  const [isClosed, setIsClosed] = useState(false);
  const { isSemrush } = useSemrush();

  return (
    <>
      <div
        className={cn('grid grid-cols-[25%,1fr,2rem] rounded-xl bg-neutrals-100 p-6', className, { hidden: isClosed })}
      >
        <img
          src={imageUrl}
          alt=""
          className="rounded-lg"
        />
        <div className="ml-8 self-center">
          <h3 className="mb-3 text-h5-desktop">{title}</h3>
          <div className="mb-5 text-body-sm text-neutrals-700">{description}</div>
          {!isSemrush && (
            <Button
              color="secondary"
              className="w-fit pl-1"
              as="a"
              href={buttonUrl}
              target="_blank"
            >
              Learn more
              <OpenInNewTabIcon className="-mr-1 size-5" />
            </Button>
          )}
        </div>

        <IconButton
          className="self-start"
          onClick={() => setIsClosed(true)}
          icon={<CloseIcon className="size-6 path:fill-black" />}
        />
      </div>
    </>
  );
};

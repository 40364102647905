import { Breadcrumbs } from '@/components/Breadcrumbs.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';
import { useAuth } from '@/hooks/auth/useAuth';
import { ExpandLeftNavButton } from '@/layouts/MainLayout/components/ExpandLeftNavButton.tsx';
import { Breadcrumb } from '@/models/breadcrumb.interface';
import InviteIcon from '@assets/invite.svg';
import { Button } from 'flowbite-react';
import { useSaveReactFlow } from '../hooks/useSaveReactFlow';
import { useStopExecution } from '../hooks/useStopExecution';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { Whiteboard } from '../types';
import { TemplateDropdownOptions } from './templates/TemplateDropdownOptions';
import { useWhiteboardToXlsx } from '@/api/for-client-features/whiteboard-to-xlsx';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useCanUseForClientFeature } from '@/hooks/useCanUseForClientFeature';
import { ForClientFeature } from '@/enums/for-client-feature.enum';

type WhiteboardHeaderProps = {
  breadcrumbs: Breadcrumb[];
  whiteboard?: Whiteboard;
};

function WhiteboardHeader({ breadcrumbs, whiteboard }: WhiteboardHeaderProps) {
  const { stopExecution } = useStopExecution();
  const { saveReactFlow } = useSaveReactFlow();
  const { openModal } = useGlobalModal();
  const { user } = useAuth();
  const { whiteboardToXlsx } = useWhiteboardToXlsx();
  const { showLoader, hideLoader } = useGlobalLoader();
  const canUseWhiteboardToXlsx = useCanUseForClientFeature(ForClientFeature.WHITEBOARD_TO_XLSX);

  const isProcessingOrQueued = useWhiteboardStore((state) => state.isProcessingOrQueued());

  function handleSave() {
    saveReactFlow();
  }

  function handleShare() {
    openModal(MODALS.ShareWhiteboard, whiteboard);
  }

  async function handleSaveAsXLSX() {
    showLoader();

    const url = await whiteboardToXlsx(whiteboard!.id);
    const link = document.createElement('a');

    link.href = url;
    link.download = `${whiteboard?.name}.xlsx`;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    hideLoader();
  }

  return (
    <header className="flex h-[66px] items-center">
      <div className="flex w-full justify-between">
        <ExpandLeftNavButton />
        <div className="flex w-full items-center justify-between px-3">
          <div>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
      </div>
      {isProcessingOrQueued && (
        <Button
          color="secondary"
          size="xs"
          onClick={stopExecution}
          className="mr-3"
        >
          Stop
        </Button>
      )}

      {whiteboard?.template && (
        <>
          <div className="mr-3 text-caption-md uppercase text-red-600">template</div>
          <div onClick={(e) => e.stopPropagation()}>
            <TemplateDropdownOptions template={whiteboard.template}>
              <Button
                color="primary"
                size="xs"
              >
                edit
              </Button>
            </TemplateDropdownOptions>
          </div>
          <Button
            color="primary"
            size="xs"
            className="mr-3"
            onClick={handleSave}
          >
            save
          </Button>
        </>
      )}

      {user &&
        whiteboard &&
        !whiteboard.template &&
        (user.org_id === whiteboard.ownerId || user.sub === whiteboard.ownerId) && (
          <Button
            color="secondary"
            size="sm"
            onClick={handleShare}
            className="mr-3"
          >
            <InviteIcon className="size-4 path:fill-neutrals-1000" />
            Share
          </Button>
        )}

      {canUseWhiteboardToXlsx && (
        <Button
          color="secondary"
          size="sm"
          onClick={handleSaveAsXLSX}
          className="mr-3"
        >
          Save as XLSX
        </Button>
      )}
    </header>
  );
}

export default WhiteboardHeader;

import { IconType } from '@/components/Icon';
import CanvasesIcon from '@assets/canvases.svg';
import FilesIcon from '@assets/files.svg';
import PersonaIcon from '@assets/persona.svg';
import ProductsIcon from '@assets/products.svg';
import SearchIcon from '@assets/search-check-color.svg';
import { ToolCategory } from 'src/libs/tools/type.model.ts';
import { NodeConfig } from '../types';
import { getToolCategoryConfig } from './tool-helpers';

const dataRoomCategoryConfig = {
  files: {
    label: 'Files',
    icon: FilesIcon,
  },
  personas: {
    label: 'Segments',
    icon: PersonaIcon,
  },
  products: {
    label: 'Products',
    icon: ProductsIcon,
  },
  canvases: {
    label: 'Canvases',
    icon: CanvasesIcon,
  },
} as const;

export function getCategoryConfig(category: string) {
  if (category in ToolCategory) {
    return getToolCategoryConfig(category as ToolCategory);
  }
  if (category in dataRoomCategoryConfig) {
    return dataRoomCategoryConfig[category as keyof typeof dataRoomCategoryConfig];
  }
  return {
    label: category,
    icon: SearchIcon,
  };
}

export type CategoryConfig = {
  label: string;
  icon: IconType;
};

export function getToolsByCategory(tools: NodeConfig[]) {
  return tools.reduce(
    (acc, tool) => {
      const category = tool.toolConfig.category;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(tool);
      return acc;
    },
    {} as Record<ToolCategory, NodeConfig[]>,
  );
}

import { useApi } from '@/api/api.ts';
import { AssetCreatePayload } from '@/models/asset.model.ts';
import { mutate } from 'swr';

export const useCreateAsset = () => {
  const { postFetcher } = useApi();

  const createAsset = async (data: AssetCreatePayload) => {
    await postFetcher('/assets', { arg: data });
    await mutate('/assets');
  };

  return { createAsset };
};

import { useStartExecutionEngine } from '../api/start-execution-engine';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { useUpdateEENodesOutputs } from './useUpdateEENodesOutputs';

export function useStartExecution(reactFlowNodeId: string) {
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);
  const updateNodeStreamOutput = useWhiteboardStore((state) => state.updateNodeStreamOutput);
  const validateNodesForExecution = useWhiteboardStore((state) => state.validateNodesForExecution);
  const getReactFlow = useWhiteboardStore((state) => state.getReactFlow);
  const setStopped = useWhiteboardStore((state) => state.setStopped);
  const { startExecutionEngine } = useStartExecutionEngine();
  const { updateEENodesOutputs } = useUpdateEENodesOutputs();

  async function startExecution() {
    if (!validateNodesForExecution(reactFlowNodeId)) {
      return;
    }

    try {
      await updateEENodesOutputs(reactFlowNodeId);

      updateNodeData(reactFlowNodeId, { loading: true, output: undefined });
      updateNodeStreamOutput(reactFlowNodeId, undefined);
      setStopped(false);

      const reactFlow = getReactFlow();
      await startExecutionEngine(reactFlowNodeId, reactFlow);
    } catch {
      updateNodeData(reactFlowNodeId, { loading: false });
    }
  }

  return {
    startExecution,
  };
}

import { createBrowserRouter, Outlet } from 'react-router-dom';

import { Dashboard } from './routes/dashboard/Dashboard.tsx';
import { DataPanel } from './routes/data/DataPanel.tsx';
import { App } from './routes/App.tsx';
import { Urls } from '@/consts/urls.ts';
import { CanvasPanel } from './routes/canvases/CanvasPanel.tsx';
import { MyCanvases } from './routes/canvases/routes/my-canvases/MyCanvases.tsx';
import { CanvasLibrary } from './routes/canvases/routes/canvas-library/CanvasLibrary.tsx';
import { StrategyCanvasDetailsPage } from './routes/canvases/routes/canvas-deatils/StrategyCanvasDetailsPage.tsx';
import { FlowEditor } from './routes/flows/FlowEditor.tsx';
import { ProjectListPanel } from './routes/projects/ProjectListPanel.tsx';
import { ProjectDetails } from './routes/projects/routes/project-details/ProjectDetails.tsx';
import { FlowsLibrary } from './routes/projects/routes/flows-library/FlowsLibrary.tsx';
import { FlowTemplateEditor } from './routes/flows/FlowTemplateEditor.tsx';
import { WebSearcherPage } from './routes/web-searcher/WebSearcherPage.tsx';
import { UserSettings } from './routes/settings/UserSettings.tsx';
import { OrganizationSettings } from './routes/settings/OrganizationSettings.tsx';
import { OrganizationMembers } from './routes/settings/OrganizationMembers.tsx';
import { WorkspaceSettings } from './routes/workspace/WorkspaceSettings.tsx';
import { WorkspaceMembers } from './routes/workspace/WorkspaceMembers.tsx';
import { AuthorizedApp } from './routes/AuthorizedApp.tsx';
import { PublicApp } from './routes/public-app/PublicApp.tsx';
import { PublicWebSearcherPage } from './routes/public-app/web-searcher/PublicWebSearcherPage.tsx';
import { PricingPage } from './routes/pricing/PricingPage.tsx';
import { WorkspacesListPanel } from './routes/workspaces/WorkspacesPage.tsx';
import TableScreen from './libs/tables/example/TableScreen.tsx';
import { SegmentsPanel } from './routes/segments/SegmentsPanel.tsx';
import { PersonaCanvasDetailsPage } from './routes/segments/persona/PersonaCanvasDetailsPage.tsx';
import { ProductCanvasPanel } from './routes/products/ProductCanvasList.tsx';
import { ProductCanvasView } from './routes/products/ProductCanvasView.tsx';
import { CreateOrganizationPage } from './routes/settings/CreateOrganizationPage.tsx';
import { OnboardingStarter } from './routes/public-app/onboarding-starter/OnboardingStarter.tsx';
import { StartOnboardingLoadingScreen } from './routes/onboarding/StartOnboardingLoadingScreen.tsx';
import { OnboardingWebSearcher } from './routes/onboarding/web-searcher/OnboardingWebSearcher.tsx';
import { OnboardingCanvasInitializer } from './routes/onboarding/canvas/OnboardingCanvasInitializer.tsx';
import { OnboardingCanvasDetails } from './routes/onboarding/canvas/OnboardingCanvasDetails.tsx';
import { OnboardingFlowInitializer } from './routes/onboarding/flow/OnboardingFlowInitializer.tsx';
import { SemrushMarketplace } from './routes/public-app/semrush/SemrushMarketplace.tsx';
import { Page404 } from './routes/404/404.tsx';
import { WhiteboardPage } from './routes/whiteboards/WhiteboardPage.tsx';
import { SemrushPage } from './routes/semrush/SemrushPage.tsx';
import { MainLayout } from '@/layouts/MainLayout/MainLayout.tsx';
import { AssetAdminPanel } from './routes/admin-panel/AssetAdminPanel.tsx';
import { PublicTemplatesPage } from './routes/public-templates/PublicTemplatesPage.tsx';
import { PublicTemplatePage } from './routes/public-templates/PublicTemplatePage.tsx';
import { PublicWhiteboardPage } from './routes/public-whiteboard/PublicWhiteboardPage.tsx';
import { WhiteboardGainAccessPage } from './routes/whiteboards/WhiteboardGainAccessPage.tsx';
import { PublicWhiteboardTemplatePreview } from './routes/public-templates/preview/PublicWhiteboardTemplatePreview.tsx';
import { WhiteboardOnboardingInitializer } from './routes/whiteboards/onboarding/WhiteboardOnboardingInitializer.tsx';

export const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '',
        element: (
          <>
            <AuthorizedApp />
          </>
        ),
        children: [
          {
            path: '',
            element: (
              <MainLayout>
                <Outlet />
              </MainLayout>
            ),
            children: [
              {
                path: Urls.ADMIN_PANEL,
                element: <AssetAdminPanel />,
              },
              {
                path: Urls.SHARED,
                children: [
                  {
                    path: Urls.FLOW_EDITOR,
                    element: <FlowEditor />,
                  },
                ],
              },
              {
                path: Urls.ONBOARDING,
                element: <Outlet />,
                children: [
                  {
                    path: Urls.ONBOARDING_PREPARE,
                    element: <StartOnboardingLoadingScreen />,
                  },
                  {
                    path: Urls.ONBOARDING_FLOW_INITIALIZER,
                    element: <OnboardingFlowInitializer />,
                  },
                  {
                    path: `${Urls.PROJECTS}/${Urls.PROJECT}/${Urls.FLOW_EDITOR}`,
                    element: <FlowEditor onboarding={true} />,
                  },
                  {
                    path: Urls.WEB_SEARCHER,
                    element: <OnboardingWebSearcher />,
                  },
                  {
                    path: Urls.CANVASES,
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <OnboardingCanvasInitializer />,
                      },
                      {
                        path: Urls.CANVAS,
                        element: <OnboardingCanvasDetails />,
                      },
                    ],
                  },
                ],
              },
              {
                path: Urls.PRICING,
                element: <PricingPage />,
              },
              {
                path: Urls.DASHBOARD,
                element: <Dashboard />,
              },
              {
                path: '/tables',
                element: <TableScreen />,
              },
              // {
              //   path: Urls.PRE_CANVAS_TEST,
              //   element: <PreCanvas />,
              // },
              {
                path: Urls.WORKSPACES,
                element: <WorkspacesListPanel />,
              },
              {
                path: Urls.DATA_ROOM,
                element: <DataPanel />,
              },
              {
                path: Urls.SEMRUSH,
                element: <SemrushPage />,
              },
              {
                path: Urls.SEMRUSH_VIEW,
                element: <SemrushPage />,
              },
              {
                path: Urls.WEB_SEARCHER,
                element: <WebSearcherPage />,
              },
              {
                path: Urls.WEB_SEARCHER_ROOM,
                element: <WebSearcherPage />,
              },
              {
                path: Urls.CANVASES,
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <CanvasPanel />,
                  },
                  {
                    path: Urls.CANVAS,
                    element: <StrategyCanvasDetailsPage />,
                  },
                  {
                    path: Urls.MY_CANVASES,
                    element: <MyCanvases />,
                  },
                  {
                    path: Urls.CANVAS_LIBRARY,
                    element: <CanvasLibrary />,
                  },
                ],
              },
              {
                path: Urls.SEGMENTS,
                children: [
                  {
                    path: '',
                    element: <SegmentsPanel />,
                  },
                  {
                    path: Urls.PERSONA_DETAILS,
                    element: <PersonaCanvasDetailsPage />,
                  },
                ],
              },
              {
                path: Urls.PRODUCTS,
                children: [
                  {
                    path: '',
                    element: <ProductCanvasPanel />,
                  },
                  {
                    path: Urls.PRODUCT_CANVAS,
                    element: <ProductCanvasView />,
                  },
                ],
              },
              {
                path: Urls.USER_SETTINGS,
                element: <UserSettings />,
              },
              {
                path: Urls.CREATE_ORGANIZATION,
                element: <CreateOrganizationPage />,
              },
              {
                path: Urls.ORGANIZATION_SETTINGS,
                element: <OrganizationSettings />,
              },
              {
                path: Urls.ORGANIZATION_MEMBERS,
                element: <OrganizationMembers />,
              },
              {
                path: Urls.WORKSPACE_SETTINGS,
                element: <WorkspaceSettings />,
              },
              {
                path: Urls.WORKSPACE_MEMBERS,
                element: <WorkspaceMembers />,
              },
              {
                path: Urls.PROJECTS,
                element: <Outlet />,
                children: [
                  {
                    path: '',
                    element: <ProjectListPanel />,
                  },
                  {
                    path: Urls.PROJECT,
                    element: <Outlet />,
                    children: [
                      {
                        path: '',
                        element: <ProjectDetails />,
                      },
                      {
                        path: Urls.FLOW_EDITOR,
                        element: <FlowEditor />,
                      },
                      {
                        path: Urls.FLOWS_LIBRARY,
                        element: <FlowsLibrary />,
                      },
                      {
                        path: Urls.WHITEBOARD,
                        element: <WhiteboardPage />,
                        handle: {
                          hideMemoryLog: true,
                        },
                      },
                    ],
                  },
                ],
              },
              {
                path: Urls.FLOWS_LIBRARY,
                element: <FlowsLibrary />,
              },
              {
                path: Urls.FLOW_TEMPLATE_EDITOR,
                element: <FlowTemplateEditor />,
              },
              {
                path: Urls.WHITEBOARD_GAIN_ACCESS,
                element: <WhiteboardGainAccessPage />,
              },
              {
                path: Urls.WHITEBOARD,
                element: <WhiteboardPage />,
                handle: {
                  hideMemoryLog: true,
                },
              },
            ],
          },
          {
            path: Urls.NO_MENU_APP,
            element: <Outlet />,
            children: [
              {
                path: Urls.SEMRUSH,
                element: <SemrushPage />,
              },
              {
                path: Urls.SEMRUSH_VIEW,
                element: <SemrushPage />,
              },
              {
                path: Urls.WEB_SEARCHER,
                element: <WebSearcherPage />,
              },
              {
                path: Urls.WEB_SEARCHER_ROOM,
                element: <WebSearcherPage />,
              },
              {
                path: Urls.WHITEBOARD_ONBOARDING_INITIALIZER,
                element: <WhiteboardOnboardingInitializer />,
              },
            ],
          },
        ],
      },
      {
        path: Urls.PUBLIC_APP,
        element: <PublicApp />,
        children: [
          {
            path: Urls.WHITEBOARD_TEMPLATES,
            element: <Outlet />,
            children: [
              {
                path: Urls.WHITEBOARD_TEMPLATE_PREVIEW,
                element: <PublicWhiteboardTemplatePreview />,
              },
            ],
          },
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          // dowal tu refactoring tych nazw, daj je do Urls i wgl popraw
          {
            path: 'templates',
            element: <PublicTemplatesPage />,
          },
          {
            path: 'templates/:uuid',
            element: <PublicTemplatePage />,
          },
          {
            path: 'whiteboard/:uuid',
            element: <PublicWhiteboardPage />,
          },
          {
            path: Urls.SEMRUSH,
            element: <SemrushMarketplace />,
          },
          {
            path: Urls.WEB_SEARCHER,
            element: <PublicWebSearcherPage />,
          },
          {
            path: Urls.WEB_SEARCHER_ROOM,
            element: <PublicWebSearcherPage />,
          },
          {
            path: Urls.ONBOARDING_STARTER,
            children: [
              {
                path: '',
                element: <OnboardingStarter />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Page404 />,
  },
]);

import fileToBase64 from '@/helpers/file-to-base64';
import { useMemo } from 'react';
import { ToolField, ToolName, ToolValue } from 'src/libs/tools/type.model';
import { FormValues, validateData } from '../helpers/data-transfer-helpers';
import { DataItemType, dataItemTypeToToolMap } from './useDataTransferNodesCreator';
import { useNodeRegistry } from './useNodeRegistry';

export function useNodeParamsFromDataTransferItem() {
  const { nodeConfigs } = useNodeRegistry();

  const getNodeConfig = useMemo(() => {
    const configMap = new Map(nodeConfigs.map((config) => [config.toolConfig.name, config]));
    return (toolName: ToolName) => configMap.get(toolName);
  }, [nodeConfigs]);

  async function nodeParamsFromDataTransferItem(data: File | string, dataItemType: DataItemType) {
    const { toolName, inputKey } = dataItemTypeToToolMap[dataItemType];
    const nodeConfig = getNodeConfig(toolName);

    if (!nodeConfig) {
      return {
        success: false,
        error: `No configuration found for tool: ${toolName}`,
        type: dataItemType,
      };
    }

    const defaultValues: FormValues = Object.fromEntries(
      Object.entries(nodeConfig.toolConfig.config.input)
        .map(([key, value]) => [key, value.defaultValue])
        .filter(([, value]) => value !== undefined),
    );

    const values: FormValues =
      data instanceof File
        ? {
            [inputKey]: data,
            fileName: data.name,
            mimeType: data.type,
          }
        : { [inputKey]: data };

    if (
      !validateData(nodeConfig, {
        ...defaultValues,
        ...values,
      })
    ) {
      return {
        success: false,
        error: 'Data validation failed',
        type: dataItemType,
      };
    }

    const parsedValue = data instanceof File ? await fileToBase64(data) : data;

    const fields: ToolField[] = Object.entries(values).map(([key, value]) => ({
      key,
      value: key === inputKey ? parsedValue : (value as ToolValue),
    }));

    return {
      toolConfig: nodeConfig.toolConfig,
      fields,
      execute: true,
      nodeName: data instanceof File ? data.name : nodeConfig.name,
    };
  }
  return {
    nodeParamsFromDataTransferItem,
  };
}

import { Button, HR } from 'flowbite-react';
import { useEffect, useRef, useState } from 'react';
import { ToolConfig, ToolOutput as ToolOutputFinalType, ToolOutputType } from './type.model';
import { useToolOutputHistory } from '@/api/tools/get-tool-output-history.ts';
import { useUpdateToolCurrentOutput } from '@/api/tools/update-tool-current-output.ts';
import { ToolTextOutputEditor } from './ToolTextOutputEditor.tsx';
import { llmMarked } from '@/helpers/llmMarked.ts';
import { cn } from '@/helpers/cn.ts';
import toast from 'react-hot-toast';
import { finishEditingTool, setCurrentlyEditingToolId, useToolOutputEditorStore } from './tool-output-editor.store.ts';
import { ToolHtmlOutput } from './ToolHtmlOutput.tsx';

export interface ToolOutputProps {
  output: ToolOutputFinalType;
  toolId: number;
  outputType: ToolOutputType;
  showHistory?: boolean;
  className?: string;
  enableEditing?: boolean;
  onFinishEditing?: (output: string) => void;
  scrollToBottomOnOutputUpdate?: boolean;
  toolConfigs?: ToolConfig[];
}

export const ToolOutput = ({
  output,
  toolId,
  outputType,
  showHistory,
  className,
  enableEditing,
  onFinishEditing,
  scrollToBottomOnOutputUpdate = true,
  toolConfigs,
}: ToolOutputProps) => {
  const { historyOutputs, outputHistory } = useToolOutputHistory(toolId);
  const [currentOutput, setCurrentOutput] = useState<string | string[] | Record<string, string>>(output);
  const [allOutputs, setAllOutputs] = useState<Array<string | string[] | Record<string, string>>>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [llmMarkedOutput, setLlmMarkedOutput] = useState<string>('');
  const { updateCurrentOutput } = useUpdateToolCurrentOutput();
  const [isSavingOutput, setIsSavingOutput] = useState(false);
  const isEditingOutput = useToolOutputEditorStore((state) => state.currentlyEditingToolId) === toolId;
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const canScroll = output && scrollToBottomOnOutputUpdate;

    if (canScroll) {
      scrollContainerRef.current?.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [output]);

  useEffect(() => {
    if (outputType === ToolOutputType.TEXT && typeof output === 'string') {
      setLlmMarkedOutput(llmMarked(output) as string);
    }
  }, [output]);

  useEffect(() => {
    setCurrentOutput(output);
    setAllOutputs([output, ...historyOutputs]);
  }, [output]);

  const setNext = () => {
    if (currentIndex < allOutputs.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentOutput(allOutputs[currentIndex + 1]);
    }
  };

  const setPrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentOutput(allOutputs[currentIndex - 1]);
    }
  };

  const getImage = (output: string | string[]) => {
    if (Array.isArray(output)) {
      return output.map((image) => (
        <img
          key={image}
          src={import.meta.env.VITE_ASSETS + image}
          alt="Output image"
        />
      ));
    }

    return (
      <img
        src={import.meta.env.VITE_ASSETS + output}
        alt="Output image"
      />
    );
  };

  const getVideo = (output: string | string[]) => {
    if (Array.isArray(output)) {
      return output.map((video) => (
        <video
          key={video}
          src={import.meta.env.VITE_ASSETS + video}
        />
      ));
    }
  };

  const startEditingOutput = () => {
    if (!enableEditing || outputType !== ToolOutputType.TEXT) {
      return;
    }

    setCurrentlyEditingToolId(toolId);
  };

  const finishEditingOutput = async (newOutput: string) => {
    setIsSavingOutput(true);
    try {
      await updateCurrentOutput(
        Number(toolId),
        newOutput,
        outputHistory!.find((_, index) => index === currentIndex)!.id,
      );
      onFinishEditing?.(newOutput);
    } catch {
      toast.error('Failed to save output');
    } finally {
      setIsSavingOutput(false);
      finishEditingTool();
    }
  };

  const cancelEditingOutput = () => {
    onFinishEditing?.(currentOutput as string);
    finishEditingTool();
  };

  return (
    <>
      {showHistory && (
        <>
          <div className="flex gap-2">
            <Button onClick={setPrev}>Prev</Button>
            <Button onClick={setNext}>Next</Button>
            {/*<Button onClick={() => updateCurrentOutput(toolId as number, currentOutput)}>Set as current</Button>*/}
          </div>
          <HR />
        </>
      )}

      <div className="relative">
        <div
          ref={scrollContainerRef}
          onDoubleClick={startEditingOutput}
          className={cn('relative', className, { '!p-0': isEditingOutput })}
        >
          {isEditingOutput && toolConfigs ? (
            <ToolTextOutputEditor
              initialOutput={llmMarkedOutput}
              onFinishEditing={finishEditingOutput}
              onCancelEditing={cancelEditingOutput}
              toolConfigs={toolConfigs}
            />
          ) : (
            <>
              {outputType === ToolOutputType.TEXT && typeof currentOutput === 'string' && (
                <p
                  className="ProseMirror cursor-text !p-0"
                  dangerouslySetInnerHTML={{ __html: llmMarkedOutput }}
                ></p>
              )}
              {outputType === ToolOutputType.JSON && <ToolHtmlOutput toolId={toolId} />}
              {outputType === ToolOutputType.IMAGE && getImage(currentOutput as string | string[])}
              {outputType === ToolOutputType.VIDEO && getVideo(currentOutput as string | string[])}
            </>
          )}
        </div>
        {isSavingOutput && (
          <div className="absolute left-0 top-0 z-20 flex h-full w-full flex-col items-center justify-center gap-2 bg-white opacity-80">
            <img
              src="/loading-blue.svg"
              className="!size-10 animate-spin-slow"
              alt=""
            />
            <div className="text-body-lg">Saving output...</div>
          </div>
        )}
      </div>
    </>
  );
};

import { ReactNode } from 'react';
import { CheckboxWithLabel } from '@/components/CheckboxWithLabel.tsx';

interface FormikCheckboxWithLabelProps {
  name: string;
  label: ReactNode;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
}

export function FormikCheckboxWithLabel({ name, label, formik, disabled }: FormikCheckboxWithLabelProps) {
  return (
    <CheckboxWithLabel
      name={name}
      label={label}
      checked={formik.values[name]}
      onChange={formik.handleChange}
      disabled={disabled}
    />
  );
}

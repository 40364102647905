import { cn } from '@/helpers/cn.ts';
import RedoIcon from '@assets/redo.svg';
import UndoIcon from '@assets/undo.svg';
import { useWhiteboardStore } from 'src/routes/whiteboards/store/whiteboard.store.ts';
import { ExecutionEngineNode, NodeData, NodeType } from '../types';

type OutputHistorySwitcherProps = {
  nodeId: NodeType['id'];
  nodeData: NodeData;
  executionEngineNode: ExecutionEngineNode;
};

export function OutputHistorySwitcher({ nodeId, nodeData, executionEngineNode }: OutputHistorySwitcherProps) {
  const setOutputHistoryIndex = useWhiteboardStore((store) => store.setOutputHistoryIndex);
  const syncOutputs = useWhiteboardStore((store) => store.syncOutputs);

  const currentOutputIndex = nodeData.outputHistoryIndex || 0;
  const outputLength = executionEngineNode.tool.outputHistory?.length || 0;

  const hasPrevious = currentOutputIndex > 0;
  const hasNext = currentOutputIndex < outputLength - 1;

  function prev() {
    if (hasPrevious) {
      setOutputHistoryIndex(nodeId, currentOutputIndex - 1);
      syncOutputs(nodeId);
    }
  }

  function next() {
    if (hasNext) {
      setOutputHistoryIndex(nodeId, currentOutputIndex + 1);
      syncOutputs(nodeId);
    }
  }

  if (outputLength <= 1 || nodeData.loading) return null;

  return (
    <div className="flex items-center gap-1 px-1">
      <button
        onClick={prev}
        disabled={!hasPrevious}
        className={cn('rounded-lg p-2 transition-all', !hasPrevious ? 'opacity-50' : 'hover:bg-neutrals-100')}
        title="Previous output"
      >
        <UndoIcon className="size-5 path:fill-neutrals-600" />
      </button>

      <span className="text-xs text-neutrals-600">
        {currentOutputIndex + 1}/{outputLength}
      </span>

      <button
        onClick={next}
        disabled={!hasNext}
        className={cn('rounded-lg p-2 transition-all', !hasNext ? 'opacity-50' : 'hover:bg-neutrals-100')}
        title="Next output"
      >
        <RedoIcon className="size-5 path:fill-neutrals-600" />
      </button>
    </div>
  );
}

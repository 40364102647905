import { GLOBAL_LOADER_MESSAGES } from '@/components/GlobalLoader/global-loader-messages.const.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { cn } from '@/helpers/cn';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper.ts';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface.ts';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAddResource } from '@/api/data-room/add-resource.ts';
import Upload from '@assets/upload-dr.svg';
import { Button } from 'flowbite-react';
import { MODALS } from '@/components/GlobalModal/modals.const';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { useSemrush } from '@/state/semrush.store';

function DataDropFile() {
  const { addResource } = useAddResource();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { openModal } = useGlobalModal();
  const { isSemrush } = useSemrush();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files: AddResourcePayload[] = await inputsPayloadMapper[InputType.File](acceptedFiles);
      showLoader(GLOBAL_LOADER_MESSAGES.AddingData);
      await Promise.all(files.map((file) => addResource(file)));
      hideLoader();
    },
    [addResource, hideLoader, showLoader],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
    multiple: false,
  });

  return (
    <div
      className="rounded-3xl p-6 text-center"
      style={{ backgroundImage: 'url(/gradient-wide.svg)', backgroundSize: 'cover' }}
    >
      <div
        {...getRootProps()}
        className={cn(
          'flex w-full flex-col items-center rounded-xl border border-dashed border-primary-600 bg-white bg-opacity-40 p-8',
          {
            'bg-opacity-100': isDragActive,
          },
        )}
      >
        <label className="flex cursor-pointer flex-col items-center pt-6">
          <img
            src="/data-room-drag-drop.png"
            alt=""
          />
          <span className="mb-4 mt-2 text-3xl text-body-md text-neutrals-700">Drag & drop any file </span>
          <span className="text-body-sm text-neutrals-500">TXT, DOCX, PPTX, CSV, PDF, MPEG, MP4, MOV, AVI</span>
          <input {...getInputProps()} />
        </label>

        <div className="mt-10 flex items-center justify-center gap-3 rounded-2xl border-[1px] border-gray-200 px-5 py-3">
          <p className="flex items-center justify-center gap-2">
            <Upload />
            Add data from your device
          </p>
          <Button
            className="relative h-10 w-10"
            color="secondary"
            onClick={() => openModal(MODALS.DataRoomAddWebsiteLinks)}
          >
            <img
              className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4"
              src="/link-black.svg"
              alt=""
            />
          </Button>
          {!isSemrush && (
            <Button
              className="relative h-10 w-10"
              color="secondary"
              onClick={() => openModal(MODALS.DataRoomAddRecordings)}
            >
              <img
                className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4"
                src="/play-black.svg"
                alt=""
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default DataDropFile;

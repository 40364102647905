import SearchCheck from '@assets/search-check-black.svg';
import SemrushLogo from '@assets/semrush-logo.svg';
import { Urls } from '@/consts/urls.ts';

export const WebsearchTabsOptions = [
  {
    label: 'Web Searcher',
    href: `../${Urls.WEB_SEARCHER}`,
    icon: <SearchCheck />,
  },
  {
    label: 'Semrush',
    href: `../${Urls.SEMRUSH}`,
    icon: <SemrushLogo className="scale-75 rounded-md" />,
  },
];

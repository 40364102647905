import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { FormikProvider, useFormik } from 'formik';
import { Button, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { HiSearch } from 'react-icons/hi';
import { object, string } from 'yup';
import { useAssets } from '@/api/assets/useAssets.ts';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/Card.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { DropdownAssetCardOptions } from './components/DropdownAssetCardOptions.tsx';
import { createElement, useMemo } from 'react';
import { PageTitle } from '@/components/PageTitle.tsx';
import { getAssetIconByAssetName } from '../whiteboards/helpers/tool-helpers.ts';

export const AssetAdminPanel = () => {
  const { assets } = useAssets();
  const { openModal } = useGlobalModal();

  const formik = useFormik({
    initialValues: {
      search: '',
    },
    validationSchema: object({
      search: string(),
    }),
    onSubmit: () => {},
  });

  const filteredAssets = useMemo(
    () =>
      assets
        ?.filter((asset) => asset.format.toLowerCase().includes(formik.values.search.toLowerCase()))
        .sort((a, b) => b.id - a.id),
    [assets, formik.values.search],
  );

  return (
    <>
      <PageTitle title="Digitalfirst.ai | Admin Panel" />

      <PageWithHeaderLayout
        header={{
          breadcrumbs: [{ label: 'Admin Panel', href: '#' }],
          icon: '/folder-black.svg',
          rightAddons: [
            <Button
              onClick={() => openModal(MODALS.AssetModal)}
              key="1"
              size="sm"
            >
              Add Asset
            </Button>,
          ],
        }}
      >
        <div className="flex items-start gap-8">
          <FormikProvider value={formik}>
            <div className="flex min-w-64 flex-col gap-3">
              <div className="relative">
                <TextInput
                  {...getDfFlowbiteFieldProps('search', formik)}
                  placeholder="Search"
                  icon={HiSearch}
                />
              </div>
            </div>
          </FormikProvider>

          <div className="grid grow grid-cols-3 gap-6">
            <>
              {filteredAssets?.map((asset) => (
                <div
                  key={asset.id}
                  className="relative"
                >
                  <div className="absolute right-4 top-4">
                    <DropdownAssetCardOptions asset={asset}>
                      <div>
                        <IconButton
                          icon="/three-dots-gray.svg"
                          size={24}
                          alt="More"
                        />
                      </div>
                    </DropdownAssetCardOptions>
                  </div>
                  <Card
                    key={asset.id}
                    onClick={() => openModal(MODALS.AssetModal, { asset })}
                    interactive
                  >
                    <CardHeader>
                      <div className="mb-2 *:size-8">{createElement(getAssetIconByAssetName(asset.name))}</div>
                      <CardTitle>{asset.format}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="mb-2 text-body-sm font-medium">{asset.name}</div>
                    </CardContent>
                  </Card>
                </div>
              ))}
            </>
          </div>
        </div>
      </PageWithHeaderLayout>
    </>
  );
};

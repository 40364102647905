import { useTemplateFlowAccess } from '@/api/flow/use-template-flow-access';
import { usePermissions } from '@/api/permission/get-permissions';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { WHITEBOARD_TEMPLATE_CATEGORIES, WhiteboardTemplateCategory } from '@/models/whiteboard-template';
import { Button, Checkbox, Label, Select, Textarea, TextInput } from 'flowbite-react';
import { FormikProps, FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

export type WhiteboardTemplatePayload = {
  name: string;
  description?: string;
  category: WhiteboardTemplateCategory;
  forOrganization?: string;
  public?: boolean;
};

type WhiteboardTemplateFormProps = {
  onSubmit: (values: WhiteboardTemplatePayload) => Promise<void>;
  initialValues: WhiteboardTemplatePayload;
  submitLabel: string;
};

export function WhiteboardTemplateForm({ onSubmit, initialValues, submitLabel }: WhiteboardTemplateFormProps) {
  const { closeModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { canCreateFlowsLibrary } = usePermissions();

  const formik = useFormik<WhiteboardTemplatePayload>({
    initialValues,
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
      category: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (values) => {
      closeModal();
      showLoader();
      await onSubmit(values);
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form
        className="flex flex-col gap-3"
        onSubmit={formik.handleSubmit}
      >
        <div>
          <Label htmlFor="name">Name</Label>
          <TextInput
            {...getDfFlowbiteFieldProps('name', formik)}
            placeholder="Template name"
          />
        </div>

        <div>
          <Label htmlFor="description">Description</Label>
          <Textarea
            {...getDfFlowbiteFieldProps('description', formik)}
            placeholder="Template description"
            className="rounded-lg"
            rows={2}
          />
        </div>
        {canCreateFlowsLibrary && <WhiteboardTemplateAdminFields formik={formik} />}

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            {submitLabel}
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}

function WhiteboardTemplateAdminFields({ formik }: { formik: FormikProps<WhiteboardTemplatePayload> }) {
  const { templateAccess } = useTemplateFlowAccess();

  return (
    <>
      <div>
        <Label htmlFor="category">Category</Label>
        <Select {...getDfFlowbiteFieldProps('category', formik)}>
          {WHITEBOARD_TEMPLATE_CATEGORIES.map((option) => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </Select>
      </div>
      <div>
        <Label htmlFor="forOrganization">Organization</Label>
        <Select {...getDfFlowbiteFieldProps('forOrganization', formik)}>
          {templateAccess?.map((option) => (
            <option
              key={option.owner}
              value={option.owner}
            >
              {option.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex gap-2">
        <Checkbox
          checked={formik.values.public}
          onChange={formik.handleChange}
          name="public"
        />
        <Label htmlFor="public">Use as public mini app (without DF account)</Label>
      </div>
    </>
  );
}

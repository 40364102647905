import { defaultNodes, DocxSerializerState } from 'prosemirror-docx';
import { Node } from 'prosemirror-model';
export const DocxSchemaSerializer = {
  ...defaultNodes,
  hardBreak: defaultNodes.hard_break,
  codeBlock: defaultNodes.code_block,
  orderedList: defaultNodes.ordered_list,
  listItem: defaultNodes.list_item,
  bulletList: defaultNodes.bullet_list,
  horizontalRule: defaultNodes.horizontal_rule,

  quote(state: DocxSerializerState, node: Node) {
    state.renderInline(node);
    state.closeBlock(node);
  },
  blockquoteFigure(state: DocxSerializerState, node: Node) {
    state.renderInline(node);
    state.closeBlock(node);
  },
  imageBlock(state: DocxSerializerState, node: Node) {
    state.image(
      node.attrs.src,
      node.attrs.width ? Number(node.attrs?.width?.replace('%', '')) : 100,
      node?.attrs?.align ?? 'center',
    );
    state.closeBlock(node);
  },
  image(state: DocxSerializerState, node: Node) {
    state.image(
      node.attrs.src,
      node.attrs.width ? Number(node.attrs?.width?.replace('%', '')) : 100,
      node?.attrs?.align ?? 'center',
    );
    state.closeBlock(node);
  },
  dfGenOutput(state: DocxSerializerState, node: Node) {
    state.renderInline(node);
    state.closeBlock(node);
  },
  DFUserInput(state: DocxSerializerState, node: Node) {
    state.renderInline(node);
    state.closeBlock(node);
  },
  emoji(state: DocxSerializerState, node: Node) {
    state.renderInline(node);
    state.closeBlock(node);
  },
  quoteCaption(state: DocxSerializerState, node: Node) {
    state.renderInline(node);
    state.closeBlock(node);
  },
  table(state: DocxSerializerState, node: Node) {
    state.table(node, { tableOptions: { columnWidths: [3505, 5505] } });
  },
};

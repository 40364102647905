import { WebSearcherPanel } from '../../web-searcher/components/WebSearcherPanel.tsx';
import { WebSearcherNewSearchForm } from '../../web-searcher/components/WebSearcherNewSearchForm.tsx';
import { usePublicWebSearcherRooms } from '@/state/app-store.ts';

export function PublicWebSearcherPage() {
  const { publicWebSearcherRooms } = usePublicWebSearcherRooms();

  return (
    <>
      <title>Digitalfirst.ai | WebSearcher</title>
      <div className="h-svh w-full">
        {publicWebSearcherRooms.length ? (
          <WebSearcherPanel webSearcherRooms={publicWebSearcherRooms} />
        ) : (
          <WebSearcherNewSearchForm />
        )}
      </div>
    </>
  );
}

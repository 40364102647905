/* eslint-disable @typescript-eslint/no-explicit-any */
import { useVirtualizer } from '@tanstack/react-virtual';
import { Checkbox, Label } from 'flowbite-react';
import { FormikProps } from 'formik';
import { useRef } from 'react';
import GeneratedIcon from '@assets/generate.svg';

interface Url {
  url: string;
  checked: boolean;
}

interface VirtualSitesListProps {
  urls: Url[];
  formik: FormikProps<any>;
}

export const VirtualSitesList = ({ urls, formik }: VirtualSitesListProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const virtualizer = useVirtualizer({
    count: urls.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 30,
    overscan: 30,
    enabled: true,
    gap: 12,
  });

  return (
    <div
      ref={parentRef}
      style={{
        height: urls.length < 10 ? `auto` : `400px`,
        overflow: urls.length < 10 ? `visible` : 'auto',
      }}
    >
      <div
        style={{
          height: `${virtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {virtualizer.getVirtualItems().map((item) => {
          return (
            <div
              className="mt-3"
              key={item.key}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${item.size}px`,
                transform: `translateY(${item.start}px)`,
              }}
            >
              <Label className="inline-flex items-center">
                <Checkbox
                  id={`id - ${item}`}
                  onChange={(e) => {
                    formik.setFieldValue(`urls.${item.index}.checked`, e.target.checked);
                  }}
                  defaultChecked={formik.values.urls[item.index].checked}
                />
                <span className="content-trim ml-3 text-[0.875rem] font-medium leading-[1.1rem]">
                  {formik.values.urls[item.index].url}
                  {formik.values.urls[item.index]?.suggested && (
                    <span className="ml-2 inline-flex text-xs text-primary-default">
                      <GeneratedIcon className="mr-1 path:fill-primary-default" /> Suggested
                    </span>
                  )}
                </span>
              </Label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

import { XYPosition } from '@xyflow/react';
import { StateCreator } from 'zustand';
import { GROUP_DIMENSIONS, NODE_DIMENSIONS } from '../consts/whiteboard.const';
import { calculateAbsolutePosition, calculateRelativePosition, isNodeInsideGroup } from '../helpers/group-helpers';
import { NodeType, NodeTypesEnum } from '../types';
import { ReactFlowSlice } from './react-flow.slice';

type GroupAction = {
  regroupNode: (nodeId: string) => void;
  addNodeToGroup: (nodeId: string, groupId: string) => XYPosition | undefined;
  removeNodeFromGroup: (nodeId: string) => XYPosition | undefined;
  addOrRemove: (
    node: NodeType,
    group: NodeType,
    onlyAdd?: boolean,
  ) => { position: XYPosition | undefined; isInGroup: boolean } | undefined;
};
export type GroupSlice = GroupAction;

export const createGroupSlice: StateCreator<GroupSlice & ReactFlowSlice, [], [], GroupSlice> = (set, get) => ({
  regroupNode: (nodeId: string) => {
    const nodes = get().nodes;
    const node = nodes.find((n) => n.id === nodeId);

    if (!node) return;

    if (node.type === NodeTypesEnum.Group) {
      const nonGroupNodes = nodes.filter((n) => n.type !== NodeTypesEnum.Group);
      nonGroupNodes.forEach((n) => {
        get().addOrRemove(n, node, true);
      });
      return;
    }

    const groups = nodes.filter((n) => n.type === NodeTypesEnum.Group);
    if (groups.length === 0) return;

    const groupWithNode = groups.find((group) => group.id === node.parentId);
    const otherGroups = groups.filter((group) => group.id !== node.parentId);

    let newPosition: XYPosition | undefined;

    const allGroups = [groupWithNode, ...otherGroups].filter(Boolean);
    for (const group of allGroups) {
      if (!group) continue;

      const result = get().addOrRemove(
        {
          ...node,
          position: newPosition || node.position,
        },
        group,
        false,
      );

      if (result?.isInGroup) {
        break;
      }

      if (result?.position) {
        newPosition = result.position;
      }
    }
  },

  addOrRemove: (node: NodeType, group: NodeType, onlyAdd = false) => {
    if (node.id === group.id) return;

    const groupPosition = group?.position;
    const groupDimensions = group?.measured;

    if (!groupPosition || !groupDimensions) return;

    const width = node.measured?.width ?? NODE_DIMENSIONS.width;
    const height = node.measured?.height ?? NODE_DIMENSIONS.height;
    const groupWidth = groupDimensions.width ?? GROUP_DIMENSIONS.width;
    const groupHeight = groupDimensions.height ?? GROUP_DIMENSIONS.height;

    const isNodeAlreadyInGroup = node.parentId === group.id;

    if (!isNodeAlreadyInGroup && node.parentId && onlyAdd) {
      return { position: undefined, isInGroup: false };
    }

    if (isNodeAlreadyInGroup) {
      const absPos = calculateAbsolutePosition(node, group);
      const isStillInside = isNodeInsideGroup(
        { ...node, position: absPos },
        group,
        width,
        height,
        groupWidth,
        groupHeight,
      );

      if (!isStillInside) {
        const position = get().removeNodeFromGroup(node.id);
        return { position, isInGroup: false };
      }

      return { position: undefined, isInGroup: true };
    }

    const isInside = isNodeInsideGroup(node, group, width, height, groupWidth, groupHeight);

    if (isInside) {
      const position = get().addNodeToGroup(node.id, group.id);
      return { position, isInGroup: true };
    }

    if (!onlyAdd && node.parentId) {
      const position = get().removeNodeFromGroup(node.id);
      return { position, isInGroup: false };
    }

    return undefined;
  },

  addNodeToGroup: (nodeId: string, groupId: string) => {
    const nodes = get().nodes;
    const group = nodes.find((n) => n.id === groupId);
    const node = nodes.find((n) => n.id === nodeId);

    if (!group || !node || node.type === NodeTypesEnum.Group) return;

    const newPosition = calculateRelativePosition(node, group);

    set((state) => ({
      nodes: state.nodes.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            parentId: groupId,
            position: newPosition,
          };
        }
        return n;
      }),
    }));

    return newPosition;
  },

  removeNodeFromGroup: (nodeId: string) => {
    const nodes = get().nodes;
    const node = nodes.find((n) => n.id === nodeId);
    if (!node || !node.parentId) return;

    const group = nodes.find((n) => n.id === node.parentId);
    if (!group || node.type === NodeTypesEnum.Group) return;

    const newPosition = calculateAbsolutePosition(node, group);

    set((state) => ({
      nodes: state.nodes.map((n) => {
        if (n.id === nodeId) {
          return {
            ...n,
            parentId: undefined,
            position: newPosition,
          };
        }
        return n;
      }),
    }));
    return newPosition;
  },
});

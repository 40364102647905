import { ReactNode, useEffect, useState } from 'react';
import { setUserPlan } from '../../routes/pricing/user-plan-store.ts';
import { useUserPlan } from '@/api/pricing/get-user-plan.ts';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';

export const StoreFiller = ({ children }: { children: ReactNode }) => {
  const { userPlan, isLoading } = useUserPlan();
  const [isAppReady, setIsAppReady] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (userPlan) {
      setUserPlan(userPlan);

      if (window.location.pathname === '/') {
        navigate(buildUrl([Urls.PROJECTS]));
      }
    }

    setTimeout(() => setIsAppReady(true), 500);
  }, [userPlan, isLoading]);

  return <>{isAppReady && children}</>;
};

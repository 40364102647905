import { cn } from '@/helpers/cn';
import SettingsIcon from '@assets/settings.svg';
import TrashIcon from '@assets/trash.svg';
import { useState } from 'react';
import { STICKY_NOTE_COLORS } from '../consts/whiteboard.const';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { NodeData, NodeType } from '../types';
import { ColorSelector } from './ColorSelector';
import { useSaveReactFlow } from '../hooks/useSaveReactFlow';

type NodeSettingsProps = {
  id: NodeType['id'];
  data: NodeData;
  colors?: string[];
};

export function NodeSettings({ id, data, colors = STICKY_NOTE_COLORS }: NodeSettingsProps) {
  const { saveReactFlow } = useSaveReactFlow();
  const addToHistoryStack = useWhiteboardStore((state) => state.addToHistoryStack);
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);
  const removeNode = useWhiteboardStore((state) => state.removeNode);

  const [showOptions, setShowOptions] = useState(false);

  function handleColorChange(color: string) {
    updateNodeData(id, { backgroundColor: color });
    addToHistoryStack();
    saveReactFlow();
  }

  function handleRemove() {
    removeNode(id);
  }

  return (
    <>
      <button
        onClick={() => setShowOptions(!showOptions)}
        className="absolute bottom-1 right-1 rounded-lg bg-white p-2 hover:bg-neutrals-100"
        type="button"
      >
        <SettingsIcon className={cn('size-5', showOptions ? 'path:fill-neutrals-1000' : 'path:fill-neutrals-600')} />
      </button>
      {showOptions && (
        <div className="absolute right-0 top-full flex justify-center">
          <div className="mt-2 flex items-center rounded-lg border bg-white p-1">
            <ColorSelector
              onChange={handleColorChange}
              selectedColor={data.backgroundColor ?? colors?.[0]}
              colors={colors}
            />
            <button
              onClick={handleRemove}
              className="rounded-lg p-2 hover:bg-neutrals-100"
              type="button"
            >
              <TrashIcon className="size-5 path:fill-neutrals-600" />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

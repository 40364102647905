import { useApi } from '@/api/api';
import { useSerializablePostSSE } from '@/api/useSSE';

interface GenerateWorkflowDto {
  whiteboardName: string;
  query: string;
  existingNodes: {
    id: string;
    title: string;
    toolName: string;
    inputNodes?: string[];
  }[];
}

interface GenerateNodeConfigDto {
  whiteboardName: string;
  nodeToGenerate: {
    id: string;
    title: string;
    toolName: string;
    inputNodes?: string[];
    intent?: string;
  };
  existingNodes: {
    id: string;
    title: string;
    toolName: string;
    inputNodes?: string[];
  }[];
}

export const useGenerateWorkflow = (
  onmessage: (event: { event: string; data: string }) => void,
  oncomplete: () => void,
  onerror: (error: unknown) => void,
) => {
  const { postSSE, unsubscribe } = useSerializablePostSSE(onmessage, oncomplete, onerror);
  const startWorkflowGeneration = async (arg: GenerateWorkflowDto) => {
    postSSE(`whiteboard-agent/generate-workflow`, { arg });
  };

  return { startWorkflowGeneration, abortWorkflowGeneration: unsubscribe };
};

export const useGenerateNodeConfig = () => {
  const { postFetcher } = useApi();

  const generateNodeConfig = async (arg: GenerateNodeConfigDto) => {
    return await postFetcher(`whiteboard-agent/generate-node-config`, { arg });
  };

  return { generateNodeConfig };
};

import { cn } from '@/helpers/cn';
import { NodeProps } from '@xyflow/react';
import { memo } from 'react';
import { GROUP_COLORS } from '../consts/whiteboard.const';
import { GroupNodeType, NodeData, NodeType } from '../types';
import { EditNodeName } from './EditNodeName';
import { NodeResizeWrapper } from './NodeResizeWrapper';
import { NodeSettings } from './NodeSettings';

type GroupNodeProps = {
  data: NodeData;
  id: NodeType['id'];
};

function GroupNode({ data, id }: GroupNodeProps) {
  return (
    <div className="group h-full rounded-xl">
      <div className={cn('group-node h-full w-full rounded-xl opacity-50', data.backgroundColor ?? GROUP_COLORS[0])}>
        <div className="absolute -top-10 left-0">
          <EditNodeName
            nodeData={data}
            nodeId={id}
            className="text-[2rem] uppercase"
          />
        </div>
      </div>
      <div className="invisible group-hover:visible">
        <NodeSettings
          id={id}
          data={data}
          colors={GROUP_COLORS}
        />
      </div>
    </div>
  );
}

const MemoizedGroupNode = memo(GroupNode);

export default function GroupNodeWrapper(props: NodeProps<GroupNodeType>) {
  return (
    <>
      <NodeResizeWrapper selected={props.selected} />

      <MemoizedGroupNode
        data={props.data}
        id={props.id}
      />
    </>
  );
}

import { BaseEdge, EdgeProps, getBezierPath, Position } from '@xyflow/react';
import { memo } from 'react';
import { SOCKET_PADDING } from './consts/whiteboard.const';

export const PaddingEdge = memo(({ sourceX, sourceY, targetX, targetY }: EdgeProps) => {
  const [edgePath] = getBezierPath({
    sourceX: sourceX - SOCKET_PADDING,
    sourceY: sourceY,
    targetX: targetX + SOCKET_PADDING,
    targetY: targetY,
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
  });

  return (
    <BaseEdge
      path={edgePath}
      interactionWidth={32}
    />
  );
});

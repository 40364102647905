import { ToolCategory, ToolConfig } from 'src/libs/tools/type.model';
import { openConfigurationEditor } from '../store';
import { Select } from 'flowbite-react';
import { useContext } from 'react';
import { ToolsContext } from '../../../../../../libs/tools/ToolsContext.tsx';
import { getToolConfigsByCategory } from '../../../../../../libs/tools/getToolConfigsByCategory.ts';
import { Node } from '@tiptap/pm/model';
import { useUpdateToolType } from '@/api/tools/update-tool-type.ts';

export const ConfigurationModeView = ({
  toolCategory,
  node,
  updateAttributes,
  textToDisplay,
}: {
  toolCategory: ToolCategory;
  node: Node;
  updateAttributes: (attrs: Record<string, unknown>) => void;
  textToDisplay: string;
}) => {
  const toolConfigs = useContext(ToolsContext);
  const toolConfigsByCategory = getToolConfigsByCategory(toolConfigs?.tools || []);
  const { updateToolType } = useUpdateToolType();

  const resourceForCategory = {
    [ToolCategory.TEXTGEN]: { img: '/hub.svg', name: 'LLM Model' },
    [ToolCategory.IMAGEGEN]: { img: '/imagesmode.svg', name: 'Image' },
    [ToolCategory.RESEARCHGEN]: { img: '/manage_search.svg', name: 'Researcher' },
    [ToolCategory.SEMRUSH]: { img: '/manage_search.svg', name: 'Researcher' },
  };

  return (
    <>
      <div
        contentEditable={false}
        className="df-node-header flex items-center justify-between rounded-t-2xl border-b border-gray-divider bg-gray-ultra-light px-3 py-2 text-body-sm text-gray-dark"
      >
        <div className="grow">
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-1.5 p-0.5">
              <div className="flex h-5 w-5 items-center justify-center rounded-md bg-white p-1">
                <img
                  src={resourceForCategory[toolCategory as keyof typeof resourceForCategory].img}
                  width={12}
                  height={12}
                  alt=""
                />
              </div>
              <div className="text-nav-sub">
                {resourceForCategory[toolCategory as keyof typeof resourceForCategory].name}
              </div>
            </div>

            <Select
              value={node.attrs?.selectedtool?.displayName ?? ''}
              onChange={(e) => {
                const toolDisplayName = e.target.value;
                const tool = toolConfigs?.tools?.find((tool) => tool.displayName === toolDisplayName);
                updateAttributes({ selectedtool: tool });

                if (node.attrs?.selectedtoolid && tool) {
                  updateToolType(node.attrs?.selectedtoolid, tool.name);
                }
              }}
              sizing="sm"
            >
              {toolConfigsByCategory[toolCategory]?.map((option: ToolConfig) => (
                <option key={option.name}>{option.displayName}</option>
              ))}
            </Select>
          </div>
        </div>

        <button
          className="link link--md"
          onClick={() => openConfigurationEditor(node?.attrs?.id)}
        >
          <img
            src="/page_info.svg"
            width={16}
            height={16}
            alt=""
          />
          Configure
        </button>
      </div>

      <div
        className="mt-5 p-4"
        dangerouslySetInnerHTML={{
          __html: textToDisplay,
        }}
        contentEditable={false}
      />
    </>
  );
};

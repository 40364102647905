import { TimeEntity } from '@/models/time-entity.interface.ts';
import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { Edge, Node } from '@xyflow/react';
import { Tool, ToolConfig, ToolField, ToolValue } from 'src/libs/tools/type.model';

export type ToolExecutedOutput = string | string[] | Record<string, ToolValue>;

export interface NotifyFrontendParams {
  whiteboardId: number;
  reactFlowNodeId: string;
  status: ExecutionEngineNodeStatus;
  output?: ToolExecutedOutput;
  isFinalNode?: boolean;
  isStreamable?: boolean;
  error?: string;
}

export enum ExecutionEngineNodeStatus {
  UNCLASSIFIED = 'unclassified',
  QUEUED = 'queued',
  PROCESSING = 'processing',
  DONE = 'done',
  ERROR = 'error',
  STOPPED = 'stopped',
}

export type ReactFlow = {
  id: number;
  nodes: NodeType[];
  edges: Edge[];
};

export interface ReactFlowDiff extends Partial<ReactFlow> {
  deletedNodeIds?: string[];
  deletedEdgeIds?: string[];
}

export type ExecutionEngineNode = {
  id: number;
  reactFlowNodeId: string;
  status: ExecutionEngineNodeStatus;
  tool: Tool;
  whiteboard: Whiteboard;
};

export interface Whiteboard extends TimeEntity {
  id: number;
  name: string;
  nodes: ExecutionEngineNode[];
  reactFlow: ReactFlow;
  isTemplate?: boolean;
  template?: WhiteboardTemplate;
  isPublic?: boolean;
  isEditable?: boolean;
  uuid?: string;
  shareToken?: string;
  ownerId?: string;
}

export interface UpdateWhiteboardDto {
  name?: string;
  projectId?: number;
}

export type NodeConfig = {
  name: string;
  toolConfig: ToolConfig;
  icon?: string;
  description?: string;
};

export enum NodeTypesEnum {
  Tool = 'Tool',
  StickyNote = 'StickyNote',
  Group = 'Group',
}

export type NodeData = {
  name: string;
  toolConfig: ToolConfig;
  loading: boolean;
  output?: ToolExecutedOutput;
  errors?: string[];
  valid: boolean;
  isNewlyAdded: boolean;
  hasEditedNameByUser: boolean;
  streamOutput?: string;
  status: ExecutionEngineNodeStatus;
  outputHistoryIndex?: number;
  toolInput?: ToolField[];
  toolId?: number;
  templatePublicFields?: string[];
  publicOutput?: boolean;
  content?: string;
  backgroundColor?: string;
  generationIntent?: string;
};

export type ToolNodeType = Node<NodeData, NodeTypesEnum.Tool>;
export type StickyNoteNodeType = Node<NodeData, NodeTypesEnum.StickyNote>;
export type GroupNodeType = Node<NodeData, NodeTypesEnum.Group>;

export type NodeType = ToolNodeType | StickyNoteNodeType | GroupNodeType;
export type EdgeType = Edge;

import GroupIcon from '@assets/group.svg';
import ToolboxCategory from './ToolboxCategory';
import { ToolboxGroupNode } from './ToolboxGroupNode';
import { ToolboxStickyNote } from './ToolboxStickyNote';

export function ToolboxUtilities() {
  return (
    <ToolboxCategory
      categoryConfig={{
        label: 'Utilities',
        icon: GroupIcon,
      }}
      open
    >
      <ToolboxStickyNote />
      <ToolboxGroupNode />
    </ToolboxCategory>
  );
}

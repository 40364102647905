import { useApi } from '@/api/api.ts';
import { mutate } from 'swr';

export const useDeleteWhiteboard = (projectId: number) => {
  const { deleteFetcher } = useApi();

  const deleteWhiteboard = async (id: number): Promise<void> => {
    await deleteFetcher(`/whiteboard/${id}`);
    await mutate(`/whiteboard/by-project/${projectId}`);
  };

  return {
    deleteWhiteboard,
  };
};

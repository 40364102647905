import { useApi } from '@/api/api';
import useSWR from 'swr';
import { Whiteboard } from '../types';

export function useGainAccessToWhiteboard(uuid?: string, token?: string | null) {
  const { getFetcher } = useApi();
  const { data, isLoading, error } = useSWR<Whiteboard>(
    uuid && token ? `/whiteboard/${uuid}/token/${token}` : null,
    getFetcher,
  );
  return {
    whiteboard: data,
    isLoading,
    error,
  };
}

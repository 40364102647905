export enum UserEvent {
  user_has_signed_up = 'user_has_signed_up',
  user_logged_in = 'user_logged_in',
}

export enum TagManagerEvent {
  organization_created = 'organization_created',
  sign_up = 'sign_up',
  log_in = 'log_in',
  subscription = 'subscription',
  team_member_invited = 'team_member_invited',
  team_member_joined_organization = 'team_member_joined_organization',
  plan_limits_reached = 'plan_limits_reached',
  search_done = 'search_done',
  user_created_workspace = 'user_created_workspace',
  user_deleted_workspace = 'user_deleted_workspace',
  user_created_project = 'user_created_project',
  user_created_segment = 'user_created_segment',
  user_created_product = 'user_created_product',
  user_created_flow = 'user_created_flow',
  user_created_data = 'user_created_data',
  canvas_added = 'canvas_added',
  onboarding_process = 'onboarding_process',
  start_trial = 'start_trial',
  complete_onboarding = 'complete_onboarding',
}

import { Button, Dropdown, DropdownDivider } from 'flowbite-react';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useSemrush } from '@/state/semrush.store';

export function AddDataToDataRoomButton() {
  const { openModal } = useGlobalModal();
  const { isSemrush } = useSemrush();

  return (
    <Dropdown
      label="Add data"
      placement="bottom-start"
      className="w-[288px]"
      renderTrigger={() => (
        <Button
          color="primary"
          size="sm"
        >
          Add data
          <img
            src="/chevron-up-white.svg"
            className="relative top-[1px] rotate-180"
            alt=""
          />
        </Button>
      )}
    >
      <Dropdown.Item onClick={() => openModal(MODALS.DataRoomAddWebsiteLinks)}>
        <div className="dropdown-item__icon">
          <img
            src="/link-black.svg"
            alt=""
          />
        </div>
        Add website links
      </Dropdown.Item>

      <Dropdown.Item onClick={() => openModal(MODALS.DataRoomAddFiles)}>
        <div className="dropdown-item__icon">
          <img
            src="/upload-black.svg"
            alt=""
          />
        </div>
        Upload from device
      </Dropdown.Item>

      <Dropdown.Item onClick={() => openModal(MODALS.DataRoomAddText)}>
        <div className="dropdown-item__icon">
          <img
            src="/edit-black.svg"
            alt=""
          />
        </div>
        Make note
      </Dropdown.Item>

      {!isSemrush && (
        <Dropdown.Item onClick={() => openModal(MODALS.DataRoomAddRecordings)}>
          <div className="dropdown-item__icon">
            <img
              src="/play-black.svg"
              alt=""
            />
          </div>
          Record audio
        </Dropdown.Item>
      )}

      <DropdownDivider />

      <Dropdown.Item onClick={() => openModal(MODALS.DataRoomAddFromDropbox)}>
        <div className="dropdown-item__icon">
          <img
            src="/dropbox.svg"
            alt=""
          />
        </div>
        Dropbox
      </Dropdown.Item>
    </Dropdown>
  );
}

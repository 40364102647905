import { ReactNode, useEffect } from 'react';
import { useGlobalLoader } from '../components/GlobalLoader/global-loader.store.ts';
import { useSearchParams } from 'react-router-dom';
import { useAcceptInvitation } from '@/api/organization/accept-invitation.ts';
import { useDebouncedCallback } from 'use-debounce';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { useRefreshAuth0Token } from '@/helpers/refresh-auth0-token.ts';
import { usePostHog } from 'posthog-js/react';
import { useTriggerEvent } from '@/api/events/api.ts';
import { TagManagerEvent, UserEvent } from '@/api/events/model.ts';
import { useInvitationData } from '@/state/app-store.ts';
import toast from 'react-hot-toast';
import { useAuth } from '@/hooks/auth/useAuth.ts';

export function AuthGuard({ children }: { children: ReactNode }) {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [searchParams] = useSearchParams();
  const { acceptInvitation } = useAcceptInvitation();
  const debauncedHandleAcceptInvitation = useDebouncedCallback(handleAcceptInvitation, 200);
  const { listenerOn } = useWebSocketMessageListener();
  const { refreshAuth0Token } = useRefreshAuth0Token();
  const posthog = usePostHog();
  const { trigerUserEvent, triggerDataLayer } = useTriggerEvent();
  const { invitationData, setInvitationData } = useInvitationData();

  function handleAcceptInvitation() {
    const invitationId = searchParams.get('invitation') ?? invitationData?.invitationId;
    const organizationId = searchParams.get('organization') ?? invitationData?.organizationId;

    if (invitationId && organizationId) {
      showLoader('Accepting invitation...');
      acceptInvitation(organizationId, invitationId)
        .then(() => {
          hideLoader();
          setInvitationData(null);
          triggerDataLayer(TagManagerEvent.team_member_joined_organization, { organization: organizationId });
          searchParams.delete('invitation');
          searchParams.delete('organization');
          toast.success('Invitation accepted!');
        })
        .catch(() => {
          hideLoader();
          setInvitationData(null);
          searchParams.delete('invitation');
          searchParams.delete('organization');
        });
    }
  }

  const trySavingInvitationDataToStore = () => {
    const invitationId = searchParams.get('invitation');
    const organizationId = searchParams.get('organization');

    if (invitationId && organizationId) {
      setInvitationData({ organizationId, invitationId });
    }
  };

  const updateDataLayer = () => {
    const payment = searchParams.get('payment');
    const plan = searchParams.get('plan');
    const price = searchParams.get('price');

    if (!user?.sub) return;

    if (user?.['df/applicationLoginsCount'] <= 1) {
      triggerDataLayer(TagManagerEvent.sign_up, {
        email: user.email,
        registration_method: user.sub.includes('auth0') ? 'email' : 'google',
        registration_date: new Date().toISOString(),
      });
    } else {
      triggerDataLayer(TagManagerEvent.log_in, {
        email: user.email,
        login_method: user.sub.includes('auth0') ? 'email' : 'google',
      });
    }

    if (payment && payment === 'success') {
      triggerDataLayer(TagManagerEvent.subscription, {
        email: user.email,
        plan_name: plan,
        plan_value: price,
        currency: 'USD',
        subscription_date: new Date().toISOString(),
      });
      triggerDataLayer(TagManagerEvent.start_trial, {});
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      trySavingInvitationDataToStore();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!user?.sub) return;

    updateDataLayer();

    const { listenerOff } = listenerOn(`refresh-token:${user.sub}`, () => refreshAuth0Token());
    posthog?.identify(user.sub, {
      email: user.email,
    });

    trigerUserEvent(UserEvent.user_logged_in);

    return () => {
      listenerOff();
    };
  }, [user?.sub]);

  useEffect(() => {
    if (isLoading || !isAuthenticated) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading, isAuthenticated, hideLoader, showLoader]);

  if (isLoading) {
    return null;
  }

  if (isAuthenticated) {
    debauncedHandleAcceptInvitation();
    return <>{children}</>;
  } else {
    loginWithRedirect({ authorizationParams: { redirect_uri: window.location.origin } });
  }
}

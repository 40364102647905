import { XYPosition } from '@xyflow/react';
import { NodeType } from '../types';

export const calculateRelativePosition = (node: NodeType, group: NodeType): XYPosition => ({
  x: node.position.x - group.position.x,
  y: node.position.y - group.position.y,
});

export const calculateAbsolutePosition = (node: NodeType, group: NodeType): XYPosition => ({
  x: node.position.x + group.position.x,
  y: node.position.y + group.position.y,
});

export const isNodeInsideGroup = (
  node: NodeType,
  group: NodeType,
  nodeWidth: number,
  nodeHeight: number,
  groupWidth: number,
  groupHeight: number,
): boolean => {
  const { x, y } = node.position;
  const { x: groupX, y: groupY } = group.position;

  return x >= groupX && x + nodeWidth <= groupX + groupWidth && y >= groupY && y + nodeHeight <= groupY + groupHeight;
};

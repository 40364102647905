import { useReactFlow } from '@xyflow/react';
import { useCallback } from 'react';
import { ToolField } from 'src/libs/tools/type.model';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { NodeConfig, NodeTypesEnum } from '../types';
import { useAddNodes } from './useAddNode';
import { useDataTransferNodesCreator } from './useDataTransferNodesCreator';

export const DATA_TRANSFER_KEYS = {
  nodeConfig: 'toolbox/nodeConfig',
  fields: 'toolbox/fields',
  nodeName: 'toolbox/nodeName',
  execute: 'toolbox/execute',
  nodeType: 'toolbox/nodeType',
};

export function useDropNodeCreator() {
  const { addNode } = useAddNodes();
  const { createNodesFromDataTransferItems } = useDataTransferNodesCreator();
  const { screenToFlowPosition } = useReactFlow();
  const addStickyNote = useWhiteboardStore((state) => state.addStickyNote);
  const addGroupNode = useWhiteboardStore((state) => state.addGroupNode);

  const handleDrop = useCallback(
    async (event: React.DragEvent) => {
      event.preventDefault();

      const targetClassList = (event.target as Element).classList.value;
      if (!targetClassList.includes('react-flow') && !targetClassList.includes('group-node')) {
        return;
      }

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const stickyNote = event.dataTransfer.getData(DATA_TRANSFER_KEYS.nodeType) === NodeTypesEnum.StickyNote;
      if (stickyNote) {
        addStickyNote({ position });
        return;
      }

      const groupNode = event.dataTransfer.getData(DATA_TRANSFER_KEYS.nodeType) === NodeTypesEnum.Group;
      if (groupNode) {
        addGroupNode({ position });
        return;
      }

      const nodeConfig: NodeConfig =
        event.dataTransfer.getData(DATA_TRANSFER_KEYS.nodeConfig) &&
        JSON.parse(event.dataTransfer.getData(DATA_TRANSFER_KEYS.nodeConfig));

      const fields: ToolField[] = event.dataTransfer.getData(DATA_TRANSFER_KEYS.fields)
        ? JSON.parse(event.dataTransfer.getData(DATA_TRANSFER_KEYS.fields))
        : undefined;

      const nodeName = event.dataTransfer.getData(DATA_TRANSFER_KEYS.nodeName) || undefined;

      const execute = event.dataTransfer.getData(DATA_TRANSFER_KEYS.execute) === 'true';

      if (nodeConfig) {
        await addNode({ toolConfig: nodeConfig.toolConfig, position, fields, execute, nodeName });
        return;
      }

      const dropItems = event.dataTransfer.items;
      if (!dropItems) return;
      await createNodesFromDataTransferItems(dropItems, position);
    },
    [addNode],
  );
  return handleDrop;
}

import { DfFeature } from '@/enums/df-feature.enum';

export enum ToolName {
  TEXT_BASE_MODELS = 'TEXT_BASE_MODELS',
  ASK_VISION_MODEL = 'ASK_VISION_MODEL',
  IMAGE_IMAGE_SD_MODELS = 'IMAGE_IMAGE_SD_MODELS',
  UPSCALE_SD_MODELS = 'UPSCALE_SD_MODELS',
  SD_ERASE = 'SD_ERASE',
  SD_INPAINT = 'SD_INPAINT',
  SD_OUTPAINT = 'SD_OUTPAINT',
  SD_SEARCH_AND_REPLACE = 'SD_SEARCH_AND_REPLACE',
  SD_REMOVE_BACKGROUND = 'SD_REMOVE_BACKGROUND',
  SD_SKETCH = 'SD_SKETCH',
  SD_STRUCTURE = 'SD_STRUCTURE',
  SD_STYLE = 'SD_STYLE',
  ASK_INPUT = 'ASK_INPUT',
  CANVAS_FILLER = 'CANVAS_FILLER',
  CANVAS_FILLER_POSITION = 'CANVAS_FILLER_POSITION',
  SMART_SCRAPPER = 'SMART_SCRAPPER',
  RESEARCHER = 'RESEARCHER',
  GOOGLE_ADS_KEYWORDS_IDEAS = 'GOOGLE_ADS_KEYWORDS_IDEAS',
  DYNAMIC_RESEARCHER = 'DYNAMIC_RESEARCHER',
  FLUX = 'FLUX',
  PROMPT_IMAGE_SD_MODELS = 'PROMPT_IMAGE_SD_MODELS',
  PROMPT_IMAGE_DALLE3_MODELS = 'PROMPT_IMAGE_DALLE3_MODELS',
  IDEOGRAM_DESCRIBE_IMAGE = 'IDEOGRAM_DESCRIBE_IMAGE',
  PROMPT_IMAGE_IDEOGRAM_MODELS = 'PROMPT_IMAGE_IDEOGRAM_MODELS',
  PERPLEXITY = 'PERPLEXITY',
  TAVILY = 'TAVILY',
  LUMA_PROMPT_TO_VIDEO = 'LUMA_PROMPT_TO_VIDEO',
  LUMA_IMAGE_TO_VIDEO = 'LUMA_IMAGE_TO_VIDEO',
  RESEARCHER_PRE_CANVAS = 'RESEARCHER_PRE_CANVAS',
  INSTAGRAM_SCRAPPER = 'INSTAGRAM_SCRAPPER',
  TIKTOK_SCRAPPER = 'TIKTOK_SCRAPPER',
  YOUTUBE_SCRAPPER = 'YOUTUBE_SCRAPPER',
  REDDIT_SCRAPPER = 'REDDIT_SCRAPPER',
  FACEBOOK_SCRAPPER = 'FACEBOOK_SCRAPPER',
  LINKEDIN_PROFILE_SCRAPPER = 'LINKEDIN_PROFILE_SCRAPPER',
  LINKEDIN_COMPANY_SCRAPPER = 'LINKEDIN_COMPANY_SCRAPPER',
  SEMRUSH_DOMAIN_REPORTS = 'SEMRUSH_DOMAIN_REPORTS',
  SEMRUSH_BACKLINKS = 'SEMRUSH_BACKLINKS',
  SEMRUSH_KEYWORD_REPORTS = 'SEMRUSH_KEYWORD_REPORTS',
  SEMRUSH_OVERVIEW_REPORTS = 'SEMRUSH_OVERVIEW_REPORTS',
  SEMRUSH_SUBDOMAIN_REPORTS = 'SEMRUSH_SUBDOMAIN_REPORTS',
  SEMRUSH_SUBFOLDER_REPORTS = 'SEMRUSH_SUBFOLDER_REPORTS',
  SEMRUSH_URL_REPORTS = 'SEMRUSH_URL_REPORTS',
  DEEP_RESEARCH_PREPARE_RESEARCH = 'DEEP_RESEARCH_PREPARE_RESEARCH',
  DEEP_RESEARCH_START_RESEARCH = 'DEEP_RESEARCH_START_RESEARCH',
  READER_TEXT = 'READER_TEXT',
  READER_IMAGE = 'READER_IMAGE',
  READER_CANVAS = 'READER_CANVAS',
  READER_PERSONA = 'READER_PERSONA',
  READER_DATA_ROOM = 'READER_DATA_ROOM',
  READER_TEXT_FILE = 'READER_TEXT_FILE',
  READER_YOUTUBE = 'READER_YOUTUBE',
  CRAWLER_LINKS_EXTRACTOR = 'CRAWLER_LINKS_EXTRACTOR',
  CRAWLER_CRAWL = 'CRAWLER_CRAWL',
  ASSETS = 'ASSETS',
}

export enum ToolFieldInputType {
  TEXT = 'text',
  NUMBER = 'number',
  SELECT = 'select',
  FILE = 'file',
  CHECKBOX = 'checkbox',
  HIDDEN = 'HIDDEN',
}

export enum ToolOutputType {
  TEXT = 'text',
  IMAGE = 'image',
  DOCUMENT = 'document',
  WEBSITE = 'website',
  VIDEO = 'video',
  AUDIO = 'audio',
  NULL = 'null',
  JSON = 'json',
}

export type ToolValue = string | number | boolean | string[];

export interface Tool {
  id: number;
  toolName: ToolName;
  currentOutput: ToolOutput;
  outputHistory?: ToolHistoryItem[];
  input: ToolField[];
}

export interface ToolHistoryItem {
  id: number;
  output: ToolOutput;
}

export type ToolOutput = string | string[] | Record<string, string>;

export interface ToolFieldConfig {
  type: ToolFieldInputType;
  isAdvanced: boolean;
  defaultValue?: ToolValue;
  displayName?: string;
  showIf?: Record<string, ShowIfPossibleValues>;
  isDeprecated?: boolean;
  availableOnlyIn?: DfFeature[];
  validation: {
    required?: boolean;
    valueIn?: (string | number)[];
    maxLength?: number;
    minLength?: number;
    allowExtensions?: string[];
    maxFileSize?: number;
    regexp?: string;
    isArray?: boolean;
    skipValidation?: boolean;
  };
  canBeGeneratedBy?: ToolOutputType;
}

export interface ToolConfigInput {
  [key: string]: ToolFieldConfig;
}

export interface ToolConfigConfiguration {
  input: ToolConfigInput;
  outputType: ToolOutputType;
}

export enum ToolCategory {
  TEXTGEN = 'TEXTGEN',
  IMAGEGEN = 'IMAGEGEN',
  RESEARCHGEN = 'RESEARCHGEN',
  OTHER = 'OTHER',
  SEMRUSH = 'SEMRUSH',
  ASSETS = 'ASSETS',
}

export type ShowIfPossibleValues = ToolValue | File;

export const KnownToolCategories = [
  ToolCategory.TEXTGEN,
  ToolCategory.IMAGEGEN,
  ToolCategory.RESEARCHGEN,
  ToolCategory.SEMRUSH,
  ToolCategory.ASSETS,
];

export interface ToolConfig {
  name: ToolName;
  config: ToolConfigConfiguration;
  category: ToolCategory;
  displayName: string;
  isStreamable?: boolean;
  executeOnFieldsUpdate?: boolean;
}

export interface ToolFormikFields {
  name: string;
  label: string;
  type: ToolFieldInputType;
  options?: string[];
  disabled?: boolean;
  showIf?: Record<string, ShowIfPossibleValues>;
  isDeprecated?: boolean;
}

export interface ExecuteToolPayload {
  id?: number;
  toolName: string;
  fields: ToolField[];
  wsChannel: string;
}

export interface ToolField {
  key: string;
  value: ToolValue;
}

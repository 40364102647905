import { useCallback, useEffect } from 'react';
import { ToolOutput } from 'src/libs/tools/ToolOutput';
import { ToolConfig, ToolOutput as ToolOutputFinalType } from 'src/libs/tools/type.model';
import { LoaderIcon } from 'src/routes/whiteboards/components/ui/LoaderIcon.tsx';
import { ExecutionEngineNode, NodeData, NodeType } from 'src/routes/whiteboards/types';
import { useWhiteboardStore } from '../../../store/whiteboard.store.ts';

export const ToolTextOutputContainerClassName =
  'nowheel overflow-y-auto overflow-x-visible p-4 has-[.tiptap]:!p-0' as const;

type ToolNodeGenericOutputProps = {
  nodeId: NodeType['id'];
  nodeData: NodeData;
  executionEngineNode: ExecutionEngineNode;
  toolConfigs: ToolConfig[];
  scrollContainerRef?: React.RefObject<HTMLDivElement | null>;
};
export const GenericToolNodeOutput = ({
  nodeId,
  nodeData,
  executionEngineNode,
  toolConfigs,
  scrollContainerRef,
}: ToolNodeGenericOutputProps) => {
  const { loading, output: nodeOutput, toolConfig } = nodeData;
  const { outputType } = toolConfig.config;

  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);
  const nodeStreamOutput = useWhiteboardStore((state) => state.getNodeStreamOutput(nodeId));
  const addToHistoryStack = useWhiteboardStore((state) => state.addToHistoryStack);

  const finishEditingOutput = useCallback(
    async (newOutput: string) => {
      updateNodeData(nodeId, { output: newOutput });
      addToHistoryStack();
    },
    [nodeId, updateNodeData],
  );

  const output = nodeStreamOutput || nodeOutput;

  useEffect(() => {
    if (output && scrollContainerRef) {
      scrollContainerRef.current?.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [output]);

  if (loading && !nodeStreamOutput) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoaderIcon />
      </div>
    );
  }

  return (
    <>
      {output ? (
        <ToolOutput
          output={output as ToolOutputFinalType}
          outputType={outputType}
          toolId={executionEngineNode.tool.id}
          showHistory={false}
          enableEditing={!loading}
          onFinishEditing={finishEditingOutput}
          className={ToolTextOutputContainerClassName}
          scrollToBottomOnOutputUpdate={loading}
          toolConfigs={toolConfigs}
        />
      ) : (
        <div className="text-neutrals-600">Double click to open the configuration</div>
      )}
    </>
  );
};

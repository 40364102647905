import { useApi } from '@/api/api';
import { WhiteboardTemplate, WhiteboardTemplateCategory } from '@/models/whiteboard-template';

export type CreateWhiteboardTemplatePayload = {
  name: string;
  description?: string;
  category: WhiteboardTemplateCategory;
  forOrganization?: string;
  public?: boolean;
};

export const useUpdateWhiteboardTemplate = () => {
  const { patchFetcher } = useApi();

  const updateWhiteboardTemplate = (
    templateId: number,
    arg: CreateWhiteboardTemplatePayload,
  ): Promise<WhiteboardTemplate> => {
    return patchFetcher(`/whiteboard-template/${templateId}`, {
      arg,
    });
  };

  return {
    updateWhiteboardTemplate,
  };
};

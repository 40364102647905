import { ExpandLeftNavButton } from '@/layouts/MainLayout/components/ExpandLeftNavButton.tsx';
import { Link } from 'react-router-dom';
import { WebsearchTabsOptions } from '@/consts/websearch-tabs.const';

export function WebSearcherPageHeader() {
  return (
    <>
      <header className="flex h-[66px] items-center bg-neutrals-200">
        <div className="flex items-center gap-3">
          <ExpandLeftNavButton />

          {WebsearchTabsOptions.map((option, index) => (
            <div
              key={index}
              className="flex items-center justify-center"
            >
              <div className="rounded bg-gray-ultra-light p-1.5">{option.icon}</div>
              <div>
                <span
                  key={index}
                  className="text-text-secondary"
                >
                  <Link
                    className="hover:underline"
                    to={option.href}
                  >
                    <span title={option.label}>{option.label}</span>
                  </Link>
                </span>
              </div>
            </div>
          ))}
        </div>
      </header>
    </>
  );
}

import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { object, string } from 'yup';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Button, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { useCreateWhiteboard } from '../api/create-whiteboard.ts';

export const CreateWhiteboardModalBody = () => {
  const { closeModal } = useGlobalModal<string>();
  const { createWhiteboard } = useCreateWhiteboard();
  const { showLoader, hideLoader } = useGlobalLoader();
  const projectId = useParams().projectId!;
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async ({ name }) => {
      closeModal();
      showLoader();
      const whiteboard = await createWhiteboard(projectId, name);
      navigate(
        buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.WHITEBOARD], {
          whiteboardId: whiteboard.id,
          projectId: projectId,
        }),
      );
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          {...getDfFlowbiteFieldProps('name', formik)}
          placeholder="Whiteboard name"
          autoComplete="off"
        />

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Create
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { useAccessToken } from './get-access-token';
import { useCurrentWorkspace } from '@/state/app-store';
import { Observable, Subscription } from 'rxjs';
import { useEffect, useRef } from 'react';

class RetriableError extends Error {}
class FatalError extends Error {}

// observable dla SSE
export const useSSEObservable = (maxRetries = 0) => {
  const { getAccessToken } = useAccessToken();
  const workspaceId = useCurrentWorkspace()?.id;

  const fromSSE = async <T, TEvent extends { event: string; data: string }>(url: string, { arg }: { arg: T }) => {
    const accessToken = await getAccessToken();
    const abortController = new AbortController();

    let retryCount = 0;

    return new Observable<TEvent>((subscriber) => {
      fetchEventSource(`${import.meta.env.VITE_API_SERVER}/${url}`, {
        method: 'POST',
        body: JSON.stringify(arg),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/event-stream, application/json',
          Authorization: `Bearer ${accessToken}`,
          'DF-Workspace-Id': `${workspaceId}`,
        },
        async onopen(response) {
          retryCount = 0;
          if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
            return;
          } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            throw new FatalError();
          } else {
            throw new RetriableError();
          }
        },
        signal: abortController.signal,
        onmessage: (event) => {
          if (event.event === 'FatalError') {
            throw new FatalError(event.data);
          }

          try {
            const parsedData = JSON.parse(event.data) as TEvent;
            subscriber.next(parsedData);
          } catch {
            subscriber.next(event.data as unknown as TEvent);
          }
        },
        onerror: (error) => {
          retryCount++;
          if (error instanceof FatalError || retryCount > maxRetries) {
            subscriber.error(error);
            throw error;
          }
        },
        onclose: () => {
          subscriber.complete();
        },
      });

      return () => {
        abortController.abort();
      };
    });
  };

  return {
    fromSSE,
  };
};

// serializowalny post SSE, automatycznie przerywa subskrypcję przy kolejnym wywolaniu, lub odmontowaniu komponentu
export const useSerializablePostSSE = (
  onmessage: (event: { event: string; data: string }) => void,
  oncomplete: () => void,
  onerror: (error: unknown) => void,
  retries = 0,
) => {
  const { fromSSE } = useSSEObservable(retries);
  // useref do callbackow
  const onmessageRef = useRef(onmessage);
  const oncompleteRef = useRef(oncomplete);
  const onerrorRef = useRef(onerror);

  onmessageRef.current = onmessage;
  oncompleteRef.current = oncomplete;
  onerrorRef.current = onerror;

  const sseSubscriptionRef = useRef<Subscription | null>(null);

  const unsubscribe = () => {
    if (sseSubscriptionRef.current) {
      sseSubscriptionRef.current.unsubscribe();
      sseSubscriptionRef.current = null;
    }
  };

  useEffect(() => {
    return () => unsubscribe();
  }, []);

  const postSSE = async <T>(url: string, arg: { arg: T }) => {
    unsubscribe();

    const observable = await fromSSE(url, arg);

    return (sseSubscriptionRef.current = observable.subscribe({
      next: (event) => {
        onmessageRef.current(event);
      },
      complete: () => {
        oncompleteRef.current();
      },
      error: (error) => {
        onerrorRef.current(error);
      },
    }));
  };

  return { postSSE, unsubscribe };
};

import { NodeConfig } from '../../types.ts';
import { useAddNodes } from '../../hooks/useAddNode.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import React from 'react';
import { ToolboxItem } from './ToolboxItem.tsx';
import { DATA_TRANSFER_KEYS } from '../../hooks/useDropNodeCreator.ts';
import { Asset } from '@/models/asset.model.ts';
import { useToolGraphics } from '../../../../libs/tools/useToolGraphics.tsx';

export const ToolboxAssetItem = ({ assetNodeConfig, asset }: { assetNodeConfig: NodeConfig; asset: Asset }) => {
  const { addNode } = useAddNodes();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { getToolGraphics } = useToolGraphics();

  const onDragStart = (event: React.DragEvent) => {
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.nodeConfig, JSON.stringify(assetNodeConfig));
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.fields, JSON.stringify([{ key: 'assetId', value: asset.id }]));
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.nodeName, asset.format);
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleAdd = async () => {
    showLoader();
    await addNode({ toolConfig: assetNodeConfig.toolConfig, fields: [{ key: 'assetId', value: asset.id }] });
    hideLoader();
  };

  const AssetIcon = getToolGraphics({
    toolName: assetNodeConfig.toolConfig.name,
    asset: asset,
  }).icon;

  return (
    <ToolboxItem
      onClick={handleAdd}
      onDragStart={onDragStart}
      icon={AssetIcon}
      label={asset.format}
    />
  );
};

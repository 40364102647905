import { DataRoomItem } from '@/models/data-room-item.interface';

export const URLsItemPreview = ({ item }: { item: DataRoomItem }) => {
  return item.url.split(',').map((url, index) => (
    <div
      key={index}
      className="px-4"
    >
      <p className="py-2">
        <span className="font-bold">{index + 1}.</span>
        <a
          target="_blank"
          href={url}
          rel="noreferrer"
        >
          {url}
        </a>
      </p>
    </div>
  ));
};

import { useApi } from '@/api/api';
import { Whiteboard } from '../types';

export const useCreateWhiteboardShareToken = (): {
  createShareToken: (whiteboardId: number) => Promise<Whiteboard>;
} => {
  const { postFetcher } = useApi();
  const createShareToken = async (whiteboardId: number): Promise<Whiteboard> => {
    return await postFetcher(`/whiteboard/${whiteboardId}/share-token`, {
      arg: {},
    });
  };
  return {
    createShareToken,
  };
};

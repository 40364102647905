import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useDebouncedCallback } from 'use-debounce';
import { useDeleteExecutionEngineNodes } from '../api/delete-execution-engine-node';
import { useUpdateReactFlow } from '../api/update-react-flow';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { ReactFlowDiff } from '../types';
import { useUpdateEENodesOutputs } from './useUpdateEENodesOutputs';

export function useSaveReactFlow() {
  const { updateReactFlow } = useUpdateReactFlow();
  const { deleteExecutionEngineNodes } = useDeleteExecutionEngineNodes();
  const { updateEENodesOutputs } = useUpdateEENodesOutputs();

  const clearEeNodesToRemove = useWhiteboardStore((state) => state.clearEeNodesToRemove);
  const setLastSavedState = useWhiteboardStore((state) => state.setLastSavedState);
  const eeNodesToRemove = useWhiteboardStore((state) => state.eeNodesToRemove);
  const getReactFlow = useWhiteboardStore((state) => state.getReactFlow);
  const getFlowDiff = useWhiteboardStore((state) => state.getFlowDiff);
  const setFullSync = useWhiteboardStore((state) => state.setFullSync);
  const clearSave = useWhiteboardStore((state) => state.clearSave);
  const fullSync = useWhiteboardStore((state) => state.fullSync);

  async function removeEENodes() {
    if (!eeNodesToRemove || !eeNodesToRemove.length) {
      return;
    }
    await deleteExecutionEngineNodes(eeNodesToRemove);
    clearEeNodesToRemove();
  }

  function hasChanges(reactFlowDiff: ReactFlowDiff) {
    return (
      (reactFlowDiff.nodes?.length ?? 0) > 0 ||
      (reactFlowDiff.edges?.length ?? 0) > 0 ||
      (reactFlowDiff.deletedNodeIds?.length ?? 0) > 0 ||
      (reactFlowDiff.deletedEdgeIds?.length ?? 0) > 0
    );
  }

  async function saveReactFlow() {
    try {
      if (fullSync) {
        const reactFlow = getReactFlow();
        await updateReactFlow(reactFlow, true);
        setFullSync(false);
      } else {
        const reactFlowDiff = getFlowDiff();
        if (reactFlowDiff.id && hasChanges(reactFlowDiff)) {
          await updateReactFlow(reactFlowDiff, false);
        }
      }

      await Promise.all([updateEENodesOutputs(), removeEENodes()]);

      setLastSavedState();
      clearSave();
    } catch {
      toast.error('Error saving react flow');
    }
  }

  return {
    saveReactFlow,
  };
}

export function useDebouncedSaveReactFlow() {
  const { saveReactFlow } = useSaveReactFlow();

  const debouncedSave = useDebouncedCallback(() => {
    void saveReactFlow();
  }, 1000);

  useEffect(
    () => () => {
      debouncedSave.flush();
    },
    [debouncedSave],
  );
  return {
    saveReactFlow: debouncedSave,
  };
}

import { EditorInfo } from './EditorInfo';
import { EditorUser } from '../types';
import { WebSocketStatus } from '@hocuspocus/provider';
import { ExecutionModeSwitcher } from '@/components/tiptap/components/ExecutionModeSwitcher/ExecutionModeSwitcher';
import {
  useIsTemplateEditing,
  useMemoryContextSidebar,
  useTiptapAccessMode,
  useTiptapCurrentView,
} from '@/components/tiptap/extensions/DigitalFirst/store';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';
import { useReactToPrint } from 'react-to-print';
import { Editor, PureEditorContent } from '@tiptap/react';
import { MutableRefObject, RefObject } from 'react';
import { ExpandLeftNavButton } from '@/layouts/MainLayout/components/ExpandLeftNavButton.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';
import { useParams } from 'react-router-dom';
import { useMyFlow } from '@/api/flow/my-flow/get-my-flow.ts';
import { useProject } from '@/api/projects/get-project.ts';
import { Button, Dropdown, ToggleSwitch } from 'flowbite-react';
import { Breadcrumbs } from '@/components/Breadcrumbs.tsx';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import GenerateIcon from '@assets/generate.svg';
import { useSemrush } from '@/state/semrush.store';
import { saveAs } from 'file-saver';

import { defaultMarks, DocxSerializer, writeDocx } from 'prosemirror-docx';
import { DocxSchemaSerializer } from '@/components/tiptap/lib/docx-schema-serializer';
import { useUploadDocxImage } from '@/components/tiptap/lib/api';
import { IconButton } from '@/components/IconButton';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { llmMarked } from '@/helpers/llmMarked.ts';
import { ContentNode } from 'react-to-print/lib/types/ContentNode';

export type EditorHeaderProps = {
  characters: number;
  words: number;
  collabState: WebSocketStatus;
  users: EditorUser[];
  editorRef: MutableRefObject<PureEditorContent | null>;
  editor: Editor;
  executeAll: (editor: Editor) => Promise<void>;
};

const memoryContextToggleCustomTheme = {
  toggle: {
    base: "relative bg-white rounded-full border after:absolute after:rounded-full after:bg-[url('/memory-context-on.svg')] after:bg-no-repeat after:bg-center after:bg-contain after:transition-all",
    checked: {
      on: 'after:translate-x-full after:border-white rtl:after:-translate-x-full',
      off: "after:bg-[url('/memory-context-off.svg')]",
      color: {
        blue: '',
      },
    },
    sizes: {
      md: 'h-8 w-14 min-w-14 after:left-[4px] after:top-[3px] after:h-6 after:w-6',
    },
  },
};

export const EditorHeader = ({
  characters,
  collabState,
  users,
  words,
  editorRef,
  editor,
  executeAll,
}: EditorHeaderProps) => {
  const { projectId, flowId } = useParams();
  const { myFlow } = useMyFlow(Number(flowId));
  const { project } = useProject(Number(projectId));
  const isExecutionMode = useTiptapCurrentView() == TiptapView.EXECUTION_MODE;
  const { openModal } = useGlobalModal();
  const tiptapAccessMode = useTiptapAccessMode();
  const isTemplateEditing = useIsTemplateEditing();
  const { isMemoryContextSidebarOpen, toggleMemoryContextSidebar } = useMemoryContextSidebar();
  const { isSemrush } = useSemrush();
  const { uploadDocxImage, cleanDocxImage } = useUploadDocxImage();
  const { showLoader, hideLoader } = useGlobalLoader();

  const handlePrint = useReactToPrint({
    contentRef: editorRef as unknown as RefObject<ContentNode>,
  });

  const handleShare = () => {
    openModal(MODALS.ShareFlow);
  };

  const onDoWebsearchClick = () => {
    const callback = (data: string) => {
      editor.commands.insertContent(llmMarked(data));
    };

    openModal(MODALS.WebsearchModalBody, { callback });
  };

  const exportToDocx = async () => {
    showLoader();
    const editorElement = editorRef.current as unknown as HTMLDivElement;
    const images = editorElement.querySelectorAll('img');
    const imagesBuffers: Record<string, Buffer> = {};

    for (const image of images) {
      if (!image.src) continue;

      let src = image.src;

      if (!src.includes('cdn.digitalfirst')) {
        src = import.meta.env.VITE_ASSETS + (await uploadDocxImage(image.src));
      }

      const response = await fetch(src);
      const buffer = await response.arrayBuffer();
      imagesBuffers[image.src] = Buffer.from(buffer);
    }

    const docxSerializer = new DocxSerializer(DocxSchemaSerializer, defaultMarks);

    const opts = {
      getImageBuffer: (src: string) => {
        return imagesBuffers[src] ?? imagesBuffers[encodeURI(src)];
      },
    };

    const wordDocument = docxSerializer.serialize(editor.state.doc, opts);

    await writeDocx(wordDocument, (buffer) => {
      saveAs(new Blob([buffer]), `${myFlow.name}.docx`);
      cleanDocxImage();
      hideLoader();
    });
  };

  return (
    <div className="relative flex h-14 items-center justify-between py-1 pl-2 pr-8">
      <div className="flex items-center gap-2 text-sm">
        <ExpandLeftNavButton />
        <div className="rounded bg-gray-ultra-light p-1.5">
          <img
            src="/folder-black.svg"
            width={20}
            height={20}
            alt=""
          />
        </div>
        <Breadcrumbs
          breadcrumbs={[
            { label: 'Projects', href: buildUrl([Urls.PROJECTS]) },
            { label: project?.name || '', href: buildUrl([Urls.PROJECTS, Urls.PROJECT], { projectId: projectId! }) },
            { label: myFlow?.name || '', href: '#' },
          ]}
        />
      </div>
      <div className="absolute left-[calc(50%-120px)]">
        <ExecutionModeSwitcher />
      </div>
      <div className="flex items-center gap-3">
        {isExecutionMode && (
          <>
            {tiptapAccessMode === TiptapAccessMode.FULL && (
              <>
                <ToggleSwitch
                  checked={isMemoryContextSidebarOpen}
                  onChange={toggleMemoryContextSidebar}
                  theme={memoryContextToggleCustomTheme}
                />

                {!isSemrush && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={onDoWebsearchClick}
                    className=""
                  >
                    <GenerateIcon className="size-4 path:fill-white" />
                    Websearch
                  </Button>
                )}

                <Button
                  color="primary"
                  size="sm"
                  onClick={async () => await executeAll(editor)}
                  className=""
                >
                  <GenerateIcon className="size-4 path:fill-white" />
                  Generate
                </Button>
              </>
            )}
          </>
        )}
        {tiptapAccessMode === TiptapAccessMode.FULL && !isTemplateEditing && !isSemrush && (
          <Button
            onClick={handleShare}
            color="secondary"
            size="sm"
          >
            <img
              src="/group-add-black.svg"
              width={16}
              height={16}
              alt=""
            />
            Share
          </Button>
        )}
        <Dropdown
          label=""
          renderTrigger={() => (
            <div>
              <IconButton
                icon="/three-dots-gray.svg"
                alt="More"
              />
            </div>
          )}
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown.Item
            className="w-28 text-left"
            onClick={exportToDocx}
          >
            <img
              src="/download-black.svg"
              width={16}
              height={16}
              alt=""
            />
            Export to docx
          </Dropdown.Item>
          <Dropdown.Item
            className="w-28"
            onClick={handlePrint}
          >
            <img
              src="/download-black.svg"
              width={16}
              height={16}
              alt=""
            />
            PDF
          </Dropdown.Item>
        </Dropdown>

        <EditorInfo
          characters={characters}
          words={words}
          collabState={collabState}
          users={users}
        />
      </div>
    </div>
  );
};

export enum CanvasCategory {
  ALL = 'All',
  MARKETING = 'Marketing',
  COMMUNICATION = 'Communication',
  EXECUTION = 'Execution',
  DOCUMENTATION = 'Documentation',
  ANALYSIS = 'Analysis',
  RESEARCH = 'Research',
  STRATEGY = 'Strategy',
}

import { ToolCategory, ToolConfig } from './type.model.ts';

export const getToolConfigsByCategory = (toolConfigs: ToolConfig[]) => {
  return {
    [ToolCategory.TEXTGEN]: toolConfigs ? toolConfigs.filter((tool) => tool.category === ToolCategory.TEXTGEN) : [],
    [ToolCategory.IMAGEGEN]: toolConfigs ? toolConfigs.filter((tool) => tool.category === ToolCategory.IMAGEGEN) : [],
    [ToolCategory.RESEARCHGEN]: toolConfigs
      ? toolConfigs.filter((tool) => tool.category === ToolCategory.RESEARCHGEN)
      : [],
    [ToolCategory.OTHER]: toolConfigs ? toolConfigs.filter((tool) => tool.category === ToolCategory.OTHER) : [],
    [ToolCategory.SEMRUSH]: toolConfigs ? toolConfigs.filter((tool) => tool.category === ToolCategory.SEMRUSH) : [],
    [ToolCategory.ASSETS]: toolConfigs ? toolConfigs.filter((tool) => tool.category === ToolCategory.ASSETS) : [],
  };
};

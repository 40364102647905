import { cn } from '@/helpers/cn';
import { useAccountInfo } from '@/hooks/useAccountInfo';
import { WHITEBOARD_TEMPLATE_CATEGORIES, WhiteboardTemplateCategory } from '@/models/whiteboard-template';
import { FlowTemplateCategoryIcon } from 'src/routes/projects/routes/flows-library/components/FlowTemplateCategoryIcon';

type TemplateCategoriesProps = {
  handleCategoryChange: (category: WhiteboardTemplateCategory) => void;
  activeCategory: WhiteboardTemplateCategory | null;
};

export function TemplateCategories({ handleCategoryChange, activeCategory }: TemplateCategoriesProps) {
  const accountInfo = useAccountInfo();
  const privateLabel = (accountInfo?.organization && accountInfo?.name) || 'My templates';

  return (
    <div className="w-full">
      <div className="px-3 pb-1 text-body-sm text-neutrals-700">{privateLabel}</div>
      <button
        onClick={() => handleCategoryChange(WhiteboardTemplateCategory.MY)}
        className={cn(
          'flex w-full gap-1 rounded-xl px-3 py-2.5 text-subtitle-sm hover:bg-neutrals-100',
          activeCategory === WhiteboardTemplateCategory.MY ? 'bg-neutrals-400' : '',
        )}
      >
        <FlowTemplateCategoryIcon category={WhiteboardTemplateCategory.MY} />
        <span>Saved workflows</span>
      </button>
      <div className="flex flex-col gap-1 pt-3">
        <div className="px-3 text-body-sm text-neutrals-700">Public templates</div>
        {[{ label: 'All flows', value: null }, ...WHITEBOARD_TEMPLATE_CATEGORIES]
          .filter((category) => category.value !== WhiteboardTemplateCategory.MY)
          .map((category) => (
            <button
              key={category.value}
              onClick={() => handleCategoryChange(category.value as WhiteboardTemplateCategory)}
              className={cn(
                'flex gap-1 rounded-xl px-3 py-2.5 text-subtitle-sm hover:bg-neutrals-100',
                activeCategory === category.value ? 'bg-neutrals-400' : '',
              )}
            >
              <FlowTemplateCategoryIcon category={category.value || ''} />
              <span>{category.label}</span>
            </button>
          ))}
      </div>
    </div>
  );
}

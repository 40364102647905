import { useAuth } from '@/hooks/auth/useAuth';
import { useOrganizations } from '@/api/user/get-organizations.ts';

export function useAccountInfo() {
  const { organizations } = useOrganizations();
  const { user } = useAuth();

  if (user?.org_id) {
    const organization = organizations?.find((org) => org.id === user.org_id);
    if (organization) {
      return {
        name: organization.display_name,
        picture: organization.branding?.logo_url,
        organization: true,
      };
    }
  }

  return {
    name: user?.name,
    picture: user?.picture,
    organization: false,
  };
}

import { useApi } from '@/api/api.ts';
import { Asset } from '@/models/asset.model.ts';
import { mutate } from 'swr';

export const useUpdateAsset = () => {
  const { patchFetcher } = useApi();

  const updateAsset = async (assetId: number, data: Partial<Asset>) => {
    await patchFetcher(`/assets/${assetId}`, { arg: data });
    await mutate('/assets');
  };

  return { updateAsset };
};

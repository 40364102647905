import { llmMarked } from '@/helpers/llmMarked';
import { ToolHtmlOutput } from 'src/libs/tools/ToolHtmlOutput';
import { ToolOutputType } from 'src/libs/tools/type.model';
import { NodeType } from '../whiteboards/types';
import { LoaderIcon } from '../whiteboards/components/ui/LoaderIcon';

export function PublicTemplateOutput({ node }: { node: NodeType }) {
  const data = node.data;
  const outputType = data.toolConfig.config.outputType;

  function renderOutput() {
    if (!data.output) return null;

    if (outputType === 'text') {
      return (
        <p
          className="ProseMirror cursor-text !p-0"
          dangerouslySetInnerHTML={{ __html: llmMarked(data.output as string) }}
        ></p>
      );
    }
    if (outputType === 'image') {
      return (
        <img
          className="max-h-80 max-w-full"
          src={import.meta.env.VITE_ASSETS + data.output}
          alt=""
        />
      );
    }

    if (outputType === ToolOutputType.JSON && data.toolId) {
      return <ToolHtmlOutput toolId={data.toolId} />;
    }

    return JSON.stringify(data.output);
  }

  return (
    <div className="my-4">
      <div className="my-2 flex items-center gap-2">
        <div className="text-subtitle-md">{node.data.name}</div>
        {node.data.loading ? (
          <div className="flex items-center">
            <LoaderIcon />
          </div>
        ) : null}
      </div>
      <div className="text-body-md">{renderOutput()}</div>
    </div>
  );
}

export interface AddResourcePayload {
  type: InputType;
  fileExtension?: string;
  fileName?: string;
  file?: string;
  url?: string;
  googleAuthToken?: string;
  googleFileId?: string;
  urls?: string[];
}

export enum InputType {
  File = 'File',
  Brief = 'Brief',
  Url = 'Url',
  Google = 'Google',
  Dropbox = 'Dropbox',
  Youtube = 'Youtube',
}

import { useToolConfigs } from '@/api/tools/get-tool-configs';
import { DfFeature } from '@/enums/df-feature.enum';
import { Edge, XYPosition } from '@xyflow/react';
import toast from 'react-hot-toast';
import { ToolConfig, ToolField, ToolName } from 'src/libs/tools/type.model';
import { CreateExecutionEngineNodePayload, useCreateExecutionEngineNodes } from '../api/create-execution-engine-node';
import { NODE_DIMENSIONS } from '../consts/whiteboard.const';
import { generateId } from '../helpers/node-helpers';
import { positionNodesWithDagre } from '../helpers/position-helpers';
import { useWhiteboardStore } from '../store/whiteboard.store';
import {
  ExecutionEngineNodeStatus,
  NodeData,
  NodeType,
  NodeTypesEnum,
  ToolExecutedOutput,
  ToolNodeType,
} from '../types';
import { useSaveReactFlow } from './useSaveReactFlow';

export type NewNodeParams = {
  toolConfig?: ToolConfig;
  toolName?: ToolName;
  position?: XYPosition;
  fields?: ToolField[];
  execute?: boolean;
  nodeName?: string;
  id?: string;
  data?: Partial<NodeData>;
  output?: ToolExecutedOutput;
  parentId?: string;
  width?: number;
  height?: number;
  generationIntent?: string;
};

type AddNodesParams = {
  nodes: NewNodeParams[];
  position?: XYPosition;
  edges?: Edge[];
  whiteboardId?: number;
};

type ToolNodeTypeOptionalPosition = Omit<ToolNodeType, 'position'> & { position?: XYPosition };

export function useAddNodes() {
  const { createExecutionEngineNodes } = useCreateExecutionEngineNodes();
  const { toolConfigs } = useToolConfigs(DfFeature.WHITEBOARD);
  const { saveReactFlow } = useSaveReactFlow();

  const calculateNewNodePosition = useWhiteboardStore((state) => state.calculateNewNodePosition);
  const addExecutionEngineNodes = useWhiteboardStore((state) => state.addExecutionEngineNodes);
  const updateNodeDataIfNotDone = useWhiteboardStore((state) => state.updateNodeDataIfNotDone);
  const addToHistoryStack = useWhiteboardStore((state) => state.addToHistoryStack);
  const storeWhiteboardId = useWhiteboardStore((state) => state.whiteboardId);
  const addNodesToStore = useWhiteboardStore((state) => state.addNodes);
  const addEdgesToStore = useWhiteboardStore((state) => state.addEdges);
  const fitView = useWhiteboardStore((state) => state.fitView);

  function prepareNewNode(nodeParams: NewNodeParams, toolConfig: ToolConfig) {
    const nodeData: NodeData = {
      loading: true,
      name: nodeParams.nodeName ?? toolConfig.displayName ?? 'Unnamed',
      toolConfig,
      valid: false,
      isNewlyAdded: true,
      hasEditedNameByUser: false,
      status: ExecutionEngineNodeStatus.UNCLASSIFIED,
      generationIntent: nodeParams.generationIntent,
      ...nodeParams.data,
    };

    const newNode: ToolNodeTypeOptionalPosition = {
      id: nodeParams.id ?? generateId(toolConfig.name),
      type: NodeTypesEnum.Tool,
      data: nodeData,
      width: nodeParams.width ?? NODE_DIMENSIONS.width,
      height: nodeParams.height ?? NODE_DIMENSIONS.height,
      position: nodeParams.position,
      resizing: true,
      selected: true,
      parentId: nodeParams.parentId,
    };
    return newNode;
  }

  async function addNodes({ nodes, edges, whiteboardId }: AddNodesParams) {
    const useWhiteboardId = whiteboardId ?? storeWhiteboardId;

    if (!useWhiteboardId) return;

    const newNodes: NodeType[] = [];
    const executionEngineNodesPayload: CreateExecutionEngineNodePayload[] = [];

    nodes.forEach((nodeParams) => {
      if (!nodeParams.toolConfig && !nodeParams.toolName && !toolConfigs) {
        return;
      }
      const toolConfig = nodeParams.toolConfig || toolConfigs?.find((config) => config.name === nodeParams.toolName);
      if (!toolConfig) {
        return;
      }

      const newNode = prepareNewNode(nodeParams, toolConfig);
      newNodes.push(newNode as NodeType);

      executionEngineNodesPayload.push({
        toolName: toolConfig.name,
        whiteboardId: useWhiteboardId,
        reactFlowNodeId: newNode.id,
        fields: nodeParams.fields,
        execute: nodeParams.execute,
        output: nodeParams?.output,
      });
    });

    const nodesWithoutPosition = newNodes.filter((node) => !node.position);
    const nodesWithPosition = newNodes.filter((node) => node.position);

    if (nodesWithoutPosition.length > 0) {
      const newPosition = calculateNewNodePosition();
      const nodesWithNewPosition = positionNodesWithDagre(nodesWithoutPosition, edges ?? [], newPosition, 'TB');
      nodesWithPosition.push(...nodesWithNewPosition);
    }

    addNodesToStore(nodesWithPosition);
    addEdgesToStore(edges ?? []);

    if (nodesWithoutPosition.length > 0) {
      fitView(nodesWithPosition);
    }

    try {
      const executionEngineNodes = await createExecutionEngineNodes(executionEngineNodesPayload);
      addExecutionEngineNodes(executionEngineNodes);

      executionEngineNodesPayload.forEach((node) => {
        updateNodeDataIfNotDone(node.reactFlowNodeId, { loading: false });
      });
      addToHistoryStack();
      executionEngineNodesPayload.forEach((node) => {
        updateNodeDataIfNotDone(node.reactFlowNodeId, { loading: !!node.execute });
      });

      await saveReactFlow();
    } catch {
      toast.error('Error creating execution engine nodes');
    }
    return newNodes.map((node) => node.id);
  }

  async function addNode(params: NewNodeParams) {
    return addNodes({ nodes: [params] });
  }

  return {
    addNodes,
    addNode,
  };
}

import { useApi } from '@/api/api';
import { Whiteboard } from '../types';

export const useDuplicateWhiteboard = (): {
  duplicateWhiteboard: (whiteboardId: number) => Promise<Whiteboard>;
} => {
  const { postFetcher } = useApi();
  const duplicateWhiteboard = async (whiteboardId: number): Promise<Whiteboard> => {
    return await postFetcher(`/whiteboard/${whiteboardId}/duplicate`, {
      arg: {},
    });
  };
  return {
    duplicateWhiteboard,
  };
};

import { cn } from '@/helpers/cn.ts';
import EraserIcon from '@assets/eraser.svg';
import { useClearNodeOutput } from 'src/routes/whiteboards/api/clear-node-output.ts';
import { mutateWhiteboardNodes } from 'src/routes/whiteboards/api/get-whiteboard-nodes.ts';
import { useWhiteboardStore } from 'src/routes/whiteboards/store/whiteboard.store.ts';
import { ExecutionEngineNode, ExecutionEngineNodeStatus, NodeData, NodeType } from '../types.ts';

interface GenericToolNodeFooterProps {
  nodeId: NodeType['id'];
  nodeData: NodeData;
  executionEngineNode: ExecutionEngineNode;
}

export const ClearOutput = ({ nodeId, nodeData, executionEngineNode }: GenericToolNodeFooterProps) => {
  const clearOutput = useWhiteboardStore((state) => state.clearOutput);
  const whiteboardId = useWhiteboardStore((state) => state.whiteboardId);
  const setOutputHistoryIndex = useWhiteboardStore((store) => store.setOutputHistoryIndex);
  const syncOutputs = useWhiteboardStore((store) => store.syncOutputs);
  const addToHistoryStack = useWhiteboardStore((store) => store.addToHistoryStack);
  const { clearNodeOutput } = useClearNodeOutput();

  const handleClearOutput = async () => {
    const clearOutputIndex = executionEngineNode.tool.outputHistory?.findIndex((item) => !item.output);
    if (clearOutputIndex !== -1 && clearOutputIndex !== undefined) {
      setOutputHistoryIndex(nodeId, clearOutputIndex);
      syncOutputs(nodeId);
      clearOutput(nodeId);
    } else {
      clearOutput(nodeId);
      await clearNodeOutput(nodeId);
      mutateWhiteboardNodes(Number(whiteboardId));
      addToHistoryStack();
    }
  };

  const disabled = nodeData.status !== ExecutionEngineNodeStatus.DONE || !nodeData.output;

  return (
    <button
      onClick={handleClearOutput}
      disabled={disabled}
      className={cn('rounded-lg p-2 transition-all hover:bg-neutrals-100', disabled ? 'opacity-50' : '')}
      type="button"
      title="Clear output"
    >
      <EraserIcon className="size-5 path:stroke-neutrals-600" />
    </button>
  );
};

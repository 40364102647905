import { useAccountInfo } from '@/hooks/useAccountInfo';
import { WhiteboardTemplate, WhiteboardTemplateScope } from '@/models/whiteboard-template';

type TemplateAuthorProps = {
  template: WhiteboardTemplate;
};

export function TemplateAuthor({ template }: TemplateAuthorProps) {
  const privateTemplate = template.scope === WhiteboardTemplateScope.PRIVATE;
  const accountInfo = useAccountInfo();
  const owner = privateTemplate ? accountInfo?.name : 'Digital First AI';
  const imgSrc = privateTemplate ? accountInfo?.picture : '/df-avatar.png';

  if (!owner) return null;

  return (
    <div className="flex items-center gap-1">
      {imgSrc && (
        <img
          src={imgSrc}
          className="size-6 rounded-full object-cover"
        />
      )}
      <div className="text-[0.65rem] text-neutrals-600">{owner}</div>
    </div>
  );
}

import { useApi } from '@/api/api.ts';
import { WhiteboardTemplate } from '@/models/whiteboard-template';
import useSWR from 'swr';

export const useWhiteboardTemplates = () => {
  const { getFetcher } = useApi();

  const { data, ...rest } = useSWR<WhiteboardTemplate[]>('/whiteboard-template', getFetcher);

  return {
    whiteboardTemplates: data,
    ...rest,
  };
};

import MinusIcon from '@assets/minus.svg';
import PlusIcon from '@assets/plus.svg';
import { useReactFlow } from '@xyflow/react';
import { useEffect, useState } from 'react';
import { PanelButton, PanelCard } from './PanelUIComponents';

export function ZoomPanel() {
  const { zoomTo, zoomIn, zoomOut, getZoom } = useReactFlow();
  const currentZoom = getZoom();

  const [zoom, setZoom] = useState(currentZoom);

  useEffect(() => {
    setZoom(currentZoom);
  }, [currentZoom]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value.replace('%', '');
    const numValue = Number(value) / 100;
    if (!isNaN(numValue) && numValue > 0) {
      setZoom(numValue);
    }
  }

  function handleBlur() {
    const numZoom = Number(zoom);
    if (!isNaN(numZoom) && numZoom > 0) {
      zoomTo(numZoom);
    }
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      const numZoom = Number(zoom);
      if (!isNaN(numZoom) && numZoom > 0) {
        zoomTo(numZoom);
      }
    }
    if (e.key === 'Escape') {
      setZoom(currentZoom);
    }
  }

  return (
    <PanelCard>
      <PanelButton
        onClick={() => {
          zoomOut();
        }}
      >
        <MinusIcon className="size-5 path:fill-neutrals-700" />
      </PanelButton>

      <input
        value={`${Math.round(Number(zoom) * 100)}%`}
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        className="w-9 border-0 p-0 text-center text-body-sm text-neutrals-700 outline-none"
      />

      <PanelButton
        onClick={() => {
          zoomIn();
        }}
      >
        <PlusIcon className="size-5 path:fill-neutrals-700" />
      </PanelButton>
    </PanelCard>
  );
}

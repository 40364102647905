import { useApi } from '@/api/api';
import useSWR from 'swr';
import { Whiteboard } from '../types';

export const useWhiteboard = (
  whiteboardId?: string,
): {
  whiteboard?: Whiteboard;
  isLoading: boolean;
  error?: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR<Whiteboard>(
    whiteboardId ? `/whiteboard/${whiteboardId}` : null,
    getFetcher,
  );

  return {
    whiteboard: data,
    isLoading,
    error,
  };
};

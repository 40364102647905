import { WebSearcherRoom } from '@/models/web-searcher-room.interface';
import './WebSearcherRoomChat.scss';
import { FormikProvider, useFormik } from 'formik';
import { useAskQuestionInWebSearcherRoom } from '@/api/websearcher/ask-question-in-web-searcher-room.ts';
import { object, string } from 'yup';
import { WebSearcherAnswer } from './WebSearcherAnswer.tsx';
import { useEffect, useRef, useState } from 'react';
import { useAskQuestionInPublicWebSearcherRoom } from '@/api/public/websearcher/ask-question-in-public-web-searcher-room.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useNavigate } from 'react-router-dom';
import { useDeleteWebSearcherRoom } from '@/api/websearcher/delete-web-searcher-room.ts';
import { useCurrentWorkspace, usePublicWebSearcherRooms } from '@/state/app-store.ts';
import { useWebSearcherMenuStore, useWebSearcherStore } from '../web-searcher.store.ts';
import { useAuth } from '@/hooks/auth/useAuth.ts';
import { WebSearcherRoomHistoryItem } from '@/models/web-seracher-room-history-item.interface.ts';
import { v4 as uuid } from 'uuid';
import { useWebSearcherAnswerStream } from '../useWebSearcherAnswerStream.ts';
import toast from 'react-hot-toast';
import { mutate } from 'swr';
import { PageTitle } from '@/components/PageTitle.tsx';

export function WebSearcherRoomChat({ room }: { room: WebSearcherRoom }) {
  const scrollContainer = useRef<HTMLDivElement | null>(null);
  const [editId, setEditId] = useState<number | undefined>(undefined);
  const { isAuthenticated } = useAuth();
  const { askQuestionInWebSearcherRoom } = useAskQuestionInWebSearcherRoom();
  const { askQuestionInPublicWebSearcherRoom } = useAskQuestionInPublicWebSearcherRoom();
  const { deleteWebSearcherRoom } = useDeleteWebSearcherRoom();
  const { publicWebSearcherRooms, setPublicWebSearcherRooms } = usePublicWebSearcherRooms();
  const { questions, setQuestions, isFetchingAnswer, setAnswerFetchingStart, setAnswerFetchingEnd } =
    useWebSearcherStore(room.id);
  const { setIsMenuOpen } = useWebSearcherMenuStore();
  const navigate = useNavigate();
  const workspaceId = useCurrentWorkspace()?.id;
  const { answers, updateAnswerOnStream } = useWebSearcherAnswerStream();

  const formik = useFormik({
    initialValues: {
      question: '',
    },
    validationSchema: object({
      question: string().required(),
    }),
    onSubmit: ({ question }) => {
      formik.resetForm();
      setIsMenuOpen(false);
      askNewQuestion(question);
    },
  });

  // on change of room.id, set questions in global store if not already set
  useEffect(() => {
    if (questions?.length) return;

    if (!room.history.length) {
      setQuestions([room.name]);
    } else {
      setQuestions(room.history.map((historyItem) => historyItem.question.message));
    }
  }, [room.id]);

  // on change of questions array, fetch answer for the question without answer
  useEffect(() => {
    if (!questions?.length) return;

    if (isFetchingAnswer) {
      return;
    }

    const editedQuestionIndex = room.history.findIndex((historyItem) => historyItem.id === editId);
    if (editedQuestionIndex !== -1) {
      const editedQuestion = questions[editedQuestionIndex];
      if (editedQuestion) {
        fetchAnswerForQuestion(editedQuestion);
      }
      return;
    }

    const questionWithoutAnswer = questions.find(
      (question) => !room.history.find((historyItem) => historyItem.question.message === question),
    );

    if (questionWithoutAnswer) {
      scrollToLatestQuestion();
      fetchAnswerForQuestion(questionWithoutAnswer);
    }
  }, [questions, questions?.length]);

  const askNewQuestion = (question: string) => {
    if (isFetchingAnswer) return;

    setQuestions([...questions!, question]);
  };

  const editQuestion = async (newQuestion: string, historyItem: WebSearcherRoomHistoryItem, questionIdx: number) => {
    setEditId(historyItem.id);
    setQuestions(questions.map((q, i) => (i === questionIdx ? newQuestion : q)));
  };

  const onAskFollowUpQuestion = async (question: string) => {
    setIsMenuOpen(false);
    askNewQuestion(question);
  };

  const scrollToLatestQuestion = () => {
    setTimeout(() => {
      const headers = scrollContainer.current?.querySelectorAll('h2.web-searcher-header');

      if (headers?.length) {
        headers[headers.length - 1].scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  };

  async function handleError(question: string) {
    toast.error(`Failed to fetch answer for question: ${question}`);
    setAnswerFetchingEnd();
    if (questions!.length > 1) {
      setQuestions(questions!.filter((q) => q !== question));
    }

    if (isAuthenticated) {
      if (questions!.length === 1) {
        navigate(buildUrl([Urls.WEB_SEARCHER]));
        await deleteWebSearcherRoom(room.id);
      }
    } else {
      if (questions!.length === 1) {
        navigate(buildUrl([Urls.PUBLIC_APP, Urls.WEB_SEARCHER]));
        setPublicWebSearcherRooms(publicWebSearcherRooms.filter((r) => r.id !== room.id));
      }
    }
  }

  async function handleDone(question: string) {
    if (!isAuthenticated) {
      const answer = answers[question];
      publicWebSearcherRooms
        .find((r) => r.id === room.id)
        ?.history.push({
          question: { message: question },
          answer,
        });
      setPublicWebSearcherRooms([...publicWebSearcherRooms]);
    } else {
      await mutate(`/websearcher/rooms/by-workspace/${workspaceId}`);
    }
  }

  const fetchAnswerForQuestion = async (question: string) => {
    setAnswerFetchingStart();
    const wsChannel = `websearcher-${room.id}-${uuid()}`;
    const { listenerOff } = updateAnswerOnStream(
      question,
      wsChannel,
      () => handleError(question),
      () => handleDone(question),
    );

    const askQuestion = isAuthenticated ? askQuestionInWebSearcherRoom : askQuestionInPublicWebSearcherRoom;
    try {
      await askQuestion(room.id, question, editId, wsChannel);
      setAnswerFetchingEnd();
      setEditId(undefined);
    } catch {
      listenerOff();
      handleError(question);
    }
  };

  return (
    <>
      <PageTitle title={`Digitalfirst.ai | ${questions?.[0] ?? ''}`} />

      <div className="flex items-start justify-center px-[3.75rem] pb-[3.75rem] pt-8">
        <div
          className="flex flex-col gap-10"
          ref={scrollContainer}
        >
          {questions?.map((question, i) => (
            <WebSearcherAnswer
              key={`${i}-full-answer`}
              historyItem={room.history[i]}
              question={question}
              onAskFollowUpQuestion={onAskFollowUpQuestion}
              onEditQuestion={(newQuestion) => {
                editQuestion(newQuestion, room.history[i], i);
              }}
              answer={answers[question] || room.history[i]?.answer}
            />
          ))}

          {!isFetchingAnswer && (
            <div className="sticky bottom-0 left-0 z-[1000] pb-7">
              <div className="mt-8">
                <div className="grid grid-cols-[minmax(10%,42rem),minmax(auto,22rem)] gap-12">
                  <FormikProvider value={formik}>
                    <form
                      className="flex rounded-xl bg-white p-4 pl-5 shadow-[0_0_1rem_0_#E3E3E5]"
                      onSubmit={formik.handleSubmit}
                    >
                      <input
                        {...formik.getFieldProps('question')}
                        className="ml-2 flex flex-1 outline-none"
                        placeholder="Ask anything..."
                      />
                      <button
                        className="ml-4 rounded-lg bg-primary-default p-2 hover:bg-primary-hover active:bg-primary-active"
                        type="submit"
                      >
                        <img
                          src="/arrow-right-white.svg"
                          alt=""
                        />
                      </button>
                    </form>
                  </FormikProvider>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import { ToolConfig, ToolValue } from 'src/libs/tools/type.model';

export type ReaderFileItem = {
  id: string | number;
  name: string;
};

export function prepareFieldsForOneValue(toolConfig: ToolConfig, value: ToolValue) {
  return [{ key: Object.keys(toolConfig.config.input)[0], value }];
}

import { TimeEntity } from '@/models/time-entity.interface.ts';
import { Whiteboard } from 'src/routes/whiteboards/types';

export enum WhiteboardTemplateScope {
  PUBLIC = 'PUBLIC',
  IN_APP = 'IN_APP',
  PRIVATE = 'PRIVATE',
}

export enum WhiteboardTemplateCategory {
  MY = 'my',
  MARKETING = 'marketing',
  COMMUNICATION = 'communication',
  EXECUTION = 'execution',
  DOCUMENTATION = 'documentation',
  ANALYSIS = 'analysis',
  RESEARCH = 'research',
  STRATEGY = 'strategy',
  CREATIVE_DEVELOPMENT = 'creativeDevelopment',
  CONTENT_CREATION = 'contentCreation',
  ONBOARDING = 'onboarding',
}

export const WHITEBOARD_TEMPLATE_CATEGORIES = Object.values(WhiteboardTemplateCategory).map((value) => ({
  label: value.charAt(0).toUpperCase() + value.slice(1),
  value: value as WhiteboardTemplateCategory,
}));

export interface WhiteboardTemplate extends TimeEntity {
  id: number;
  whiteboard: Whiteboard;
  name: string;
  scope: WhiteboardTemplateScope;
  ownerId: string;
  ownerName: string | null;
  category: WhiteboardTemplateCategory;
  description?: string;
  uuid: string;
}

import { create } from 'zustand';
import { Workspace } from '@/models/workspace.interface.ts';
import { persist } from 'zustand/middleware';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';

interface AppStoreProps {
  currentWorkspace: Workspace | null;
  publicWebSearcherRooms: WebSearcherRoom[];
  invitationData: {
    organizationId: string;
    invitationId: string;
  } | null;
}

const appStore = create<AppStoreProps>()(
  persist(
    (): AppStoreProps => ({
      currentWorkspace: null,
      publicWebSearcherRooms: [],
      invitationData: null,
    }),
    {
      name: 'df-app-store',
    },
  ),
);

export const useCurrentWorkspace = (): Workspace | null => {
  return appStore((state) => state.currentWorkspace);
};

export const useDataRoomId = () => {
  const currentWorkspace = useCurrentWorkspace();
  return currentWorkspace?.dataRoom?.id ?? null;
};

export const useInvitationData = () => ({
  invitationData: appStore((state) => state.invitationData),
  setInvitationData: (invitationData: { organizationId: string; invitationId: string } | null) =>
    appStore.setState(() => ({ invitationData })),
});

export const setCurrentWorkspace = (workspace: Workspace | null) => appStore.setState({ currentWorkspace: workspace });

export const usePublicWebSearcherRooms = () => ({
  publicWebSearcherRooms: appStore((state) => state.publicWebSearcherRooms),
  setPublicWebSearcherRooms: (publicWebSearcherRooms: WebSearcherRoom[]) =>
    appStore.setState(() => ({ publicWebSearcherRooms })),
});

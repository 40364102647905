import { convertYdocToJson } from '@/helpers/convert-ydoc-to-json';
import { ToolField, ToolName, ToolOutputType } from 'src/libs/tools/type.model';
import { convertToNewTool } from '@/components/tiptap/extensions/DigitalFirst/helpers/convertToNewTools';
import { turndownService } from '@/components/tiptap/extensions/DigitalFirst/turndown/turndown.service';
import { JSONContent } from '@tiptap/core';
import { NewNodeParams } from '../hooks/useAddNode';
import { useGetTiptapDocument } from '@/api/tiptap-document/get-tiptap-docuemnt';
import { useToolConfigs } from '@/api/tools/get-tool-configs';
import { DfFeature } from '@/enums/df-feature.enum';
import { useGetTool } from '@/api/tools/get-tool';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { Renderer } from 'prosemirror-to-html-js';
import { ToolConfig } from '../../../libs/tools/type.model';
import { Edge } from '@xyflow/react';
import { findElementId } from '@/components/tiptap/extensions/DigitalFirst/helpers/findElementId';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum';
import { generateId } from './node-helpers';

interface MentionRef {
  headingNameRef: Record<string, string[]>;
  dataRoomIdsRef: Record<string, string[]>;
  canvasTemplateIdsRef: Record<string, string[]>;
  canvasInstanceIdsRef: Record<string, string[]>;
  personaIdsRef: Record<string, string[]>;
  productCanvasIdsRef: Record<string, string[]>;
}

const getMarkdownFromNode = (node: JSONContent) => {
  const renderer = new Renderer();
  const html = renderer.render(node);
  return turndownService.turndown(html);
};

const getToolConfigFromNode = async (node: JSONContent, toolConfigs: ToolConfig[]) => {
  let toolConfig = node?.attrs?.selectedtool;

  const toolName = toolConfig.name as ToolName;
  const toolOutputType = toolConfig?.config?.outputType as ToolOutputType;

  if (!ToolName[toolName]) {
    toolConfig = toolConfigs.find((toolConfig) => toolConfig.name === ToolName.READER_TEXT)!;
  }

  if (!Object.values(ToolOutputType).includes(toolOutputType)) {
    toolConfig = toolConfigs.find((toolConfig) => toolConfig.name === toolConfig.name)!;
  }

  return toolConfig;
};

const getAllMentions = (fields: ToolField[], schema: JSONContent): MentionRef => {
  const headingNameRef: Record<string, string[]> = {};
  let dataRoomIdsRef: Record<string, string[]> = {};
  let canvasTemplateIdsRef: Record<string, string[]> = {};
  let canvasInstanceIdsRef: Record<string, string[]> = {};
  let personaIdsRef: Record<string, string[]> = {};
  let productCanvasIdsRef: Record<string, string[]> = {};

  for (const field of fields) {
    if (typeof field.value !== 'string') {
      continue;
    }

    const headingsIds = findElementId(field.value, `[data-type="${MentionReferenceType.SECTION}"]`);
    const dataRoomResourceIds = findElementId(field.value, `[data-type="${MentionReferenceType.DATA_ROOM}"]`);
    const canvasTemplatesIds = findElementId(field.value, `[data-type="${MentionReferenceType.CANVAS}"]`);
    const canvasInstancesIds = findElementId(field.value, `[data-type="${MentionReferenceType.CANVAS_INSTANCE}"]`);
    const personasIds = findElementId(field.value, `[data-type="${MentionReferenceType.PERSONA}"]`);
    const productCanvasesMentionsIds = findElementId(
      field.value,
      `[data-type="${MentionReferenceType.PRODUCT_CANVAS}"]`,
    );

    for (const headingId of headingsIds) {
      const heading = schema.content?.find((node) => node.type === 'heading' && node.attrs?.id === headingId);
      if (heading?.content?.[0]?.text) {
        headingNameRef[field.key] = [heading.content[0].text, ...(headingNameRef[field.key] ?? [])];
      }
    }

    dataRoomIdsRef = { [field.key]: dataRoomResourceIds, ...dataRoomIdsRef };
    canvasTemplateIdsRef = { [field.key]: canvasTemplatesIds, ...canvasTemplateIdsRef };
    canvasInstanceIdsRef = { [field.key]: canvasInstancesIds, ...canvasInstanceIdsRef };
    personaIdsRef = { [field.key]: personasIds, ...personaIdsRef };
    productCanvasIdsRef = { [field.key]: productCanvasesMentionsIds, ...productCanvasIdsRef };
  }

  return {
    headingNameRef,
    dataRoomIdsRef,
    canvasTemplateIdsRef,
    canvasInstanceIdsRef,
    personaIdsRef,
    productCanvasIdsRef,
  } as unknown as MentionRef;
};

const convertMentionsToWhiteboardNodes = (
  mentions: MentionRef,
  toolConfigs: ToolConfig[],
  targetNodeId: string,
  reactFlowNodes: NewNodeParams[],
) => {
  const mentionReactFlowNodes: NewNodeParams[] = [];
  const mentionReactFlowEdges: Edge[] = [];

  for (const key in mentions.headingNameRef) {
    const sourceNodeId = reactFlowNodes.find((node) => mentions.headingNameRef[key].includes(node.nodeName!))?.id;

    mentionReactFlowEdges.push({
      id: generateId('edge'),
      source: sourceNodeId!,
      target: targetNodeId,
      targetHandle: key,
    });
  }

  for (const key in mentions.canvasInstanceIdsRef) {
    const canvasInstanceIds = mentions.canvasInstanceIdsRef[key];
    const canvasConfig = toolConfigs.find((toolConfig) => toolConfig.name === ToolName.READER_CANVAS)!;

    canvasInstanceIds?.forEach((id) => {
      const nodeId = generateId(canvasConfig.name);
      mentionReactFlowNodes.push({
        id: nodeId,
        nodeName: 'Canvas',
        toolConfig: canvasConfig,
        fields: [{ key: 'instanceId', value: id }],
        execute: true,
        data: {
          valid: true,
        },
      });

      mentionReactFlowEdges.push({
        id: generateId('edge'),
        source: nodeId,
        target: targetNodeId,
        targetHandle: key,
      });
    });
  }

  for (const key in mentions.dataRoomIdsRef) {
    const dataRoomResourceIds = mentions.dataRoomIdsRef[key];
    const dataRoomConfig = toolConfigs.find((toolConfig) => toolConfig.name === ToolName.READER_DATA_ROOM)!;

    const nodeId = generateId(dataRoomConfig.name);

    dataRoomResourceIds?.forEach((id) => {
      mentionReactFlowNodes.push({
        id: nodeId,
        nodeName: 'Data room',
        toolConfig: dataRoomConfig,
        fields: [{ key: 'resourceId', value: id }],
        execute: true,
        data: {
          valid: true,
        },
      });

      mentionReactFlowEdges.push({
        id: generateId('edge'),
        source: nodeId,
        target: targetNodeId,
        targetHandle: key,
      });
    });
  }

  for (const key in mentions.personaIdsRef) {
    const personaResourceIds = mentions.personaIdsRef[key];
    const personaConfig = toolConfigs.find((toolConfig) => toolConfig.name === ToolName.READER_PERSONA)!;

    personaResourceIds?.forEach((id) => {
      const nodeId = generateId(personaConfig.name);
      mentionReactFlowNodes.push({
        id: nodeId,
        nodeName: 'Persona',
        toolConfig: personaConfig,
        fields: [{ key: 'instanceId', value: id }],
        execute: true,
        data: {
          valid: true,
        },
      });

      mentionReactFlowEdges.push({
        id: generateId('edge'),
        source: nodeId,
        target: targetNodeId,
        targetHandle: key,
      });
    });
  }

  //produkty?
  //canvas templaty?

  return { mentionReactFlowNodes, mentionReactFlowEdges };
};

export const useConvertTiptapToWhiteboard = () => {
  const { getTiptapDocument } = useGetTiptapDocument();
  const { toolConfigs } = useToolConfigs(DfFeature.WHITEBOARD);
  const { getTool } = useGetTool();

  const convertTiptapToWhiteboard = async (tiptapDocumentId: number) => {
    const tiptapDocument = await getTiptapDocument(tiptapDocumentId);
    const jsonSchema = tiptapDocument.data ? convertYdocToJson(tiptapDocument.data) : null;
    const reactFlowNodes: NewNodeParams[] = [];
    const reactFlowEdges: Edge[] = [];

    if (!jsonSchema || !jsonSchema.content || !toolConfigs) {
      return;
    }

    const handleNode = async (node: JSONContent, nodeName: string) => {
      const markdown = getMarkdownFromNode(node);
      let output = markdown;

      if (node && node.type !== 'dfGenOutput') {
        markdown &&
          reactFlowNodes.push({
            id: generateId(ToolName.READER_TEXT),
            nodeName,
            toolConfig: toolConfigs.find((toolConfig) => toolConfig.name === ToolName.READER_TEXT)!,
            output: markdown ?? '',
            data: {
              valid: true,
            },
          });
      } else {
        const toolConfig = await getToolConfigFromNode(node, toolConfigs);

        if (toolConfig.config.outputType === ToolOutputType.JSON && node?.attrs?.selectedtoolid) {
          const tool = await getTool(node.attrs.selectedtoolid);
          output = tool.currentOutput;
        }

        const fields = convertToNewTool(node?.attrs?.tooldata);
        const mentions = getAllMentions(fields, jsonSchema);
        const nodeId = generateId(toolConfig.name);
        const { mentionReactFlowNodes, mentionReactFlowEdges } = convertMentionsToWhiteboardNodes(
          mentions,
          toolConfigs,
          nodeId,
          reactFlowNodes,
        );

        reactFlowEdges.push(...mentionReactFlowEdges);
        reactFlowNodes.push(...mentionReactFlowNodes);
        reactFlowNodes.push({
          id: nodeId,
          nodeName,
          toolConfig,
          output,
          fields,
          data: {
            valid: true,
          },
        });
      }
    };

    for (let i = 0; i < jsonSchema.content.length; ) {
      const node = jsonSchema.content[i];

      if (node.type === 'heading') {
        const nextNode = jsonSchema.content[i + 1];
        await handleNode(nextNode, node?.content?.[0]?.text ?? 'Node');

        i = i + 2;
        continue;
      }

      await handleNode(node, node?.content?.[0]?.text ?? 'Node');
      i = i + 1;
    }

    return { reactFlowNodes, reactFlowEdges };
  };

  return { convertTiptapToWhiteboard };
};

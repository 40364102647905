import { StrategyCanvasPositionCard } from './components/StrategyCanvasPositionCard.tsx';
import { useNavigate, useParams } from 'react-router-dom';
import { useCanvasDetails } from '@/api/canvases/api.ts';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { DropdownCanvasOptions } from '../../components/DropdownCanvasOptions.tsx';
import { GenerateStrategyCanvasButton } from './components/GenerateStrategyCanvasButton.tsx';
import { InfoCard } from '@/components/InfoCard.tsx';
import { Button } from 'flowbite-react';
import DataIcon from '@assets/data.svg';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { CanvasGenerationContextSideModalBody } from './components/modals/CanvasGenerationContextSideModalBody.tsx';
import { useMemo } from 'react';
import { PageTitle } from '@/components/PageTitle.tsx';

export function StrategyCanvasDetailsPage() {
  const { canvasId } = useParams<{ canvasId: string }>();
  const { canvasDetails, isLoading } = useCanvasDetails(canvasId!);
  const navigate = useNavigate();

  const shouldShowTutorialInfo = useMemo(() => {
    if (!canvasDetails) return false;

    return (
      !!canvasDetails.tutorialUrl &&
      !!canvasDetails.tutorialImageUrl &&
      !!canvasDetails.tutorialDescription &&
      canvasDetails.positions.every((position) => position.value.length === 0)
    );
  }, [canvasDetails?.tutorialUrl, canvasDetails?.tutorialImageUrl, canvasDetails?.tutorialDescription]);

  if (isLoading || !canvasDetails) return;

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Strategy canvases', href: buildUrl([Urls.CANVASES]) },
          { label: 'My canvases', href: buildUrl([Urls.CANVASES, Urls.MY_CANVASES]) },
          { label: canvasDetails?.name ?? '', href: '#' },
        ],
        icon: '/canvases-black.svg',
        leftAddons: [
          <DropdownCanvasOptions
            key={canvasDetails.id}
            instanceId={canvasDetails.id}
            onDelete={() => {
              navigate(buildUrl([Urls.CANVASES, Urls.MY_CANVASES]));
            }}
          />,
        ],
        rightAddons: [
          <Button
            color="secondary"
            size="sm"
            key="sources"
            onClick={() => openSideModal(<CanvasGenerationContextSideModalBody />, { canvasDetails })}
          >
            <DataIcon />
            Context
          </Button>,
          <GenerateStrategyCanvasButton
            key="generate"
            canvasDetails={canvasDetails}
          />,
        ],
      }}
    >
      <PageTitle title={`Digitalfirst.ai | ${canvasDetails.name}`} />
      {shouldShowTutorialInfo && (
        <InfoCard
          title={canvasDetails.name}
          description={canvasDetails.tutorialDescription!}
          buttonUrl={canvasDetails.tutorialUrl!}
          imageUrl={canvasDetails.tutorialImageUrl!}
          className="mb-8"
        />
      )}
      <div className="grid grid-cols-2 items-start gap-6">
        {canvasDetails.positions
          .sort((a, b) => {
            return a.hierarchyPosition - b.hierarchyPosition;
          })
          .map((position, i) => (
            <StrategyCanvasPositionCard
              key={position.id}
              position={position}
              canvasDetails={canvasDetails}
              index={i}
            />
          ))}
      </div>
    </PageWithHeaderLayout>
  );
}

import { closeSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { ReactNode, RefObject } from 'react';

interface SideModalBodyWithHeaderProps {
  header: ReactNode;
  subheader: string;
  children: ReactNode;
  ref?: RefObject<HTMLDivElement | null>;
}

export const SideModalBodyWithHeader = ({ header, subheader, children, ref }: SideModalBodyWithHeaderProps) => {
  return (
    <>
      <div
        className="relative bg-gray-ultra-light p-6"
        ref={ref}
      >
        <div className="mb-2 flex w-full gap-4 text-h2-desktop font-medium text-gray-dark">
          <div className="grow">{header}</div>
          <div>
            <button
              className="hover:opacity-70"
              onClick={() => closeSideModal()}
            >
              <img
                src="/close-side-panel.svg"
                className="cursor-pointer"
                alt=""
              />
            </button>
          </div>
        </div>
        {!!subheader && <div className="text-sm font-normal text-gray-dark">{subheader}</div>}
      </div>

      {children}
    </>
  );
};

import { LangChainModel } from '@/enums/lang-chain-model.enum.ts';

export interface Asset {
  id: number;
  name: AssetName;
  category: AssetCategory;
  format: string;
  type: AssetType;
  textConfig: AssetTextConfig;
  graphicConfig: AssetGraphicConfig;
}

export type AssetCreatePayload = Omit<Asset, 'id'>;

export interface AssetTextConfig {
  prompt: string;
  model: LangChainModel;
  bodyTextMaxLength: number;
  bodyTextFieldDescription: string;
  headerMaxLength?: number;
  headerFieldDescription?: string;
  descriptionMaxLength?: number;
  descriptionFieldDescription?: string;
}

export interface AssetGraphicConfig {
  prompt: string;
  fileFormat: AssetFileFormat[];
  aspectRatio: IdeogramAspectRatio;
  resolution: string;
  maxSizeInMb: number;
  width: number;
  height: number;
}

export enum AssetName {
  FACEBOOK_ADS = 'Facebook Ads',
  INSTAGRAM_ADS = 'Instagram Ads',
  FACEBOOK_CAROUSEL_ADS = 'Facebook Carousel Ads',
}

export enum AssetCategory {
  SOCIAL_MEDIA = 'Social Media',
}

export const CategoryAssetNames: { [category in AssetCategory]: AssetName[] } = {
  [AssetCategory.SOCIAL_MEDIA]: [AssetName.FACEBOOK_ADS, AssetName.INSTAGRAM_ADS, AssetName.FACEBOOK_CAROUSEL_ADS],
} as const;

export enum AssetFileFormat {
  PNG = 'PNG',
  JPEG = 'JPEG',
  GIF = 'GIF',
  MP4 = 'MP4',
  MOV = 'MOV',
}

export enum AssetType {
  TEXT_IMAGE = 'TEXT_IMAGE',
  TEXT_VIDEO = 'TEXT_VIDEO',
  TEXT_IMAGE_OR_VIDEO = 'TEXT_IMAGE_OR_VIDEO',
}

export enum IdeogramAspectRatio {
  ASPECT_10_16 = 'ASPECT_10_16',
  ASPECT_16_10 = 'ASPECT_16_10',
  ASPECT_9_16 = 'ASPECT_9_16',
  ASPECT_16_9 = 'ASPECT_16_9',
  ASPECT_3_2 = 'ASPECT_3_2',
  ASPECT_2_3 = 'ASPECT_2_3',
  ASPECT_4_3 = 'ASPECT_4_3',
  ASPECT_3_4 = 'ASPECT_3_4',
  ASPECT_1_1 = 'ASPECT_1_1',
  ASPECT_3_1 = 'ASPECT_3_1',
  ASPECT_1_3 = 'ASPECT_1_3',
}

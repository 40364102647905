import { MouseEventHandler } from 'react';

export function TemplateButton({
  onClick,
  label,
}: {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  label: string;
}) {
  return (
    <button
      className="rounded-lg bg-primary-600 px-4 py-3 text-button-md text-white transition hover:bg-primary-700"
      onClick={onClick}
    >
      {label}
    </button>
  );
}

import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { array, number, object, string } from 'yup';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { FormikProvider, useFormik } from 'formik';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const.ts';
import { Button, Checkbox, Label, Select, TextInput } from 'flowbite-react';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import {
  Asset,
  AssetCategory,
  AssetFileFormat,
  AssetName,
  AssetType,
  IdeogramAspectRatio,
} from '@/models/asset.model.ts';
import { LangChainModel } from '@/enums/lang-chain-model.enum.ts';
import { useUpdateAsset } from '@/api/assets/useUpdateAsset.ts';
import { useCreateAsset } from '@/api/assets/useCreateAsset.ts';

export const AssetEditModalBody = () => {
  const { closeModal, context } = useGlobalModal<{ asset: Asset | undefined }>();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { updateAsset } = useUpdateAsset();
  const { createAsset } = useCreateAsset();
  const asset = context?.asset;

  const formik = useFormik({
    initialValues: {
      name: asset?.name ?? AssetName.FACEBOOK_ADS,
      category: asset?.category ?? AssetCategory.SOCIAL_MEDIA,
      format: asset?.format ?? '',
      textConfigPrompt: asset?.textConfig?.prompt ?? '',
      textConfigModel: asset?.textConfig?.model ?? LangChainModel.gpt4o,
      textConfigBodyTextMaxLength: asset?.textConfig?.bodyTextMaxLength ?? '',
      textConfigBodyTextFieldDescription: asset?.textConfig?.bodyTextFieldDescription ?? '',
      textConfigHeaderMaxLength: asset?.textConfig.headerMaxLength ?? '',
      textConfigHeaderFieldDescription: asset?.textConfig.headerFieldDescription ?? '',
      textConfigDescriptionMaxLength: asset?.textConfig.descriptionMaxLength ?? '',
      textConfigDescriptionFieldDescription: asset?.textConfig.descriptionFieldDescription ?? '',
      graphicConfigPrompt: asset?.graphicConfig.prompt ?? '',
      graphicConfigFileFormat: asset?.graphicConfig.fileFormat ?? [],
      graphicConfigAspectRatio: asset?.graphicConfig.aspectRatio ?? IdeogramAspectRatio.ASPECT_1_1,
      graphicConfigResolution: asset?.graphicConfig.resolution ?? '',
      graphicConfigMaxSizeInMb: asset?.graphicConfig.maxSizeInMb ?? '',
      graphicConfigWidth: asset?.graphicConfig.width ?? '',
      graphicConfigHeight: asset?.graphicConfig.height ?? '',
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
      category: string().required(VALIDATION_ERRORS.Required),
      format: string().required(VALIDATION_ERRORS.Required),
      textConfigPrompt: string().required(VALIDATION_ERRORS.Required),
      textConfigModel: string().required(VALIDATION_ERRORS.Required),
      textConfigBodyTextMaxLength: number().required(VALIDATION_ERRORS.Required),
      textConfigBodyTextFieldDescription: string().required(VALIDATION_ERRORS.Required),
      textConfigHeaderMaxLength: number(),
      textConfigHeaderFieldDescription: string(),
      textConfigDescriptionMaxLength: number(),
      textConfigDescriptionFieldDescription: string(),
      graphicConfigPrompt: string().required(VALIDATION_ERRORS.Required),
      graphicConfigFileFormat: array().of(string()).min(1, 'At least one item is required'),
      graphicConfigAspectRatio: string().required(VALIDATION_ERRORS.Required),
      graphicConfigResolution: string(),
      graphicConfigMaxSizeInMb: number().required(VALIDATION_ERRORS.Required),
      graphicConfigWidth: number().required(VALIDATION_ERRORS.Required),
      graphicConfigHeight: number().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (data) => {
      closeModal();
      showLoader();

      const payload = {
        name: data.name,
        category: data.category,
        format: data.format,
        type: AssetType.TEXT_IMAGE,
        textConfig: {
          prompt: data.textConfigPrompt,
          model: data.textConfigModel,
          bodyTextMaxLength: Number(data.textConfigBodyTextMaxLength),
          bodyTextFieldDescription: data.textConfigBodyTextFieldDescription,
          headerMaxLength: data.textConfigHeaderMaxLength ? Number(data.textConfigHeaderMaxLength) : undefined,
          headerFieldDescription: data.textConfigHeaderFieldDescription,
          descriptionMaxLength: data.textConfigDescriptionMaxLength
            ? Number(data.textConfigDescriptionMaxLength)
            : undefined,
          descriptionFieldDescription: data.textConfigDescriptionFieldDescription,
        },
        graphicConfig: {
          prompt: data.graphicConfigPrompt,
          fileFormat: data.graphicConfigFileFormat,
          aspectRatio: data.graphicConfigAspectRatio,
          resolution: data.graphicConfigResolution,
          maxSizeInMb: Number(data.graphicConfigMaxSizeInMb),
          width: Number(data.graphicConfigWidth),
          height: Number(data.graphicConfigHeight),
        },
      };

      if (!asset) {
        await createAsset(payload);
      } else {
        await updateAsset(asset!.id, payload);
      }

      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-3 gap-8">
          <div className="flex flex-col gap-6">
            <h2 className="mb-5 mt-2 text-h3-desktop">General</h2>

            <div>
              <Label htmlFor="name">Asset name *</Label>
              <Select {...getDfFlowbiteFieldProps('name', formik)}>
                {Object.entries(AssetName).map(([key, value]) => (
                  <option
                    key={key}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <Label htmlFor="category">Category *</Label>
              <Select {...getDfFlowbiteFieldProps('category', formik)}>
                {Object.entries(AssetCategory).map(([key, value]) => (
                  <option
                    key={key}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <Label htmlFor="format">Format *</Label>
              <TextInput {...getDfFlowbiteFieldProps('format', formik)} />
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <h2 className="mb-5 mt-2 text-h3-desktop">Text Config</h2>

            <div>
              <Label htmlFor="textConfigPrompt">Text config prompt *</Label>
              <TextInput {...getDfFlowbiteFieldProps('textConfigPrompt', formik)} />
            </div>

            <div>
              <Label htmlFor="textConfigModel">Text config model</Label>
              <Select {...getDfFlowbiteFieldProps('textConfigModel', formik)}>
                {Object.entries(LangChainModel).map(([key, value]) => (
                  <option
                    key={key}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <Label htmlFor="textConfigBodyTextMaxLength">Text config body text max length *</Label>
              <TextInput
                type="number"
                {...getDfFlowbiteFieldProps('textConfigBodyTextMaxLength', formik)}
              />
            </div>

            <div>
              <Label htmlFor="textConfigBodyTextFieldDescription">Text config body text field description *</Label>
              <TextInput {...getDfFlowbiteFieldProps('textConfigBodyTextFieldDescription', formik)} />
            </div>

            <div>
              <Label htmlFor="textConfigHeaderMaxLength">Text config header max length</Label>
              <TextInput
                type="number"
                {...getDfFlowbiteFieldProps('textConfigHeaderMaxLength', formik)}
              />
            </div>

            <div>
              <Label htmlFor="textConfigHeaderFieldDescription">Text config header field description</Label>
              <TextInput {...getDfFlowbiteFieldProps('textConfigHeaderFieldDescription', formik)} />
            </div>

            <div>
              <Label htmlFor="textConfigDescriptionMaxLength">Text config description max length</Label>
              <TextInput
                type="number"
                {...getDfFlowbiteFieldProps('textConfigDescriptionMaxLength', formik)}
              />
            </div>

            <div>
              <Label htmlFor="textConfigDescriptionFieldDescription">Text config description field description</Label>
              <TextInput {...getDfFlowbiteFieldProps('textConfigDescriptionFieldDescription', formik)} />
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <h2 className="mb-5 mt-2 text-h3-desktop">Graphic Config</h2>

            <div>
              <Label htmlFor="graphicConfigPrompt">Graphic config prompt *</Label>
              <TextInput {...getDfFlowbiteFieldProps('graphicConfigPrompt', formik)} />
            </div>

            <div>
              <Label htmlFor="graphicConfigFileFormat">Graphic config file format *</Label>
              <div className="my-4 grid grid-cols-3 gap-3">
                {Object.entries(AssetFileFormat).map(([key, value]) => (
                  <Label
                    key={key}
                    className="inline-flex items-center"
                  >
                    <Checkbox
                      id={key}
                      onChange={() => {
                        formik.setFieldValue(
                          'graphicConfigFileFormat',
                          formik.values.graphicConfigFileFormat!.includes(value)
                            ? formik.values.graphicConfigFileFormat!.filter((v) => v !== value)
                            : [...formik.values.graphicConfigFileFormat!, value],
                        );
                      }}
                      value={value}
                      checked={formik.values.graphicConfigFileFormat!.includes(value)}
                    />
                    <span className="ml-3 text-[0.875rem] font-medium leading-[1.1rem]">{value}</span>
                  </Label>
                ))}
              </div>
              {formik.errors.graphicConfigFileFormat?.length ? (
                <div className="text-sm text-red-default">{formik.errors.graphicConfigFileFormat as string}</div>
              ) : null}
            </div>

            <div>
              <Label htmlFor="graphicConfigAspectRatio">Graphic config aspect ratio *</Label>
              <Select {...getDfFlowbiteFieldProps('graphicConfigAspectRatio', formik)}>
                {Object.entries(IdeogramAspectRatio).map(([key, value]) => (
                  <option
                    key={key}
                    value={value}
                  >
                    {value}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <Label htmlFor="graphicConfigResolution">Graphic config resolution</Label>
              <TextInput {...getDfFlowbiteFieldProps('graphicConfigResolution', formik)} />
            </div>

            <div>
              <Label htmlFor="graphicConfigMaxSizeInMb">Graphic config max size in mb *</Label>
              <TextInput
                type="number"
                {...getDfFlowbiteFieldProps('graphicConfigMaxSizeInMb', formik)}
              />
            </div>

            <div>
              <Label htmlFor="graphicConfigWidth">Graphic config width *</Label>
              <TextInput
                type="number"
                {...getDfFlowbiteFieldProps('graphicConfigWidth', formik)}
              />
            </div>

            <div>
              <Label htmlFor="graphicConfigHeight">Graphic config height *</Label>
              <TextInput
                type="number"
                {...getDfFlowbiteFieldProps('graphicConfigHeight', formik)}
              />
            </div>
          </div>
        </div>

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Save changes
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

import { WhiteboardTemplateScope } from '@/models/whiteboard-template';
import SettingsIcon from '@assets/settings.svg';
import { NodeProps } from '@xyflow/react';
import { useState } from 'react';
import { useWhiteboardStore } from '../../store/whiteboard.store';
import { NodeData } from '../../types';

type TemplatePublicFieldsConfigProps = {
  nodeData: NodeData;
  nodeId: NodeProps['id'];
};

export function TemplatePublicFieldsConfig({ nodeData, nodeId }: TemplatePublicFieldsConfigProps) {
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);
  const template = useWhiteboardStore((state) => state.template);

  const [isOpen, setIsOpen] = useState(false);

  if (!template || template.scope !== WhiteboardTemplateScope.PUBLIC) return null;

  return (
    <div className="w-full rounded-lg border bg-white p-2 text-body-sm">
      <div
        className="flex cursor-pointer items-center gap-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <SettingsIcon className="size-5 path:fill-neutrals-600" />
        <span className="">Template config</span>
      </div>
      {isOpen && (
        <div className="mt-2">
          <span className="">Fields available for users:</span>
          <div className="mt-1">
            {Object.keys(nodeData.toolConfig.config.input).map((key) => (
              <div
                key={key}
                className="flex gap-1"
              >
                <label htmlFor={`checkbox-${key}`}>{key}</label>
                <input
                  type="checkbox"
                  id={`checkbox-${key}`}
                  checked={nodeData.templatePublicFields?.includes(key)}
                  onChange={(e) => {
                    updateNodeData(nodeId, {
                      templatePublicFields: e.target.checked
                        ? [...(nodeData.templatePublicFields ?? []), key]
                        : nodeData.templatePublicFields?.filter((f) => f !== key),
                    });
                  }}
                  className="size-4 rounded border border-neutrals-400 text-primary-600 focus:ring-0"
                />
              </div>
            ))}
          </div>
          <div className="mt-2 flex gap-1">
            <label htmlFor={`checkbox-public-output`}>Render node output</label>
            <input
              type="checkbox"
              className="size-4 rounded border border-neutrals-400 text-primary-600 focus:ring-0"
              checked={nodeData.publicOutput}
              onChange={(e) => {
                updateNodeData(nodeId, {
                  publicOutput: e.target.checked,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

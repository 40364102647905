import { useAuth } from '@/hooks/auth/useAuth.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactNode, useEffect, useState } from 'react';
import {
  setWhiteboardAgentPrompt,
  setWhiteboardTemplateUuid,
  useWhiteboardAgentPrompt,
  useWhiteboardTemplateUuid,
} from '../../routes/onboarding/onboarding-store.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';

export const WhiteboardOnboardingGuard = ({ children }: { children: ReactNode }) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth();
  const [searchParams] = useSearchParams();
  const [canEnterApp, setCanEnterApp] = useState(false);
  const navigate = useNavigate();
  const whiteboardTemplateUuid = useWhiteboardTemplateUuid();
  const whiteboardAgentPrompt = useWhiteboardAgentPrompt();
  const { showLoader, hideLoader } = useGlobalLoader();

  useEffect(() => {
    showLoader();
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (whiteboardTemplateUuid || whiteboardAgentPrompt) {
      redirectUserBasedOnAuth();
      return;
    }

    const whiteboardTemplateUuidParam = searchParams.get('whiteboardTemplateUuid');
    const whiteboardAgentPromptParam = searchParams.get('whiteboardAgentPrompt');

    if (whiteboardTemplateUuidParam) {
      setWhiteboardTemplateUuid(whiteboardTemplateUuidParam);
    } else if (whiteboardAgentPromptParam) {
      setWhiteboardAgentPrompt(whiteboardAgentPromptParam);
    } else {
      hideLoader();
      setCanEnterApp(true);
      return;
    }

    redirectUserBasedOnAuth();
  }, [isLoading]);

  const redirectUserBasedOnAuth = () => {
    if (isAuthenticated) {
      navigate(buildUrl([Urls.NO_MENU_APP, Urls.WHITEBOARD_ONBOARDING_INITIALIZER]));
      hideLoader();
      setCanEnterApp(true);
    } else {
      void loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          mode: 'signUp',
          redirect_uri: window.location.origin,
        },
      });
    }
  };

  return <>{canEnterApp && children}</>;
};

import { useApi } from '@/api/api.ts';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';
import { KnownToolCategories, ToolCategory, ToolConfig } from '../../../libs/tools/type.model.ts';

export const useToolConfigs = (feature: DfFeature) => {
  const { getFetcher } = useApi();
  const { data, ...rest } = useSWRImmutable<ToolConfig[]>(`/tools/for-feature/${feature}`, getFetcher);

  const toolConfigs: ToolConfig[] | undefined = useMemo(() => {
    if (!data) return;

    return data.map((tool: ToolConfig) => ({
      ...tool,
      category: KnownToolCategories.includes(tool.category) ? tool.category : ToolCategory.OTHER,
    }));
  }, [data]);

  return {
    toolConfigs,
    ...rest,
  };
};

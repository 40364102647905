import { NodeViewWrapper, NodeViewWrapperProps } from '@tiptap/react';

import { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { closeConfigurationEditor, useIsConfigurationEditorVisible, useTiptapCurrentView } from '../store';

import { cn } from '@/helpers/cn';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum';

import { Label, Select } from 'flowbite-react';
import { ToolsContext } from 'src/libs/tools/ToolsContext';
import { Tool, ToolCategory, ToolConfig, ToolField } from 'src/libs/tools/type.model';
import { ToolForm } from 'src/libs/tools/ToolForm';
import { ConfigurationModeView } from './ConfigurationModeView';
import { ExecutionModeView } from './ExecutionModeView';
import { getToolConfigsByCategory } from '../../../../../../libs/tools/getToolConfigsByCategory.ts';
import './DFGenOutputView.scss';
import { useUpdateToolType } from '@/api/tools/update-tool-type.ts';
import { useTool } from '@/api/tools/get-tool.ts';
import { useCreateOrUpdateTool } from '@/api/tools/create-or-update-tool.ts';
import { convertToNewTool } from '../helpers/convertToNewTools.ts';

export const DFGenOutputView = function DFGenOutputView(props: NodeViewWrapperProps) {
  const toolCategory = props.node.attrs.toolcategory as ToolCategory;
  const nodeId = props?.node?.attrs?.id;
  const { tool } = useTool(props.node.attrs?.selectedtoolid);
  const { createOrUpdateTool } = useCreateOrUpdateTool();

  const isExecutionMode = useTiptapCurrentView() == TiptapView.EXECUTION_MODE;
  const isConfigurationEditorVisible = useIsConfigurationEditorVisible(nodeId);

  const toolConfigs = useContext(ToolsContext);
  const toolConfigsByCategory = getToolConfigsByCategory(toolConfigs?.tools || []);
  const { updateToolType } = useUpdateToolType();

  useEffect(() => {
    //set default toolConfig
    if (!props.node.attrs?.selectedtool) {
      props.node.attrs.selectedtool = toolConfigsByCategory[toolCategory][0];
    }
  }, []);

  const getTextToDisplay = () => {
    const toolData = convertToNewTool(props.node.attrs?.tooldata) as ToolField[];
    const prompt = toolData.find((field) => field.key === 'prompt')?.value;
    const query = toolData.find((field) => field.key === 'query')?.value;
    const tool = props.node.attrs?.selectedtool;

    if (prompt) {
      return prompt;
    }

    if (query) {
      return query;
    }

    if (tool && tool.displayName) {
      return tool.displayName;
    }

    return '';
  };

  const submitToolForm = async (values: ToolField[]) => {
    const newTool = await createOrUpdateTool({
      name: props.node.attrs.selectedtool.name,
      fields: values,
      id: tool?.id,
    });

    props.updateAttributes({
      selectedtoolid: newTool.id,
      tooldata: values,
    });
    closeConfigurationEditor();
  };

  return (
    <NodeViewWrapper>
      <div className="df-node-border rounded-2xl border border-gray-divider">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between gap-1">
            <div className="relative flex w-full flex-col">
              {!isExecutionMode ? (
                <ConfigurationModeView
                  node={props?.node}
                  updateAttributes={props.updateAttributes}
                  toolCategory={toolCategory}
                  textToDisplay={getTextToDisplay()}
                />
              ) : (
                <ExecutionModeView
                  editor={props.editor}
                  nodeId={nodeId}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* </Panel> */}
      {createPortal(
        <>
          <div
            className={cn(
              'pointer-events-none absolute top-0 z-[110] h-full w-full bg-gray-dark opacity-0 transition-opacity duration-150',
              {
                'pointer-events-auto opacity-40': isConfigurationEditorVisible,
              },
            )}
            onClick={() => closeConfigurationEditor()}
          />

          <div
            className={cn(
              'fixed bottom-0 right-0 top-0 z-[300] m-3 flex max-h-full w-full max-w-[440px] flex-col overflow-hidden rounded-2xl bg-white transition-all duration-200 ease-in-out',
              {
                'pointer-events-none translate-x-[200px] opacity-0': !isConfigurationEditorVisible,
                'pointer-events-auto translate-x-0 opacity-100': isConfigurationEditorVisible,
              },
            )}
          >
            <div className="relative flex-col justify-between rounded-t-2xl bg-gray-ultra-light p-6">
              <div className="mb-2 flex w-full justify-stretch text-h2-desktop font-medium text-gray-dark">
                <div className="grow">Configuration</div>
                <div>
                  <button
                    className="hover:opacity-70"
                    onClick={() => closeConfigurationEditor()}
                  >
                    <img
                      src="/close-side-panel.svg"
                      className="cursor-pointer"
                      alt=""
                    />
                  </button>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-dark">Define your prompt and other parameters</div>
            </div>

            {props.node.attrs?.selectedtool && (
              <ToolForm
                tool={{ input: convertToNewTool(props.node.attrs.tooldata) } as Tool}
                toolConfig={props.node.attrs?.selectedtool}
                onSubmit={submitToolForm}
                tiptap={{
                  turnOnTiptapInputAsPromptInput: true,
                  toc: props.editor.extensionStorage.tableOfContents,
                }}
                extraTopChildren={
                  <div className="mb-5">
                    <Label htmlFor="select-tool">Select tool</Label>
                    <Select
                      id="select-tool"
                      name="select-tool"
                      value={props.node.attrs?.selectedtool?.displayName ?? ''}
                      onChange={(e) => {
                        const toolDisplayName = e.target.value;
                        const tool = toolConfigs?.tools?.find((tool) => tool.displayName === toolDisplayName);
                        props.updateAttributes({ selectedtool: tool, tooldata: undefined, selectedtoolid: undefined });

                        if (props.node.attrs?.selectedtoolid && tool) {
                          updateToolType(props.node.attrs?.selectedtoolid, tool.name);
                        }
                      }}
                    >
                      {toolConfigsByCategory[toolCategory]?.map((option: ToolConfig) => (
                        <option key={option.name}>{option.displayName}</option>
                      ))}
                    </Select>
                  </div>
                }
              />
            )}
          </div>
        </>,
        document.getElementById('root')!,
      )}
    </NodeViewWrapper>
  );
};

import { Editor } from '@tiptap/core';
import { findElementId } from './findElementId';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { turndownService } from '@/components/tiptap/extensions/DigitalFirst/turndown/turndown.service.ts';

export const getImageBetweenHeadings = (editor: Editor, prompt: string): string[] => {
  const headingsIds = findElementId(prompt, `[data-type="${MentionReferenceType.SECTION}"]`);
  let images = prompt
    .split(',')
    .map((e) => e.replaceAll(' ', ''))
    .map((e) => turndownService.turndown(e))
    .filter((e) => e.includes('https://'));

  if (!headingsIds || !headingsIds.length) return images;

  for (const headingId of headingsIds) {
    const firstHeader: HTMLElement = editor.extensionStorage.tableOfContents.content.find(
      ({ id }: { id: string }) => id === headingId,
    )?.dom;
    const firstHeaderLevel = firstHeader?.tagName;

    let next = firstHeader?.nextSibling as HTMLElement;
    let nextLevel = next?.tagName ?? '';
    const content: string[] = [];

    while (next && nextLevel !== firstHeaderLevel) {
      const imageElements = next.querySelectorAll('img');

      imageElements.forEach((img) => {
        const src = img.getAttribute('src');

        if (src && src.includes('https://')) {
          content.push(src);
        }
      });

      next = next?.nextSibling as HTMLElement;
      nextLevel = next?.tagName ?? '';
    }

    images = images.concat(content);
  }

  return images.flat();
};

import { BackgroundVariant, Background as FlowBackground } from '@xyflow/react';

function Background() {
  return (
    <FlowBackground
      variant={BackgroundVariant.Dots}
      color="#ececec"
      bgColor="white"
      gap={10}
      size={2}
    />
  );
}

export default Background;

import { cn } from '@/helpers/cn';
import { NodeProps } from '@xyflow/react';
import { EyeOff, MonitorPlay } from 'lucide-react';
import { useState } from 'react';
import { ToolConfig } from 'src/libs/tools/type.model';
import { LoaderIcon } from 'src/routes/whiteboards/components/ui/LoaderIcon';
import { getYouTubeVideoId } from 'src/routes/whiteboards/helpers/node-output-helpers';
import { ExecutionEngineNode, NodeData } from 'src/routes/whiteboards/types';
import { GenericToolNodeOutput } from '../generic/GenericToolNodeOutput';

type RenderYouTubeOutputProps = {
  nodeId: NodeProps['id'];
  nodeData: NodeData;
  executionEngineNode: ExecutionEngineNode;
  toolConfigs: ToolConfig[];
};

export function RenderYouTubeOutput({ nodeId, nodeData, executionEngineNode, toolConfigs }: RenderYouTubeOutputProps) {
  const [showPreview, setShowPreview] = useState(!nodeData.output);
  const videoUrl = executionEngineNode.tool.input.find((i) => i.key === 'url')?.value;

  const videoId = videoUrl ? getYouTubeVideoId(videoUrl as string) : null;

  if (nodeData.loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <LoaderIcon />
      </div>
    );
  }

  return (
    <div>
      {showPreview && videoId && (
        <div className="mb-4">
          <iframe
            className="aspect-video w-full"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            allowFullScreen
          />
        </div>
      )}
      {videoId && nodeData.output && (
        <div className={cn('my-4', nodeData.output ? 'px-4' : '')}>
          <button
            onClick={() => setShowPreview((s) => !s)}
            title="Toggle preview"
          >
            {!showPreview ? (
              <MonitorPlay className="stroke-neutrals-600" />
            ) : (
              <EyeOff className="stroke-neutrals-600" />
            )}
          </button>
        </div>
      )}

      {nodeData.output && (
        <>
          <h2 className="mb-2 px-4 text-h3-desktop">Transcript:</h2>

          <GenericToolNodeOutput
            nodeId={nodeId}
            nodeData={nodeData}
            executionEngineNode={executionEngineNode}
            toolConfigs={toolConfigs}
          />
        </>
      )}
    </div>
  );
}

import { useEffect, useState } from 'react';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { SettingsSubHeader } from './components/SettingsSubHeader';
import { Button, Dropdown, Select, Table, TextInput } from 'flowbite-react';
import { HiSearch } from 'react-icons/hi';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';
import { useOrganizationMembers } from '@/api/organization/get-organization-members';
import { dateToRelativeString } from '@/helpers/date-to-relative-string';
import { useRemoveOrganizationMember } from '@/api/organization/remove-organization-member';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store';
import { DIALOGS } from '@/consts/dialogs.const';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { User } from '@/models/user.interface';
import { useOrganizationRoles } from '@/api/organization/get-organization-roles';
import { useOrganizationWorkspaces } from '@/api/workspace/get-organization-workspaces';
import dayjs from 'dayjs';
import { useOrganizationInvitations } from '@/api/organization/get-organization-invitations';
import { OrganizationInvitation } from '@/models/organization-invitation.interface';
import { useRemoveOrganizationInvitation } from '@/api/organization/remove-organization-invitation';
import { useOrganization } from '@/api/organization/get-organization';

export function OrganizationMembers() {
  const { openModal } = useGlobalModal();
  const { openDialog } = useGlobalDialog();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { removeOrganizationMember } = useRemoveOrganizationMember();
  const { removeOrganizationInvitation } = useRemoveOrganizationInvitation();
  const { organizationRoles, isLoading: isLoadingOrganizationRoles } = useOrganizationRoles();
  const { organizationMembers, isLoading: isLoadingOrganizationMembers } = useOrganizationMembers();
  const { workspaces, isLoading: isLoadingWorkspaces } = useOrganizationWorkspaces();
  const { organizationInvitations, isLoading: isLoadingOrganizationInvitations } = useOrganizationInvitations();
  const { organization } = useOrganization();

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLastActive, setSelectedLastActive] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedWorkspace, setSelectedWorkspace] = useState('');

  useEffect(() => {
    if (
      isLoadingOrganizationRoles ||
      isLoadingOrganizationMembers ||
      isLoadingWorkspaces ||
      isLoadingOrganizationInvitations
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoadingOrganizationRoles, isLoadingOrganizationMembers, isLoadingWorkspaces, isLoadingOrganizationInvitations]);

  const filterByLastActive = (lastLogin: string) => {
    if (!selectedLastActive) return true;
    const daysMap: Record<string, number> = {
      'Last 7 days': 7,
      'Last 14 days': 14,
      'Last 30 days': 30,
    };
    const days = daysMap[selectedLastActive];
    return dayjs().diff(dayjs(lastLogin), 'day') <= days;
  };

  const getMemberFirstName = (organizationMember: User) => {
    return organizationMember.given_name ?? organizationMember.name;
  };

  const getMemberLastName = (organizationMember: User) => {
    return organizationMember.family_name ?? organizationMember.name;
  };

  const getMemberFullName = (organizationMember: User) => {
    return organizationMember.family_name && organizationMember.given_name
      ? `${organizationMember.given_name} ${organizationMember.family_name}`
      : organizationMember.name;
  };

  const isInvitation = (data: unknown): data is OrganizationInvitation => {
    return typeof data === 'object' && data !== null && 'invitation_url' in data;
  };

  const filteredMembers = [...(organizationMembers ?? []), ...(organizationInvitations ?? [])].filter(
    (member: User | OrganizationInvitation) => {
      if (isInvitation(member)) {
        return member.invitee.email.toLowerCase().includes(searchTerm.toLowerCase());
      }

      const matchesSearch = getMemberFullName(member).toLowerCase().includes(searchTerm.toLowerCase());
      const matchesLastActive = filterByLastActive(member.last_login);
      const matchesRole = selectedRole ? member.roles.some((role) => role.name === selectedRole) : true;
      const matchesWorkspace = selectedWorkspace
        ? member.workspaces.some((workspace) => workspace.name === selectedWorkspace)
        : true;

      return matchesSearch && matchesLastActive && matchesRole && matchesWorkspace;
    },
  );

  const getMemberRoles = (organizationMember: User) => {
    const roles = organizationMember.roles?.map((v) => v.name);
    return roles && roles.length > 0 ? roles.join(', ') : '-';
  };

  const getMemberWorkspaces = (organizationMember: User) => {
    return organizationMember.workspaces
      .slice(0, 1)
      .map((workspace) => (
        <div
          key={workspace.workspaceId}
          className="flex w-fit items-center gap-2 rounded bg-gray-ultra-light p-1"
        >
          <div className="flex size-4 items-center justify-center rounded bg-pink-300 text-caption-sm">
            {workspace.name.charAt(0)}
          </div>
          <span className="text-caption-sm text-gray-dark">{workspace.name}</span>
        </div>
      ))
      .concat(
        organizationMember.workspaces.length > 1 ? (
          <div className="flex w-fit items-center gap-2 rounded bg-gray-ultra-light p-1">
            <span className="text-caption-sm text-gray-dark">+{organizationMember.workspaces.length - 1}</span>
          </div>
        ) : (
          <></>
        ),
      );
  };

  return (
    <PageWithHeaderLayout
      subHeader={<SettingsSubHeader />}
      header={{
        breadcrumbs: [{ label: 'Settings', href: '#' }],
        icon: '/settings-black.svg',
      }}
    >
      <title>Digitalfirst.ai | Settings</title>

      <div className="flex justify-between">
        <h3 className="text-h3-desktop">Organization members</h3>
        <div className="flex flex-row gap-3">
          <div className="relative">
            <TextInput
              placeholder="Search members..."
              className="w-56"
              icon={HiSearch}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <Button
            color="light"
            onClick={() => openModal(MODALS.InviteToOrganization, { orgName: organization?.name })}
          >
            <img src="/members-black.svg" /> Invite
          </Button>
          <Button
            className="hidden"
            color="primary"
          >
            <img src="/arrow-circle-up-black.svg" /> Upgrade
          </Button>
        </div>
      </div>

      <div className="mt-6 flex flex-row gap-4">
        <Select
          className="flex-1"
          value={selectedLastActive}
          onChange={(e) => setSelectedLastActive(e.target.value)}
        >
          <option value="">Last active</option>
          <option>Last 7 days</option>
          <option>Last 14 days</option>
          <option>Last 30 days</option>
        </Select>
        <Select
          className="flex-1"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          <option value="">Role</option>
          {organizationRoles?.map((role) => <option key={role.id}>{role.name}</option>)}
        </Select>
        <Select
          className="flex-1"
          value={selectedWorkspace}
          onChange={(e) => setSelectedWorkspace(e.target.value)}
        >
          <option value="">Workspace</option>
          {workspaces?.map((workspace) => <option key={workspace.id}>{workspace.name}</option>)}
        </Select>
      </div>

      <div className="mt-8">
        <Table hoverable>
          <Table.Head>
            <Table.HeadCell>Name</Table.HeadCell>
            <Table.HeadCell>Last active</Table.HeadCell>
            <Table.HeadCell>Role</Table.HeadCell>
            <Table.HeadCell>Workspaces</Table.HeadCell>
            <Table.HeadCell className="w-20">
              <span className="sr-only">Edit</span>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {filteredMembers?.map((organizationMember) =>
              isInvitation(organizationMember) ? (
                <Table.Row
                  key={organizationMember.invitation_url}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell>
                    <div className="flex items-center gap-3">
                      <DfAvatar
                        firstName={'U'}
                        lastName={'U'}
                      />
                      <span className="text-caption-md">{organizationMember.invitee.email}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>Invitation sent</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      label=""
                      size={'sm'}
                      renderTrigger={() => (
                        <img
                          className="cursor-pointer"
                          src="/more-black.svg"
                        />
                      )}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          openDialog(DIALOGS.DeleteOrganizationMember, async () => {
                            showLoader('Deleting member...');
                            await removeOrganizationInvitation(organizationMember.id);
                            hideLoader();
                          });
                        }}
                      >
                        <img src="/remove-gray.svg" />
                        Remove
                      </Dropdown.Item>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row
                  key={organizationMember.user_id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell>
                    <div className="flex items-center gap-3">
                      {organizationMember?.picture ? (
                        <img
                          src={organizationMember?.picture}
                          className="size-8 h-auto rounded-full"
                        />
                      ) : (
                        <DfAvatar
                          firstName={getMemberFirstName(organizationMember)}
                          lastName={getMemberLastName(organizationMember)}
                        />
                      )}
                      <span className="text-caption-md">{getMemberFullName(organizationMember)}</span>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{dateToRelativeString(organizationMember.last_login)}</Table.Cell>
                  <Table.Cell>{getMemberRoles(organizationMember)}</Table.Cell>
                  <Table.Cell className="flex gap-1">{getMemberWorkspaces(organizationMember)}</Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      label=""
                      size={'sm'}
                      renderTrigger={() => (
                        <img
                          className="cursor-pointer"
                          src="/more-black.svg"
                        />
                      )}
                    >
                      <Dropdown.Item onClick={() => openModal(MODALS.EditOrganizationMember, organizationMember)}>
                        <img src="/edit-gray.svg" />
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item className="hidden">
                        <img src="/refresh-gray.svg" />
                        Resent invitation
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          openDialog(DIALOGS.DeleteOrganizationMember, async () => {
                            showLoader('Deleting member...');
                            await removeOrganizationMember(organizationMember.user_id);
                            hideLoader();
                          });
                        }}
                      >
                        <img src="/remove-gray.svg" />
                        Remove
                      </Dropdown.Item>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ),
            )}
          </Table.Body>
        </Table>
      </div>
    </PageWithHeaderLayout>
  );
}

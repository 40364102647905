import CustomToaster from '@/components/CustomToaster';
import { GlobalLoader } from '@/components/GlobalLoader/GlobalLoader.tsx';
import { GlobalModal } from '@/components/GlobalModal/GlobalModal.tsx';
import { GlobalDialog } from '@/components/GlobalDialog/GlobalDialog.tsx';
import { Outlet } from 'react-router-dom';
import { GlobalConfetti } from '@/components/GlobalConfetti/GlobalConfetti.tsx';
import { GlobalSideModal } from '@/components/GlobalSideModal/GlobalSideModal.tsx';

export function App() {
  return (
    <>
      <title>Digitalfirst.ai</title>
      <GlobalLoader />
      <GlobalModal />
      <GlobalSideModal />
      <GlobalDialog />
      <GlobalConfetti />
      <div>
        <CustomToaster />
      </div>

      <Outlet />
    </>
  );
}

import DragIcon from '@assets/drag.svg';
import PlusIcon from '@assets/plus.svg';
import React, { ReactElement } from 'react';

type ToolboxItemProps = {
  icon: ReactElement;
  label: string;
  onDragStart?: (event: React.DragEvent) => void;
  onClick?: () => void;
};

export function ToolboxItem({ onClick, onDragStart, icon, label }: ToolboxItemProps) {
  return (
    <div
      className="group flex w-full cursor-pointer items-center justify-between rounded-lg py-1 pl-2 pr-1 text-caption-sm hover:bg-neutrals-100"
      draggable={!!onDragStart}
      onDragStart={onDragStart}
    >
      <div className="flex items-center gap-3">
        <div className="shrink-0 *:size-4">{icon}</div>
        <div>{label}</div>
      </div>
      <div className="invisible flex gap-1 group-hover:visible">
        {onClick && (
          <button onClick={onClick}>
            <PlusIcon className="size-4" />
          </button>
        )}
        {onDragStart && <DragIcon className="size-4" />}
      </div>
    </div>
  );
}

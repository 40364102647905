import { getAllKeysFromObject } from '@/helpers/get-all-keys-from-object';
import { Edge } from '@xyflow/react';
import * as jsondiffpatch from 'jsondiffpatch';
import { StateCreator } from 'zustand';
import { NodeType } from '../types';
import { ExecutionSlice } from './execution.slice';
import { ReactFlowSlice } from './react-flow.slice';
import { WhiteboardSlice } from './whiteboard.slice';
import { ToolOutputType } from '../../../libs/tools/type.model.ts';
import { NodeSlice } from './node.slice.ts';

export type HistoryStateItem = {
  nodes: NodeType[];
  edges: Edge[];
};

export type EeNodeToUpdateOutput = {
  reactFlowNodeId: string;
  output: string;
  toolId: number;
  historyItemId: number;
  outputType: ToolOutputType;
};

const MAX_HISTORY_STACK_SIZE = 50;

type DeltaType = {
  nodes?: jsondiffpatch.ArrayDelta;
  edges?: jsondiffpatch.ArrayDelta;
};

type HistoryState = {
  undoStack: DeltaType[];
  redoStack: DeltaType[];
  historyState: HistoryStateItem;
  readonly maxStackSize: number;
};

type HistoryAction = {
  addToHistoryStack: (item?: Partial<HistoryStateItem>) => void;
  undo: () => void;
  redo: () => void;
  canUndo: () => boolean;
  canRedo: () => boolean;
  syncOutputs: (reactFlowNodeId?: string) => void;
};

export type HistorySlice = HistoryState & HistoryAction;

export const initialHistoryState: HistoryState = {
  undoStack: [],
  redoStack: [],
  historyState: {
    nodes: [],
    edges: [],
  },
  maxStackSize: MAX_HISTORY_STACK_SIZE,
};

export const createHistorySlice: StateCreator<
  HistorySlice & ReactFlowSlice & ExecutionSlice & WhiteboardSlice & NodeSlice,
  [],
  [],
  HistorySlice
> = (set, get) => ({
  ...initialHistoryState,
  addToHistoryStack: (historyItem) => {
    const newHistoryItem: HistoryStateItem = {
      nodes: get().nodes,
      edges: get().edges,
      ...historyItem,
    };

    const delta: DeltaType = jsondiffpatch.diff(get().historyState, newHistoryItem) as DeltaType;

    if (!delta) {
      return;
    }

    if (delta) {
      set((state) => {
        const newUndoStack = [...state.undoStack, delta];
        if (newUndoStack.length > state.maxStackSize) {
          newUndoStack.shift();
        }

        return {
          undoStack: newUndoStack,
          redoStack: [],
          historyState: newHistoryItem,
        };
      });
    }
  },

  undo: () => {
    const { undoStack, historyState } = get();
    if (undoStack.length === 0) {
      return;
    }

    const lastUndo = undoStack[undoStack.length - 1];
    const inverseDelta = jsondiffpatch.reverse(lastUndo);
    const historyStateCopy = jsondiffpatch.clone(historyState) as HistoryStateItem;
    const previousHistoryState = jsondiffpatch.patch(historyStateCopy, inverseDelta) as HistoryStateItem;

    set((state) => ({
      nodes: previousHistoryState.nodes,
      edges: previousHistoryState.edges,
      historyState: previousHistoryState,
      undoStack: state.undoStack.slice(0, state.undoStack.length - 1),
      redoStack: [...state.redoStack, lastUndo],
      shouldSave: true,
    }));

    if (getAllKeysFromObject(lastUndo).includes('output')) {
      get().syncOutputs();
    }
  },

  redo: () => {
    const { redoStack, historyState } = get();
    if (redoStack.length === 0) {
      return;
    }

    const lastRedo = redoStack[redoStack.length - 1];
    const historyStateCopy = jsondiffpatch.clone(historyState) as HistoryStateItem;
    const nextHistoryState = jsondiffpatch.patch(historyStateCopy, lastRedo) as HistoryStateItem;

    set((state) => ({
      nodes: nextHistoryState.nodes,
      edges: nextHistoryState.edges,
      historyState: nextHistoryState,
      redoStack: state.redoStack.slice(0, state.redoStack.length - 1),
      undoStack: [...state.undoStack, lastRedo],
      shouldSave: true,
    }));

    if (getAllKeysFromObject(lastRedo).includes('output')) {
      get().syncOutputs();
    }
  },

  syncOutputs: (reactFlowNodeId?: string) => {
    const executionEngineNodes = get().executionEngineNodes;
    const nodes = get().nodes;

    const nodesToUpdate = reactFlowNodeId ? nodes.filter((node) => node.id === reactFlowNodeId) : nodes;

    const eeNodesToUpdateOutput = nodesToUpdate
      .map((node) => {
        const executionEngineNode = executionEngineNodes.find((een) => een.reactFlowNodeId === node.id);
        if (!executionEngineNode) {
          return;
        }

        const outputHistory = executionEngineNode.tool.outputHistory;

        const historyItem = outputHistory
          ? outputHistory.find((item) => !jsondiffpatch.diff(item.output, node.data.output)) ||
            outputHistory[outputHistory.length - 1]
          : undefined;

        if (!historyItem) {
          return;
        }

        const outputType = node.data.toolConfig.config.outputType;

        if (outputType === ToolOutputType.JSON) {
          get().updateNodeData(node.id, { loading: true });
        }

        return {
          reactFlowNodeId: executionEngineNode.reactFlowNodeId,
          output: node.data.output,
          toolId: executionEngineNode.tool.id,
          historyItemId: historyItem.id,
          outputType,
        };
      })
      .filter(Boolean) as EeNodeToUpdateOutput[];

    set({ eeNodesToUpdateOutput, shouldSave: true });
  },

  canUndo: () => get().undoStack.length > 0,
  canRedo: () => get().redoStack.length > 0,
});

import { useApi } from '@/api/api.ts';
import { mutate } from 'swr';

export const useDeleteAsset = () => {
  const { deleteFetcher } = useApi();

  const deleteAsset = async (assetId: number) => {
    await deleteFetcher(`/assets/${assetId}`);
    await mutate('/assets');
  };

  return { deleteAsset };
};

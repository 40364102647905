import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { TemplateGridCard } from './TemplateGridCard';

type TemplatesGridProps = {
  templates: WhiteboardTemplate[];
  onSelect: (template: WhiteboardTemplate) => void;
  onAdd: (template: WhiteboardTemplate) => void;
  showEditOptions?: boolean;
  showButton?: boolean;
};

export function TemplatesGrid({
  templates,
  onSelect,
  onAdd,
  showEditOptions = true,
  showButton = true,
}: TemplatesGridProps) {
  return (
    <div className="grid flex-1 grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
      {templates.map((template) => (
        <TemplateGridCard
          key={template.id}
          template={template}
          onSelect={() => onSelect(template)}
          onAddTemplate={() => onAdd(template)}
          showEditOptions={showEditOptions}
          showButton={showButton}
        />
      ))}
    </div>
  );
}

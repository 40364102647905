import { XYPosition } from '@xyflow/react';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export function useMousePosition() {
  const [position, setPosition] = useState<XYPosition>({ x: 0, y: 0 });

  const handleMouseMove = useDebouncedCallback((event: MouseEvent) => {
    setPosition({ x: event.clientX, y: event.clientY });
  }, 100);

  useEffect(() => {
    const controller = new AbortController();
    document.addEventListener('mousemove', handleMouseMove, controller);
    return () => controller.abort();
  }, [handleMouseMove]);

  return position;
}

import { prepareFieldsForOneValue, ReaderFileItem } from '../../helpers/reader-file-helpers';
import { CategoryConfig } from '../../helpers/toolbox-helpers';
import { useAddNodes } from '../../hooks/useAddNode';
import { DATA_TRANSFER_KEYS } from '../../hooks/useDropNodeCreator';
import { NodeConfig } from '../../types';
import ToolboxCategory from './ToolboxCategory';
import { ToolboxItem } from './ToolboxItem';
import { ReactElement } from 'react';

type ToolboxFilesProps = {
  categoryConfig: CategoryConfig;
  nodeConfig: NodeConfig;
  items: ReaderFileItem[];
  itemIcon: ReactElement;
  searchQuery?: string;
};

export function ToolboxFilesCategory({ items, nodeConfig, itemIcon, categoryConfig, searchQuery }: ToolboxFilesProps) {
  const { addNode } = useAddNodes();

  function handleDragStart(event: React.DragEvent, item: ReaderFileItem) {
    const fields = prepareFieldsForOneValue(nodeConfig.toolConfig, item.id);
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.nodeConfig, JSON.stringify(nodeConfig));
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.fields, JSON.stringify(fields));
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.nodeName, item.name);
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.execute, 'true');
    event.dataTransfer.effectAllowed = 'move';
  }

  function handleClick(item: ReaderFileItem) {
    const fields = prepareFieldsForOneValue(nodeConfig.toolConfig, item.id);
    addNode({ toolConfig: nodeConfig.toolConfig, fields, execute: true, nodeName: item.name });
  }

  if (items.length === 0) {
    return null;
  }

  return (
    <ToolboxCategory
      categoryConfig={categoryConfig}
      open={!!searchQuery}
    >
      {items.map((item) => (
        <ToolboxItem
          key={item.id}
          label={item.name}
          icon={itemIcon}
          onClick={() => {
            handleClick(item);
          }}
          onDragStart={(event) => {
            handleDragStart(event, item);
          }}
        />
      ))}
    </ToolboxCategory>
  );
}

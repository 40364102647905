import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useProjects } from '@/api/projects/get-projects.ts';
import { FormikProvider, useFormik } from 'formik';
import { WorkspaceProjectsSelector } from '../../routes/projects/routes/project-details/components/WorkspaceProjectsSelector.tsx';
import { Button } from 'flowbite-react';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useRunWhiteboardOnboarding } from '../../routes/whiteboards/onboarding/useRunWhiteboardOnboarding.ts';

export const SelectProjectForWhiteboardModalBody = () => {
  const { closeModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { projects } = useProjects();
  const currentWorkspace = useCurrentWorkspace();
  const { runWhiteboardOnboarding } = useRunWhiteboardOnboarding();

  const formik = useFormik({
    initialValues: {
      projectId: '',
    },
    onSubmit: async ({ projectId }) => {
      closeModal();
      showLoader();
      await runWhiteboardOnboarding(Number(projectId));
      hideLoader();
    },
  });

  if (!projects) {
    return null;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-4">
            <WorkspaceProjectsSelector
              currentWorkspace={currentWorkspace}
              projects={projects}
              formik={formik}
              fieldName="projectId"
            />

            <div className="modal-divider mb-6 mt-8"></div>

            <div className="flex justify-end">
              <Button type="submit">Select project</Button>
            </div>
          </div>
        </form>
      </FormikProvider>
    </>
  );
};

import { getOutgoers, IsValidConnection } from '@xyflow/react';
import { getValidationSchema } from 'src/libs/tools/getValidationSchema';
import * as Yup from 'yup';
import { StateCreator } from 'zustand';
import { getToolFormInitialValues } from '../../../libs/tools/getToolFormInitialValues.ts';
import { getDependentNodeIds } from '../helpers/node-helpers';
import { ExecutionEngineNodeStatus, NodeData, NodeType } from '../types';
import { ExecutionSlice } from './execution.slice';
import { NodeSlice } from './node.slice.ts';
import { ReactFlowSlice } from './react-flow.slice';

type ValidationAction = {
  isValidConnection: IsValidConnection;
  setNodeValid: (nodeId: string, valid: boolean) => void;
  validateNode: (nodeId: string, skipDone?: boolean) => boolean;
  getValidationSchema: (reactFlowNodeId: string) => ReturnType<typeof getValidationSchema>;
  validateNodesForExecution: (nodeId: string) => boolean;
  isFieldConnected: (nodeId: string, fieldName: string) => boolean;
  getConnectedFields: (nodeId: string) => string[];
};
export type ValidationSlice = ValidationAction;

export const createValidationSlice: StateCreator<
  ReactFlowSlice & ExecutionSlice & ValidationSlice & NodeSlice,
  [],
  [],
  ValidationSlice
> = (_, get) => ({
  isValidConnection: (connection) => {
    if (connection.target === connection.source) {
      return false;
    }

    const nodes = get().nodes;
    const edges = get().edges;
    const targetNode = nodes.find((node) => node.id === connection.target);
    const sourceNode = nodes.find((node) => node.id === connection.source);

    if (!targetNode || !sourceNode) {
      return false;
    }

    // prevent-cycles
    const hasCycle = (node: NodeType, visited = new Set()) => {
      if (visited.has(node.id)) return false;

      visited.add(node.id);

      for (const outgoer of getOutgoers(node, nodes, edges)) {
        if (outgoer.id === connection.source) return true;
        if (hasCycle(outgoer, visited)) return true;
      }
    };

    if (targetNode.id === connection.source) {
      return false;
    }
    return !hasCycle(targetNode);
  },

  setNodeValid: (nodeId: string, valid: boolean) => {
    const newData: Partial<NodeData> = {
      valid,
    };
    if (valid) {
      newData.errors = [];
    }
    get().updateNodeData(nodeId, newData);
  },

  validateNode: (nodeId: string, skipDone = true) => {
    const node = get().nodes.find((node) => node.id === nodeId);
    const een = get().getExecutionEngineNode(nodeId);

    if (!node || !een) {
      get().updateNodeData(nodeId, {
        errors: ['Node or execution engine node not found'],
        valid: false,
      });
      return false;
    }

    if (skipDone && een.status === ExecutionEngineNodeStatus.DONE) {
      get().setNodeValid(nodeId, true);
      return true;
    }

    const schema = get().getValidationSchema(nodeId);

    try {
      const initialValues = getToolFormInitialValues(node.data.toolConfig, een.tool);

      schema.validateSync(initialValues, {
        strict: true,
        abortEarly: false, // Collect all errors instead of stopping at first error
      });

      get().setNodeValid(nodeId, true);
      return true;
    } catch (validationError: unknown) {
      if (validationError instanceof Yup.ValidationError) {
        const errors =
          validationError.inner.length > 0
            ? validationError.inner.map((err) => `${err.message} at ${err.path}`)
            : [`${validationError.message} ${validationError.path}`];

        get().updateNodeData(nodeId, {
          errors,
          valid: false,
          loading: false,
        });
      } else {
        get().updateNodeData(nodeId, {
          errors: ['Unexpected validation error occurred'],
          valid: false,
          loading: false,
        });
      }
      return false;
    }
  },

  getValidationSchema: (nodeId: string) => {
    const node = get().nodes.find((node) => node.id === nodeId);

    if (!node?.data.toolConfig) {
      throw new Error(`Node ${nodeId} not found or missing tool configuration`);
    }

    const connectedFields = get().getConnectedFields(nodeId);
    const { input } = node.data.toolConfig.config;

    const inputEntries = Object.entries(input).map(([key, value]) => [
      key,
      {
        ...value,
        // Field is not required if it's connected to another node
        validation: connectedFields.includes(key)
          ? {
              skipValidation: true,
            }
          : value.validation,
      },
    ]);

    return getValidationSchema(Object.fromEntries(inputEntries));
  },

  validateNodesForExecution: (nodeId: string): boolean => {
    const edges = get().edges;
    const nodeIdsToValidate = getDependentNodeIds(nodeId, edges);
    return nodeIdsToValidate.every((nodeId, index) => {
      return get().validateNode(nodeId, index !== 0);
    });
  },

  isFieldConnected: (nodeId, fieldName) => {
    return get().edges.some((edge) => edge.target === nodeId && edge.targetHandle === fieldName);
  },

  getConnectedFields: (nodeId: string) => {
    const edges = get().edges;
    return edges.filter((edge) => edge.target === nodeId).map((edge) => edge.targetHandle!);
  },
});

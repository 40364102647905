import useSWR from 'swr';
import { useApi } from '@/api/api.ts';
import { Asset } from '@/models/asset.model.ts';

export const useAssets = () => {
  const { getFetcher } = useApi();

  const { data, ...rest } = useSWR<Asset[]>('/assets', getFetcher);

  return {
    assets: data,
    ...rest,
  };
};

import { usePublicApi } from '@/api/public-api';
import { ToolValue } from 'src/libs/tools/type.model';
import { Whiteboard } from 'src/routes/whiteboards/types';

type ExecutePublicTemplatePayload = {
  uuid: string;
  fields: {
    key: string;
    value: ToolValue;
    reactFlowNodeId: string;
  }[];
};

export const useExecutePublicWhiteboardTemplate = () => {
  const { publicPostFetcher } = usePublicApi();

  const executePublicWhiteboardTemplate = async (arg: ExecutePublicTemplatePayload): Promise<Whiteboard> => {
    return await publicPostFetcher(`/public-template/execute/${arg.uuid}`, {
      arg: {
        fields: arg.fields,
      },
    });
  };

  return {
    executePublicWhiteboardTemplate,
  };
};

import { IconButton } from '@/components/IconButton.tsx';
import { Button, Dropdown } from 'flowbite-react';
import { MenuItem } from '@/components/MenuItem.tsx';
import { cn } from '@/helpers/cn';

import { TableCategory, TableDto, useDeleteTable } from 'src/libs/tables/api/tables.ts';
import { SemrushConfigurationSidebar } from './SemrushConfigurationSidebar.tsx';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mutate } from 'swr';

export function SemrushMenuPanel({ tables }: { tables: TableDto[] }) {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const params = useParams();
  const deleteTable = useDeleteTable();

  const clearLabel = (name: string) => {
    const capitalizedName = String(name).charAt(0).toUpperCase() + String(name).slice(1);
    return capitalizedName.replace('_', ' ');
  };

  const onDeleteTable = (tableId: string) => {
    deleteTable(tableId).then(() => {
      mutate(`/table/category/${TableCategory.SEMRUSH_API}`);
    });
  };

  return (
    <div className="relative">
      <IconButton
        icon="/sidebar-black.svg"
        className={cn('absolute right-2 top-5 z-10', { 'rotate-180': isMenuOpen }, { '!-right-8 !top-2': !isMenuOpen })}
        size={24}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />

      <div
        className={cn(
          'z-0 w-[17.5rem] overflow-x-hidden border-r border-b-neutrals-300 transition-[width] duration-300',
          {
            'w-0 border-none': !isMenuOpen,
          },
        )}
      >
        <div className="flex h-[calc(100svh-66px)] w-[17.5rem] flex-col py-6">
          <div className="mb-4 px-3">
            <div className="text-caption-sm text-neutrals-700">Research results</div>
          </div>

          <Button
            color="primary"
            size="sm"
            className="mx-3 mb-3"
            onClick={() => openSideModal(<SemrushConfigurationSidebar />)}
          >
            <img
              src="/search-check-white.svg"
              alt=""
            />
            New research
          </Button>

          <div className="flex grow flex-col gap-1 overflow-y-auto px-3">
            {tables.map((table, i) => (
              <MenuItem
                label={clearLabel(table.name)}
                href={buildUrl(['..', Urls.SEMRUSH_VIEW], { tableId: table._id }, undefined, true)}
                leftIcon="/edit-note-gray.svg"
                customActiveCondition={table?._id === params.tableId}
                key={`table-${i}`}
                addons={
                  <Dropdown
                    label=""
                    renderTrigger={() => (
                      <div>
                        <IconButton
                          icon="/three-dots-gray.svg"
                          size={24}
                        />
                      </div>
                    )}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Dropdown.Item onClick={() => onDeleteTable(table._id)}>
                      <img
                        src="/trash-gray.svg"
                        className="mr-2"
                        alt=""
                      />
                      Delete
                    </Dropdown.Item>
                  </Dropdown>
                }
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

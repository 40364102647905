import { useApi } from '@/api/api.ts';
import useSWR from 'swr';

export const useToolOutputAsHTML = (toolId?: number) => {
  const { getFetcher } = useApi();

  const { data, ...rest } = useSWR<string>(toolId ? `/tools/output-html/${toolId}` : null, getFetcher);

  return {
    toolOutputAsHTML: data,
    ...rest,
  };
};

import { useWorkspaces } from '../api/workspace/get-workspaces.ts';
import { setCurrentWorkspace, useCurrentWorkspace } from '../state/app-store.ts';
import { useCreateWorkspace } from '../api/workspace/create-workspace.ts';
import { ReactNode, useEffect, useState } from 'react';
import {
  setOnboardingScrappedUrl,
  setUnFinishedOnboardingProcesses,
  useOnboardingFormData,
  useUnFinishedOnboardingProcesses,
} from '../../routes/onboarding/onboarding-store.ts';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useStartOnboardingProcess } from '@/api/onboarding/start-onboarding-process.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { useDebouncedCallback } from 'use-debounce';
import { useAuth } from '@/hooks/auth/useAuth.ts';

export function AuthorizedAppInitializer({ children }: { children: ReactNode }) {
  const { workspaces, isLoading: isLoadingWorkspaces, error: workspaceError } = useWorkspaces();
  const currentWorkspace = useCurrentWorkspace();
  const { createWorkspace, isLoading: isCreatingWorkspace } = useCreateWorkspace();
  const [appInitialized, setAppInitialized] = useState(false);
  const onboardingFormData = useOnboardingFormData();
  const { startOnboardingProcess, isStartingOnboarding } = useStartOnboardingProcess();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { user } = useAuth();
  const navigate = useNavigate();
  const debouncedInitOnboardingProcess = useDebouncedCallback(initOnboardingProcess, 100);
  const unfinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);

  useEffect(() => {
    if (onboardingFormData && !isStartingOnboarding) {
      debouncedInitOnboardingProcess();
    }
  }, []);

  useEffect(() => {
    if (isLoadingWorkspaces || isCreatingWorkspace || isStartingOnboarding || appInitialized) return;

    if (!workspaces?.length) {
      // If there are no workspaces, create the first one
      if (!workspaceError) {
        createWorkspace('My first workspace').then(() => {
          setAppInitialized(true);
        });
      }
    } else if (currentWorkspace && workspaces.find((w) => w.id === currentWorkspace.id)) {
      // If the current workspace is in the list of workspaces, we are good to go
      setAppInitialized(true);
    } else {
      // If the current workspace is not in the list of workspaces, set the first workspace as the current
      setCurrentWorkspace(workspaces[0]);
      setAppInitialized(true);
    }
  }, [isLoadingWorkspaces, workspaces, isCreatingWorkspace, isStartingOnboarding, appInitialized, workspaceError]);

  async function initOnboardingProcess() {
    const { url, option, onboardingProcessType } = onboardingFormData!;

    // if array is empty, it means it is the first onboarding process, so we fill it with all the processes
    if (!unfinishedOnboardingProcesses.length) {
      setUnFinishedOnboardingProcesses(user!.sub!, [...Object.values(OnboardingProcessType)]);
    }

    setOnboardingScrappedUrl(user!.sub!, url);
    showLoader();
    await startOnboardingProcess(url, onboardingProcessType, option);
    hideLoader();

    setAppInitialized(true);
    navigate(buildUrl([Urls.ONBOARDING, Urls.ONBOARDING_PREPARE]));
  }

  return appInitialized && <>{children}</>;
}

import { Badge, Table } from 'flowbite-react';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { DropdownDataRoomItemOptions } from './DropdownDataRoomItemOptions.tsx';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { cn } from '@/helpers/cn';
import { DfAvatar } from '@/components/DfAvatar.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { PreviewAndSummaryModal } from './PreviewAndSummaryModal.tsx';
import { useState } from 'react';
import moment from 'moment';
import { useOrganizationMembers } from '@/api/organization/get-organization-members.ts';
import { useAuth } from '@/hooks/auth/useAuth.ts';

export function DataRoomItemsTable() {
  const { user } = useAuth();
  const { organizationMembers } = useOrganizationMembers();
  const { dataRoomItems, isLoading } = useDataRoomItems();

  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DataRoomItem | null>(null);

  const previewItem = (item: DataRoomItem) => {
    if (!item.isParsed) return;

    setSelectedItem(item);
    setOpenModal(true);
  };

  const getCreator = (data: DataRoomItem) => {
    if (data.creatorId === user?.sub) {
      return user;
    }

    const creator = (organizationMembers ?? []).find((value) => value.user_id === data.creatorId);
    return creator;
  };

  const getStatusIcon = (item: DataRoomItem) => {
    if (item.isErrored) {
      return '/error.svg';
    }

    if (item.isParsed) {
      return item.icon;
    }

    return '/loading-clockwise-gray.svg';
  };

  const getCreatorLabel = (data: DataRoomItem): string => {
    if (data.creatorId === user?.sub) {
      return 'You';
    }

    const creator = (organizationMembers ?? []).find((value) => value.user_id === data.creatorId);
    if (!creator) {
      return 'Unknown user';
    }

    if (creator.given_name && creator.family_name) {
      return `${creator.given_name} ${creator.family_name}`;
    }

    return creator.email;
  };

  return (
    <>
      <div className="mb-6 flex items-center gap-3">
        <h2 className="text-h4-desktop">Added</h2>
        <Badge color="blue">{dataRoomItems.length}</Badge>
      </div>
      <Table>
        <Table.Head>
          <Table.HeadCell className="w-[40%]">Name</Table.HeadCell>
          <Table.HeadCell>Date</Table.HeadCell>
          <Table.HeadCell>Added by</Table.HeadCell>
          <Table.HeadCell>Folder</Table.HeadCell>
          <Table.HeadCell className="w-[70px]">
            <span className="sr-only">Edit</span>
          </Table.HeadCell>
        </Table.Head>
        <Table.Body>
          {!isLoading &&
            dataRoomItems.map((item: DataRoomItem, index) => (
              <Table.Row key={index}>
                <Table.Cell
                  className={cn('group flex items-center gap-3', {
                    'hover:cursor-pointer': item.isParsed && !item.isErrored,
                  })}
                  onClick={() => !item.isErrored && previewItem(item)}
                >
                  <div
                    className={cn('shrink-0 rounded-lg p-2.5', {
                      'bg-gray-ultra-light transition group-hover:bg-neutrals-300': item.isParsed && !item.isErrored,
                      'bg-negative-100': item.isErrored,
                    })}
                  >
                    <img
                      className={cn('size-5', { 'animate-spin-slow': !item.isParsed })}
                      src={getStatusIcon(item)}
                      alt=""
                    />
                  </div>
                  <div className="flex min-w-0 grow items-center gap-2 text-caption-md font-medium text-text-primary">
                    <div className="grow-0 overflow-hidden text-ellipsis whitespace-nowrap">{item.name}</div>
                  </div>
                </Table.Cell>
                <Table.Cell>{moment(item.date).format('DD.MM.YYYY')}</Table.Cell>
                <Table.Cell>
                  <div className="flex items-center gap-3">
                    {getCreator(item)?.picture ? (
                      <img
                        src={getCreator(item)?.picture}
                        className="size-6 rounded-full"
                      />
                    ) : (
                      <DfAvatar
                        firstName={getCreator(item)?.given_name || 'U'}
                        lastName={getCreator(item)?.family_name || 'U'}
                        size={24}
                      />
                    )}
                    <span>{getCreatorLabel(item)}</span>
                  </div>
                </Table.Cell>
                <Table.Cell>-</Table.Cell>
                <Table.Cell>
                  <DropdownDataRoomItemOptions
                    item={item}
                    onPreview={() => previewItem(item)}
                  >
                    <div>
                      <IconButton
                        icon="/three-dots-gray.svg"
                        size={24}
                        alt="More"
                      />
                    </div>
                  </DropdownDataRoomItemOptions>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>

      {!!dataRoomItems?.length && (
        <PreviewAndSummaryModal
          open={openModal}
          item={selectedItem}
          onClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
}

import { SemrushEmptyView } from './components/SemrushEmptyView';
import { TableCategory, useMyTablesByCategory } from 'src/libs/tables/api/tables';
import { SemrushMenuPanel } from './components/SemrushMenuPanel';
import { useParams } from 'react-router-dom';
import SmartTable from 'src/libs/tables/table/SmartTable';
import { WebSearcherPageHeader } from '@/components/WebSearcherPageHeader';
import { useEffect, useState } from 'react';
import { inIframe } from '@/components/tiptap/extensions/DigitalFirst/helpers/inIframe.ts';
import { Button } from 'flowbite-react';
import tablemark, { LooseObject } from 'tablemark';
import { cn } from '@/helpers/cn';
import { useSemrush } from '@/state/semrush.store';
export const SemrushPage = () => {
  const { data: tables } = useMyTablesByCategory(TableCategory.SEMRUSH_API);
  const params = useParams();
  const [tableId, setTableId] = useState<string | null>(null);
  const [selectedRows, setSelectedRows] = useState<unknown[]>([]);
  const { isSemrush } = useSemrush();

  useEffect(() => {
    if (params.tableId) {
      setTableId(params.tableId);
    } else {
      setTableId(tables?.[tables.length - 1]?._id ?? null);
    }
  }, [params.tableId, tables]);

  return (
    <>
      <title>Digitalfirst.ai | Semrush</title>
      <WebSearcherPageHeader />
      <main
        className={cn('h-screen w-full pb-3 pr-3', {
          'bg-neutrals-200': !inIframe(),
        })}
      >
        <div className="flew-row flex h-[calc(100%-66px)] w-[calc(100%-5px)] flex-row rounded-2xl bg-white">
          <SemrushMenuPanel tables={tables ?? []} />
          {!tables?.length && <SemrushEmptyView />}
          {tableId && (
            <div className="m-4 overflow-x-scroll">
              {inIframe() && !isSemrush && (
                <div className="mb-4 flex w-full justify-end">
                  <Button
                    onClick={() => {
                      const table = tables?.find((table) => table._id === tableId);

                      window?.top?.postMessage(
                        JSON.stringify({
                          title: table?.name,
                          type: 'export',
                          data: tablemark(selectedRows as LooseObject[]),
                        }),
                        '*',
                      );
                    }}
                    className="w-32"
                    color="secondary"
                  >
                    Export
                  </Button>
                </div>
              )}
              <SmartTable
                tableId={tableId}
                onRowSelectionChange={(rows) => {
                  setSelectedRows(rows);
                }}
              />
            </div>
          )}
        </div>
      </main>
    </>
  );
};

import { cn } from '@/helpers/cn.ts';
import GenerateIcon from '@assets/generate.svg';
import { ToolName } from 'src/libs/tools/type.model.ts';
import { LoaderIcon } from 'src/routes/whiteboards/components/ui/LoaderIcon.tsx';
import { useStartExecution } from '../../../hooks/useStartExecution.ts';
import { ExecutionEngineNodeStatus, NodeData, NodeType } from '../../../types.ts';
import { EditNodeName } from '../../EditNodeName.tsx';

type NodeHeaderProps = {
  nodeId: NodeType['id'];
  nodeData: NodeData;
  canExecute: boolean;
  status?: ExecutionEngineNodeStatus;
  preview?: boolean;
};

export function GenericToolNodeHeader({ nodeId, nodeData, canExecute, status, preview }: NodeHeaderProps) {
  const { startExecution } = useStartExecution(nodeId);

  const hideExecuteButton = nodeData.toolConfig.name === ToolName.READER_TEXT;

  return (
    <div className="p-2">
      <div className="flex min-h-8 items-center gap-2">
        <div className="w-[200px] grow px-2">
          {!preview ? (
            <EditNodeName
              nodeData={nodeData}
              nodeId={nodeId}
            />
          ) : (
            <h2 className="line-clamp-3 min-w-3 text-subtitle-md">{nodeData.name}</h2>
          )}
        </div>
        <div className="flex shrink-0 items-center">
          {status !== ExecutionEngineNodeStatus.UNCLASSIFIED && (
            <span className="pr-2 text-xs text-neutrals-600">{status}</span>
          )}
          {nodeData.loading && !preview ? (
            <>
              <div className="p-2">
                <LoaderIcon />
              </div>
            </>
          ) : (
            <button
              disabled={!canExecute || nodeData.loading || preview}
              onClick={() => startExecution()}
              className={cn(
                'run-btn rounded-lg p-2 hover:bg-neutrals-100',
                canExecute && !hideExecuteButton ? '' : 'invisible pointer-events-none',
              )}
            >
              <GenerateIcon className="size-5" />
            </button>
          )}
        </div>
      </div>

      {nodeData.errors?.map((e) => (
        <div
          key={e}
          className="px-2 text-negative-600"
        >
          {e}
        </div>
      ))}
    </div>
  );
}

import { cn } from '@/helpers/cn';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

import { Panel } from '@xyflow/react';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';
import { useGenerateNodeConfig, useGenerateWorkflow } from '../../api/generate-workflow.ts';
import { useGenerateNodes } from '../../hooks/useGenerateNodes.ts';
import { useWhiteboardStore } from '../../store/whiteboard.store.ts';
import { generateId } from '../../helpers/node-helpers.ts';
import { llmMarked } from '@/helpers/llmMarked.ts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { ToolName, ToolOutputType } from 'src/libs/tools/type.model.ts';
import { useToolConfigs } from '@/api/tools/get-tool-configs.ts';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { setWhiteboardAgentPrompt, useWhiteboardAgentPrompt } from '../../../onboarding/onboarding-store.ts';
import { ExecutionEngineNodeStatus } from '../../types.ts';
import { Button } from 'flowbite-react';

export const AiAssistantPanel = () => {
  const { addNodesAndEdges, updateNodesAndEdges } = useGenerateNodes();
  const { whiteboardName, nodes, edges } = useWhiteboardStore();
  const { toolConfigs } = useToolConfigs(DfFeature.WHITEBOARD);
  const [isGenerating, setIsGenerating] = useState(false);
  const [messages, setMessages] = useState<{ type: 'user' | 'bot'; text: string }[]>([]);
  const [generatedAnswer, setGeneratedAnswer] = useState<string>('');
  const generatedAnswerRef = useRef<string>('');
  const onboardingAgentPrompt = useWhiteboardAgentPrompt();
  const [nodesQueuedForGeneration, setNodesQueuedForGeneration] = useState<string[] | null>(null);
  const [isGeneratingNodes, setIsGeneratingNodes] = useState(false);
  const [generationAborted, setGenerationAborted] = useState(false);
  const { generateNodeConfig } = useGenerateNodeConfig();

  useEffect(() => {
    if (nodes && nodes.length > 0 && nodesQueuedForGeneration == null) {
      setNodesQueuedForGeneration(
        nodes
          .filter((node) => node.data.status === ExecutionEngineNodeStatus.UNCLASSIFIED && node.data.generationIntent)
          .map((node) => node.id),
      );
    }
  }, [nodes, nodesQueuedForGeneration]);
  // const nodesToBeGenerated = useMemo(() => {

  //   console.log('checking nodes to be generated');
  //   return nodes.filter((node) => node.data.status === ExecutionEngineNodeStatus.UNCLASSIFIED && node.data.generationIntent);
  // }, [nodes]);

  // if (nodesToBeGenerated.length > 0) {
  //   // console.log('nodesToBeGenerated', nodesToBeGenerated);
  //   // setNodesQueuedForGeneration(nodesToBeGenerated.map((node) => node.data.generationIntent));
  // }

  useEffect(() => {
    if (onboardingAgentPrompt) {
      formik.setFieldValue('query', onboardingAgentPrompt);
      setWhiteboardAgentPrompt(null);
      setTimeout(() => formik.submitForm(), 1000);
    }
  }, []);

  const clearGeneratedAnswer = () => {
    generatedAnswerRef.current = '';
    setGeneratedAnswer('');
  };

  const messagesWithGeneratedAnswer = useMemo(
    () => (generatedAnswer?.length > 0 ? [...messages, { type: 'bot' as const, text: generatedAnswer }] : messages),
    [generatedAnswer, messages],
  );

  const addGeneratedAnswerToMessages = () => {
    if (generatedAnswerRef.current) {
      const answer = generatedAnswerRef.current;
      setMessages((messages) => {
        const result = [...messages, { type: 'bot' as const, text: answer }];
        return result;
      });
    }
    clearGeneratedAnswer();
  };

  const appendDeltaToGeneratedAnswer = (delta: string) => {
    generatedAnswerRef.current = generatedAnswerRef.current + delta;
    setGeneratedAnswer((generatedAnswer) => generatedAnswer + delta);
  };

  const abortGeneration = (e: React.MouseEvent<HTMLButtonElement>) => {
    abortWorkflowGeneration();
    addGeneratedAnswerToMessages();
    setIsGenerating(false);
    setGenerationAborted(true);
    setIsGeneratingNodes(false);
    e.preventDefault();
  };

  const findCompatibleTargetHandle = (
    sourceNodeId: string,
    newNodes: { id: string; toolName: ToolName; name: string }[],
    targetToolName: string,
  ) => {
    const sourceNode = nodes.find((n) => n.id === sourceNodeId);
    let sourceNodeToolConfig = sourceNode?.data?.toolConfig?.config;

    if (!sourceNodeToolConfig) {
      const sourceNodeInNewNdoes = newNodes.find((n) => n.id === sourceNodeId);
      sourceNodeToolConfig = toolConfigs!.find((t) => t.name === sourceNodeInNewNdoes?.toolName)?.config;
    }

    const outputNodeConfig = toolConfigs!.find((t) => t.name === targetToolName)?.config;

    const sourceNodeOutputType = sourceNodeToolConfig?.outputType;

    if (outputNodeConfig?.input) {
      for (const key of Object.keys(outputNodeConfig?.input)) {
        const field = outputNodeConfig?.input[key];

        if (
          field.canBeGeneratedBy === sourceNodeOutputType ||
          (field.canBeGeneratedBy === ToolOutputType.TEXT && sourceNodeOutputType === ToolOutputType.JSON)
        ) {
          return key;
        }
      }
    }

    // fallback for images
    if (sourceNodeOutputType === ToolOutputType.IMAGE && targetToolName === ToolName.ASK_VISION_MODEL) {
      return 'images';
    }

    return null;
  };

  const onmessage = async (event: { event: string; data: string }) => {
    if (event.event === 'thinking-delta') {
      const data = JSON.parse(event.data);
      const delta = data.replace(/\n/g, '\n\n');
      appendDeltaToGeneratedAnswer(delta);
    } else if (event.event === 'nodes-to-add') {
      const data = JSON.parse(event.data);
      const allNodes = [] as { id: string; toolName: ToolName; name: string; generationIntent?: string }[];
      let allEdges = [];

      for (const node of data) {
        allNodes.push({
          id: node.id,
          toolName: node.toolName,
          name: node.title,
          generationIntent: node.intent,
        });

        allEdges.push(
          ...node.inputNodes.map((inputNode: string) => ({
            id: generateId(),
            source: inputNode,
            target: node.id,
            targetHandle: findCompatibleTargetHandle(inputNode, allNodes, node.toolName),
          })),
        );
      }
      allEdges = allEdges.filter((e) => e.targetHandle !== null);
      addNodesAndEdges(allNodes, allEdges);
      setNodesQueuedForGeneration((queued) => [...(queued || []), ...allNodes.map((node) => node.id)]);
    }
  };

  const oncomplete = () => {
    addGeneratedAnswerToMessages();
    setIsGenerating(false);
    formik.resetForm();
  };

  const onerror = () => {
    setIsGenerating(false);
    addGeneratedAnswerToMessages();
    setMessages((prev) => [
      ...prev,
      { type: 'bot' as const, text: 'An error occurred while generating the workflow.' },
    ]);
    // TODO: show error and retry button
  };
  const { startWorkflowGeneration, abortWorkflowGeneration } = useGenerateWorkflow(onmessage, oncomplete, onerror);

  const startGeneration = (query: string) => {
    setMessages((prev) => [...prev, { type: 'user' as const, text: query }]);
    setIsGenerating(true);
    setGenerationAborted(false);
    clearGeneratedAnswer();

    startWorkflowGeneration({
      whiteboardName: whiteboardName || '',
      query,
      existingNodes: nodes.map((node) => ({
        id: node.id,
        title: node.data.name,
        toolName: node.data.toolConfig?.name,
        inputNodes: edges
          .filter((edge) => edge.target === node.id)
          .map((edge) => edge.source)
          .filter(Boolean),
      })),
    });
  };

  const startNodeGeneration = useCallback(
    async (nodeId: string) => {
      setIsGeneratingNodes(true);

      const node = nodes.find((node) => node.id === nodeId);

      if (node) {
        try {
          setMessages((prev) => [
            ...prev,
            { type: 'bot' as const, text: `\n\n\n\n**${node.data.name}**\n\n${node.data.generationIntent}\n\n` },
          ]);

          const response = await generateNodeConfig({
            whiteboardName: whiteboardName || '',
            nodeToGenerate: {
              id: node.id,
              title: node.data.name,
              toolName: node.data.toolConfig?.name,
              inputNodes: edges
                .filter((edge) => edge.target === node.id)
                .map((edge) => edge.source)
                .filter(Boolean),
              intent: node.data.generationIntent,
            },
            existingNodes: nodes.map((node) => ({
              id: node.id,
              title: node.data.name,
              toolName: node.data.toolConfig?.name,
              inputNodes: edges
                .filter((edge) => edge.target === node.id)
                .map((edge) => edge.source)
                .filter(Boolean),
            })),
          });

          updateNodesAndEdges(
            [
              {
                id: response.id,
                fields: response.fields,
              },
            ],
            [],
            true,
          );
        } catch {
          setGenerationAborted(true);
          return;
        }
      }

      setNodesQueuedForGeneration((queued) => (queued || []).filter((id) => id !== nodeId));
      setIsGeneratingNodes(false);
    },
    [nodes, nodesQueuedForGeneration, whiteboardName, updateNodesAndEdges],
  );

  useEffect(() => {
    if (nodesQueuedForGeneration && nodesQueuedForGeneration.length > 0 && !isGeneratingNodes && !generationAborted) {
      startNodeGeneration(nodesQueuedForGeneration[0]);
    }
  }, [nodesQueuedForGeneration, isGeneratingNodes, startNodeGeneration, generationAborted]);

  const formik = useFormik({
    initialValues: {
      query: '',
    },
    validationSchema: object({
      query: string().required(),
    }),
    onSubmit: async ({ query }) => {
      startGeneration(query);
    },
  });

  const messagesEndRef = useRef<HTMLDivElement>(null);

  // const messages = [
  //   { type: 'user', text: 'Hello, how are you?' },
  //   { type: 'bot', text: 'I am good, thank you!' },
  //   { type: 'user', text: 'What is the weather in Tokyo, What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  //   { type: 'user', text: 'What is the weather in Tokyo?' },
  //   { type: 'bot', text: 'The weather in Tokyo is sunny and warm.' },
  // ];

  const [focused, setFocused] = useState(false);
  const expandedView =
    messages.length > 0 ||
    focused ||
    (generationAborted && nodesQueuedForGeneration && nodesQueuedForGeneration.length > 0);

  // const test =

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setTest(!test);
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [test]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messagesWithGeneratedAnswer]);

  const containerRef = useRef(null);

  const backgroundVariants = {
    inactive: {
      opacity: 0,
      borderRadius: 8,
      boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
      margin: 0,
      transition: { duration: 0.2, ease: 'easeOut', delay: 0.1 },
    },
    active: {
      opacity: 1,
      borderRadius: 16,
      boxShadow: '0px 2px 20px 0px #E2E1E8',
      margin: -10,
      transition: { duration: 0.2, ease: 'easeOut' },
    },
  };

  const containerVariants = {
    inactive: {
      opacity: 0,
      height: 0,
      'min-height': 0,
      transition: { duration: 0.2, ease: 'easeIn' },
    },
    active: {
      opacity: 1,
      height: 'auto',
      'min-height': '80px',
      transition: { duration: 0.2, ease: 'easeOut', delay: 0.05 },
    },
  };

  return (
    <>
      <Panel
        position="bottom-right"
        className="!m-4"
      >
        <div
          ref={containerRef}
          className="relative flex max-h-[80vh] w-[400px] flex-col gap-2"
        >
          <motion.div
            className="absolute inset-0 -z-10 bg-neutral-50"
            variants={backgroundVariants}
            animate={expandedView ? 'active' : 'inactive'}
          />
          <AnimatePresence>
            {expandedView && (
              <motion.div
                className="side-chat-message-list gap-6 overflow-auto py-3"
                variants={containerVariants}
                initial="inactive"
                exit="inactive"
                animate="active"
              >
                {messagesWithGeneratedAnswer.length > 0
                  ? messagesWithGeneratedAnswer.map(({ type, text }, index) => (
                      <div
                        key={index}
                        className={cn(
                          'mr-2 flex max-w-full flex-row-reverse items-start gap-3 p-2 text-sm',
                          {
                            'ml-[80px] justify-self-end rounded-lg rounded-br-none bg-gray-ultra-light':
                              type === 'user',
                          },
                          { 'justify-end': type !== 'user' },
                        )}
                      >
                        <div dangerouslySetInnerHTML={{ __html: llmMarked(text) as string }}></div>
                        {type !== 'user' && (
                          <img
                            src="/df-logo-small.svg"
                            className="rounded-full border border-gray-divider"
                            alt=""
                          />
                        )}
                      </div>
                    ))
                  : !(generationAborted && nodesQueuedForGeneration && nodesQueuedForGeneration.length > 0) && (
                      <div className="text-center text-sm text-gray-500">Placeholder for some ideas to start with</div>
                    )}
                <div ref={messagesEndRef} />
                {generationAborted && nodesQueuedForGeneration && nodesQueuedForGeneration.length > 0 && (
                  <div
                    className={cn('flex flex-col items-start', {
                      'ml-[35px]': messagesWithGeneratedAnswer.length > 0,
                      'ml-1': messagesWithGeneratedAnswer.length === 0,
                    })}
                  >
                    <div className="mb-3 flex-1 text-sm text-red-500">Generation has not been completed. </div>
                    <Button
                      size="sm"
                      className="text-sm"
                      onClick={() => setGenerationAborted(false)}
                    >
                      Resume
                    </Button>
                  </div>
                )}
                {messagesWithGeneratedAnswer.length > 0 && (
                  <button
                    className="absolute left-0 top-0 bg-neutral-50 p-1"
                    onClick={() => {
                      setMessages([]);
                    }}
                  >
                    <img
                      src="/trash-gray.svg"
                      alt="trashcan"
                    />
                  </button>
                )}
                <AnimatePresence>
                  {(isGenerating || isGeneratingNodes) && (
                    <motion.div
                      className="ml-6 flex items-start"
                      exit={{ opacity: 0, scale: 0 }}
                      initial={{ opacity: 1, scale: 1 }}
                    >
                      <DotLottieReact
                        src="/typing2.lottie"
                        loop
                        autoplay
                        style={{
                          height: '26px',
                          width: '60px',
                        }}
                      />
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
          <FormikProvider value={formik}>
            <div className="rounded-xl shadow-card">
              <form
                className="flex w-full rounded-xl border-neutrals-300 bg-white p-3"
                onSubmit={formik.handleSubmit}
              >
                <input
                  {...formik.getFieldProps('query')}
                  className="ml-1 flex flex-1 bg-white outline-none disabled:opacity-50"
                  placeholder="Ask what you want to create..."
                  autoComplete="off"
                  disabled={isGenerating || isGeneratingNodes}
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                />

                {!(isGenerating || isGeneratingNodes) ? (
                  <button
                    className="ml-4 rounded-lg bg-primary-default p-2 hover:bg-primary-hover active:bg-primary-active"
                    type="submit"
                  >
                    <img
                      src="/arrow-right-white.svg"
                      alt=""
                    />
                  </button>
                ) : (
                  <button
                    className="ml-4 rounded-lg bg-primary-default p-2 hover:bg-primary-hover active:bg-primary-active"
                    onClick={(e) => abortGeneration(e)}
                  >
                    <img
                      src="/stop-white.svg"
                      alt=""
                    />
                  </button>
                )}
              </form>
            </div>
          </FormikProvider>
        </div>
      </Panel>
    </>
  );
};

import { useApi } from '@/api/api.ts';
import { ReactFlowDiff } from '../types';

export const useUpdateReactFlow = () => {
  const { patchFetcher } = useApi();

  const updateReactFlow = async (reactFlowDiff: ReactFlowDiff, fullSync?: boolean) => {
    return patchFetcher(`/react-flow/${reactFlowDiff.id}`, { arg: { ...reactFlowDiff, fullSync } });
  };

  return {
    updateReactFlow,
  };
};

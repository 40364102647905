import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { setFlowInstance } from '@/helpers/flowInstance.ts';
import { useMyFlows } from '@/api/flow/my-flow/get-my-flows.ts';

export const OnboardingFlowInitializer = () => {
  const projectId = useParams().projectId!;
  const { myFlows } = useMyFlows(+projectId);
  const navigate = useNavigate();

  useEffect(() => {
    if (myFlows?.length) {
      const newestFlowId = myFlows[0].id;
      setFlowInstance(newestFlowId);
      navigate(
        buildUrl([Urls.ONBOARDING, Urls.PROJECTS, Urls.PROJECT, Urls.FLOW_EDITOR], {
          flowId: newestFlowId,
          projectId,
        }),
      );
    }
  }, [myFlows]);

  return <></>;
};

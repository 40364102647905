import { useEffect, useState } from 'react';
import { useChatHistory } from '@/api/chat/get-chat-history.ts';
import { useSendMessage } from '@/api/chat/send-message.ts';
import { useDeleteMessages } from '@/api/chat/delete-messages.ts';
import { useAgentPanel } from './agent-panel.store.ts';
import { v4 as uuid } from 'uuid';
import { ChatMessage } from '@/models/chat-message.interface.ts';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { mutate } from 'swr';
import { useDataRoomId } from '@/state/app-store.ts';
import { htmlToText } from '@/helpers/html-to-text.ts';
import { llmMarked } from '../../helpers/llmMarked';
// import './AgentPanel.scss';
import { SideChat } from '../SideChat/SideChat.tsx';

export function AgentPanel() {
  const { messages, isLoading } = useChatHistory();
  const { sendMessage } = useSendMessage();
  const { deleteMessages } = useDeleteMessages();
  const { isVisible, hideAgentPanel } = useAgentPanel();
  const [messagesHistory, setMessagesHistory] = useState<ChatMessage[]>([]);
  const { listenerOn } = useWebSocketMessageListener();
  const dataRoomId = useDataRoomId();

  const exampleQuestions = [
    'What is the pricing plan?',
    'How does a web application work?',
    'What are the benefits of using the application?',
  ];

  const onQuestionSend = async (question: string) => {
    const wsChannel = 'chat-question:' + uuid();
    let messageResponse = '';

    const message: ChatMessage = {
      text: htmlToText(question),
      type: 'user',
    };

    const messagesWithNewQuestion = [...messagesHistory, message];

    setMessagesHistory(messagesWithNewQuestion);
    await sendMessage(htmlToText(question), wsChannel);

    const { listenerOff } = listenerOn(wsChannel, (data: string | Record<string, boolean>) => {
      if (typeof data === 'string') {
        messageResponse += data;

        const messageAnswer: ChatMessage = {
          text: llmMarked(messageResponse) as string,
          type: 'bot',
        };

        setMessagesHistory([...messagesWithNewQuestion, messageAnswer]);
      } else if (data.done) {
        listenerOff();
        setTimeout(() => {
          mutate(`/chat/${dataRoomId}`);
        }, 250);
      }
    });
  };

  useEffect(() => {
    setMessagesHistory(messages);
  }, [messages]);

  return (
    <SideChat
      messages={messagesHistory}
      title="DigitalFirst AI Agent"
      isLoading={isLoading}
      isVisible={isVisible}
      sampleQuestionsHeader="Ask a question"
      onHideClick={hideAgentPanel}
      onSendMessage={onQuestionSend}
      onDeleteMessages={deleteMessages}
      sampleQuestions={exampleQuestions}
      inputPlaceholder="Ask a question..."
    />
  );
}

import { ReactNode } from 'react';

type ToolboxGroupProps = {
  label: string;
  children: ReactNode;
};

export function ToolboxGroup({ label, children }: ToolboxGroupProps) {
  return (
    <>
      <div className="mt-3 pb-1 pl-2 text-subtitle-sm text-neutrals-800">{label}</div>
      <div className="flex flex-col gap-1.5">{children}</div>
    </>
  );
}

import { IconType } from '@/components/Icon';
import { WhiteboardTemplate, WhiteboardTemplateScope } from '@/models/whiteboard-template';
import BackIcon from '@assets/back.svg';
import LinkIcon from '@assets/link.svg';
import { copyTemplateUrl } from '../../helpers/template-helper';
import { TemplateAuthor } from './TemplateAuthor';
import { TemplateButton } from './TemplateButton';
import { TemplateDropdownOptions } from './TemplateDropdownOptions';
import { WhiteboardPreview } from '../../preview/WhiteboardPreview';
import { TemplateToolsIcons } from './TemplateToolsIcons';

interface TemplateDetailsProps {
  template: WhiteboardTemplate;
  onBack: () => void;
  onAddTemplate: () => void;
}

export function TemplateDetails({ template, onBack, onAddTemplate }: TemplateDetailsProps) {
  return (
    <div className="flex h-full flex-col justify-start py-4">
      <div className="flex items-center justify-between">
        <NavButton
          onClick={onBack}
          label="Back to templates"
          Icon={BackIcon}
        />
        <div className="flex items-center gap-2">
          {template.scope === WhiteboardTemplateScope.PUBLIC && (
            <NavButton
              onClick={() => {
                copyTemplateUrl(template);
              }}
              label="Copy link to share"
              Icon={LinkIcon}
            />
          )}
          <TemplateDropdownOptions template={template} />
        </div>
      </div>
      <div className="grid grid-cols-5 gap-4 pt-5">
        <div className="col-span-2">
          <TemplateAuthor template={template} />
          <div className="flex items-baseline justify-between">
            <h2 className="min-h-16 pt-2 text-h2-desktop">{template.name}</h2>
          </div>
          <div className="py-5">
            <TemplateToolsIcons template={template} />
          </div>
          <div className="flex items-center justify-between">
            <TemplateButton
              onClick={onAddTemplate}
              label="Use this template"
            />
          </div>
        </div>
        {template.description && (
          <div className="col-span-3 max-h-56 overflow-y-auto rounded-lg border border-neutrals-300 bg-primary-100 px-5 py-4">
            <h4 className="text-h5-desktop">About this template</h4>
            <p>{template.description}</p>
          </div>
        )}
      </div>
      <div className="grow pt-5">
        <WhiteboardPreview
          reactFlow={template.whiteboard.reactFlow}
          badge="Example effect"
        />
      </div>
    </div>
  );
}

export function NavButton({ onClick, label, Icon }: { onClick: () => void; label: string; Icon: IconType }) {
  return (
    <button
      onClick={onClick}
      className="group flex items-center gap-0.5 hover:underline"
    >
      <Icon className="size-4 path:fill-neutrals-900 group-hover:path:fill-neutrals-1000" />
      <span className="text-nav-sub text-neutrals-900 group-hover:text-neutrals-1000">{label}</span>
    </button>
  );
}

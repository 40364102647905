import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { useState } from 'react';
import { TemplatesGrid } from '../whiteboards/components/templates/TemplatesGrid';
import { PublicTemplate } from './PublicTemplate';

type PublicTemplatesProps = {
  publicTemplates: WhiteboardTemplate[];
  selectedTemplateUUID?: string;
};

export function PublicTemplates({ publicTemplates, selectedTemplateUUID }: PublicTemplatesProps) {
  const [selectedTemplate, setSelectedTemplate] = useState<WhiteboardTemplate | null>(
    publicTemplates.find((t) => t.uuid === selectedTemplateUUID) || null,
  );

  function handleSelectTemplate(template: WhiteboardTemplate) {
    setSelectedTemplate(template);
  }

  function handleBack() {
    setSelectedTemplate(null);
  }

  return selectedTemplate ? (
    <PublicTemplate
      publicTemplate={selectedTemplate}
      onBack={handleBack}
    />
  ) : (
    <div className="h-screen overflow-y-auto">
      <div className="m-auto max-w-xl p-4">
        <TemplatesGrid
          templates={publicTemplates}
          onSelect={handleSelectTemplate}
          onAdd={handleSelectTemplate}
          showEditOptions={false}
          showButton={false}
        />
      </div>
    </div>
  );
}

import { Urls } from '@/consts/urls.ts';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { CreateProjectButton } from './components/CreateProjectButton.tsx';
import { OnboardingDashboard } from './components/OnboardingDashboard.tsx';

export function Dashboard() {
  // const { projects, isLoading } = useProjects();

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [{ label: 'Dashboard', href: Urls.DASHBOARD }],
        icon: '/dashboard-black.svg',
        rightAddons: [<CreateProjectButton key="create-project-button" />],
      }}
    >
      <OnboardingDashboard />

      {/*<section*/}
      {/*  className="relative flex w-full flex-col items-center justify-center rounded-3xl pb-10 pt-16"*/}
      {/*  style={{ backgroundImage: 'url(/gradient-wide.svg)', backgroundSize: 'cover' }}*/}
      {/*>*/}
      {/*  <div*/}
      {/*    className="absolute left-0 top-0 -z-10 h-full w-full rounded-3xl"*/}
      {/*    style={{ backgroundImage: 'url(/noise-wide.svg)' }}*/}
      {/*  ></div>*/}
      {/*  <div className="flex w-full flex-col items-center gap-4 text-center">*/}
      {/*    <h1 className="flex text-h1-desktop">*/}
      {/*      Search precise data{' '}*/}
      {/*      <span className="web-searcher-section__header__icon mx-2 flex w-8 items-center justify-center bg-mint-400">*/}
      {/*        <GenerateIcon className="size-4" />*/}
      {/*      </span>{' '}*/}
      {/*      in seconds*/}
      {/*    </h1>*/}

      {/*    <div className="text-body-md text-text-tertiary">*/}
      {/*      Enhance your strategies and marketing actions by leveraging AI. <br /> Paste your company URL to seamlessly*/}
      {/*      load your brand data.*/}
      {/*    </div>*/}

      {/*    <div className="flex w-full max-w-[600px]">*/}
      {/*      <DashboardSearch />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="w-full">*/}
      {/*    <div className="mx-5 mt-7 rounded-xl bg-primary-100 p-4">*/}
      {/*      <RecommendedFlows />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}

      {/*{!isLoading && !!projects?.length && (*/}
      {/*  <section className="mt-7">*/}
      {/*    <div className="flex items-center justify-between">*/}
      {/*      <h2 className="text-h3-desktop">Recent projects</h2>*/}
      {/*      <Link*/}
      {/*        to={Urls.PROJECTS}*/}
      {/*        className="link link--lg"*/}
      {/*      >*/}
      {/*        View all*/}
      {/*        <img*/}
      {/*          src="/arrow-right-blue.svg"*/}
      {/*          alt=""*/}
      {/*        />*/}
      {/*      </Link>*/}
      {/*    </div>*/}

      {/*    <div className="mt-5 grid grid-cols-3 gap-4">*/}
      {/*      {projects.slice(0, 3).map((project) => (*/}
      {/*        <ProjectCard*/}
      {/*          key={project.id}*/}
      {/*          project={project}*/}
      {/*        />*/}
      {/*      ))}*/}
      {/*    </div>*/}
      {/*  </section>*/}
      {/*)}*/}
    </PageWithHeaderLayout>
  );
}

import { llmMarked } from '@/helpers/llmMarked';
import { ToolCategory, ToolName, ToolOutput } from 'src/libs/tools/type.model';
import { HtmlElementToString } from './HtmlElementToString';

export const parseOutput = async (
  output: ToolOutput,
  toolCategory: ToolCategory,
  toolName: ToolName,
  getToolOutput?: () => Promise<string>,
): Promise<string> => {
  if (!output) return '';

  if (toolName.includes('DEEP_RESEARCH')) {
    return llmMarked(output as string) as string;
  }

  switch (toolCategory) {
    case ToolCategory.TEXTGEN: {
      return llmMarked(output as string) as string;
    }

    case ToolCategory.IMAGEGEN: {
      let images = `<div>`;

      if (toolName.includes('VIDEO')) {
        (output as string[]).forEach((element) => {
          const video = document.createElement('video');
          video.src = import.meta.env.VITE_ASSETS + element;
          images += HtmlElementToString(video);
        });
      } else {
        (output as string[]).forEach((element) => {
          const img = document.createElement('img');
          img.src = import.meta.env.VITE_ASSETS + element;
          img.alt = '';
          images += HtmlElementToString(img);
        });
      }

      return (images += '</div>');
    }

    case ToolCategory.RESEARCHGEN:
      return (await getToolOutput?.()) ?? '';
  }

  return '';
};

import { useNavigate, useParams } from 'react-router-dom';
import { useCanvasDetails } from '@/api/canvases/api.ts';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { DropdownCanvasOptions } from '../../canvases/components/DropdownCanvasOptions.tsx';
import { GenerateStrategyCanvasButton } from '../../canvases/routes/canvas-deatils/components/GenerateStrategyCanvasButton.tsx';
import { StrategyCanvasPositionCard } from '../../canvases/routes/canvas-deatils/components/StrategyCanvasPositionCard.tsx';
import { useEffect } from 'react';
import { confettiBoom } from '@/components/GlobalConfetti/global-confetti.store.ts';
import { setUnFinishedOnboardingProcesses, useUnFinishedOnboardingProcesses } from '../onboarding-store.ts';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { useOpenOnboardingModal } from '@/helpers/open-onboarding-modal.ts';
import { useAuth } from '@/hooks/auth/useAuth.ts';
import { PageTitle } from '@/components/PageTitle.tsx';

export const OnboardingCanvasDetails = () => {
  const { canvasId } = useParams<{ canvasId: string }>();
  const { canvasDetails, isLoading } = useCanvasDetails(canvasId!);
  const navigate = useNavigate();
  const { openOnboardingModal } = useOpenOnboardingModal();
  const { user } = useAuth();
  const unFinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);

  useEffect(() => {
    if (canvasDetails) {
      confettiBoom();
      setUnFinishedOnboardingProcesses(
        user!.sub!,
        unFinishedOnboardingProcesses.filter((type) => type !== OnboardingProcessType.CANVAS),
      );

      setTimeout(() => {
        openOnboardingModal();
      }, 30000);
    }
  }, [canvasDetails]);

  if (isLoading || !canvasDetails) return;

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Strategy canvases', href: buildUrl([Urls.CANVASES]) },
          { label: 'My canvases', href: buildUrl([Urls.CANVASES, Urls.MY_CANVASES]) },
          { label: canvasDetails?.name ?? '', href: '#' },
        ],
        icon: '/canvases-black.svg',
        leftAddons: [
          <DropdownCanvasOptions
            key={canvasDetails.id}
            instanceId={canvasDetails.id}
            onDelete={() => {
              navigate(buildUrl([Urls.CANVASES, Urls.MY_CANVASES]));
            }}
          />,
        ],
        rightAddons: [
          <GenerateStrategyCanvasButton
            key={canvasDetails.id}
            canvasDetails={canvasDetails}
          />,
        ],
      }}
    >
      <PageTitle title={`Digitalfirst.ai | ${canvasDetails.name}`} />

      <div className="grid grid-cols-2 items-start gap-6">
        {canvasDetails.positions
          .sort((a, b) => {
            return a.hierarchyPosition - b.hierarchyPosition;
          })
          .map((position, i) => (
            <StrategyCanvasPositionCard
              key={position.id}
              position={position}
              canvasDetails={canvasDetails}
              index={i}
            />
          ))}
      </div>
    </PageWithHeaderLayout>
  );
};

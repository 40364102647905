import { ShowIfPossibleValues } from './type.model';

const handleArray = (
  field: { showIf?: Record<string, ShowIfPossibleValues> },
  key: string,
  values: Record<string, ShowIfPossibleValues>,
) => {
  if (field?.showIf && Array.isArray(field?.showIf[key]) && values[key]) {
    return field.showIf[key].includes(values[key] as string);
  }

  return true;
};

const handleNumber = (
  field: {
    showIf?: Record<string, ShowIfPossibleValues>;
  },
  key: string,
  values: Record<string, ShowIfPossibleValues>,
) => {
  if (!field?.showIf || !field?.showIf[key] || typeof field?.showIf[key] !== 'string') return true;

  const [firstChar, value] = (field.showIf[key] as string).split(' ');

  if (firstChar === '<' && Number(value) < (values[key] as string).length) return false;
  if (firstChar === '>' && Number(value) > (values[key] as string).length) return false;
  if (firstChar === '=' && Number(value) === (values[key] as string).length) return false;

  return true;
};

export const fieldCanBeRender = (
  field: {
    showIf?: Record<string, ShowIfPossibleValues>;
    isDeprecated?: boolean;
  },
  values: Record<string, ShowIfPossibleValues>,
) => {
  if (field?.isDeprecated) return false;
  if (!field?.showIf) return true;

  const render: boolean[] = [];

  for (const dependentFieldKey in field.showIf) {
    render.push(handleArray(field, dependentFieldKey, values));
    render.push(handleNumber(field, dependentFieldKey, values));
  }

  return !render.some((r) => !r);
};

import { useContext, useState } from 'react';
import { PromptEditorContext } from '@/components/tiptap/extensions/DigitalFirst/editing/prompt-editor.context.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { IconButton } from '@/components/IconButton.tsx';
import { cn } from '@/helpers/cn';
import { useSegments } from '@/api/segments/get-segments.ts';
import { Persona } from '@/models/persona.interface.ts';

export const PersonaMentionAccordionBody = () => {
  const { segments } = useSegments();
  const [expandPersona, setExpandPersona] = useState<Persona | null>(null);
  const { editor } = useContext(PromptEditorContext);

  const toggleExpandedPersona = (persona: Persona) => {
    if (expandPersona?.id === persona.id) {
      setExpandPersona(null);
    } else {
      setExpandPersona(persona);
    }
  };

  const handleAddPersonaToPrompt = (persona: Persona) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: MentionReferenceType.PERSONA,
        attrs: {
          id: persona.id,
          label: persona.name,
        },
      })
      .insertContent(' ') // add an extra space after the mention
      .run();
  };

  const handleAddPersonaPositionToPrompt = (persona: Persona, position: { label: string }) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: MentionReferenceType.PERSONA,
        attrs: {
          id: persona.id,
          label: `${persona.name}/${position.label}`,
        },
      })
      .insertContent(' ') // add an extra space after the mention
      .run();
  };

  return (
    <>
      <div className="mention-accordion">
        <div className="mention-accordion__header text-neutrals-1000">Add to prompt</div>
        {!!segments &&
          segments.map((segment, i) => (
            <div
              key={i}
              className="mt-5"
            >
              <div className="mention-accordion__header !text-neutrals-1000">{segment.name}</div>

              <div className="mention-accordion__list">
                {segment.personaCanvases.map((persona) => (
                  <div
                    key={persona.id}
                    className="mention-accordion__add-button-wrapper"
                  >
                    <IconButton
                      icon="/add-black.svg"
                      size={20}
                      className="mention-accordion__add-button"
                      onClick={() => handleAddPersonaToPrompt(persona)}
                    />
                    <div className="mention-accordion__list__item">
                      <button
                        onClick={() => toggleExpandedPersona(persona)}
                        className="mention-accordion__button-toggle"
                        type="button"
                      >
                        {/* placeholder for IconButton */}
                        <div className="mention-accordion__button-toggle__placeholder"></div>
                        <span className="mention-accordion__button-toggle__title">{persona.name}</span>
                        <img
                          src="/chevron-down-black.svg"
                          className={cn('mention-accordion__button-toggle__icon', {
                            'rotate-180': expandPersona?.id === persona.id,
                          })}
                          alt=""
                          width={16}
                          height={16}
                        />
                      </button>

                      {expandPersona?.id === persona.id && (
                        <div className="mention-accordion__badge-list">
                          {persona.template.positions.map((position) => (
                            <button
                              key={position.id}
                              className="mention-accordion__badge !bg-lime-100 hover:!bg-lime-200 active:!bg-lime-300"
                              onClick={() => handleAddPersonaPositionToPrompt(persona, position)}
                              type="button"
                            >
                              <img
                                src="/add-violet.svg"
                                alt=""
                                width={12}
                                height={12}
                              />
                              <span className="text-lime-1000">{position.label}</span>
                            </button>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

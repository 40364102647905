import { AccordionInfo } from '@/components/AccordionInfo.tsx';
import { Button, Label, TextInput } from 'flowbite-react';

import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useState } from 'react';
import { ToolName } from 'src/libs/tools/type.model';
import { v4 as uuid } from 'uuid';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { FormikProvider, useFormik } from 'formik';
import { useAddResource } from '@/api/data-room/add-resource';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface';
import toast from 'react-hot-toast';
import { VirtualSitesList } from '../VirtualSitesList';
import { normalizeUrl } from '@/helpers/normalize-url';
import { HiSearch } from 'react-icons/hi';
import { useExecuteTool } from '@/api/tools/execute-tool.ts';
import { URL_REGEXP } from '@/consts/url-regexp-validators.const';

export function DataRoomAddWebsiteLinksModalBody() {
  const { addResource } = useAddResource();
  const { closeModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [startedUrl, setStartedUrl] = useState('');
  const [startedSearchQuery, setStartedSearchQuery] = useState('');
  const [urlsState, setUrlsState] = useState<{ url: string; checked: boolean }[]>([]);
  const { executeTool } = useExecuteTool();
  const { listenerOn } = useWebSocketMessageListener();
  const [isScanning, setIsScanning] = useState(false);
  const [isLongScanning, setLongIsScanning] = useState(false);
  const [scanningError, setScanningError] = useState<string | null>(null);

  const formik = useFormik<{ urls: { url: string; checked: boolean }[]; name: string }>({
    initialValues: {
      urls: [],
      name: 'My collection',
    },
    onSubmit: async ({ urls, name }) => {
      const checkedUrl = urls.filter((url) => url.checked).map((url) => url.url);

      if (checkedUrl.length > 10) {
        toast.error('You can only add up to 10 URLs at a time.');
        return;
      }

      showLoader();
      const payloads: AddResourcePayload = inputsPayloadMapper[InputType.Url](checkedUrl, name);
      await addResource(payloads);
      hideLoader();
      closeModal();
    },
  });

  const onScan = async () => {
    const normalizedStartedUrl = normalizeUrl(startedUrl);

    if (!URL_REGEXP.test(normalizedStartedUrl)) {
      setScanningError('The URL is not valid');
      return;
    }

    setIsScanning(true);
    const timerId = setTimeout(() => setLongIsScanning(true), 3000);

    const wsChannel = `${ToolName.CRAWLER_LINKS_EXTRACTOR}: ${uuid()}`;

    const { listenerOff } = listenerOn(wsChannel, (data: { url: string; suggested: boolean }[]) => {
      const mapedUrls = data
        .map((url) => ({ url: url.url, checked: url.suggested, suggested: url.suggested }))
        .sort((item) => (item.suggested ? -1 : 1));

      setUrlsState(mapedUrls);
      formik.setFieldValue('urls', mapedUrls);

      clearTimeout(timerId);
      setIsScanning(false);
      setLongIsScanning(false);
      listenerOff();
    });

    try {
      await executeTool({
        fields: [
          { key: 'url', value: normalizedStartedUrl },
          { key: 'searchQuery', value: startedSearchQuery },
        ],
        toolName: ToolName.CRAWLER_LINKS_EXTRACTOR,
        wsChannel,
      });
      setScanningError(null);
    } catch {
      clearTimeout(timerId);
      setScanningError('Something went wrong while scanning the website.');
      setIsScanning(false);
      setLongIsScanning(false);
    }
  };

  const getSelectedPagesCount = () => {
    return formik?.values?.urls?.filter((url) => url?.checked)?.length ?? 0;
  };

  return (
    <>
      <AccordionInfo
        question="Why share a website URL?"
        answer="By providing a URL, you enable our AI to gather information from the website, enhancing the results generated for your business. You can share main pages, landing pages, product pages, or about us sections – essentially, any URL containing written information about your business."
      ></AccordionInfo>

      <div className="mt-4">
        <Label
          htmlFor={`startedUrl`}
          value="Website URL"
        />
        <div className="flex w-full items-start gap-2">
          <div className="grow">
            <TextInput
              onChange={(e) => setStartedUrl(e.target.value)}
              type="url"
              name="startedUrl"
              placeholder="Paste website URL"
            />
          </div>
        </div>
        <Label
          htmlFor={`searchQuery`}
          value="Search query"
          className="mt-2"
        />
        <div className="flex w-full items-start gap-2">
          <div className="grow">
            <TextInput
              onChange={(e) => setStartedSearchQuery(e.target.value)}
              name="searchQuery"
              placeholder="Paste search query"
            />
          </div>
        </div>
        {scanningError && <div className="mt-2 text-red-500">{scanningError}</div>}
      </div>

      <Button
        color="primary"
        onClick={onScan}
        className="ml-auto mt-4"
        size="xs"
        isProcessing={isScanning}
      >
        Search for subpages
      </Button>

      {isLongScanning && (
        <div className="flex items-center justify-center bg-white opacity-80">
          <div className="flex flex-col items-center gap-4">
            <img
              src="/loading-blue.svg"
              className="animate-spin-slow"
              alt=""
            />
            <div className="text-center text-body-lg">
              We are searching for subpages, it may take up to a few minutes
            </div>
          </div>
        </div>
      )}

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          {!!urlsState.length && (
            <>
              <Label className="w-full">
                <span className="text-[0.875rem] font-medium leading-[1.1rem]">Collection name</span>
                <TextInput
                  className="mt-2"
                  onChange={(e) => {
                    const name = e.target.value;
                    formik.setFieldValue('name', name);
                  }}
                  name="name"
                  placeholder="Name the collection"
                />
              </Label>
              <div className="rounded-lg border border-gray-divider px-4 py-4">
                <h2 className="mb-2 text-sm font-medium">Choosed subpages ({getSelectedPagesCount()}/10):</h2>
                <div className="relative">
                  <TextInput
                    className="mb-3"
                    onChange={(e) => {
                      const search = e.target.value;
                      const filteredUrls = urlsState.filter(({ url }) => url.includes(search));
                      formik.setFieldValue('urls', filteredUrls);
                    }}
                    name="search"
                    placeholder="Search URL"
                    icon={HiSearch}
                  />
                </div>

                <VirtualSitesList
                  urls={formik.values.urls}
                  formik={formik}
                />
              </div>
            </>
          )}

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!formik.values.urls.some((url) => url.checked)}
            >
              Add
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}

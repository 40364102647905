import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  NodeChange,
  NodePositionChange,
  ReactFlowInstance,
} from '@xyflow/react';
import { StateCreator } from 'zustand';
import { NodeType, ReactFlow, ReactFlowDiff } from '../types';
import { ExecutionSlice } from './execution.slice';
import { GroupSlice } from './group.slice';
import { HistorySlice } from './history.slice';
import { ValidationSlice } from './validation.slice';

type Node = NodeType;

type ReactFlowState = {
  nodes: Node[];
  edges: Edge[];
  reactFlowId?: number;
  reactFlowInstance?: ReactFlowInstance<NodeType>;
  lastSavedNodes?: Node[];
  lastSavedEdges?: Edge[];
  isEdgeConnecting: boolean;
};

type ReactFlowAction = {
  getReactFlow: () => ReactFlow;
  setReactFlow: (reactFlow: ReactFlow) => void;
  setReactFlowInstance: (rfi: ReactFlowInstance<NodeType>) => void;
  addEdge: (connection: Connection) => void;
  onConnect: (connection: Connection) => void;
  onConnectStart: () => void;
  onConnectEnd: () => void;
  onEdgesChange: (changes: EdgeChange<Edge>[]) => void;
  onNodesChange: (changes: NodeChange<Node>[]) => void;
  fitView: (nodes?: Pick<Node, 'id'>[]) => void;
  addEdges: (edges: Edge[]) => void;
  updateEdge: (edge: Edge) => void;
  duplicateEdgesForNodes: (nodeIds: string[], nodeIdMap: Map<string, string>) => void;
  setLastSavedState: () => void;
  getFlowDiff: () => ReactFlowDiff;
};
export type ReactFlowSlice = ReactFlowState & ReactFlowAction;

export const initialFlowState: ReactFlowState = {
  nodes: [],
  edges: [],
  reactFlowId: undefined,
  reactFlowInstance: undefined,
  lastSavedNodes: undefined,
  lastSavedEdges: undefined,
  isEdgeConnecting: false,
};

export const createFlowSlice: StateCreator<
  ReactFlowSlice & ExecutionSlice & ValidationSlice & HistorySlice & GroupSlice,
  [],
  [],
  ReactFlowSlice
> = (set, get) => ({
  ...initialFlowState,
  setReactFlow: (reactFlow) => set({ nodes: reactFlow.nodes, edges: reactFlow.edges }),
  getReactFlow: () => ({ id: get().reactFlowId!, nodes: get().nodes, edges: get().edges }),
  setReactFlowInstance: (rfi) => set({ reactFlowInstance: rfi }),
  onNodesChange: (changes) => {
    const removeChanges = changes.filter((c) => c.type === 'remove');
    const positionChanges = changes.filter((c) => c.type === 'position' && c.dragging === false);
    const resizingChanges = changes.filter((c) => c.type === 'dimensions' && c.resizing === false);

    set({
      nodes: applyNodeChanges(changes, get().nodes),
    });

    if (positionChanges.length > 0 || resizingChanges.length > 0) {
      [...positionChanges, ...resizingChanges].forEach((change) => {
        get().regroupNode((change as NodePositionChange).id);
      });
    }

    if (positionChanges.length > 0 || removeChanges.length > 0 || resizingChanges.length > 0) {
      [...positionChanges, ...removeChanges, ...resizingChanges].forEach(() => {
        get().addToHistoryStack();
      });
    }
  },

  onEdgesChange: (changes) => {
    const nodesToValidate: string[] = [];
    changes.forEach((change) => {
      if (change.type === 'remove') {
        const edge = get().edges.find((edge) => edge.id === change.id);
        const targetNode = get().nodes.find((node) => node.id === edge?.target);
        if (targetNode) {
          nodesToValidate.push(targetNode.id);
        }
      }
    });
    const newEdges = applyEdgeChanges(changes, get().edges);
    set({
      edges: newEdges,
    });
    nodesToValidate.forEach((nodeId) => {
      get().validateNode(nodeId);
    });

    get().addToHistoryStack();
  },

  addEdge: (connection: Connection) => {
    const newEdges = addEdge(connection, get().edges);
    set({
      edges: newEdges,
    });
  },

  onConnect: (connection) => {
    get().addEdge(connection);
    const targetNode = connection.target;
    get().validateNode(targetNode);
    get().addToHistoryStack();
  },

  onConnectStart: () => {
    set({ isEdgeConnecting: true });
  },

  onConnectEnd: () => {
    set({ isEdgeConnecting: false });
  },

  fitView: (nodes) => {
    setTimeout(() => {
      get().reactFlowInstance?.fitView({
        nodes: nodes ?? get().nodes,
        padding: 1,
        maxZoom: 1,
        minZoom: 0.5,
        duration: 800,
      });
    }, 50);
  },

  addEdges: (edges: Edge[]) => {
    set({
      edges: [...get().edges, ...edges],
    });
  },

  updateEdge: (edge: Edge) => {
    set((state) => ({
      edges: state.edges.map((e) => (e.id === edge.id ? edge : e)),
    }));
  },

  duplicateEdgesForNodes: (nodeIds, nodeIdMap) => {
    const edges = get().edges;

    nodeIds.forEach((originalNodeId) => {
      const newNodeId = nodeIdMap.get(originalNodeId);
      if (newNodeId) {
        edges.forEach((edge) => {
          if (edge.source === originalNodeId) {
            const connection = {
              source: newNodeId,
              sourceHandle: edge.sourceHandle?.replace(originalNodeId, newNodeId) || null,
              target: nodeIdMap.get(edge.target) || edge.target,
              targetHandle: edge.targetHandle || null,
            };

            get().addEdge(connection);
          }
          if (edge.target === originalNodeId) {
            const newEdgeSource = nodeIdMap.get(edge.source) || edge.source;
            const connection = {
              source: newEdgeSource,
              sourceHandle: edge.sourceHandle?.replace(edge.source, newEdgeSource) || null,
              target: newNodeId,
              targetHandle: edge.targetHandle || null,
            };
            get().addEdge(connection);
          }
        });
      }
    });
  },

  setLastSavedState: () => {
    const { nodes, edges } = get();
    set({ lastSavedNodes: nodes, lastSavedEdges: edges });
  },

  getFlowDiff: () => {
    const { nodes, edges, reactFlowId, lastSavedNodes, lastSavedEdges } = get();

    if (!lastSavedNodes || !lastSavedEdges) {
      return { id: reactFlowId!, nodes, edges };
    }

    const changedNodes = nodes.filter((node) => {
      const savedNode = lastSavedNodes.find((n) => n.id === node.id);
      if (!savedNode) return true;
      return JSON.stringify(node) !== JSON.stringify(savedNode);
    });

    const changedEdges = edges.filter((edge) => {
      const savedEdge = lastSavedEdges.find((e) => e.id === edge.id);
      if (!savedEdge) return true;
      return JSON.stringify(edge) !== JSON.stringify(savedEdge);
    });

    const deletedNodeIds = lastSavedNodes
      .filter((savedNode) => !nodes.some((node) => node.id === savedNode.id))
      .map((node) => node.id);

    const deletedEdgeIds = lastSavedEdges
      .filter((savedEdge) => !edges.some((edge) => edge.id === savedEdge.id))
      .map((edge) => edge.id);

    return {
      id: reactFlowId!,
      nodes: changedNodes,
      edges: changedEdges,
      deletedNodeIds,
      deletedEdgeIds,
    };
  },
});

import { useEffect, useState } from 'react';

type TypewriterOptions = {
  words: string[];
  prefix?: string;
  typingSpeed?: number;
  deletingSpeed?: number;
  delayBetweenWords?: number;
};

export function useTypewriter({
  words,
  prefix = '',
  typingSpeed = 25,
  deletingSpeed = 25,
  delayBetweenWords = 1300,
}: TypewriterOptions) {
  const [text, setText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const currentWord = words[wordIndex];
    let timeout: ReturnType<typeof setTimeout>;
    const visiblePart = text.slice(prefix.length);

    if (isDeleting) {
      timeout = setTimeout(() => {
        setText(prefix + visiblePart.slice(0, -1));
      }, deletingSpeed);
    } else {
      timeout = setTimeout(() => {
        setText(prefix + currentWord.slice(0, visiblePart.length + 1));
      }, typingSpeed);
    }

    if (!isDeleting && visiblePart === currentWord) {
      timeout = setTimeout(() => setIsDeleting(true), delayBetweenWords);
    }

    if (isDeleting && visiblePart === '') {
      setIsDeleting(false);
      setWordIndex((prev) => (prev + 1) % words.length);
    }

    return () => clearTimeout(timeout);
  }, [text, isDeleting, wordIndex, words, prefix, typingSpeed, deletingSpeed, delayBetweenWords]);

  return text;
}

import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { Whiteboard } from '../types.ts';

export const useWhiteboards = (projectId: number) => {
  const { getFetcher } = useApi();

  const { data, ...rest } = useSWR<Whiteboard[]>(`/whiteboard/by-project/${projectId}`, getFetcher);

  return {
    whiteboards: data ? data.sort((a, b) => b.id - a.id) : data,
    ...rest,
  };
};

import { cn } from '@/helpers/cn';
import SearchIcon from '@assets/search-outline.svg';
import SidebarOffIcon from '@assets/sidebar-off.svg';
import SidebarOnIcon from '@assets/sidebar-on.svg';
import * as Collapsible from '@radix-ui/react-collapsible';

type ToolboxHeaderProps = {
  open: boolean;
  searchQuery: string;
  setOpen: (open: boolean) => void;
  setSearchQuery: (searchQuery: string) => void;
};

export function ToolboxHeader({ open, searchQuery, setOpen, setSearchQuery }: ToolboxHeaderProps) {
  return (
    <div className="flex items-center justify-between gap-2 pb-1">
      <div className="relative min-w-4 grow">
        <SearchIcon
          className={cn(
            'pointer-events-none absolute left-3 top-1/2 size-5 -translate-y-1/2 cursor-pointer',
            open ? 'path:fill-neutrals-600' : 'path:fill-neutrals-800',
          )}
        />
        <input
          type="text"
          placeholder="Search"
          className={cn(
            'rounded-lg border border-neutrals-300 py-2.5 pl-10 pr-4 text-sm',
            open ? 'w-full' : 'w-0 cursor-pointer border-transparent pl-4',
          )}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onFocus={() => setOpen(true)}
        />
      </div>
      <Collapsible.Trigger className="rounded-full p-2 hover:bg-neutrals-100">
        {open ? (
          <SidebarOffIcon className="path:fill-neutrals-800" />
        ) : (
          <SidebarOnIcon className="path:fill-neutrals-800" />
        )}
      </Collapsible.Trigger>
    </div>
  );
}

import { useApi } from '@/api/api.ts';

export const useGetToolOutputAsHTML = () => {
  const { getFetcher } = useApi();

  const getToolOutput = async (toolId: number) => {
    return getFetcher(`/tools/output-html/${toolId}`);
  };

  return {
    getToolOutput,
  };
};

import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createExecutionSlice, ExecutionSlice } from './execution.slice';
import { createGroupSlice, GroupSlice } from './group.slice';
import { createHistorySlice, HistorySlice } from './history.slice';
import { createNodeSlice, NodeSlice } from './node.slice';
import { createFlowSlice, ReactFlowSlice } from './react-flow.slice';
import { createValidationSlice, ValidationSlice } from './validation.slice';
import { createWhiteboardSlice, WhiteboardSlice } from './whiteboard.slice';

type WhiteboardStore = ReactFlowSlice &
  NodeSlice &
  ExecutionSlice &
  WhiteboardSlice &
  ValidationSlice &
  HistorySlice &
  GroupSlice;

export const useWhiteboardStore = create<WhiteboardStore>()(
  devtools((...a) => ({
    ...createFlowSlice(...a),
    ...createNodeSlice(...a),
    ...createExecutionSlice(...a),
    ...createWhiteboardSlice(...a),
    ...createValidationSlice(...a),
    ...createHistorySlice(...a),
    ...createGroupSlice(...a),
  })),
);

import { useCallback } from 'react';
import { DataRoomItemsTable } from './components/DataRoomItemsTable.tsx';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { useDataRoomId } from '@/state/app-store.ts';
import { useOnWebSocketMessage } from '@/hooks/useOnWebSocketMessage.ts';

import { mutate } from 'swr';
import { AddDataToDataRoomButton } from './components/AddDataToDataRoomButton.tsx';
import { CompanyCanvasesShortcuts } from './components/CompanyCanvasesShortcuts.tsx';
import DataDropFile from './components/DataDropFile.tsx';

export function DataPanel() {
  const dataRoomId = useDataRoomId();

  const refreshInputs = useCallback(async () => {
    await mutate(`/inputs/dataRoom/${dataRoomId}`);
  }, [dataRoomId]);

  useOnWebSocketMessage(`INPUT_PROCESSOR:${dataRoomId}`, refreshInputs);

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [{ label: 'Data Room', href: '#' }],
        icon: '/rows-black.svg',
        rightAddons: [<AddDataToDataRoomButton key="add-data-to-data-room-button" />],
      }}
    >
      <title>Digitalfirst.ai | Data room</title>

      <div>
        <section className="mb-8">
          <DataDropFile />
        </section>

        <section className="mb-10">
          <CompanyCanvasesShortcuts />
        </section>

        <section>
          <DataRoomItemsTable />
        </section>
      </div>
    </PageWithHeaderLayout>
  );
}

import { usePublicApi } from '@/api/public-api';
import useSWR from 'swr';
import { Whiteboard } from '../types';

export const usePublicWhiteboard = (
  uuid?: string,
): {
  whiteboard?: Whiteboard;
  isLoading: boolean;
  error?: unknown;
} => {
  const { publicGetFetcher } = usePublicApi();

  const { data, isLoading, error } = useSWR<Whiteboard>(uuid ? `/whiteboard/public/${uuid}` : null, publicGetFetcher);

  return {
    whiteboard: data,
    isLoading,
    error,
  };
};

import { Auth0Provider } from '@auth0/auth0-react';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { Flowbite } from 'flowbite-react';
import { dfFlowbiteTheme } from './dfFlowbiteTheme.const.ts';
import ReactDOM from 'react-dom/client';
import './index.scss';
import React, { useEffect } from 'react';
import { router } from './router.tsx';
import { Settings } from 'luxon';
import * as Sentry from '@sentry/react';
import { setGoogleTagManager } from './external-prod-scripts.tsx';
import { PostHogProvider } from 'posthog-js/react';

if (import.meta.env.VITE_NODE_ENV === 'production') {
  setGoogleTagManager();
}

if (import.meta.env.VITE_NODE_ENV !== 'local')
  Sentry.init({
    dsn: 'https://056e83c488b94906955934cfa653036c@sentry.seedigitalfirst.eu/13',
    environment: import.meta.env.VITE_NODE_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

Settings.defaultLocale = 'en';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Flowbite theme={dfFlowbiteTheme}>
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.href,
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          scope: 'openid profile email offline_access',
        }}
        cacheLocation="localstorage"
      >
        <PostHogProvider
          apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{ api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST }}
        >
          <RouterProvider router={router} />
        </PostHogProvider>
      </Auth0Provider>
    </Flowbite>
  </React.StrictMode>,
);

import { DialogContent } from '@/models/dialog-content.interface.ts';

export const DIALOGS: DfDialogs = {
  DeleteAddedDataConfirmation: {
    header: 'Are you sure you want to delete the added data?',
    confirmButtonText: 'Delete',
  },
  DeleteCanvas: {
    header: 'Are you sure you want to delete the canvas?',
    subheader: 'After this action the canvas cannot be restored',
    confirmButtonText: 'Delete',
  },
  DeleteProject: {
    header: 'Are you sure you want to delete this project?',
    confirmButtonText: 'Delete',
  },
  DeleteFlowTemplate: {
    header: 'Are you sure you want to delete this template?',
    confirmButtonText: 'Delete',
  },
  UnpinMyFlowFromProject: {
    header: 'Are you sure you want to delete this flow?',
    confirmButtonText: 'Delete',
  },
  DeleteWorkspace: {
    header: 'Are you sure you want to delete this workspace?',
    confirmButtonText: 'Delete',
  },
  DeleteWebSearcherRoom: {
    header: 'Are you sure you want to delete this web search?',
    confirmButtonText: 'Delete',
  },
  DeleteOrganization: {
    header: 'Are you sure you want to delete this organization?',
    confirmButtonText: 'Delete',
  },
  DeleteOrganizationMember: {
    header: 'Are you sure you want to delete this member?',
    confirmButtonText: 'Delete',
  },
  DeleteWorkspaceMember: {
    header: 'Are you sure you want to delete this member?',
    confirmButtonText: 'Delete',
  },
  DeleteSegment: {
    header: 'Are you sure you want to delete this segment?',
    confirmButtonText: 'Delete',
  },
  DeletePersona: {
    header: 'Are you sure you want to delete this persona?',
    confirmButtonText: 'Delete',
  },
  DeleteProductCanvas: {
    header: 'Are you sure you want to delete this product table?',
    confirmButtonText: 'Delete',
  },
  ChangeTypeOfTableColumn: {
    header: 'Are you sure you want to change the type of the column?',
    subheader: 'After this action all cells in this column will be reset',
    confirmButtonText: 'Change',
  },
  DeleteWhiteboard: {
    header: 'Are you sure you want to delete this whiteboard?',
    confirmButtonText: 'Delete',
  },
  DeleteWhiteboardTemplate: {
    header: 'Are you sure you want to delete this whiteboard template?',
    confirmButtonText: 'Delete',
  },
  DeleteAsset: {
    header: 'Are you sure you want to delete this asset?',
    confirmButtonText: 'Delete',
  },
};

export interface DfDialogs {
  DeleteAddedDataConfirmation: DialogContent;
  DeleteCanvas: DialogContent;
  DeleteProject: DialogContent;
  DeleteFlowTemplate: DialogContent;
  UnpinMyFlowFromProject: DialogContent;
  DeleteWorkspace: DialogContent;
  DeleteWebSearcherRoom: DialogContent;
  DeleteOrganization: DialogContent;
  DeleteOrganizationMember: DialogContent;
  DeleteWorkspaceMember: DialogContent;
  DeleteSegment: DialogContent;
  DeletePersona: DialogContent;
  DeleteProductCanvas: DialogContent;
  ChangeTypeOfTableColumn: DialogContent;
  DeleteWhiteboard: DialogContent;
  DeleteWhiteboardTemplate: DialogContent;
  DeleteAsset: DialogContent;
}

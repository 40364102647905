import { useApi } from '@/api/api';
import { ToolField } from 'src/libs/tools/type.model';
import { ExecutionEngineNode } from '../types';

type UpdateExecutionEngineNodePayload = {
  reactFlowNodeId: string;
  fields: ToolField[];
  execute?: boolean;
};

export const useUpdateExecutionEngineNode = () => {
  const { patchFetcher } = useApi();

  const updateFields = async (arg: UpdateExecutionEngineNodePayload): Promise<ExecutionEngineNode> => {
    return await patchFetcher(`/execution-engine-node/${arg.reactFlowNodeId}`, {
      arg: {
        fields: arg.fields,
        execute: !!arg.execute,
      },
    });
  };

  return {
    updateFields,
  };
};

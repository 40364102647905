import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { useInjectTemplateIntoWhiteboard } from '../api/useInjectTemplateIntoWhiteboard.ts';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { useSaveReactFlow } from './useSaveReactFlow';

export function useAddTemplate() {
  const { injectTemplateIntoWhiteboard } = useInjectTemplateIntoWhiteboard();
  const { closeModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { saveReactFlow } = useSaveReactFlow();

  const whiteboardId = useWhiteboardStore((state) => state.whiteboardId);
  const setupEENodesFromServer = useWhiteboardStore((state) => state.setupEENodesFromServer);
  const addTemplateNodes = useWhiteboardStore((state) => state.addTemplateNodes);
  const unselectNodes = useWhiteboardStore((state) => state.unselectNodes);
  const setShowToolbox = useWhiteboardStore((state) => state.setShowToolbox);

  async function addTemplate(template: WhiteboardTemplate) {
    if (!whiteboardId) return;

    showLoader();
    unselectNodes();

    await saveReactFlow();
    closeModal();

    const { whiteboard, reactFlowNodes, reactFlowEdges } = await injectTemplateIntoWhiteboard(whiteboardId, {
      templateId: template.id,
    });

    addTemplateNodes(reactFlowNodes, reactFlowEdges, template.name);

    setupEENodesFromServer(whiteboard.nodes);

    setShowToolbox(false);
    hideLoader();
    saveReactFlow();
  }
  return {
    addTemplate,
  };
}

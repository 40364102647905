import { Edge } from '@xyflow/react';
import { ToolField, ToolName } from 'src/libs/tools/type.model';
import { useUpdateExecutionEngineNode } from '../api/update-execution-engine-node';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { ExecutionEngineNodeStatus, ToolExecutedOutput } from '../types';
import { useAddNodes } from './useAddNode';

type NewNode = {
  id: string;
  name: string;
  toolName: ToolName;
  generationIntent?: string;
};

type UpdateNode = {
  id: string;
  fields?: ToolField[];
  status?: ExecutionEngineNodeStatus;
  output?: ToolExecutedOutput;
};

export function useGenerateNodes() {
  const { addNodes } = useAddNodes();
  const { updateFields } = useUpdateExecutionEngineNode();

  const updateEdge = useWhiteboardStore((state) => state.updateEdge);
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);
  const updateExecutionEngineNode = useWhiteboardStore((store) => store.updateExecutionEngineNode);

  function addNodesAndEdges(newNodes: NewNode[], edges: Edge[]) {
    addNodes({
      nodes: newNodes.map((newNode) => ({
        toolName: newNode.toolName,
        nodeName: newNode.name,
        id: newNode.id,
        generationIntent: newNode.generationIntent,
      })),
      edges,
    });
  }

  async function updateNodesAndEdges(nodes: UpdateNode[], edges: Edge[], execute?: boolean) {
    for (const node of nodes) {
      const { id, fields, ...nodeData } = node;

      if (fields) {
        const updatedExecutionEngineNode = await updateFields({
          reactFlowNodeId: id,
          fields: fields,
          execute: !!execute,
        });

        updateExecutionEngineNode(id, updatedExecutionEngineNode);
        updateNodeData(id, {
          toolInput: updatedExecutionEngineNode.tool.input,
          loading: false,
          valid: true,
          ...nodeData,
        });
      } else {
        updateNodeData(id, { loading: false, ...nodeData });
      }
    }
    edges.forEach((edge) => updateEdge(edge));
  }

  return { addNodesAndEdges, updateNodesAndEdges };
}

import { FileInput, Label, Select, TextInput } from 'flowbite-react';
import { ToolFieldInputType, ToolFormikFields } from './type.model';
import { PromptEditor, TOC } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';
import { useEffect, useState } from 'react';
import { FormikCheckboxWithLabel } from '@/components/FormikCheckboxWithLabel.tsx';
import { fieldCanBeRender } from './fieldCanBeRender';
import { AutoSizingTextarea } from '@/components/AutoSizingTextarea/AutoSizingTextarea';

export interface FormikDynamicFormItemProps {
  field: ToolFormikFields;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any;
  tiptap?: {
    turnOnTiptapInputAsPromptInput: boolean;
    toc: TOC;
  };
}

export const FormikDynamicFormItem = ({ field, formik, tiptap }: FormikDynamicFormItemProps) => {
  const [tiptapFieldValue, setTiptapFieldValue] = useState(formik.values[field.name]);
  const [renderField, setRenderField] = useState(true);

  useEffect(() => {
    if (!fieldCanBeRender(field, formik.values)) {
      setRenderField(false);
    } else {
      setRenderField(true);
    }
  }, [field, formik.values]);

  if (!renderField) return null;

  switch (field.type) {
    case ToolFieldInputType.TEXT:
      return (
        <div key={field.name}>
          <Label htmlFor={field.name}>{field.label}</Label>

          {tiptap?.turnOnTiptapInputAsPromptInput ? (
            <>
              <PromptEditor
                toc={tiptap.toc}
                initialContent={formik.values[field.name]}
                onContentChanged={(val) => {
                  setTiptapFieldValue(val);
                  formik.setFieldValue(field.name, val);
                }}
              />
              <input
                type="hidden"
                name={field.name}
                value={tiptapFieldValue}
                onChange={formik.handleChange}
                id={field.name}
              />
            </>
          ) : (
            <div className="flex w-full items-center">
              <AutoSizingTextarea
                id={field.name}
                name={field.name}
                onChange={formik.handleChange}
                defaultValue={formik.values[field.name]}
                disabled={field.disabled}
                className="block min-h-10 w-full overflow-hidden rounded-lg border border-gray-divider bg-white px-4 text-sm text-text-primary placeholder-text-tertiary !shadow-none !outline-none !ring-0 transition disabled:cursor-not-allowed disabled:bg-gray-bg disabled:text-text-secondary disabled:opacity-50"
              />
            </div>
          )}

          {formik.errors[field.name] && formik.touched[field.name] ? (
            <div className="mt-2 text-red-500">{formik.errors[field.name]}</div>
          ) : null}
        </div>
      );

    case ToolFieldInputType.NUMBER:
      return (
        <div key={field.name}>
          <Label htmlFor={field.name}>{field.label}</Label>
          <TextInput
            id={field.name}
            type="number"
            name={field.name}
            onChange={formik.handleChange}
            defaultValue={formik.values[field.name]}
            disabled={field.disabled}
          />
          {formik.errors[field.name] && formik.touched[field.name] ? (
            <div className="text-red-500">{formik.errors[field.name]}</div>
          ) : null}
        </div>
      );

    case ToolFieldInputType.SELECT:
      return (
        <div key={field.name}>
          <Label htmlFor={field.name}>{field.label}</Label>
          <Select
            id={field.name}
            name={field.name}
            onChange={formik.handleChange}
            defaultValue={formik.values[field.name]}
            disabled={field.disabled}
          >
            {field.options?.map((option) => (
              <option
                key={option}
                value={option}
              >
                {option}
              </option>
            ))}
          </Select>
          {formik.errors[field.name] && formik.touched[field.name] ? (
            <div className="text-red-500">{formik.errors[field.name]}</div>
          ) : null}
        </div>
      );

    case ToolFieldInputType.FILE:
      return (
        <div key={field.name}>
          <Label htmlFor={field.name}>{field.label}</Label>
          <FileInput
            id={field.name}
            name={field.name}
            onChange={(event) => {
              if (event.target.files) {
                const file = event.target.files[0];
                formik.setFieldValue(field.name, file);
                formik.setFieldValue('fileName', file.name);
                formik.setFieldValue('mimeType', file.type);
              }
            }}
            defaultValue={undefined}
            disabled={field.disabled}
          />
          {formik.errors[field.name] && formik.touched[field.name] ? (
            <div className="text-red-500">{formik.errors[field.name]}</div>
          ) : null}
        </div>
      );

    case ToolFieldInputType.CHECKBOX:
      return (
        <div key={field.name}>
          <FormikCheckboxWithLabel
            name={field.name}
            label={field.label}
            formik={formik}
            disabled={field.disabled}
          />
          {formik.errors[field.name] && formik.touched[field.name] ? (
            <div className="text-red-500">{formik.errors[field.name]}</div>
          ) : null}
        </div>
      );
    case ToolFieldInputType.HIDDEN:
      return (
        <div key={field.name}>
          <input
            id={field.name}
            type="hidden"
            name={field.name}
            onChange={formik.handleChange}
            value={formik.values[field.name]}
            disabled={field.disabled}
          />

          {formik.errors[field.name] && formik.touched[field.name] ? (
            <div className="mt-2 text-red-500">{formik.errors[field.name]}</div>
          ) : null}
        </div>
      );
  }
};

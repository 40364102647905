import { Checkbox, Label } from 'flowbite-react';
import { ReactNode } from 'react';
import { cn } from '@/helpers/cn.ts';

export interface CheckboxWithLabelProps {
  name: string;
  label: ReactNode;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  labelClassName?: string;
  checkboxSize?: 'size-3' | 'size-4' | 'size-5';
}

export const CheckboxWithLabel = ({
  name,
  label,
  disabled,
  onChange,
  checked,
  labelClassName,
  checkboxSize = 'size-5',
}: CheckboxWithLabelProps) => {
  return (
    <>
      <Label className={cn('inline-flex items-center', labelClassName)}>
        <Checkbox
          id={name}
          name={name}
          onChange={onChange}
          value={String(checked)}
          checked={checked}
          disabled={disabled}
          theme={{
            root: {
              base: `${checkboxSize} rounded border border-neutrals-400 !ring-0 !ring-offset-0 focus:!ring-0 focus:!ring-offset-0 focus:!shadow-none focus:!outline-0 focus:!outline-none`,
            },
          }}
        />
        <span className="ml-3 text-[0.875rem] font-medium leading-[1.1rem]">{label}</span>
      </Label>
    </>
  );
};

import { useReactFlow } from '@xyflow/react';
import { useEffect } from 'react';
import { useDataTransferNodesCreator } from './useDataTransferNodesCreator';
import { useMousePosition } from './useMousePosition';

export function useClipboardNodeCreator() {
  const { createNodesFromDataTransferItems } = useDataTransferNodesCreator();
  const { screenToFlowPosition } = useReactFlow();
  const mousePosition = useMousePosition();

  async function handlePaste(event: ClipboardEvent) {
    const target = event.target as HTMLElement;

    if (
      target.isContentEditable ||
      target.tagName === 'INPUT' ||
      target.tagName === 'TEXTAREA' ||
      target.closest('.react-flow__node') ||
      target.className.includes('ProseMirror')
    ) {
      return true;
    }

    const clipboardItems = event.clipboardData?.items;
    if (!clipboardItems) return;

    event.preventDefault();
    await createNodesFromDataTransferItems(clipboardItems, screenToFlowPosition(mousePosition));
  }

  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => document.removeEventListener('paste', handlePaste);
  }, [handlePaste]);
}

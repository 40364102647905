// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getAllKeysFromObject(obj: any, keysSet: Set<string> = new Set()): string[] {
  for (const key in obj) {
    keysSet.add(key);
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      getAllKeysFromObject(obj[key], keysSet);
    }
  }
  return Array.from(keysSet);
}

import { cn } from '@/helpers/cn.ts';
import { NodeToolbar, Position } from '@xyflow/react';
import { useCallback, useRef } from 'react';
import { setCurrentlyEditingToolId } from 'src/libs/tools/tool-output-editor.store.ts';
import { ToolConfig, ToolName, ToolOutputType } from 'src/libs/tools/type.model.ts';
import { isNodeConfigurable } from '../helpers/is-node-configurable.ts';
import { useOpenConfiguration } from '../hooks/useOpenConfiguration.tsx';
import { useWhiteboardStore } from '../store/whiteboard.store.ts';
import { ExecutionEngineNode, ToolNodeType } from '../types';
import { NodeInputSockets } from './sockets/NodeInputSockets.tsx';
import { NodeOutputSocket } from './sockets/NodeOutputSocket.tsx';
import { GenericToolNodeFooter } from './tools/generic/GenericToolNodeFooter.tsx';
import { GenericToolNodeHeader } from './tools/generic/GenericToolNodeHeader.tsx';
import { GenericToolNodeOutput } from './tools/generic/GenericToolNodeOutput.tsx';
import { ReaderTextOutput } from './tools/READER_TEXT/ReaderTextOutput.tsx';
import { RenderTextFooter } from './tools/READER_TEXT/RenderTextFooter.tsx';
import { RenderYouTubeOutput } from './tools/READER_YOUTUBE/RenderYouTubeOutput.tsx';
import { TemplatePublicFieldsConfig } from './tools/TemplatePublicFieldsConfig.tsx';

type ToolNodeContentProps = {
  id: ToolNodeType['id'];
  data: ToolNodeType['data'];
  toolConfigs: ToolConfig[];
  canExecute: boolean;
  executionEngineNode?: ExecutionEngineNode;
};

export function ToolNodeContent({ id, data, toolConfigs, executionEngineNode, canExecute }: ToolNodeContentProps) {
  const outputType = data.toolConfig.config.outputType;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { openConfiguration } = useOpenConfiguration(id, data, executionEngineNode!);
  const isConfigurable = isNodeConfigurable(data);
  const isEdgeConnecting = useWhiteboardStore((state) => state.isEdgeConnecting);

  const openConfigurationIfIsConfigurable = () => {
    if (isConfigurable) {
      openConfiguration();
    }
  };

  const renderHeader = useCallback(() => {
    switch (data.toolConfig.name) {
      default:
        return (
          <GenericToolNodeHeader
            nodeId={id}
            nodeData={data}
            canExecute={canExecute}
            status={data.status}
          />
        );
    }
  }, [data.toolConfig.name, data.loading, canExecute, data.name, data.status]);

  const renderOutput = useCallback(() => {
    if (!executionEngineNode || !toolConfigs) return null;

    switch (data.toolConfig.name) {
      case ToolName.READER_TEXT:
        return (
          <ReaderTextOutput
            nodeId={id}
            nodeData={data}
            executionEngineNode={executionEngineNode}
            toolConfigs={toolConfigs}
          />
        );
      case ToolName.READER_YOUTUBE:
        return (
          <RenderYouTubeOutput
            nodeId={id}
            nodeData={data}
            executionEngineNode={executionEngineNode}
            toolConfigs={toolConfigs}
          />
        );
      default:
        return (
          <GenericToolNodeOutput
            nodeId={id}
            nodeData={data}
            executionEngineNode={executionEngineNode}
            toolConfigs={toolConfigs}
            scrollContainerRef={containerRef}
          />
        );
    }
  }, [data.toolConfig.name, executionEngineNode, toolConfigs, data.loading, data.output]);

  const renderFooter = useCallback(() => {
    if (!executionEngineNode) return null;

    switch (data.toolConfig.name) {
      case ToolName.READER_TEXT:
        return (
          <RenderTextFooter
            nodeId={id}
            nodeData={data}
            executionEngineNode={executionEngineNode}
          />
        );
      default:
        return (
          <GenericToolNodeFooter
            nodeId={id}
            nodeData={data}
            executionEngineNode={executionEngineNode}
          />
        );
    }
  }, [data.toolConfig.name, data.loading, executionEngineNode, data.outputHistoryIndex]);

  return (
    <>
      <div>{renderHeader()}</div>

      <div
        className={cn(
          'relative h-auto grow overflow-auto rounded-b-xl p-4 opacity-100 transition has-[.nowheel]:!p-0',
          {
            'opacity-20': isEdgeConnecting,
          },
        )}
        ref={containerRef}
        onDoubleClick={(e) => {
          if (data.toolConfig.name === ToolName.READER_TEXT && executionEngineNode) {
            setCurrentlyEditingToolId(executionEngineNode.tool.id);
            return;
          }
          if (
            e.target === e.currentTarget ||
            !data.output ||
            data.toolConfig.config.outputType !== ToolOutputType.TEXT
          ) {
            openConfigurationIfIsConfigurable();
          }
        }}
      >
        {renderOutput()}
      </div>

      <div className="relative border-t-2 empty:hidden">{renderFooter()}</div>

      <NodeInputSockets
        nodeData={data}
        nodeId={id}
      />

      <NodeOutputSocket
        nodeId={id}
        socketType={outputType}
      />

      <NodeToolbar position={Position.Bottom}>
        <TemplatePublicFieldsConfig
          nodeData={data}
          nodeId={id}
        />
      </NodeToolbar>
    </>
  );
}

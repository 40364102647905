import { XYPosition } from '@xyflow/react';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { ToolName } from 'src/libs/tools/type.model';
import { NODE_DIMENSIONS, NODE_GAP } from '../consts/whiteboard.const';
import {
  getImageMimeTypes,
  getTextFileMimeTypes,
  isImageUrl,
  isInstagramUrl,
  isRedditUrl,
  isValidUrl,
  isYouTubeUrl,
  isYouTubeVideoUrl,
} from '../helpers/data-transfer-helpers';
import { NewNodeParams, useAddNodes } from './useAddNode';
import { useNodeParamsFromDataTransferItem } from './useNodeParamsFromDataTransferItem';
import { useNodeRegistry } from './useNodeRegistry';

export enum DataItemType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  URL = 'URL',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  YOUTUBE = 'YOUTUBE',
  INSTAGRAM = 'INSTAGRAM',
  REDDIT = 'REDDIT',
  TEXT_FILE = 'TEXT_FILE',
}

export const dataItemTypeToToolMap = {
  [DataItemType.TEXT]: {
    toolName: ToolName.READER_TEXT,
    inputKey: 'content',
  },
  [DataItemType.IMAGE]: {
    toolName: ToolName.READER_IMAGE,
    inputKey: 'content',
  },
  [DataItemType.URL]: {
    toolName: ToolName.CRAWLER_CRAWL,
    inputKey: 'url',
  },
  [DataItemType.TEXT_FILE]: {
    toolName: ToolName.READER_TEXT_FILE,
    inputKey: 'base64Content',
  },
  [DataItemType.YOUTUBE_VIDEO]: {
    toolName: ToolName.READER_YOUTUBE,
    inputKey: 'url',
  },
  [DataItemType.YOUTUBE]: {
    toolName: ToolName.YOUTUBE_SCRAPPER,
    inputKey: 'startUrls',
  },

  [DataItemType.INSTAGRAM]: {
    toolName: ToolName.INSTAGRAM_SCRAPPER,
    inputKey: 'url',
  },
  [DataItemType.REDDIT]: {
    toolName: ToolName.REDDIT_SCRAPPER,
    inputKey: 'url',
  },
} as const;

export const useDataTransferNodesCreator = () => {
  const { nodeParamsFromDataTransferItem } = useNodeParamsFromDataTransferItem();
  const { nodeConfigs } = useNodeRegistry();
  const { addNodes } = useAddNodes();

  const supportedMimeTypes = useMemo(
    () => ({
      textFile: getTextFileMimeTypes(nodeConfigs),
      image: getImageMimeTypes(nodeConfigs),
    }),
    [nodeConfigs],
  );

  const createNodesFromDataTransferItems = async (items: DataTransferItemList, position: XYPosition) => {
    const itemsToProcess: {
      item: string | File;
      dataItemType: DataItemType;
    }[] = [];

    for (const item of Array.from(items)) {
      if (supportedMimeTypes.image.includes(item.type)) {
        const file = item.getAsFile();
        if (file) {
          itemsToProcess.push({ item: file, dataItemType: DataItemType.IMAGE });
          continue;
        }
      }
      if (supportedMimeTypes.textFile.includes(item.type)) {
        const file = item.getAsFile();
        if (file) {
          itemsToProcess.push({ item: file, dataItemType: DataItemType.TEXT_FILE });
          continue;
        }
      }
      if (item.type === 'text/plain') {
        const text = await new Promise<string>((resolve) => {
          item.getAsString((text) => {
            resolve(text);
          });
        });
        if (isImageUrl(text)) {
          itemsToProcess.push({ item: text, dataItemType: DataItemType.TEXT });
          continue;
        }
        if (isYouTubeVideoUrl(text)) {
          itemsToProcess.push({ item: text, dataItemType: DataItemType.YOUTUBE_VIDEO });
          continue;
        }
        if (isYouTubeUrl(text)) {
          itemsToProcess.push({ item: text, dataItemType: DataItemType.YOUTUBE });
          continue;
        }
        if (isInstagramUrl(text)) {
          itemsToProcess.push({ item: text, dataItemType: DataItemType.INSTAGRAM });
          continue;
        }
        if (isRedditUrl(text)) {
          itemsToProcess.push({ item: text, dataItemType: DataItemType.REDDIT });
          continue;
        }
        if (isValidUrl(text)) {
          itemsToProcess.push({ item: text, dataItemType: DataItemType.URL });
          continue;
        }
        itemsToProcess.push({ item: text, dataItemType: DataItemType.TEXT });
        continue;
      }
    }
    if (itemsToProcess.length === 0) {
      toast.error('Unsupported file');
      return;
    }

    const nodesParams: NewNodeParams[] = [];
    for (const [index, { item, dataItemType }] of itemsToProcess.entries()) {
      const offsetPosition = {
        x: position.x + (NODE_DIMENSIONS.width + NODE_GAP) * index,
        y: position.y,
      };
      const nodeParams = await nodeParamsFromDataTransferItem(item, dataItemType);

      if (nodeParams) {
        nodesParams.push({ ...nodeParams, position: offsetPosition });
      }
    }
    await addNodes({ nodes: nodesParams });
  };

  return { createNodesFromDataTransferItems };
};

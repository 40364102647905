import { useApi } from '@/api/api';
import useSWR, { mutate } from 'swr';
import { ExecutionEngineNode } from '../types';

export const useWhiteboardNodes = (
  whiteboardId?: number,
): {
  executionEngineNodes?: ExecutionEngineNode[];
  isLoading: boolean;
  error?: unknown;
} => {
  const { getFetcher } = useApi();

  const { data, isLoading, error } = useSWR<ExecutionEngineNode[]>(
    whiteboardId ? `/whiteboard/${whiteboardId}/nodes` : null,
    getFetcher,
  );

  return {
    executionEngineNodes: data,
    isLoading,
    error,
  };
};

export const mutateWhiteboardNodes = (whiteboardId?: number) => {
  if (!whiteboardId) return;
  return mutate(`/whiteboard/${whiteboardId}/nodes`);
};

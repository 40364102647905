import { Dropdown } from 'flowbite-react';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { ReactElement } from 'react';
import { useRemoveResource } from '@/api/data-room/remove-resource.ts';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import RegenerateIcon from '@assets/regenerate.svg';
import EditIcon from '@assets/edit.svg';
import { useRetryProcessResource } from '@/api/data-room/retry-process-resource';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';
export interface DropdownDataRoomItemOptionsProps {
  children: ReactElement;
  item: DataRoomItem;
  onPreview?: () => void;
}

export function DropdownDataRoomItemOptions({ children, item, onPreview }: DropdownDataRoomItemOptionsProps) {
  const { removeResource } = useRemoveResource();
  const { openDialog } = useGlobalDialog();
  const { openModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { retryProcessResource } = useRetryProcessResource();

  return (
    <>
      <Dropdown
        label=""
        renderTrigger={() => children}
        onClick={(e) => e.stopPropagation()}
      >
        {onPreview && item.isParsed && !item.isErrored && (
          <Dropdown.Item onClick={onPreview}>
            <img
              src="/preview-gray.svg"
              className="mr-2 size-4"
              alt=""
            />
            Preview
          </Dropdown.Item>
        )}

        {item.isParsed && (
          <Dropdown.Item onClick={() => openModal(MODALS.DataRoomRenameResource, { item })}>
            <EditIcon />
            <span>Rename</span>
          </Dropdown.Item>
        )}

        {item.isErrored && (
          <Dropdown.Item onClick={() => retryProcessResource(item.id)}>
            <RegenerateIcon />
            <span>Try again</span>
          </Dropdown.Item>
        )}

        <Dropdown.Item
          onClick={() => {
            openDialog(DIALOGS.DeleteAddedDataConfirmation, async () => {
              showLoader('Deleting added data...');
              await removeResource(item.id);
              hideLoader();
            });
          }}
        >
          <img
            src="/trash-gray.svg"
            className="mr-2"
            alt=""
          />
          Delete
        </Dropdown.Item>
      </Dropdown>
    </>
  );
}

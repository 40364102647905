import { useRenameResource } from '@/api/data-room/rename-resource';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props';
import { Button, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';

import { object, string } from 'yup';

export const DataRoomRenameResourceModalBody = () => {
  const { renameResource } = useRenameResource();
  const { closeModal, context } = useGlobalModal<{ item: { id: string; extension?: string } }>();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (data) => {
      const newName = context?.item?.extension ? `${data.name}.${context.item.extension}` : data.name;
      await renameResource(context.item.id, newName);
      closeModal();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          {...getDfFlowbiteFieldProps('name', formik)}
          placeholder="Project name"
        />

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Save changes
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

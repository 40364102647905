import { useApi } from '@/api/api';

export const useStopExecutionEngine = () => {
  const { postFetcher } = useApi();
  const stopExecutionEngine = (whiteboardId: number): Promise<void> => {
    return postFetcher(`execution-engine/stop/whiteboard/${whiteboardId}`, {
      arg: {},
    });
  };
  return {
    stopExecutionEngine,
  };
};

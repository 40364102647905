import { useApi } from '../api.ts';
import { Organization } from '@/models/organization.interface.ts';
import useSWRImmutable from 'swr/immutable';

export const useOrganization = () => {
  const { getFetcher } = useApi({ silent: true });

  const { data, isLoading, error } = useSWRImmutable<Organization | null>('/organization', getFetcher);

  return {
    organization: data,
    isLoading,
    error,
  };
};

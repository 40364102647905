import { Connection, Edge, EdgeChange, NodeChange, ReactFlow, ReactFlowInstance } from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { memo, useCallback, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { nodeTypes } from '../consts/whiteboard.const';
import { useClipboardNodeCreator } from '../hooks/useClipboardNodeCreator';
import { useDropNodeCreator } from '../hooks/useDropNodeCreator';
import { useDuplicateNode } from '../hooks/useDuplicateNode';
import { useSetLayout } from '../hooks/useLayout';
import { useDebouncedSaveReactFlow } from '../hooks/useSaveReactFlow';
import { PaddingEdge } from '../PaddingEdge';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { NodeType } from '../types';
import Background from './Background';
import { ControlsPanel } from './Panels/ControlsPanel';
import Toolbox from './Toolbox/Toolbox';
import { AiAssistantPanel } from './Panels/AiAssistantPanel.tsx';

function WhiteboardShell() {
  const { saveReactFlow } = useDebouncedSaveReactFlow();
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onConnectStart,
    onConnectEnd,
    isValidConnection,
    setReactFlowInstance,
    shouldSave,
  } = useWhiteboardStore(
    useShallow((state) => ({
      nodes: state.nodes,
      edges: state.edges,
      onNodesChange: state.onNodesChange,
      onEdgesChange: state.onEdgesChange,
      onConnect: state.onConnect,
      onConnectStart: state.onConnectStart,
      onConnectEnd: state.onConnectEnd,
      isValidConnection: state.isValidConnection,
      setReactFlowInstance: state.setReactFlowInstance,
      shouldSave: state.shouldSave,
    })),
  );

  useEffect(() => {
    if (shouldSave) {
      saveReactFlow();
    }
  }, [shouldSave]);

  useClipboardNodeCreator();
  useDuplicateNode();
  useSetLayout();
  const handleDrop = useDropNodeCreator();

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleInit = useCallback((reactFlowInstance: ReactFlowInstance<NodeType>) => {
    setReactFlowInstance(reactFlowInstance);
  }, []);

  const handleNodeChange = useCallback((changes: NodeChange<NodeType>[]) => {
    onNodesChange(changes);
    const skipOnTypes = new Set(['select']);

    if (changes.some((change) => !skipOnTypes.has(change.type))) {
      saveReactFlow();
    }
  }, []);

  const handleOnConnect = useCallback((connection: Connection) => {
    onConnect(connection);
    saveReactFlow();
  }, []);

  const handleEdgeChange = useCallback((changes: EdgeChange<Edge>[]) => {
    onEdgesChange(changes);
    if (changes.some((change) => change.type === 'remove')) {
      saveReactFlow();
    }
  }, []);

  return (
    <div className="flex h-full w-full">
      <ReactFlow
        className="rounded-xl"
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        edgeTypes={{
          default: PaddingEdge,
        }}
        onNodesChange={handleNodeChange}
        onEdgesChange={handleEdgeChange}
        onConnect={handleOnConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        deleteKeyCode={['Backspace', 'Delete']}
        snapToGrid
        maxZoom={2}
        minZoom={0.1}
        onInit={handleInit}
        isValidConnection={isValidConnection}
        fitView
        fitViewOptions={{ maxZoom: 1, minZoom: 0.1, padding: 0.3 }}
      >
        <Toolbox />
        <Background />
        <ControlsPanel />
        <AiAssistantPanel />
      </ReactFlow>
    </div>
  );
}

export default memo(WhiteboardShell);

import { WhiteboardTemplate } from '@/models/whiteboard-template';
import toast from 'react-hot-toast';
import { NodeTypesEnum } from '../types';

export function getTemplateTools(template: WhiteboardTemplate) {
  const nodes = template.whiteboard.reactFlow.nodes.filter((node) => node.type === NodeTypesEnum.Tool);

  return nodes.map((node) => node.data.toolConfig);
}

export function copyTemplateUrl(template: WhiteboardTemplate) {
  if (!template.uuid) return;
  navigator.clipboard.writeText(`${window.location.origin}/public-app/templates/${template.uuid}`);
  toast.success('Template URL copied to clipboard');
}

import { llmMarked } from '@/helpers/llmMarked.ts';
import { NodeProps } from '@xyflow/react';
import { useEffect } from 'react';
import { setCurrentlyEditingToolId, useToolOutputEditorStore } from 'src/libs/tools/tool-output-editor.store';
import { ToolConfig } from 'src/libs/tools/type.model';
import { ExecutionEngineNode, NodeData } from 'src/routes/whiteboards/types';
import { ToolTextOutputContainerClassName } from '../generic/GenericToolNodeOutput';
import { ReaderTextForm } from './ReaderTextForm';

type ReaderTextOutputProps = {
  nodeId: NodeProps['id'];
  nodeData: NodeData;
  executionEngineNode: ExecutionEngineNode;
  toolConfigs: ToolConfig[];
};

export function ReaderTextOutput({ nodeId, nodeData, executionEngineNode, toolConfigs }: ReaderTextOutputProps) {
  useEffect(() => {
    if (nodeData.isNewlyAdded && !nodeData.output && !nodeData.loading) {
      setCurrentlyEditingToolId(executionEngineNode.tool.id);
    }
  }, [nodeData.loading]);

  const isEditing = useToolOutputEditorStore((store) => store.currentlyEditingToolId === executionEngineNode.tool.id);

  return (
    <div className={ToolTextOutputContainerClassName}>
      {isEditing ? (
        <ReaderTextForm
          reactFlowNodeId={nodeId}
          nodeData={nodeData}
          toolConfigs={toolConfigs}
        />
      ) : (
        <>
          {!nodeData.output ? (
            <span className="text-neutrals-600">Double click to edit...</span>
          ) : (
            <p
              className="ProseMirror !p-0"
              dangerouslySetInnerHTML={{ __html: llmMarked(nodeData.output as string) }}
            ></p>
          )}
        </>
      )}
    </div>
  );
}

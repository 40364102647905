import { cn } from '@/helpers/cn';
import { Slot } from '@radix-ui/react-slot';
import { Surface } from '../Surface';

export type PanelProps = {
  selected?: boolean;
  noShadow?: boolean;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const Panel = ({
  ref,
  asChild,
  className,
  children,
  selected,
  noShadow,
  ...rest
}: PanelProps & {
  ref?: React.RefObject<HTMLDivElement>;
}) => {
  const panelClass = cn('', className);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      ref={ref}
      {...rest}
    >
      <Surface
        className={panelClass}
        withShadow={!noShadow}
        selected={selected}
      >
        {children}
      </Surface>
    </Comp>
  );
};

Panel.displayName = 'Panel';

export const PanelDivider = ({
  ref,
  asChild,
  className,
  children,
  ...rest
}: {
  ref?: React.RefObject<HTMLDivElement>;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const dividerClass = cn('border-b border-b-black/10 mb-2 pb-2', className);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={dividerClass}
      {...rest}
      ref={ref}
    >
      {children}
    </Comp>
  );
};

PanelDivider.displayName = 'PanelDivider';

export const PanelHeader = ({
  ref,
  asChild,
  className,
  children,
  ...rest
}: {
  ref?: React.RefObject<HTMLDivElement>;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const headerClass = cn('border-b border-b-black/10 text-sm mb-2 pb-2', className);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={headerClass}
      {...rest}
      ref={ref}
    >
      {children}
    </Comp>
  );
};

PanelHeader.displayName = 'PanelHeader';

export const PanelSection = ({
  ref,
  asChild,
  className,
  children,
  ...rest
}: {
  ref?: React.RefObject<HTMLDivElement>;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const sectionClass = cn('mt-4 first:mt-1', className);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={sectionClass}
      {...rest}
      ref={ref}
    >
      {children}
    </Comp>
  );
};

PanelSection.displayName = 'PanelSection';

export const PanelHeadline = ({
  ref,
  asChild,
  className,
  children,
  ...rest
}: {
  ref?: React.RefObject<HTMLDivElement>;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const headlineClass = cn('text-zinc-600 text-sm font-medium px-4 py-2.5 bg-slate-50 rounded-t-lg', className);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={headlineClass}
      {...rest}
      ref={ref}
    >
      {children}
    </Comp>
  );
};

PanelHeadline.displayName = 'PanelHeadline';

export const PanelFooter = ({
  ref,
  asChild,
  className,
  children,
  ...rest
}: {
  ref?: React.RefObject<HTMLDivElement>;
  asChild?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) => {
  const footerClass = cn('border-t border-black/10 text-sm mt-2 pt-2', className);

  const Comp = asChild ? Slot : 'div';

  return (
    <Comp
      className={footerClass}
      {...rest}
      ref={ref}
    >
      {children}
    </Comp>
  );
};

PanelFooter.displayName = 'PanelFooter';

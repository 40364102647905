import { useParams } from 'react-router-dom';
import { Dropdown } from 'flowbite-react';
import { ReactElement } from 'react';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { useDeleteWhiteboard } from 'src/routes/whiteboards/api/delete-whiteboard';
import { Whiteboard } from '../../../../whiteboards/types.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useDuplicateWhiteboard } from 'src/routes/whiteboards/api/duplicate-whiteboard.ts';
import CopyIcon from '@assets/copy.svg';
import { mutate } from 'swr';

export function DropdownWhiteboardOptions({
  children,
  whiteboard,
}: {
  children: ReactElement;
  whiteboard: Whiteboard;
}) {
  const { projectId } = useParams();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { openDialog } = useGlobalDialog();
  const { openModal } = useGlobalModal();
  const { deleteWhiteboard } = useDeleteWhiteboard(Number(projectId));
  const { duplicateWhiteboard } = useDuplicateWhiteboard();

  const onRenameClick = () => {
    openModal(MODALS.EditWhiteboard, whiteboard);
  };

  const onDeleteClick = () => {
    openDialog(DIALOGS.DeleteWhiteboard, async () => {
      showLoader();
      await deleteWhiteboard(whiteboard.id);
      hideLoader();
    });
  };

  const onDuplicateClick = async () => {
    showLoader();
    await duplicateWhiteboard(whiteboard.id);
    await mutate(`/whiteboard/by-project/${projectId}`);
    hideLoader();
  };

  return (
    <>
      <Dropdown
        label=""
        onClick={(e) => e.stopPropagation()}
        renderTrigger={() => <div>{children}</div>}
        placement="bottom-end"
        className="w-[200px]"
      >
        <Dropdown.Item onClick={() => onRenameClick()}>
          <img
            src="/pencil-black.svg"
            alt=""
          />
          Rename
        </Dropdown.Item>

        <Dropdown.Item onClick={onDeleteClick}>
          <img
            src="/delete-black.svg"
            alt=""
          />
          Delete
        </Dropdown.Item>
        <Dropdown.Item onClick={onDuplicateClick}>
          <CopyIcon />
          Duplicate
        </Dropdown.Item>
      </Dropdown>
    </>
  );
}

import { cn } from '@/helpers/cn';
import * as Collapsible from '@radix-ui/react-collapsible';
import { Panel } from '@xyflow/react';
import { memo, useState } from 'react';
import { useNodeRegistry } from '../../hooks/useNodeRegistry';
import { useWhiteboardStore } from '../../store/whiteboard.store';
import { ToolboxFiles } from './ToolboxFiles';
import { ToolboxHeader } from './ToolboxHeader';
import { ToolboxTemplates } from './ToolboxTemplates';
import { ToolboxTools } from './ToolboxTools';
import { ToolboxWebSearcher } from './ToolboxWebSearcher';
import { ToolboxAssetCategoryList } from './ToolboxAssetCategoryList.tsx';
import { AssetCategory } from '@/models/asset.model.ts';
import { useSemrush } from '@/state/semrush.store.ts';

function Toolbox() {
  const { nodeConfigs } = useNodeRegistry();
  const [searchQuery, setSearchQuery] = useState('');
  const showToolbox = useWhiteboardStore((state) => state.showToolbox);
  const setShowToolbox = useWhiteboardStore((state) => state.setShowToolbox);
  const { isSemrush } = useSemrush();

  return (
    <Panel
      position="top-left"
      className="!m-4"
    >
      <Collapsible.Root
        open={showToolbox}
        onOpenChange={setShowToolbox}
      >
        <div
          className={cn(
            'flex max-h-[calc(100vh-108px)] shrink-0 flex-col rounded-xl border border-neutrals-300 bg-white p-3 shadow-card',
            showToolbox ? 'h-[calc(100vh-108px)]' : '',
          )}
        >
          <ToolboxHeader
            open={showToolbox}
            searchQuery={searchQuery}
            setOpen={setShowToolbox}
            setSearchQuery={setSearchQuery}
          />

          <Collapsible.Content asChild>
            <div className="w-72 flex-1 overflow-y-auto scrollbar-hide">
              <ToolboxTemplates />
              <ToolboxTools
                nodeConfigs={nodeConfigs}
                searchQuery={searchQuery}
                open={!!searchQuery}
              />
              {!isSemrush && <ToolboxWebSearcher nodeConfigs={nodeConfigs} />}
              <ToolboxFiles
                searchQuery={searchQuery}
                nodeConfigs={nodeConfigs}
              />
              {Object.values(AssetCategory).map((category) => (
                <ToolboxAssetCategoryList
                  key={category}
                  searchQuery={searchQuery}
                  nodeConfigs={nodeConfigs}
                  category={category}
                />
              ))}
            </div>
          </Collapsible.Content>
        </div>
      </Collapsible.Root>
    </Panel>
  );
}

export default memo(Toolbox);

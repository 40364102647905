export enum LangChainModel {
  // Groq
  Groq = 'groq',

  // Moonshot - not implemented
  // MoonshotV18k = 'moonshot-v1-8k',
  // MoonshotV132k = 'moonshot-v1-32k',
  // MoonshotV1128k = 'moonshot-v1-128k',

  // Anthropic
  claude37SonnetLatest = 'claude-3-7-sonnet-latest',
  claude35HaikuLatest = 'claude-3-5-haiku-latest',
  claude35Sonnet20240620 = 'claude-3-5-sonnet-20240620',
  claude3Opus20240229 = 'claude-3-opus-20240229',
  claude3Sonnet20240229 = 'claude-3-sonnet-20240229',
  claude3Haiku20240307 = 'claude-3-haiku-20240307',

  // MistralAI
  OpenMistral7b = 'open-mistral-7b',
  OpenMixtral8x7b = 'open-mixtral-8x7b',
  OpenMixtral8x22b = 'open-mixtral-8x22b',
  MistralSmallLatest = 'mistral-small-latest',
  MistralMediumLatest = 'mistral-medium-latest',
  MistralLargeLatest = 'mistral-large-latest',
  MistralEmbed = 'mistral-embed',
  CodestralLatest = 'codestral-latest',

  // OpenAI
  gpt35Turbo16k = 'gpt-3.5-turbo-16k',
  // Gpt35Turbo16k0613 = 'gpt-3.5-turbo-16k-0613',
  // Gpt35Turbo0613 = 'gpt-3.5-turbo-0613',
  // Gpt35Turbo0125 = 'gpt-3.5-turbo-0125',
  // Gpt35TurboInstruct = 'gpt-3.5-turbo-instruct',
  // Gpt35TurboInstruct0914 = 'gpt-3.5-turbo-instruct-0914',
  // Gpt35Turbo0301 = 'gpt-3.5-turbo-0301',
  // Gpt35Turbo1106 = 'gpt-3.5-turbo-1106',
  gpt4 = 'gpt-4',
  o1 = 'o1',
  o3Mini = 'o3-mini',
  // Gpt40613 = 'gpt-4-0613',
  // Gpt40125Preview = 'gpt-4-0125-preview',
  // Gpt41106Preview = 'gpt-4-1106-preview',
  gpt4Turbo = 'gpt-4-turbo',
  // Gpt4TurboPreview = 'gpt-4-turbo-preview',
  // Gpt4Turbo20240409 = 'gpt-4-turbo-2024-04-09',
  gpt4o = 'gpt-4o',
  // Gpt4o20240513 = 'gpt-4o-2024-05-13',
  gpt4o20241120 = 'gpt-4o-2024-11-20',

  gpt4oMini = 'gpt-4o-mini',
  o1Preview = 'o1-preview',
  o1Mini = 'o1-mini',
}

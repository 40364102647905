import { NodeTypes } from '@xyflow/react';
import GroupNode from '../nodes/GroupNode';
import StickyNoteNode from '../nodes/StickyNoteNode';
import ToolNode from '../nodes/ToolNode';

export const nodeTypes = {
  Tool: ToolNode,
  StickyNote: StickyNoteNode,
  Group: GroupNode,
} as NodeTypes;

export const TOOLBOX_WIDTH = 314;

export const NODE_DIMENSIONS = { width: 500, height: 500 };

export const GROUP_DIMENSIONS = { width: 700, height: 700 };

export const NODE_RESIZE_CONSTRAINTS = {
  minWidth: 300,
  minHeight: 200,
};

export const STICKY_NOTE_DIMENSIONS = { width: 200, height: 200 };
export const STICKY_NOTE_RESIZE_CONSTRAINTS = { minWidth: 100, minHeight: 50 };

export const NODE_GAP = 50;

export const STICKY_NOTE_COLORS = [
  'bg-yellow-100',
  'bg-green-100',
  'bg-blue-100',
  'bg-red-100',
  'bg-purple-100',
  'bg-pink-100',
  'bg-orange-100',
  'bg-teal-100',
];

export const GROUP_COLORS = [
  'bg-neutrals-300',
  'bg-green-300',
  'bg-blue-300',
  'bg-yellow-300',
  'bg-purple-300',
  'bg-pink-300',
  'bg-orange-300',
  'bg-teal-300',
  'bg-cyan-300',
  'bg-indigo-300',
];

export const SOCKET_PADDING = 60;

import { useApi } from '@/api/api';
import { ToolField, ToolName } from 'src/libs/tools/type.model';
import { ExecutionEngineNode, ToolExecutedOutput } from '../types';

export type CreateExecutionEngineNodePayload = {
  toolName: ToolName;
  whiteboardId: number;
  reactFlowNodeId: string;
  fields?: ToolField[];
  execute?: boolean;
  output?: ToolExecutedOutput;
};

export const useCreateExecutionEngineNode = () => {
  const { postFetcher } = useApi();
  const createExecutionEngineNode = (arg: CreateExecutionEngineNodePayload): Promise<ExecutionEngineNode> => {
    return postFetcher('/execution-engine-node', {
      arg,
    });
  };
  return {
    createExecutionEngineNode,
  };
};

export const useCreateExecutionEngineNodes = () => {
  const { postFetcher } = useApi();
  const createExecutionEngineNodes = (arg: CreateExecutionEngineNodePayload[]): Promise<ExecutionEngineNode[]> => {
    return postFetcher('/execution-engine-node/create-many', {
      arg,
    });
  };
  return {
    createExecutionEngineNodes,
  };
};

import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { DataRoomItemType } from '@/enums/data-room-item.enum.ts';
import { useGenerateInputFileUrl } from '@/api/data-room/generate-input-file-url.ts';
import { DataRoomFileTypes } from '@/consts/data-room-file-types.const.ts';
import { marked } from 'marked';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
export function AddedDataItemPreview({ item }: { item: DataRoomItem }) {
  const [textFileContent, setTextFileContent] = useState<string | null>(null);
  const [itemUrl, setItemUrl] = useState<string>('');
  const { generateInputFileUrl } = useGenerateInputFileUrl();

  useEffect(() => {
    if (DataRoomFileTypes.includes(item.type)) {
      generateInputFileUrl(item.id).then((url) => {
        setItemUrl(url);
      });
    } else {
      // website links case
      setItemUrl(item.url);
    }
  }, []);

  const previewElement = useMemo(() => {
    switch (item.type) {
      case DataRoomItemType.WEBSITE:
        return (
          <iframe
            src={itemUrl}
            height="100%"
            width="100%"
          />
        );
      case DataRoomItemType.TEXT:
        axios.get(itemUrl).then((response) => {
          setTextFileContent(response.data);
        });
        return;
      case DataRoomItemType.DOCUMENT:
        if (item.mimeType?.includes('officedocument') || item.mimeType?.includes('ms-excel'))
          return (
            <DocViewer
              pluginRenderers={DocViewerRenderers}
              documents={[{ uri: itemUrl, fileType: 'doc' }]}
              style={{ width: '100%', height: '100%' }}
              config={{ header: { disableHeader: true } }}
            />
          );

        return (
          itemUrl && (
            <iframe
              src={itemUrl}
              height="100%"
              width="100%"
            ></iframe>
          )
        );

      case DataRoomItemType.AUDIO:
        return (
          <audio
            controls
            src={itemUrl}
          />
        );
      case DataRoomItemType.VIDEO:
        return (
          <video
            controls
            width="100%"
            height="100%"
            src={itemUrl}
          />
        );
    }
  }, [item.type, itemUrl]);

  return (
    <div className="flex size-full items-center justify-center p-4">
      {previewElement}
      {textFileContent && (
        <pre className="w-full self-start text-wrap text-body-md">{marked(textFileContent) as string}</pre>
      )}
    </div>
  );
}

import { useApi } from '@/api/api';
import { Whiteboard } from '../types';

export const useCreateWhiteboard = (): {
  createWhiteboard: (projectId: string, name: string) => Promise<Whiteboard>;
} => {
  const { postFetcher } = useApi();
  const createWhiteboard = async (projectId: string, name: string): Promise<Whiteboard> => {
    return await postFetcher('/whiteboard', {
      arg: {
        projectId: Number(projectId),
        name,
      },
    });
  };
  return {
    createWhiteboard,
  };
};

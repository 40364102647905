import { WhiteboardTemplateCategory, WhiteboardTemplateScope } from '@/models/whiteboard-template';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useCreateWhiteboardTemplate } from '../../api/templates/create-whiteboard-template';
import { useWhiteboardStore } from '../../store/whiteboard.store';
import { WhiteboardTemplateForm, WhiteboardTemplatePayload } from './TemplateForm';

export function CreateWhiteboardTemplateModal() {
  const { createWhiteboardTemplate } = useCreateWhiteboardTemplate();
  const whiteboardId = useWhiteboardStore((state) => state.whiteboardId);
  const whiteboardName = useWhiteboardStore((state) => state.whiteboardName);
  const navigate = useNavigate();

  async function handleSubmit(values: WhiteboardTemplatePayload) {
    const template = await createWhiteboardTemplate({
      ...values,
      whiteboardId: whiteboardId!,
    });
    if (template.scope === WhiteboardTemplateScope.PUBLIC) {
      navigate(`/whiteboard/${template.whiteboard.id}`);
    }
    toast.success('Template created successfully');
  }

  const initialValues = {
    whiteboardId: whiteboardId!,
    name: whiteboardName ?? '',
    category: WhiteboardTemplateCategory.MY,
    public: false,
  };

  return (
    <WhiteboardTemplateForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      submitLabel="Create template"
    />
  );
}

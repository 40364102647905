import { ForClientFeature } from '@/enums/for-client-feature.enum';
import { useAuth } from './auth/useAuth';
import { useOrganization } from '@/api/organization/get-organization';

export const useCanUseForClientFeature = (featureName: ForClientFeature) => {
  const { user } = useAuth();
  const { organization } = useOrganization();

  if (user?.email?.includes('digitalfirst')) {
    return true;
  }

  if (organization?.name.toLowerCase().includes('digitalfirst')) {
    return true;
  }

  switch (featureName) {
    case ForClientFeature.WHITEBOARD_TO_XLSX:
      if (organization?.name.toLowerCase().includes('profitroom')) {
        return true;
      }
      break;
  }

  return false;
};

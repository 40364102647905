import { useWhiteboardAgentPrompt, useWhiteboardTemplateUuid } from '../../onboarding/onboarding-store.ts';
import { useEffect, useState } from 'react';
import { useProjects } from '@/api/projects/get-projects.ts';
import { useCreateProject } from '@/api/projects/create-project.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useNavigate } from 'react-router-dom';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import toast from 'react-hot-toast';
import { useRunWhiteboardOnboarding } from './useRunWhiteboardOnboarding.ts';

export const WhiteboardOnboardingInitializer = () => {
  const agentPrompt = useWhiteboardAgentPrompt();
  const templateUuid = useWhiteboardTemplateUuid()!;
  const { projects, isLoading } = useProjects();
  const { createProject } = useCreateProject();
  const { openModal } = useGlobalModal();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [atLeastOneParameterExists, setAtLeastOneParameterExists] = useState(false);
  const { runWhiteboardOnboarding } = useRunWhiteboardOnboarding();

  useEffect(() => {
    if (!agentPrompt && !templateUuid) {
      toast.error('Nor agent prompt nor template uuid provided. Redirecting.');
      setTimeout(() => navigate(buildUrl([Urls.DASHBOARD])), 3000);
    } else {
      setAtLeastOneParameterExists(true);
      showLoader();
    }
  }, []);

  useEffect(() => {
    if (isLoading || !atLeastOneParameterExists) return;

    if (projects.length === 0) {
      void runOnboardingForNewUser();
    } else if (projects.length === 1) {
      void runOnboarding(projects[0].id);
    } else {
      hideLoader();
      openModal(MODALS.SelectProjectForWhiteboard);
    }
  }, [isLoading, atLeastOneParameterExists]);

  const runOnboardingForNewUser = async () => {
    const project = await createProject('Onboarding');
    await runOnboarding(project.id);
  };

  const runOnboarding = async (projectId: number) => {
    await runWhiteboardOnboarding(projectId);
    hideLoader();
  };

  return (
    <div
      className="absolute left-0 top-0 h-svh w-screen"
      style={{ backgroundImage: 'url(/main-gradient-lg.jpg)', backgroundSize: 'cover' }}
    ></div>
  );
};

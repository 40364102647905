import { ToggleSwitch } from 'flowbite-react';

interface ToggleProps {
  onChange: (checked: boolean) => void;
  checked: boolean;
  label?: string;
  subLabel?: string;
  color?: string;
  sizing?: string;
  disabled?: boolean;
}

export function Toggle({
  checked = false,
  onChange,
  label,
  subLabel,
  color = 'blue',
  sizing = 'md',
  disabled = false,
}: ToggleProps) {
  return (
    <div className="flex w-full items-center justify-between gap-2">
      {label && (
        <div>
          <div className="text-sm font-medium leading-[17.50px]">{label}</div>
          {subLabel && <div className="mt-0.5 text-xs leading-[18px] text-neutrals-700">{subLabel}</div>}
        </div>
      )}
      <ToggleSwitch
        checked={checked}
        onChange={(e) => {
          onChange(e);
        }}
        color={color}
        sizing={sizing}
        disabled={disabled}
      />
    </div>
  );
}

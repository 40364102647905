import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { WhiteboardTemplate, WhiteboardTemplateScope } from '@/models/whiteboard-template';
import toast from 'react-hot-toast';
import { useUpdateWhiteboardTemplate } from '../../api/templates/update-whiteboard-template';
import { WhiteboardTemplateForm, WhiteboardTemplatePayload } from './TemplateForm';

export function EditWhiteboardTemplateModal() {
  const { updateWhiteboardTemplate } = useUpdateWhiteboardTemplate();
  const { context: template } = useGlobalModal<WhiteboardTemplate>();

  async function handleSubmit(values: WhiteboardTemplatePayload) {
    await updateWhiteboardTemplate(template.id, {
      ...values,
    });
    toast.success('Template updated successfully');
  }

  const initialValues = {
    name: template.name ?? '',
    description: template.description ?? '',
    category: template.category,
    forOrganization: template.ownerId,
    public: template.scope === WhiteboardTemplateScope.PUBLIC,
  };

  return (
    <WhiteboardTemplateForm
      onSubmit={handleSubmit}
      initialValues={initialValues}
      submitLabel="Save changes"
    />
  );
}

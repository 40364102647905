import { useCanvases } from '@/api/canvases/api';
import { Tool, ToolConfig } from '../../../../../libs/tools/type.model';
import { ReaderFileForm } from '../RenderFileForm.tsx';

interface ReaderCanvasFormProps {
  reactFlowNodeId: string;
  toolConfig: ToolConfig;
  tool?: Tool;
}

export const ReaderCanvasForm = ({ reactFlowNodeId, toolConfig, tool }: ReaderCanvasFormProps) => {
  const { canvases } = useCanvases();

  return (
    <ReaderFileForm
      reactFlowNodeId={reactFlowNodeId}
      toolConfig={toolConfig}
      items={canvases || []}
      tool={tool}
      label="Canvas"
    />
  );
};

export default ReaderCanvasForm;

import { extToMime } from '@/consts/mime-types.const';
import { IMAGE_URL_REGEXP, INSTAGRAM_URL_REGEXP, YOUTUBE_VIDEO_URL_REGEXP } from '@/consts/url-regexp-validators.const';
import toast from 'react-hot-toast';
import { getValidationSchema } from 'src/libs/tools/getValidationSchema';
import { ToolName, ToolValue } from 'src/libs/tools/type.model';
import * as Yup from 'yup';
import { NodeConfig } from '../types';

export type FormValues = Record<string, ToolValue | File>;

export const validateData = (nodeConfig: NodeConfig, values: FormValues) => {
  const schema = getValidationSchema(nodeConfig.toolConfig.config.input);

  try {
    schema.validateSync(values, { strict: true });
    return true;
  } catch (validationError: unknown) {
    if (validationError instanceof Yup.ValidationError) {
      toast.error(`Validation errors: ${validationError.errors}`);
    } else {
      toast.error('Error validating data');
    }
    return false;
  }
};

export function isImageUrl(url: string) {
  return IMAGE_URL_REGEXP.test(url);
}

export function isYouTubeVideoUrl(url: string) {
  return YOUTUBE_VIDEO_URL_REGEXP.test(url);
}

export function isYouTubeUrl(url: string) {
  return /^(https?:\/\/)?(www\.|m\.)?(youtube\.com|youtu\.be)(\/|$)/i.test(url);
}

export function isInstagramUrl(url: string) {
  return INSTAGRAM_URL_REGEXP.test(url);
}

export function isValidUrl(string: string) {
  try {
    new URL(string);
    return true;
  } catch {
    return false;
  }
}

export function isRedditUrl(url: string) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname.endsWith('reddit.com');
  } catch {
    return false;
  }
}

function getMimeTypes(extensions: string[]): string[] {
  return extensions.map((ext) => extToMime[ext.toLowerCase()]).filter(Boolean) as string[];
}

export function getTextFileMimeTypes(nodeConfigs: NodeConfig[]) {
  const textFileExtensions =
    nodeConfigs?.find((config) => config.toolConfig.name === ToolName.READER_TEXT_FILE)?.toolConfig.config.input
      ?.base64Content.validation.allowExtensions ?? [];
  return getMimeTypes(textFileExtensions);
}

export function getImageMimeTypes(nodeConfigs: NodeConfig[]) {
  const imageFileExtensions =
    nodeConfigs?.find((config) => config.toolConfig.name === ToolName.READER_IMAGE)?.toolConfig.config.input.content
      ?.validation.allowExtensions ?? [];
  return getMimeTypes(imageFileExtensions);
}

import { useUpdateToolCurrentOutput } from '@/api/tools/update-tool-current-output';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { mutate } from 'swr';
import { ToolOutputType } from '../../../libs/tools/type.model.ts';

export function useUpdateEENodesOutputs() {
  const { updateCurrentOutput } = useUpdateToolCurrentOutput();
  const eeNodesToUpdateOutput = useWhiteboardStore((state) => state.eeNodesToUpdateOutput);
  const clearEeNodesToUpdateOutput = useWhiteboardStore((state) => state.clearEeNodesToUpdateOutput);
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);

  async function updateEENodesOutputs(reactFlowNodeIdToSkip?: string) {
    if (!eeNodesToUpdateOutput) return;

    const filteredEENodesToUpdateOutput = eeNodesToUpdateOutput.filter(
      (nodeOutput) => nodeOutput.reactFlowNodeId !== reactFlowNodeIdToSkip,
    );

    await Promise.all(
      filteredEENodesToUpdateOutput.map(async ({ output, toolId, historyItemId, outputType, reactFlowNodeId }) => {
        await updateCurrentOutput(toolId, output, historyItemId);

        if (outputType === ToolOutputType.JSON) {
          await mutate(`/tools/output-html/${toolId}`);
          updateNodeData(reactFlowNodeId, { loading: false });
        }
      }),
    );

    clearEeNodesToUpdateOutput();
  }

  return { updateEENodesOutputs };
}

/* eslint-disable react-refresh/only-export-components */
import { EditorContent, useEditor } from '@tiptap/react';

import Paragraph from '@tiptap/extension-paragraph';
import { Document } from '@/components/tiptap/extensions/Document';
import Text from '@tiptap/extension-text';
import { Column, Columns } from '@/components/tiptap/extensions/MultiColumn';
import { TrailingNode } from '@/components/tiptap/extensions/TrailingNode';

import './PromptEditor.css';
import { SectionMention } from './mentions/SectionMention';
import { CanvasMention } from './mentions/CanvasMention';
import { useRef } from 'react';
import tippy from 'tippy.js';
import { useCanvases, useCanvasTemplates } from '@/api/canvases/api';
import { DataRoomMention } from './mentions/DataRoomMention.tsx';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { MentionInjector } from '@/components/tiptap/extensions/DigitalFirst/editing/mention-injector/MentionInjector.tsx';
import { PromptEditorContext } from '@/components/tiptap/extensions/DigitalFirst/editing/prompt-editor.context.ts';
import { PersonaMention } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/PersonaMention.tsx';
import { useSegments } from '@/api/segments/get-segments.ts';
import { ProductCanvasMention } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/ProductCanvasMention.tsx';
import { useProductCanvases } from '@/api/products/get-product-canvases.ts';
import { CanvasInstanceMention } from './mentions/CanvasInstanceMention.tsx';

export interface TOC {
  content: {
    id: string;
    dom: {
      textContent: string;
    };
  }[];
}

interface PromptEditorProps {
  initialContent: string;
  onContentChanged: (content: string) => void;
  toc: TOC;
}

const getTippy = () =>
  tippy('body', {
    interactive: true,
    trigger: 'manual',
    placement: 'bottom-start',
    theme: 'slash-command',
    maxWidth: '16rem',
    offset: [16, 8],
    popperOptions: {
      strategy: 'fixed',
      modifiers: [
        {
          name: 'flip',
          enabled: false,
        },
      ],
    },
  });

export enum PromptMentionType {
  SECTION = 'SECTION',
  CANVAS = 'CANVAS',
  DATA_ROOM = 'DATA_ROOM',
  PERSONA = 'PERSONA',
  PRODUCT_CANVAS = 'PRODUCT_CANVAS',
}

export const PromptEditor = ({ initialContent, onContentChanged, toc }: PromptEditorProps) => {
  const tippyRefCanvas = useRef(getTippy());
  const tippyRefSections = useRef(getTippy());
  const tippyRefInputResources = useRef(getTippy());

  const { canvasTemplates, isLoading: canvasTemplatesLoading } = useCanvasTemplates();
  const { dataRoomItems, isLoading: dataRoomLoading } = useDataRoomItems();
  const { segments } = useSegments();
  const { productCanvases } = useProductCanvases();
  const { canvases } = useCanvases();

  const editor = useEditor(
    {
      extensions: [
        Document,
        Paragraph,
        Columns,
        Column,
        SectionMention(toc, tippyRefSections),
        DataRoomMention(tippyRefInputResources, dataRoomItems),
        CanvasMention(tippyRefCanvas, canvasTemplates),
        CanvasInstanceMention(tippyRefCanvas, canvases ?? []),
        PersonaMention(tippyRefInputResources, segments!),
        ProductCanvasMention(tippyRefCanvas, productCanvases!),
        Text,
        TrailingNode,
      ],
      content: initialContent,
      injectCSS: false,
      onUpdate({ editor }) {
        onContentChanged(editor.getHTML());
      },
    },
    [canvasTemplates, segments],
  );

  return (
    <PromptEditorContext.Provider value={{ editor, toc }}>
      {!canvasTemplatesLoading && !dataRoomLoading && !!segments && (
        <>
          <div className="df__propseditor rounded-t-lg border border-neutrals-400 px-4 py-2.5 text-input-text">
            <EditorContent editor={editor} />
          </div>
          <MentionInjector />
        </>
      )}
    </PromptEditorContext.Provider>
  );
};

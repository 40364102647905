import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { TemplateAuthor } from './TemplateAuthor';
import { TemplateButton } from './TemplateButton';
import { TemplateToolsIcons } from './TemplateToolsIcons';
import { TemplateDropdownOptions } from './TemplateDropdownOptions';

type TemplateGridCardProps = {
  template: WhiteboardTemplate;
  onSelect: () => void;
  onAddTemplate: () => void;
  showEditOptions?: boolean;
  showButton?: boolean;
};

export function TemplateGridCard({
  template,
  onSelect,
  onAddTemplate,
  showEditOptions = true,
  showButton = true,
}: TemplateGridCardProps) {
  return (
    <div
      className="group relative flex cursor-pointer flex-col justify-between rounded-lg border border-neutrals-300 bg-primary-100 px-3 py-2 transition hover:bg-primary-200"
      onClick={onSelect}
    >
      <div className="flex items-baseline justify-between">
        <div className="min-h-12 text-h5-desktop">{template.name}</div>
        {showEditOptions && (
          <div
            className="-mr-1 ml-1"
            onClick={(e) => e.stopPropagation()}
          >
            <TemplateDropdownOptions template={template} />
          </div>
        )}
      </div>

      <div>
        <TemplateToolsIcons template={template} />

        {showButton ? (
          <div className="relative flex justify-end">
            <div className="absolute left-0 flex h-full items-center group-hover:invisible">
              <TemplateAuthor template={template} />
            </div>
            <div className="invisible group-hover:visible">
              <TemplateButton
                onClick={(e) => {
                  e.stopPropagation();
                  onAddTemplate();
                }}
                label="Use template"
              />
            </div>
          </div>
        ) : (
          <div className="flex h-10 items-center">
            <TemplateAuthor template={template} />
          </div>
        )}
      </div>
    </div>
  );
}

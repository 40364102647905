import { WhiteboardTemplate } from '@/models/whiteboard-template';
import BackIcon from '@assets/back.svg';
import LinkIcon from '@assets/link.svg';
import { useEffect, useRef, useState } from 'react';
import { TemplateAuthor } from '../whiteboards/components/templates/TemplateAuthor';
import { NavButton } from '../whiteboards/components/templates/TemplateDetails';
import { TemplateToolsIcons } from '../whiteboards/components/templates/TemplateToolsIcons';
import { copyTemplateUrl } from '../whiteboards/helpers/template-helper';
import { NodeType } from '../whiteboards/types';
import { PublicTemplateForm } from './PublicTemplateForm';
import { PublicTemplateOutput } from './PublicTemplateOutput';

export function PublicTemplate({ publicTemplate, onBack }: { publicTemplate: WhiteboardTemplate; onBack: () => void }) {
  const [nodes, setNodes] = useState<Record<string, NodeType>>({});
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    scrollContainerRef.current?.scrollTo({
      top: scrollContainerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [nodes]);

  return (
    <div
      className="h-screen overflow-y-auto"
      ref={scrollContainerRef}
    >
      <div className="m-auto max-w-xl p-4">
        <div className="flex items-center justify-between">
          <NavButton
            onClick={onBack}
            label="Back to templates"
            Icon={BackIcon}
          />
          <NavButton
            onClick={() => {
              copyTemplateUrl(publicTemplate);
            }}
            label="Copy link to share"
            Icon={LinkIcon}
          />
        </div>
        <div className="mt-5">
          <TemplateAuthor template={publicTemplate} />
          <div className="flex items-baseline justify-between">
            <h2 className="pt-2 text-h2-desktop">{publicTemplate.name}</h2>
          </div>

          {publicTemplate.description && (
            <div className="mt-5 rounded-lg border border-neutrals-300 bg-primary-100 px-5 py-4">
              <h4 className="text-h5-desktop">About this template</h4>
              <p>{publicTemplate.description}</p>
            </div>
          )}
          <div className="mt-4">
            <TemplateToolsIcons template={publicTemplate} />
          </div>
        </div>
        <PublicTemplateForm
          template={publicTemplate}
          onReset={() => setNodes({})}
          onOutput={(node) => {
            setNodes((prev) => ({
              ...prev,
              [node.id]: node,
            }));
          }}
        />
        {Object.values(nodes).length > 0 && (
          <div>
            <h3 className="mt-5 text-h3-desktop">Outputs</h3>
            <div className="flex flex-col gap-4">
              {Object.values(nodes).map((node) => (
                <div key={node.id}>
                  <PublicTemplateOutput node={node} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

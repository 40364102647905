import { mutate } from 'swr';
import { useApi } from '../api';
import { useDataRoomId } from '@/state/app-store';

export const useRetryProcessResource = () => {
  const { patchFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const retryProcessResource = async (resourceId: string) => {
    await patchFetcher(`/inputs/dataRoom/${dataRoomId}/retry-process-resource/${resourceId}`, { arg: {} });
    await mutate(`/inputs/dataRoom/${dataRoomId}`);
  };

  return {
    retryProcessResource,
  };
};

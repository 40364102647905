import { useApi } from '@/api/api';
import { ReactFlow } from '../types';

export const useStartExecutionEngine = () => {
  const { postFetcher } = useApi();

  const startExecutionEngine = (reactFlowNodeId: string, reactFlow: ReactFlow): Promise<void> => {
    return postFetcher(`/execution-engine/start/node/${reactFlowNodeId}`, {
      arg: reactFlow,
    });
  };

  return {
    startExecutionEngine,
  };
};

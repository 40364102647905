import { Editor } from '@tiptap/core';
import { getNode } from './getNode';
import { getTextContentBeetweenHeadings } from './getTextContentBeetweenHeadings';

import { useState } from 'react';
import { ExecuteToolPayload, ToolConfig, ToolField, ToolName, ToolOutput } from 'src/libs/tools/type.model';
import toast from 'react-hot-toast';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { v4 as uuid } from 'uuid';
import { sleep } from '@/components/tiptap/extensions/DigitalFirst/helpers/sleep';
import { useGetCanvasesFromPrompt } from './getCanvasesFromPrompt.ts';
import { canvasToText } from './canvasToText';
import { turndownService } from '../turndown/turndown.service.ts';
import { getImageBetweenHeadings } from './getImageBetweenHeadings.ts';
import { parseOutput } from './parseOutput.ts';
import { useInjectPersonasIntoPrompt } from '@/components/tiptap/extensions/DigitalFirst/helpers/injectPersonasIntoPrompt.ts';
import { useTiptapResourceChunks } from './tiptap-api/getTiptapResourceSummary.ts';
import { getDataRoomItemChunks } from './getDataRoomItemChunks.ts';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { useProductCanvases } from '@/api/products/get-product-canvases.ts';
import { useTableLoadData } from '../../../../../../libs/tables/api/tables.ts';
import { injectProductCanvasesIntoPrompt } from '@/components/tiptap/extensions/DigitalFirst/helpers/injectProductCanvasesIntoPrompt.ts';
import { useExecuteTool } from '@/api/tools/execute-tool.ts';
import { useGetToolOutputAsHTML } from '@/api/tools/get-http-tool-output-as-html.ts';
import { convertToNewTool } from './convertToNewTools.ts';

export const useExecuteAllFields = () => {
  const [isExecute, setIsExecute] = useState(false);
  const { execute } = useExecuteField();

  const executeAll = async (editor: Editor) => {
    setIsExecute(true);

    const nodesPos = editor.$nodes('dfGenOutput') ?? [];

    for (const nodePos of nodesPos) {
      const nodeId = nodePos?.node?.attrs?.id;
      await sleep(1000);
      await execute(editor, nodeId);
    }

    setIsExecute(false);
  };

  return {
    executeAll,
    isExecute,
  };
};

const usePrepareToExecute = () => {
  const { executeTool } = useExecuteTool();
  const { listenerOn } = useWebSocketMessageListener();
  const { getToolOutput } = useGetToolOutputAsHTML();

  return async (editor: Editor, tool: ToolConfig, fields: ToolField[], toolId: number, nodeId: string) => {
    const wsChannel = `${tool?.name}: ${uuid()}`;

    const payload: ExecuteToolPayload = {
      id: toolId,
      toolName: tool.name,
      fields,
      wsChannel,
    };

    try {
      setTimeout(async () => {
        const data = await executeTool(payload);
        toolId = toolId ?? data.toolId;
      }, 100);
    } catch {
      return; // error is handled by WebSocketErrorListener
    }

    await new Promise((resolve) => {
      let output = '';

      const { listenerOff } = listenerOn(wsChannel, async (data) => {
        try {
          if (tool?.isStreamable) {
            if (typeof data === 'object' && (data as Record<string, boolean>).done) {
              listenerOff();
              resolve(true);
            } else {
              output = (output + data) as string;
              const node = getNode(editor, nodeId);
              if (!node) return;

              node.content = await parseOutput(output as ToolOutput, tool.category, tool.name);
            }
          } else {
            const node = getNode(editor, nodeId);
            if (!node) return;

            node.content = await parseOutput(data as ToolOutput, tool.category, tool.name, () => getToolOutput(toolId));
            listenerOff();
            resolve(true);
          }
        } catch {
          // silent
        }
      });
    });
  };
};

export const useExecuteField = () => {
  const [isExecuting, setIsExecuting] = useState(false);
  const prepareToExecute = usePrepareToExecute();
  const { dataRoomItems } = useDataRoomItems();
  const { tiptapResourceChunks } = useTiptapResourceChunks();
  const { getCanvasesFromPrompt } = useGetCanvasesFromPrompt();
  const { injectPersonasIntoPrompt } = useInjectPersonasIntoPrompt();
  const { productCanvases } = useProductCanvases();
  const loadTableData = useTableLoadData();

  const execute = async (editor: Editor, nodeId: string) => {
    setIsExecuting(true);
    const node = getNode(editor, nodeId);

    if (!node) {
      return toast.error('Something went wrong. Please try again.');
    }

    const selectedTool = node?.attributes?.selectedtool as ToolConfig;
    const toolData = convertToNewTool(node?.attributes?.tooldata) as ToolField[];

    const toolDataCopy = structuredClone(toolData);
    const injectableMentionFields = ['prompt', 'query', 'question', 'url', 'postURLs', 'startUrls', 'searchQueries'];

    for (const [index, { key, value }] of toolDataCopy.entries()) {
      if (!injectableMentionFields.includes(key) || typeof value !== 'string') {
        continue;
      }

      let valueWithMentions = value;

      valueWithMentions = getTextContentBeetweenHeadings(editor, valueWithMentions as string);
      valueWithMentions = await getDataRoomItemChunks(valueWithMentions, dataRoomItems, tiptapResourceChunks);
      const canvases = await getCanvasesFromPrompt(valueWithMentions);
      valueWithMentions = canvasToText(canvases ?? [], valueWithMentions);
      valueWithMentions = injectPersonasIntoPrompt(valueWithMentions);
      valueWithMentions = await injectProductCanvasesIntoPrompt(valueWithMentions, productCanvases!, loadTableData);
      valueWithMentions = turndownService.turndown(valueWithMentions);

      toolDataCopy[index].value = valueWithMentions;
    }

    if (selectedTool.name === ToolName.ASK_VISION_MODEL) {
      const imagesFieldsIndex = toolDataCopy.findIndex((field) => field.key === 'images')!;
      toolDataCopy[imagesFieldsIndex].value = getImageBetweenHeadings(
        editor,
        toolDataCopy[imagesFieldsIndex].value as string,
      );
    }

    await prepareToExecute(editor, selectedTool, toolDataCopy, node?.attributes?.selectedtoolid, nodeId);

    setIsExecuting(false);
  };

  return {
    execute,
    isExecuting,
  };
};

import { FileInput, Label } from 'flowbite-react';
import { ReactNode, useCallback, useRef, useState } from 'react';
import { UploadedFile } from './UploadedFile.tsx';
import { useDropzone } from 'react-dropzone';
import { cn } from '@/helpers/cn';
import toast from 'react-hot-toast';

const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'audio/aac',
  'video/x-msvideo',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'audio/mpeg',
  'video/mp4',
  'video/mpeg',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'audio/wav',
  'audio/webm',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export interface FileDragAndDropInputProps {
  id: string;
  children?: ReactNode;
  accept?: string;
  onChange: (files: File[]) => void;
  asContainer?: boolean;
}

export function FileDragAndDropInput({
  id,
  children,
  accept = '*',
  onChange,
  asContainer = false,
}: FileDragAndDropInputProps) {
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const validateFiles = (files: File[]) => {
    const validFiles: File[] = [];

    files.forEach((file) => {
      if (file.size >= 100000000) {
        toast.error(file.name + ' is too large. Max size: 100MB');
      } else if (file?.name?.length > 255) {
        toast.error(file.name + ' has too many characters. Max length: 35');
      } else if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
        toast.error(file.name + ' is not an accepted file type');
      } else {
        validFiles.push(file);
      }
    });

    return validFiles;
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const acceptedFilesFiltred = validateFiles(acceptedFiles);

      const newFiles = [...files, ...acceptedFilesFiltred];
      onChange(acceptedFilesFiltred);

      setFiles(newFiles);
    },
    [files, onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: asContainer,
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <Label
        htmlFor={id}
        className={cn(
          '!m-0 flex w-full cursor-pointer flex-col items-center justify-center rounded-lg border-[1.3px] border-dashed border-primary-default p-12 hover:bg-primary-100',
          {
            'bg-white': !isDragActive,
            'bg-primary-100': isDragActive,
          },
        )}
      >
        <img
          src="/upload-blue.svg"
          alt=""
        />
        {children}
        <FileInput
          id={id}
          {...getInputProps()}
          onChange={(e) => {
            const fileList = e.target.files;
            if (fileList) {
              const newFiles = Array.from(fileList);
              const acceptedFiles = validateFiles(newFiles);

              onChange(acceptedFiles);
              setFiles(acceptedFiles);
            }
          }}
          className="hidden"
          accept={accept}
          multiple={false}
          ref={fileInputRef}
        />
      </Label>

      <div className="my-4 flex flex-col gap-2">
        {files.map((file, index) => (
          <UploadedFile
            key={index}
            file={file}
            url={URL.createObjectURL(file)}
            onRemove={(file) => {
              const newFiles = files.filter((f) => f !== file);
              const dataTransfer = new DataTransfer();

              for (let i = 0; i < newFiles.length; i++) {
                dataTransfer.items.add(newFiles[i]);
              }
              const newFileList = dataTransfer.files;

              if (fileInputRef.current) {
                fileInputRef.current.files = newFileList;
              }

              onChange(newFiles);
              setFiles(newFiles);
            }}
          />
        ))}
      </div>
    </div>
  );
}

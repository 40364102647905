import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useStoredUserPlan } from '../../routes/pricing/user-plan-store.ts';
import { useSemrush } from '@/state/semrush.store.ts';
import toast from 'react-hot-toast';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { ModalProps } from '@/models/modal-props.interface.ts';
import { PlanLimitErrorType } from '@/enums/plan-limit-error-type.enum.ts';
import { captureException } from '@sentry/react';

export const useErrorComposer = (closeModalOnError: boolean = true) => {
  const { openModal, closeModal } = useGlobalModal();
  const { closeDialog } = useGlobalDialog();
  const { hideLoader } = useGlobalLoader();
  const userPlan = useStoredUserPlan();
  const { isSemrush } = useSemrush();

  const closeAllGlobalComponents = () => {
    closeModal();
    closeDialog();
    hideLoader();
  };

  const errorComposer = (status: number, message: string) => {
    if (closeModalOnError) closeAllGlobalComponents();

    captureException(message, { extra: { status, message } });

    if (status === 400) {
      toast.error('Bad request');
    }

    if (status === 403 || status === 401) {
      toast.error(message || 'No access to this resource.');
    }

    if (status === 404) {
      toast.error('Resource not found');
    }

    if (status === 413) {
      toast.error('File too large. Max size: 100MB');
    }

    if (status === 498) {
      if (message) {
        toast.error(message);
      } else {
        toast.error('Something went wrong. Please try again later.');
      }
    }

    if (status === 499) {
      if (isSemrush) {
        window.SM.client('requestInAppPurchase');
        return;
      }

      if (userPlan) {
        handlePlanLimitErrorForUserWithPlan(message, openModal);
      } else {
        openModal(MODALS.UpgradePlan);
      }
    }

    if (status?.toString()?.startsWith('5')) {
      toast.error('Something went wrong. Please try again later.');
    }
  };

  return {
    errorComposer,
  };
};

const handlePlanLimitErrorForUserWithPlan = (
  errorType: string,
  openModal: (props: ModalProps, context?: object) => void,
) => {
  if (
    errorType === PlanLimitErrorType.PROJECT_LIMIT_EXCEEDED ||
    errorType === PlanLimitErrorType.DF_CREDITS_LIMIT_EXCEEDED
  ) {
    openModal(MODALS.GenericPlanLimitInfo);
  } else {
    openModal(MODALS.BuyUpsellProduct, { errorType });
  }
};

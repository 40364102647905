import { FormikDynamicForm } from './FormikDynamicForm';
import { Tool, ToolConfig, ToolField, ToolFormikFields, ToolValue } from './type.model';
import { ReactNode, useMemo } from 'react';
import { TOC } from '@/components/tiptap/extensions/DigitalFirst/editing/PromptEditor.tsx';
import fileToBase64 from '@/helpers/file-to-base64';
import { getToolFormInitialValues } from './getToolFormInitialValues.ts';
import { getValidationSchema } from './getValidationSchema.ts';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { clearLabel } from '@/helpers/clear-label.ts';

export interface ToolFormProps {
  tool?: Tool;
  toolConfig: ToolConfig;
  onSubmit: (fields: ToolField[]) => Promise<void>;
  externalValidationSchema?: ReturnType<typeof getValidationSchema>;
  submitLabel?: string;
  tiptap?: {
    turnOnTiptapInputAsPromptInput: boolean;
    toc: TOC;
  };
  extraTopChildren?: ReactNode;
  dfFeature?: DfFeature;
}

export const ToolForm = ({
  tool,
  toolConfig,
  onSubmit,
  externalValidationSchema,
  submitLabel,
  tiptap,
  extraTopChildren,
  dfFeature,
}: ToolFormProps) => {
  const initialValues: Record<string, ToolValue> = getToolFormInitialValues(toolConfig, tool);
  const validationSchema = useMemo(() => {
    return externalValidationSchema ?? getValidationSchema(toolConfig?.config?.input);
  }, [toolConfig]);

  const [baseFields, advancedFields] = useMemo(() => {
    const formicBaseFields: ToolFormikFields[] = [];
    const formicAdvancedFields: ToolFormikFields[] = [];

    for (const key in toolConfig?.config?.input) {
      const field = toolConfig.config.input[key];

      if (field.availableOnlyIn && (!dfFeature || !field.availableOnlyIn.includes(dfFeature))) {
        continue;
      }

      const label = field.displayName ?? key;

      const item: ToolFormikFields = {
        name: key,
        label: clearLabel(label),
        type: toolConfig.config.input[key].type,
        options: (field.validation?.valueIn as string[]) ?? [],
        showIf: field.showIf,
        isDeprecated: field?.isDeprecated,
      };

      if (field.isAdvanced) {
        formicAdvancedFields.push(item);
      } else {
        formicBaseFields.push(item);
      }
    }

    return [formicBaseFields, formicAdvancedFields];
  }, [toolConfig]);

  const onToolFormSubmit = async (values: Record<string, ToolValue>) => {
    const fields = await Promise.all(
      Object.keys(values).map(async (key) => {
        let value = values[key] as unknown;

        if (value instanceof File) {
          value = await fileToBase64(value);
        }

        if (typeof value === 'string') {
          value = value.replace('<p>', '').replace('</p>', '');
        }

        return {
          key: key,
          value: value as string | number | boolean,
        } as ToolField;
      }),
    );

    await onSubmit(fields);
  };

  return (
    <>
      {baseFields && (
        <FormikDynamicForm
          initialValues={initialValues}
          validationSchema={validationSchema}
          formikBaseFields={baseFields}
          formikAdvancedFields={advancedFields}
          onSubmit={onToolFormSubmit}
          submitLabel={submitLabel}
          tiptap={tiptap}
          extraTopChildren={extraTopChildren}
        />
      )}
    </>
  );
};

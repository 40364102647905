import { Button, Progress } from 'flowbite-react';
import { usePlanLimits } from '@/api/pricing/get-plan-limit.ts';
import { useMemo } from 'react';
import { getPlanLimitForUpsellProduct } from '@/helpers/get-plan-limit-for-upsell-product.ts';
import { StripeUpsellProductName } from '@/enums/StripeUpsellProductName.enum.ts';
import { DateTime } from 'luxon';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';

export function CreditsLimitInfoBox() {
  const { planLimits } = usePlanLimits();
  const { openModal } = useGlobalModal();

  const creditsPlanLimit = useMemo(() => {
    if (!planLimits) return;

    return getPlanLimitForUpsellProduct(StripeUpsellProductName.DF_TOKEN, planLimits);
  }, [planLimits]);

  const countCredits = (max: number, current: number) => {
    if (max - current < 0) {
      return 0;
    }
    return max - current;
  };

  if (!creditsPlanLimit) return null;

  return (
    <div className="flex flex-col gap-2 rounded-lg bg-primary-200 p-3">
      <div className="flex items-center gap-1">
        <img
          src="/coin-black.svg"
          alt=""
        />
        <span className="grow text-caption-md">Credits</span>
        <span className="text-caption-md">
          <span>
            {countCredits(creditsPlanLimit.maxValue, creditsPlanLimit.currentValue)}/{creditsPlanLimit.maxValue}
          </span>{' '}
          <span className="text-neutrals-700">per day</span>
        </span>
      </div>

      <Progress
        progress={
          (countCredits(creditsPlanLimit.maxValue, creditsPlanLimit.currentValue) / creditsPlanLimit.maxValue) * 100
        }
        size="sm"
        color="primary"
        theme={{ base: 'w-full overflow-hidden rounded-full bg-neutrals-400' }}
      />

      <div className="text-body-sm">
        {creditsPlanLimit.currentValue < creditsPlanLimit.maxValue ? (
          <div className="text-positive-900">Credits are available</div>
        ) : (
          <>
            {creditsPlanLimit.nextRenewal && (
              <div className="text-neutrals-700">
                Credits available tomorrow at{' '}
                <span className="font-medium text-neutrals-1000">
                  {DateTime.fromISO(creditsPlanLimit.nextRenewal).toFormat('t')}
                </span>
              </div>
            )}
          </>
        )}
      </div>

      <Button
        color="primary"
        size="sm"
        className="mt-0.5 cursor-pointer"
        as="span"
        onClick={() => openModal(MODALS.UpgradePlan)}
      >
        <img
          src="/arrow-circle-up-white.svg"
          alt=""
        />
        Upgrade plan
      </Button>
    </div>
  );
}

import { Edge } from '@xyflow/react';
import { v4 as uuid } from 'uuid';
import { ExecutionEngineNode, ExecutionEngineNodeStatus, NodeType, NodeTypesEnum } from '../types';

export function generateId(prefix?: string) {
  return (prefix ? `${prefix}-` : '') + uuid();
}

export function getDependentNodeIds(nodeId: string, edges: Edge[]) {
  let nodesToValidate = [nodeId];
  const filteredEdges = edges.filter((edge) => edge.target === nodeId);
  filteredEdges.forEach((edge) => {
    nodesToValidate = [...nodesToValidate, ...getDependentNodeIds(edge.source, edges)];
  });
  return nodesToValidate;
}

export function hydrateNodeData(nodes: NodeType[], executionEngineNodes: ExecutionEngineNode[]) {
  nodes.sort((a, b) => {
    const isAGroup = a.type === NodeTypesEnum.Group;
    const isBGroup = b.type === NodeTypesEnum.Group;

    if (isAGroup === isBGroup) return 0;
    return isAGroup ? -1 : 1;
  });

  return nodes.map((node) => {
    const executionEngineNode = executionEngineNodes.find((eeNode) => eeNode.reactFlowNodeId === node.id);
    if (!executionEngineNode) {
      return {
        ...node,
        // edges default zIndex: 0, node selection increases zIndex by 1000 so -1001 to keep group always below edges
        zIndex: node.type === NodeTypesEnum.Group ? -1001 : undefined,
      };
    }
    return {
      ...node,
      data: {
        ...node.data,
        output: executionEngineNode.tool.currentOutput,
        loading: isExecutionEngineNodeLoading(executionEngineNode),
        status: executionEngineNode.status,
        toolId: executionEngineNode.tool.id,
      },
    };
  });
}

const loadingStates = [ExecutionEngineNodeStatus.PROCESSING, ExecutionEngineNodeStatus.QUEUED];

function isExecutionEngineNodeLoading(executionEngineNode: ExecutionEngineNode) {
  return loadingStates.includes(executionEngineNode.status);
}

export function isNodeLoading(node: NodeType) {
  return loadingStates.includes(node.data.status);
}

export function filterExecutionEngineNodes(nodes: NodeType[], serverExecutionEngineNodes: ExecutionEngineNode[]) {
  const executionEngineNodes: ExecutionEngineNode[] = [];
  const eeNodesToRemove: string[] = [];

  serverExecutionEngineNodes.forEach((een) => {
    if (!nodes.find((node) => node.id === een.reactFlowNodeId)) {
      eeNodesToRemove.push(een.reactFlowNodeId);
    } else {
      executionEngineNodes.push(een);
    }
  });

  return { executionEngineNodes, eeNodesToRemove };
}

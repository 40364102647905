import { useDataRoomId } from '@/state/app-store';
import { useApi } from '../api';
import { mutate } from 'swr';

export const useRenameResource = () => {
  const { patchFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const renameResource = async (resourceId: string, newName: string) => {
    await patchFetcher(`/inputs/dataRoom/${dataRoomId}/rename-resource/${resourceId}`, {
      arg: {
        name: newName,
      },
    });

    mutate(`/inputs/dataRoom/${dataRoomId}`);
  };

  return {
    renameResource,
  };
};

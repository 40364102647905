import { useApi } from '@/api/api';
import { mutate } from 'swr';

export const useDeleteWhiteboardTemplate = () => {
  const { deleteFetcher } = useApi();

  const deleteWhiteboardTemplate = async (whiteboardTemplateId: number) => {
    await deleteFetcher(`/whiteboard-template/${whiteboardTemplateId}`);
    await mutate('/whiteboard-template');
  };

  return {
    deleteWhiteboardTemplate,
  };
};

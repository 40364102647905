import { cn } from '@/helpers/cn.ts';
import { Handle, Position } from '@xyflow/react';
import { ToolOutputType } from 'src/libs/tools/type.model.ts';
import { SOCKET_PADDING } from '../../consts/whiteboard.const.ts';
import { NodeType } from '../../types.ts';
import { socketColors } from './socketColors.ts';
import { useWhiteboardStore } from '../../store/whiteboard.store.ts';

type NodeOutputSocket = {
  nodeId: NodeType['id'];
  socketType: ToolOutputType;
};

export function NodeOutputSocket({ nodeId, socketType }: NodeOutputSocket) {
  const isEdgeConnecting = useWhiteboardStore((state) => state.isEdgeConnecting);

  return (
    <Handle
      type="source"
      className="!-mr-px !flex items-center justify-center !border-0 !bg-transparent"
      style={{
        padding: isEdgeConnecting ? 120 : SOCKET_PADDING,
      }}
      position={Position.Right}
      id={`output-${nodeId}`}
    >
      <div className={cn('pointer-events-none absolute size-3 rounded-full', socketColors[socketType])}></div>
    </Handle>
  );
}

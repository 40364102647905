const WHITEBOARD_IDS_KEY = 'public-template-whiteboard-ids';

export function setPublicWhiteboardUuid(uuid: string) {
  const whiteboardIds = JSON.parse(localStorage.getItem(WHITEBOARD_IDS_KEY) || '[]');
  localStorage.setItem(WHITEBOARD_IDS_KEY, JSON.stringify([...whiteboardIds, uuid]));
}

export function getPublicWhiteboardUuids(): string[] {
  return JSON.parse(localStorage.getItem(WHITEBOARD_IDS_KEY) || '[]');
}

export function clearPublicWhiteboardUuids() {
  localStorage.removeItem(WHITEBOARD_IDS_KEY);
}

import { useApi } from '@/api/api.ts';
import { LangChainDto } from '@/models/lang-chain-dto.interface.ts';

export const useAskAI = () => {
  const { postFetcher } = useApi();

  const askAI = async (payload: LangChainDto): Promise<{ data: string }> => {
    return await postFetcher('/lang-chain', {
      arg: {
        ...payload,
      },
    });
  };

  return { askAI };
};

import { create } from 'zustand';

type TextOutputEditorState = {
  currentlyEditingToolId: number | null;
};

const initialExecutionState: TextOutputEditorState = {
  currentlyEditingToolId: null,
};

export const useToolOutputEditorStore = create<TextOutputEditorState>(() => ({
  ...initialExecutionState,
}));

export const setCurrentlyEditingToolId = (toolId: number) => {
  useToolOutputEditorStore.setState({ currentlyEditingToolId: toolId });
};

export const finishEditingTool = () => {
  useToolOutputEditorStore.setState({ currentlyEditingToolId: null });
};

import { Urls } from '@/consts/urls';
import { buildUrl } from '@/helpers/build-url';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout';
import { useParams } from 'react-router-dom';
import SmartTable from 'src/libs/tables/table/SmartTable';
import { useProductCanvas } from '@/api/products/get-product-canvas.ts';

export const ProductCanvasView = () => {
  const { productCanvasId } = useParams<{ productCanvasId: string }>();
  const { productCanvas } = useProductCanvas(productCanvasId!);

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Data Room', href: buildUrl([Urls.DATA_ROOM]) },
          { label: 'Products', href: buildUrl([Urls.PRODUCTS]) },
          { label: productCanvas?.name || '', href: '#' },
        ],
        icon: '/user-black.svg',
      }}
      pageBodyClassName="!max-w-full h-full !mx-0"
    >
      <title>Digitalfirst.ai | Product Canvases</title>

      {productCanvasId && <SmartTable tableId={productCanvasId} />}
    </PageWithHeaderLayout>
  );
};

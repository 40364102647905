import { useParams } from 'react-router-dom';
import { usePublicWhiteboardTemplates } from './api/usePublicWhiteboardTemplates.ts';
import { PublicTemplates } from './PublicTemplates';

export function PublicTemplatePage() {
  const { uuid } = useParams();
  const { publicWhiteboardTemplates } = usePublicWhiteboardTemplates();

  if (!publicWhiteboardTemplates) return null;

  return (
    <PublicTemplates
      publicTemplates={publicWhiteboardTemplates}
      selectedTemplateUUID={uuid}
    />
  );
}

import { useUpdateMe } from '@/api/user/update-me';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props';
import { Button, Label, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import toast from 'react-hot-toast';
import { object, ref, string } from 'yup';

export const PasswordChange = () => {
  const { updateMe } = useUpdateMe();
  const { showLoader, hideLoader } = useGlobalLoader();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: object({
      newPassword: string()
        .required('New password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
        ),
      confirmPassword: string()
        .required('Confirm new password is required')
        .oneOf([ref('newPassword')], 'Passwords must match'),
    }),
    onSubmit: async (values) => {
      showLoader();
      await updateMe({
        password: values.newPassword,
      });

      hideLoader();
      formik.resetForm();
      toast.success('Password updated successfully');
    },
  });

  return (
    <div className="h-fit rounded-2xl border p-6">
      <h4 className="text-h4-desktop">Change Password</h4>
      <FormikProvider value={formik}>
        <form
          className="mt-4"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <Label
              htmlFor="newPassword"
              value="New password"
            />
            <TextInput
              {...getDfFlowbiteFieldProps('newPassword', formik)}
              placeholder="New password"
              type="password"
            />
          </div>
          <div className="mt-5">
            <Label
              htmlFor="confirmPassword"
              value="Confirm new password"
            />
            <TextInput
              {...getDfFlowbiteFieldProps('confirmPassword', formik)}
              placeholder="Confirm new password"
              type="password"
            />
          </div>
          <div className="mt-6 flex justify-end">
            <Button
              color="primary"
              type="submit"
            >
              Save changes
            </Button>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

import {
  setWhiteboardTemplateUuid,
  useWhiteboardAgentPrompt,
  useWhiteboardTemplateUuid,
} from '../../onboarding/onboarding-store.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { LangChainModel } from '@/enums/lang-chain-model.enum.ts';
import { useCreateWhiteboard } from '../api/create-whiteboard.ts';
import { useAskAI } from '@/api/lang-chain/useAskAI.ts';
import { useCreateWhiteboardFromTemplate } from '../api/useCreateWhiteboardFromTemplate.ts';
import { useNavigate } from 'react-router-dom';

export const useRunWhiteboardOnboarding = () => {
  const { createWhiteboard } = useCreateWhiteboard();
  const { askAI } = useAskAI();
  const { createWhiteboardFromTemplate } = useCreateWhiteboardFromTemplate();
  const navigate = useNavigate();
  const agentPrompt = useWhiteboardAgentPrompt();
  const templateUuid = useWhiteboardTemplateUuid();

  const runOnboardingTemplate = async (projectId: number) => {
    const whiteboard = await createWhiteboardFromTemplate(templateUuid!, projectId);
    setWhiteboardTemplateUuid(null);
    navigate(
      buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.WHITEBOARD], {
        projectId,
        whiteboardId: whiteboard.id,
      }),
    );
  };

  const runOnboardingAgent = async (projectId: number) => {
    const { data: aiGeneratedName } = await askAI({
      model: LangChainModel.gpt4o,
      message: `
    Context: User has provided the prompt for the AI agent.
    The user's prompt is: \n${agentPrompt}\n
    %%%%%
    User: Make a title for this operation. The answer should be a extremely short, has as few words as possible. 
    Max 4 words. 
    `,
    });
    const whiteboard = await createWhiteboard(String(projectId), aiGeneratedName);
    navigate(
      buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.WHITEBOARD], {
        projectId,
        whiteboardId: whiteboard.id,
      }),
    );
  };

  const runWhiteboardOnboarding = async (projectId: number) => {
    if (templateUuid) {
      await runOnboardingTemplate(projectId);
    } else if (agentPrompt) {
      await runOnboardingAgent(projectId);
    }
  };

  return {
    runWhiteboardOnboarding,
  };
};

import { closeSideModal, useSideModalContext } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { SideModalBodyWithHeader } from '@/components/SideModalBodyWithHeader.tsx';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { useEffect, useMemo, useRef } from 'react';
import { useWhiteboardStore } from 'src/routes/whiteboards/store/whiteboard.store.ts';
import { ToolForm } from '../../../../libs/tools/ToolForm.tsx';
import { ToolName } from '../../../../libs/tools/type.model.ts';
import { useWhiteboardFormSubmit } from '../../hooks/useWhiteboardFormSubmit.ts';
import { ExecutionEngineNode, NodeData } from '../../types.ts';
import { ReaderCanvasForm } from './READER_CANVAS/ReaderCanvasForm.tsx';
import { ReaderDataRoomForm } from './READER_DATA_ROOM/ReaderDataRoomForm.tsx';
import { ReaderPersonaForm } from './READER_PERSONA/ReaderPersonaForm.tsx';

export const ConfigureToolNodeSideModalBody = () => {
  const { reactFlowNodeId, nodeData, executionEngineNode } = useSideModalContext<{
    reactFlowNodeId: string;
    nodeData: NodeData;
    executionEngineNode: ExecutionEngineNode;
  }>();
  const { toolConfig } = nodeData;
  const { tool } = executionEngineNode;

  const getValidationSchema = useWhiteboardStore((store) => store.getValidationSchema);
  const validationSchema = useMemo(() => getValidationSchema(reactFlowNodeId), []);
  const onSubmit = useWhiteboardFormSubmit(reactFlowNodeId);

  const renderForm = () => {
    switch (toolConfig.name) {
      case ToolName.READER_DATA_ROOM:
        return (
          <ReaderDataRoomForm
            reactFlowNodeId={reactFlowNodeId}
            toolConfig={toolConfig}
            tool={tool}
          />
        );
      case ToolName.READER_CANVAS:
        return (
          <ReaderCanvasForm
            reactFlowNodeId={reactFlowNodeId}
            toolConfig={toolConfig}
            tool={tool}
          />
        );
      case ToolName.READER_PERSONA:
        return (
          <ReaderPersonaForm
            reactFlowNodeId={reactFlowNodeId}
            toolConfig={toolConfig}
            tool={tool}
          />
        );
      default:
        return (
          <ToolForm
            tool={tool}
            toolConfig={toolConfig}
            onSubmit={onSubmit}
            externalValidationSchema={validationSchema}
            dfFeature={DfFeature.WHITEBOARD}
          />
        );
    }
  };

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const firstInput = containerRef.current?.querySelector('input, textarea, select') as HTMLElement;
    if (firstInput) {
      firstInput.focus();
    }
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeSideModal();
      }
    };

    const controller = new AbortController();
    document.addEventListener('keydown', handleEscapeKey, controller);
    return () => {
      controller.abort();
    };
  }, []);

  return (
    <SideModalBodyWithHeader
      header={nodeData.name}
      subheader={`Configure parameters for the tool: ${toolConfig.displayName}`}
      ref={containerRef}
    >
      {renderForm()}
    </SideModalBodyWithHeader>
  );
};

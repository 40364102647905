import { useKeyPress } from '@xyflow/react';
import { useEffect } from 'react';
import { NODE_GAP } from '../consts/whiteboard.const';
import { generateId } from '../helpers/node-helpers';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { NodeTypesEnum } from '../types';
import { useAddNodes } from './useAddNode';

export function useDuplicateNode() {
  const { addNodes } = useAddNodes();
  const cmdDPressed = useKeyPress(['Meta+d', 'Strg+d']);
  const getSelectedNodes = useWhiteboardStore((state) => state.getSelectedNodes);
  const duplicateEdgesForNodes = useWhiteboardStore((state) => state.duplicateEdgesForNodes);
  const addStickyNote = useWhiteboardStore((state) => state.addStickyNote);
  const addGroupNode = useWhiteboardStore((state) => state.addGroupNode);

  useEffect(() => {
    if (cmdDPressed) {
      duplicateNodes();
    }
  }, [cmdDPressed]);

  async function duplicateNodes() {
    const selectedNodes = getSelectedNodes();
    if (!selectedNodes.length) return;
    const nodeIdMap = new Map<string, string>();

    const selectedToolNodes = selectedNodes.filter((node) => node.type === NodeTypesEnum.Tool);
    const nodesParams = selectedToolNodes.map((node) => {
      const newId = generateId(node.data.toolConfig?.name ?? '');
      nodeIdMap.set(node.id, newId);
      return {
        id: newId,
        toolConfig: node.data.toolConfig,
        fields: node.data.toolInput,
        position: {
          x: node.position.x + NODE_GAP,
          y: node.position.y + NODE_GAP,
        },
        nodeName: `${node.data.name} (copy)`,
        width: node.width,
        height: node.height,
        parentId: node.parentId,
      };
    });
    await addNodes({ nodes: nodesParams });

    const selectedStickyNoteNodes = selectedNodes.filter((node) => node.type === NodeTypesEnum.StickyNote);
    selectedStickyNoteNodes.forEach((node) => {
      const newId = generateId(NodeTypesEnum.StickyNote);
      nodeIdMap.set(node.id, newId);
      addStickyNote(
        {
          id: newId,
          position: { x: node.position.x + NODE_GAP, y: node.position.y + NODE_GAP },
          width: node.width,
          height: node.height,
          parentId: node.parentId,
        },
        {
          name: `${node.data.name} (copy)`,
          content: node.data.content,
          isNewlyAdded: true,
          backgroundColor: node.data.backgroundColor,
        },
      );
    });

    const selectedGroupNodes = selectedNodes.filter((node) => node.type === NodeTypesEnum.Group);
    selectedGroupNodes.forEach((node) => {
      const newId = generateId(NodeTypesEnum.Group);
      nodeIdMap.set(node.id, newId);
      addGroupNode(
        {
          id: newId,
          position: { x: node.position.x + NODE_GAP, y: node.position.y + NODE_GAP },
          width: node.width,
          height: node.height,
        },
        {
          name: `${node.data.name} (copy)`,
          isNewlyAdded: true,
          backgroundColor: node.data.backgroundColor,
        },
      );
    });

    duplicateEdgesForNodes(
      selectedNodes.map((node) => node.id),
      nodeIdMap,
    );
  }

  return {
    duplicateNodes,
  };
}

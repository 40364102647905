import { useApi } from '@/api/api';
import { Edge, XYPosition } from '@xyflow/react';
import { NodeType, Whiteboard } from '../types';

type AddTemplateToWhiteboardPayload = {
  templateId: number;
  position?: XYPosition;
};

type AddTemplateToWhiteboardResponse = {
  whiteboard: Whiteboard;
  reactFlowNodes: NodeType[];
  reactFlowEdges: Edge[];
};

export const useInjectTemplateIntoWhiteboard = () => {
  const { postFetcher } = useApi();

  const injectTemplateIntoWhiteboard = (
    whiteboardId: number,
    arg: AddTemplateToWhiteboardPayload,
  ): Promise<AddTemplateToWhiteboardResponse> => {
    return postFetcher(`/whiteboard/${whiteboardId}/inject-template`, {
      arg,
    });
  };

  return {
    injectTemplateIntoWhiteboard,
  };
};

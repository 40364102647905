import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { DropdownDataRoomItemOptions } from './DropdownDataRoomItemOptions.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { Tabs } from 'flowbite-react';
import { AddedDataItemPreview } from './AddedDataItemPreview.tsx';
import { cn } from '@/helpers/cn';
import { useEffect, useRef, useState } from 'react';
import { AddedDataItemSummary } from './AddedDataItemSummary.tsx';
import { DataRoomItemType } from '@/enums/data-room-item.enum.ts';
import { AddedDataItemChunks } from './AddedDataItemChunks.tsx';
import { URLsItemPreview } from './URLsItemPreview.tsx';

export function PreviewAndSummaryModal({
  open,
  item,
  onClose,
}: {
  open: boolean;
  item: DataRoomItem | null;
  onClose: () => void;
}) {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const animationDuration = 400;

  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    if (open) {
      overlayRef.current?.classList.add('!z-50');
      overlayRef.current?.classList.add('!bg-[rgba(0,0,0,0.5)]');
      setShowContent(true);
    } else {
      overlayRef.current?.classList.remove('!bg-[rgba(0,0,0,0.5)]');
      setTimeout(() => {
        overlayRef.current?.classList.remove('!z-50');
        setShowContent(false);
      }, animationDuration);
    }
  }, [open]);

  return (
    <>
      <div
        className={cn(`fixed left-0 top-0 -z-50 h-[100svh] w-[100svw] bg-[rgba(0,0,0,0)] transition-colors`)}
        style={{ transitionDuration: `${animationDuration}ms` }}
        onClick={onClose}
        ref={overlayRef}
      ></div>
      <div
        className={cn(
          'fixed right-3 top-3 z-50 flex h-[calc(100svh-1.5rem)] w-[45svw] transform flex-col overflow-hidden rounded-2xl bg-white',
          { 'translate-x-0': open, 'translate-x-[105%]': !open },
        )}
        style={{ transitionDuration: `${animationDuration}ms` }}
      >
        {item && showContent && (
          <>
            <div className="flex items-center justify-between bg-neutrals-200 p-6">
              <div className="flex items-center">
                <div className="mr-3 rounded-lg bg-white p-2.5">
                  <img
                    src="/file-black.svg"
                    alt=""
                  />
                </div>
                <h2 className="mr-2 text-h5-desktop">
                  {item.extension ? (
                    item.name
                  ) : (
                    <a
                      target="_blank"
                      href={item.url}
                      rel="noreferrer"
                    >
                      {item.name}
                    </a>
                  )}
                </h2>
                <DropdownDataRoomItemOptions item={item}>
                  <div>
                    <IconButton
                      icon="/three-dots-gray.svg"
                      size={24}
                      alt="More"
                    />
                  </div>
                </DropdownDataRoomItemOptions>
              </div>

              <IconButton
                icon="/close-side-panel-black.svg"
                size={24}
                alt="Close"
                onClick={onClose}
              />
            </div>

            <div className="min-h-0 grow">
              <Tabs
                aria-label="Preview and Data Summary"
                variant="underline"
              >
                {item.type !== DataRoomItemType.WEBSITE && (
                  <Tabs.Item
                    active
                    title="Preview"
                  >
                    <AddedDataItemPreview item={item} />
                  </Tabs.Item>
                )}
                {item.type === DataRoomItemType.WEBSITE && (
                  <Tabs.Item
                    active
                    title="URLs"
                  >
                    <URLsItemPreview item={item} />
                  </Tabs.Item>
                )}
                <Tabs.Item title="Chunks">
                  <AddedDataItemChunks item={item} />
                </Tabs.Item>
                <Tabs.Item title="Data Summary">
                  <AddedDataItemSummary item={item} />
                </Tabs.Item>
              </Tabs>
            </div>
          </>
        )}
      </div>
    </>
  );
}

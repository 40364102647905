import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { WebSearcherAnswerModel } from '@/models/web-searcher-answer-model.interface.ts';
import { useState } from 'react';

type WebSearcherAnswerStreamData =
  | string
  | { sources: WebSearcherAnswerModel['sources'] }
  | { followUpQuestions: WebSearcherAnswerModel['followUpQuestions'] }
  | { maps: WebSearcherAnswerModel['maps'] }
  | { videos: WebSearcherAnswerModel['videos'] }
  | { images: WebSearcherAnswerModel['images'] }
  | { done: true }
  | { error: unknown };

export function useWebSearcherAnswerStream() {
  const { listenerOn } = useWebSocketMessageListener();
  const [answers, setAnswers] = useState<{ [question: string]: WebSearcherAnswerModel }>({});

  const emptyAnswer = {
    answer: '',
    sources: [],
    followUpQuestions: [],
    maps: [],
    videos: [],
    images: [],
  } satisfies WebSearcherAnswerModel;

  function updateAnswerOnStream(question: string, wsChannel: string, onError: () => void, onDone?: () => void) {
    const answer = answers[question];
    if (!answer) {
      setAnswers((prevAnswers) => ({ ...prevAnswers, [question]: emptyAnswer }));
    }
    const { listenerOff } = listenerOn(wsChannel, (data: WebSearcherAnswerStreamData) => {
      if (typeof data === 'object') {
        if (
          'images' in data ||
          'videos' in data ||
          'maps' in data ||
          'sources' in data ||
          'followUpQuestions' in data
        ) {
          if ('sources' in data) {
            setAnswers((prevAnswers) => ({
              ...prevAnswers,
              [question]: { ...prevAnswers[question], sources: data.sources.flat() },
            }));
          } else {
            setAnswers((prevAnswers) => ({ ...prevAnswers, [question]: { ...prevAnswers[question], ...data } }));
          }
        }

        if ('done' in data) {
          listenerOff();
          if ('error' in data) {
            onError();
          } else {
            onDone?.();
          }
        }
      } else {
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          [question]: { ...prevAnswers[question], answer: prevAnswers[question].answer + data },
        }));
      }
    });
    return { listenerOff };
  }

  return {
    answers,
    updateAnswerOnStream,
  };
}

import { ToolOutputType } from 'src/libs/tools/type.model';

export const socketColors: Record<ToolOutputType, string> = {
  [ToolOutputType.TEXT]: '!bg-primary-600 !border-primary-600',
  [ToolOutputType.IMAGE]: '!bg-orange-600 !border-orange-600',
  [ToolOutputType.DOCUMENT]: '!bg-yellow-600 !border-yellow-600',
  [ToolOutputType.WEBSITE]: '!bg-purple-600 !border-purple-600',
  [ToolOutputType.VIDEO]: '!bg-red-600 !border-red-600',
  [ToolOutputType.AUDIO]: '!bg-orange-600 !border-orange-600',
  [ToolOutputType.NULL]: '!bg-gray-600 !border-gray-600',
  [ToolOutputType.JSON]: '!bg-green-600 !border-green-600',
};

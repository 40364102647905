export const Urls = {
  DASHBOARD: '',

  WORKSPACES: 'workspaces',

  DATA_ROOM: 'data',

  NO_MENU_APP: 'no-menu-app',

  CANVASES: 'canvases',
  CANVAS: 'canvas/:canvasId',
  MY_CANVASES: 'my-canvases',
  CANVAS_LIBRARY: 'canvas-library/:category',

  FLOWS_LIBRARY: 'flows',
  FLOW_EDITOR: 'flow/:flowId',
  FLOW_TEMPLATE_EDITOR: 'flow-template/:flowId',

  TOOLS_LIBRARY: 'tools',

  PROJECTS: 'projects',
  PROJECT: 'project/:projectId',

  WEB_SEARCHER: 'web-searcher',
  WEB_SEARCHER_ROOM: 'web-searcher/:roomId',

  USER_SETTINGS: 'settings/user-settings',
  CREATE_ORGANIZATION: 'settings/create-organization',
  ORGANIZATION_SETTINGS: 'settings/organization-settings',
  ORGANIZATION_MEMBERS: 'settings/organization-members',

  WORKSPACE_SETTINGS: 'settings/workspace-settings',
  WORKSPACE_MEMBERS: 'settings/workspace-members',

  PUBLIC_APP: 'public-app',

  SHARED: 'shared',

  PRICING: 'pricing',

  PRE_CANVAS_TEST: 'pre-canvas-test',

  SEGMENTS: 'segments',
  PERSONA_DETAILS: 'persona/:personaId',

  PRODUCTS: 'products',
  PRODUCT_CANVAS: ':productCanvasId',

  ONBOARDING_STARTER: 'onboarding-starter',
  ONBOARDING_PREPARE: 'onboarding-prepare',
  ONBOARDING_FLOW_INITIALIZER: 'onboarding-flow-initializer/:projectId',

  ONBOARDING: 'onboarding',

  SEMRUSH: 'semrush',
  SEMRUSH_VIEW: 'semrush/:tableId',

  WHITEBOARD: 'whiteboard/:whiteboardId',
  WHITEBOARD_GAIN_ACCESS: 'whiteboard/:uuid/:token',
  WHITEBOARD_TEMPLATES: 'whiteboard-templates',
  WHITEBOARD_TEMPLATE_PREVIEW: ':uuid/preview',
  WHITEBOARD_ONBOARDING_INITIALIZER: 'whiteboard-onboarding-initializer',

  ADMIN_PANEL: 'admin-panel',
};

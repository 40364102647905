import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { ToolName, ToolOutput as ToolOutputType } from '../../../libs/tools/type.model.ts';
import { mutateWhiteboardNodes } from '../api/get-whiteboard-nodes.ts';
import { useWhiteboardStore } from '../store/whiteboard.store.ts';
import { ExecutionEngineNodeStatus, NotifyFrontendParams } from '../types.ts';
import { LangChainModel } from '@/enums/lang-chain-model.enum.ts';
import { useAskAI } from '@/api/lang-chain/useAskAI.ts';

export function useNodeExecutionSubscriber() {
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);
  const updateNodeStreamOutput = useWhiteboardStore((state) => state.updateNodeStreamOutput);
  const addToHistoryStack = useWhiteboardStore((state) => state.addToHistoryStack);
  const getReactFlow = useWhiteboardStore((state) => state.getReactFlow);
  const getExecutionEngineNode = useWhiteboardStore((state) => state.getExecutionEngineNode);
  const getStopped = useWhiteboardStore((state) => state.getStopped);
  const { askAI } = useAskAI();

  const { listenerOn } = useWebSocketMessageListener();

  const listenOnNodeExecution = (whiteboardId: string) => {
    const whiteboardWsChannel = `whiteboard-${whiteboardId}`;
    return listenerOn(whiteboardWsChannel, handleWsMessageWithWhiteboardId(whiteboardId));
  };

  const handleWsMessageWithWhiteboardId = (whiteboardId: string) => (message: NotifyFrontendParams) =>
    handleWsMessage(message, whiteboardId);

  const handleWsMessage = (message: NotifyFrontendParams, whiteboardId: string) => {
    updateNodeData(message.reactFlowNodeId, { status: message.status });

    switch (message.status) {
      case ExecutionEngineNodeStatus.UNCLASSIFIED:
        updateNodeData(message.reactFlowNodeId, { loading: false });
        break;

      case ExecutionEngineNodeStatus.QUEUED:
        updateNodeData(message.reactFlowNodeId, { loading: true });
        break;

      case ExecutionEngineNodeStatus.PROCESSING:
        generateNodeNameBasedOnConfiguration(message.reactFlowNodeId);
        updateNodeData(message.reactFlowNodeId, { loading: true });
        if (message.isStreamable) {
          handleStreamingMessage(message);
        }
        break;

      case ExecutionEngineNodeStatus.ERROR:
        updateNodeData(message.reactFlowNodeId, {
          loading: false,
          errors: message.error ? [message.error] : ['Node execution error'],
        });
        addToHistoryStack();
        break;

      case ExecutionEngineNodeStatus.DONE:
        updateNodeData(message.reactFlowNodeId, { loading: false, output: message.output, valid: true });
        addToHistoryStack();
        mutateWhiteboardNodes(Number(whiteboardId));
        break;

      case ExecutionEngineNodeStatus.STOPPED:
        updateNodeData(message.reactFlowNodeId, { loading: false, output: message.output });
        break;
    }
  };

  function handleStreamingMessage(message: NotifyFrontendParams) {
    let output = '';
    const nodeStreamingWsChannel = `execution-engine-node-output:${message.reactFlowNodeId}`;

    const { listenerOff: nodeStreamingListenerOff } = listenerOn(
      nodeStreamingWsChannel,
      (streamingMessage: ToolOutputType) => {
        if (getStopped()) {
          nodeStreamingListenerOff();
          return;
        }
        if (typeof streamingMessage === 'object' && (streamingMessage as unknown as Record<string, boolean>).done) {
          updateNodeData(message.reactFlowNodeId, { output: output });
          nodeStreamingListenerOff();
        } else {
          output += streamingMessage;
          updateNodeStreamOutput(message.reactFlowNodeId, output);
        }
      },
    );
  }

  const generateNodeNameBasedOnConfiguration = (reactFlowNodeId: string) => {
    const node = getReactFlow().nodes.find((node) => node.id === reactFlowNodeId)!;
    const een = getExecutionEngineNode(reactFlowNodeId)!;
    const excludedToolNames = [ToolName.READER_TEXT_FILE, ToolName.READER_YOUTUBE, ToolName.READER_IMAGE];

    if (excludedToolNames.includes(node.data.toolConfig.name)) {
      return;
    } else if (!node.data.hasEditedNameByUser && node.data.toolConfig.name === ToolName.CRAWLER_CRAWL) {
      updateNodeData(reactFlowNodeId, {
        name: new URL(een.tool.input.find((input) => input.key === 'url')!.value as string).hostname,
      });
    } else if (!node.data.hasEditedNameByUser) {
      const message = `Context: ' + ${JSON.stringify(een?.tool.input)};
      \n\n\n %%%% \n\n\n
      Question: I gave you in the context above, what is the configuration of the AI tool.
      The name of the AI tool is: ${een?.tool.toolName}.
      Your task is to generate a name for the node based on the configuration of the AI tool.
      Mainly, you should focus on the prompt field value, because it contains info about the user's need.
      The AI tool name should indicate what will be shown in the AI tool output.
      \n\n\n
      The answer should be a extremely short, has as few words as possible. Max 6 words. 
      Do not include any info about the AI, don't write 'tool', 'node', 'generator', 'output' etc. Just the name of the output in business terms.
      The answer should be a noun and tell what will be result of the AI tool.
    `;

      askAI({ model: LangChainModel.gpt4o, message }).then(({ data }) => {
        updateNodeData(reactFlowNodeId, { name: data });
      });
    }
  };

  return { listenOnNodeExecution };
}

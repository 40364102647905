/* eslint-disable react-refresh/only-export-components */
import { ButtonHTMLAttributes, HTMLProps } from 'react';

import { cn } from '@/helpers/cn';
import { Surface } from './Surface';
import { Button, ButtonProps } from './Button';
import Tooltip from './Tooltip';

export type ToolbarWrapperProps = {
  shouldShowContent?: boolean;
  isVertical?: boolean;
} & HTMLProps<HTMLDivElement>;

const ToolbarWrapper = ({
  ref,
  shouldShowContent = true,
  children,
  isVertical = false,
  className,
  ...rest
}: ToolbarWrapperProps & {
  ref?: React.RefObject<HTMLDivElement>;
}) => {
  const toolbarClassName = cn(
    'text-black inline-flex h-full leading-none gap-0.5',
    isVertical ? 'flex-col p-2' : 'flex-row p-1 items-center',
    className,
  );

  return (
    shouldShowContent && (
      <Surface
        className={toolbarClassName}
        {...rest}
        ref={ref}
      >
        {children}
      </Surface>
    )
  );
};

ToolbarWrapper.displayName = 'Toolbar';

export type ToolbarDividerProps = {
  horizontal?: boolean;
} & HTMLProps<HTMLDivElement>;

const ToolbarDivider = ({
  ref,
  horizontal,
  className,
  ...rest
}: ToolbarDividerProps & {
  ref?: React.RefObject<HTMLDivElement>;
}) => {
  const dividerClassName = cn(
    'bg-neutral-200 dark:bg-neutral-800',
    horizontal
      ? 'w-full min-w-[1.5rem] h-[1px] my-1 first:mt-0 last:mt-0'
      : 'h-full min-h-[1.5rem] w-[1px] mx-1 first:ml-0 last:mr-0',
    className,
  );

  return (
    <div
      className={dividerClassName}
      ref={ref}
      {...rest}
    />
  );
};

ToolbarDivider.displayName = 'Toolbar.Divider';

export type ToolbarButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  active?: boolean;
  activeClassname?: string;
  tooltip?: string;
  tooltipShortcut?: string[];
  buttonSize?: ButtonProps['buttonSize'];
  variant?: ButtonProps['variant'];
};

const ToolbarButton = ({
  ref,
  children,
  buttonSize = 'icon',
  variant = 'ghost',
  className,
  tooltip,
  tooltipShortcut,
  activeClassname,
  ...rest
}: ToolbarButtonProps & {
  ref?: React.RefObject<HTMLButtonElement>;
}) => {
  const buttonClass = cn('gap-1 min-w-[2rem] px-2 w-auto', className);

  const content = (
    <Button
      activeClassname={activeClassname}
      className={buttonClass}
      variant={variant}
      buttonSize={buttonSize}
      ref={ref}
      {...rest}
    >
      {children}
    </Button>
  );

  if (tooltip) {
    return (
      <Tooltip
        title={tooltip}
        shortcut={tooltipShortcut}
      >
        {content}
      </Tooltip>
    );
  }

  return content;
};

ToolbarButton.displayName = 'ToolbarButton';

export const Toolbar = {
  Wrapper: ToolbarWrapper,
  Divider: ToolbarDivider,
  Button: ToolbarButton,
};

import { useApi } from '@/api/api';
import { WhiteboardTemplate, WhiteboardTemplateCategory } from '@/models/whiteboard-template';
import { useAuth } from '@/hooks/auth/useAuth.ts';

export type CreateWhiteboardTemplatePayload = {
  whiteboardId: number;
  name: string;
  description?: string;
  category: WhiteboardTemplateCategory;
  forOrganization?: string;
  public?: boolean;
};

export const useCreateWhiteboardTemplate = () => {
  const { postFetcher } = useApi();
  const { user } = useAuth();

  const createWhiteboardTemplate = (payload: CreateWhiteboardTemplatePayload): Promise<WhiteboardTemplate> => {
    return postFetcher('/whiteboard-template', {
      arg: {
        ...payload,
        ownerName: `${user?.given_name} ${user?.family_name}`,
      },
    });
  };

  return {
    createWhiteboardTemplate,
  };
};

import { InputType, AddResourcePayload } from '@/models/add-resource-payload.interface.ts';
import fileToBase64 from './file-to-base64.ts';
import { DropboxFile } from '../../routes/data/components/modal-body-templates/DataRoomAddFromDropboxModalBody.tsx';
import { normalizeUrl } from '@/helpers/normalize-url.ts';

export const inputsPayloadMapper = {
  [InputType.File]: (files: File[]) => mapFiles(files),
  [InputType.Url]: (values: string[], name: string) => mapUrls(values, InputType.Url, name),
  [InputType.Youtube]: (values: string[]) => mapYoutube(values, InputType.Youtube),
  [InputType.Dropbox]: (values: DropboxFile[]) => mapDropbox(values),
  [InputType.Google]: (values: Record<string, string>[]) => mapGoogle(values),
};

async function mapFiles(files: File[]): Promise<AddResourcePayload[]> {
  const payloadValues: AddResourcePayload[] = [];

  for (const file of files) {
    console.log(file);
    const content = await fileToBase64(file);
    payloadValues.push({
      type: InputType.File,
      file: content,
      fileExtension: file.name.split('.').pop(),
      fileName: file.name,
    });
  }

  return payloadValues;
}

function mapYoutube(urls: string[], type: InputType): AddResourcePayload[] {
  const payloadValues: AddResourcePayload[] = [];

  urls.forEach((url: string) => {
    url = normalizeUrl(url);
    payloadValues.push({ type: type, url });
  });

  return payloadValues;
}

function mapUrls(urls: string[], type: InputType, name: string): AddResourcePayload {
  const fixedURLs = urls.map((url: string) => normalizeUrl(url));
  return { type: type, urls: fixedURLs, fileName: name };
}

function mapDropbox(files: DropboxFile[]): AddResourcePayload[] {
  return files.map((file: DropboxFile) => ({
    type: InputType.Dropbox,
    value: file.url,
    fileName: file.name,
  }));
}

function mapGoogle(values: Record<string, string>[]): AddResourcePayload[] {
  const payloadValues: AddResourcePayload[] = [];

  values.forEach((value: Record<string, string>) => {
    if (value?.id)
      payloadValues.push({
        type: InputType.Google,
        googleFileId: value.id,
        googleAuthToken: value.token,
        fileName: 'google', // TODO: add file name
      });
  });

  return payloadValues;
}

import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useStopExecutionEngine } from '../api/stop-execution-engine';
import { useWhiteboardStore } from '../store/whiteboard.store';

export function useStopExecution() {
  const whiteboardId = useWhiteboardStore((state) => state.whiteboardId);
  const setStopped = useWhiteboardStore((state) => state.setStopped);
  const { showLoader, hideLoader } = useGlobalLoader();
  const { stopExecutionEngine } = useStopExecutionEngine();

  async function stopExecution() {
    if (!whiteboardId) {
      return;
    }

    showLoader();
    await stopExecutionEngine(whiteboardId);
    setStopped(true);
    hideLoader();
  }

  return {
    stopExecution,
  };
}

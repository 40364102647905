import { useApi } from '@/api/api.ts';
import { Tool } from '../../../libs/tools/type.model.ts';
import { CreateOrUpdateToolPayload } from '@/models/create-or-update-tool-payload.ts';

export const useCreateOrUpdateTool = () => {
  const { postFetcher } = useApi();

  const createOrUpdateTool = async (payload: CreateOrUpdateToolPayload): Promise<Tool> => {
    return await postFetcher('/tools', { arg: payload });
  };

  return {
    createOrUpdateTool,
  };
};

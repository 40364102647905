import TiptapEditor from '../../shared/components/tiptap/TiptapEditor';
import { ToolsContext } from 'src/libs/tools/ToolsContext';
import { useLeftSideNav } from '@/layouts/MainLayout/store.ts';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useMyFlow } from '@/api/flow/my-flow/get-my-flow.ts';
import {
  setIsMemoryContextSidebarOpen,
  setIsTemplateEditing,
  setTiptapAccessMode,
  setTiptapCurrentView,
  useMemoryContextSidebar,
  useTiptapAccessMode,
} from '@/components/tiptap/extensions/DigitalFirst/store.ts';
import { TiptapAccessMode } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-access-mode.enum.ts';
import { TiptapView } from '@/components/tiptap/extensions/DigitalFirst/models/tiptap-mode.enum.ts';
import { useCanAccessTiptapDocument } from '@/api/tiptap-document/can-access-tiptap-document.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import toast from 'react-hot-toast';
import { clearFlowInstance, getFlowInstance } from '@/helpers/flowInstance';
import { convertYdocToJson } from '@/helpers/convert-ydoc-to-json';
import { v4 as uuid } from 'uuid';
import { convertJsonToYdoc } from '@/helpers/convert-json-to-ydoc';
import { encodeStateAsUpdate } from 'yjs';
import { fromUint8Array } from 'js-base64';
import { useUpdateDataTiptapDocument } from '@/api/tiptap-document/update-data-tiptap';
import { useAccessToken } from '@/api/get-access-token.ts';
import { confettiBoom } from '@/components/GlobalConfetti/global-confetti.store.ts';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { useToolConfigs } from '@/api/tools/get-tool-configs.ts';
import { PageTitle } from '@/components/PageTitle';
import { Loader2 } from 'lucide-react';

export function FlowEditor({ onboarding = false }: { onboarding?: boolean }) {
  const { flowId } = useParams<{ flowId: string }>();
  const { myFlow, isLoading } = useMyFlow(Number(flowId));
  const { canAccessTiptapDocument } = useCanAccessTiptapDocument();
  const { toolConfigs } = useToolConfigs(DfFeature.FLOW);
  const { collapseNav } = useLeftSideNav();
  const { getAccessToken } = useAccessToken();
  const [token, setToken] = useState('');
  const [isTiptapInitialized, setIsTiptapInitialized] = useState(false);
  const navigate = useNavigate();
  const tiptapAccessMode = useTiptapAccessMode();
  const { isMemoryContextSidebarOpen } = useMemoryContextSidebar();
  const [searchParams] = useSearchParams();
  const toolName = searchParams.get('toolName');

  const { updateDataTiptapDocument } = useUpdateDataTiptapDocument();

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, [getAccessToken]);

  useEffect(() => {
    if (isLoading || !myFlow) return;

    prepareDoc().then(() => {
      canAccessTiptapDocument(myFlow.tiptapDocument.id).then((accessMode) => {
        if (accessMode === TiptapAccessMode.NON_ACCESS) {
          navigate(buildUrl([Urls.DASHBOARD]));
          toast.error('You do not have access to this document');
        } else {
          if (accessMode === TiptapAccessMode.READONLY && !location.href.includes('shared')) {
            navigate(
              buildUrl([Urls.SHARED, Urls.FLOW_EDITOR], {
                flowId: myFlow.id,
              }),
            );
          }

          setTiptapAccessMode(accessMode);
          setTiptapCurrentView(toolName ? TiptapView.CONFIGURATION_MODE : TiptapView.EXECUTION_MODE);
          setIsTemplateEditing(false);
          if (isMemoryContextSidebarOpen) setIsMemoryContextSidebarOpen(false); // close memory context sidebar by default
          setIsTiptapInitialized(true);

          if (onboarding) {
            setTimeout(() => {
              confettiBoom();
            }, 1000);
          }
        }
      });
    });
  }, [isLoading, myFlow?.tiptapDocument.id]);

  useEffect(() => {
    if (window.innerWidth < 1440) {
      collapseNav();
    }
  }, [collapseNav]);

  const prepareDoc = async () => {
    const ids = getFlowInstance();

    if (!ids.includes(Number(flowId))) {
      return true;
    }

    const json = convertYdocToJson(myFlow.tiptapDocument.data);

    if (!json) {
      return true;
    }

    for (const element of json?.content ?? []) {
      if (element.type === 'dfGenOutput') {
        element.attrs = { ...element.attrs, selectedtoolid: null, id: uuid() };
        element.content = [
          {
            type: 'paragraph',
            content: [{ type: 'text', text: '   ' }],
          },
        ];
      }
    }

    const yDoc = convertJsonToYdoc(json);
    const encoded = encodeStateAsUpdate(yDoc);
    const base64 = fromUint8Array(encoded);
    clearFlowInstance(Number(flowId));
    return updateDataTiptapDocument(myFlow.tiptapDocument.id, base64).then(() => true);
  };

  if (!isTiptapInitialized || !toolConfigs) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader2 className="h-10 w-10 animate-spin" />
      </div>
    );
  }

  return (
    <>
      <PageTitle title={`Digitalfirst.ai | ${myFlow.name}`} />

      <div className="flex h-svh w-full flex-col">
        <ToolsContext.Provider value={{ tools: toolConfigs, onboarding }}>
          {token && (
            <TiptapEditor
              docName={String(myFlow.tiptapDocument.id)}
              token={token}
              editable={tiptapAccessMode === TiptapAccessMode.FULL}
            />
          )}
        </ToolsContext.Provider>
      </div>
    </>
  );
}

import SettingsIcon from '@assets/settings.svg';
import { useOpenConfiguration } from 'src/routes/whiteboards/hooks/useOpenConfiguration.tsx';
import { ExecutionEngineNode, NodeData, NodeType } from '../../../types.ts';
import { ClearOutput } from '../../ClearOutput.tsx';
import { OutputHistorySwitcher } from '../../OutputHistorySwitcher.tsx';
import { isNodeConfigurable } from '../../../helpers/is-node-configurable.ts';
import { useToolGraphics } from '../../../../../libs/tools/useToolGraphics.tsx';

interface GenericToolNodeFooterProps {
  nodeId: NodeType['id'];
  nodeData: NodeData;
  executionEngineNode: ExecutionEngineNode;
}

export const GenericToolNodeFooter = ({ nodeId, nodeData, executionEngineNode }: GenericToolNodeFooterProps) => {
  const { openConfiguration } = useOpenConfiguration(nodeId, nodeData, executionEngineNode);
  const showConfigurationButton = isNodeConfigurable(nodeData);
  const { getToolGraphics } = useToolGraphics();

  return (
    <div className="flex h-12 justify-between p-2">
      <div className="px-2 py-1 *:h-full">
        {
          getToolGraphics({
            toolName: nodeData.toolConfig.name,
            tool: executionEngineNode.tool,
          }).icon
        }
      </div>

      <div className="flex">
        <OutputHistorySwitcher
          executionEngineNode={executionEngineNode}
          nodeId={nodeId}
          nodeData={nodeData}
        />
        <ClearOutput
          nodeId={nodeId}
          nodeData={nodeData}
          executionEngineNode={executionEngineNode}
        />
        {showConfigurationButton && (
          <button
            onClick={openConfiguration}
            className="rounded-lg p-2 hover:bg-neutrals-100"
            type="button"
            title="Configuration"
          >
            <SettingsIcon className="size-5 path:fill-neutrals-600" />
          </button>
        )}
      </div>
    </div>
  );
};

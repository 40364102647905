import { forwardRef, RefObject, TextareaHTMLAttributes, useEffect, useRef } from 'react';

interface AutoSizingTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  selectAllOnFocus?: boolean;
  maxRows?: number;
}

export const AutoSizingTextarea = forwardRef<HTMLTextAreaElement, AutoSizingTextareaProps>(
  ({ selectAllOnFocus, maxRows, ...props }, ref) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      function handleInput() {
        resizeTextarea(textareaRef, maxRows);
      }

      if (textareaRef.current) {
        if (props.autoFocus) {
          textareaRef.current.focus();
        }

        textareaRef.current.setSelectionRange(
          selectAllOnFocus ? 0 : textareaRef.current.value.length,
          textareaRef.current.value.length,
        );

        resizeTextarea(textareaRef, maxRows);

        textareaRef.current.addEventListener('input', handleInput);
      }

      return () => {
        textareaRef.current?.removeEventListener('input', handleInput);
      };
    }, []);

    return (
      <textarea
        {...props}
        rows={1}
        ref={(node) => {
          textareaRef.current = node;
          if (!ref) return;
          if (typeof ref === 'function') {
            ref(node);
          } else {
            ref.current = node;
          }
        }}
      />
    );
  },
);

function resizeTextarea(textareaRef: RefObject<HTMLTextAreaElement | null>, maxRows?: number) {
  if (!textareaRef || !textareaRef.current) return;

  textareaRef.current.style.height = '';
  const height = textareaRef.current.scrollHeight;

  if (maxRows) {
    const lineHeight = parseInt(window.getComputedStyle(textareaRef.current).lineHeight, 10);
    const lines = height ? Math.ceil(height / lineHeight) : 0;
    textareaRef.current.style.height = `${lineHeight * Math.min(maxRows, lines)}px`;
  } else {
    textareaRef.current.style.height = `${height}px`;
  }
}

import { AuthGuard } from '@/guards/AuthGuard.tsx';
import { AuthorizedAppInitializer } from '@/guards/AuthorizedAppInitializer.tsx';
import { Outlet } from 'react-router-dom';
import { WebSocketErrorListener } from '@/components/WebSocketErrorListener.tsx';
import { StoreFiller } from '@/components/StoreFiller.tsx';
import { OnboardingModeRedirectGuard } from '@/guards/OnboardingModeRedirectGuard.tsx';
import { SWRConfig } from 'swr';
import { WhiteboardOnboardingGuard } from '@/guards/WhiteboardOnboardingGuard.tsx';

export function AuthorizedApp() {
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
      }}
    >
      <WhiteboardOnboardingGuard>
        <OnboardingModeRedirectGuard>
          <AuthGuard>
            <WebSocketErrorListener>
              <AuthorizedAppInitializer>
                <StoreFiller>
                  <Outlet />
                </StoreFiller>
              </AuthorizedAppInitializer>
            </WebSocketErrorListener>
          </AuthGuard>
        </OnboardingModeRedirectGuard>
      </WhiteboardOnboardingGuard>
    </SWRConfig>
  );
}

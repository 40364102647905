import { Button } from 'flowbite-react';

import PageInfo from '@assets/page_info.svg';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { SemrushConfigurationSidebar } from './SemrushConfigurationSidebar';

export const SemrushEmptyView = () => {
  return (
    <>
      <div className="flex w-full">
        <section className="flex h-full w-full flex-col items-center justify-center bg-main-image bg-cover bg-[50%] p-8 text-center">
          <h1 className="flex items-center text-h1-desktop">
            <span>Generate insights</span>
            <span className="mx-3 inline-block rounded-xl bg-mint-600 p-3">
              <img
                src="/search-check-black.svg"
                alt=""
              />
            </span>
            in seconds
          </h1>
          <div className="mt-4 text-body-md text-neutrals-700">
            Enhance your strategies and marketing actions by leveraging AI.
            <br />
            Paste your company website URL to seamlessly load your brand data.
          </div>
          <Button
            color="primary"
            className="mt-4"
            onClick={() => openSideModal(<SemrushConfigurationSidebar />)}
          >
            <span className="flex items-center gap-2">
              <PageInfo />
              Configure prompt
            </span>
          </Button>
        </section>
      </div>
    </>
  );
};

import { useToolConfigs } from '@/api/tools/get-tool-configs';
import { closeSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { DfFeature } from '@/enums/df-feature.enum';
import { Label, Select } from 'flowbite-react';
import { useEffect, useMemo, useState } from 'react';
import { ToolForm } from 'src/libs/tools/ToolForm';
import { ExecuteToolPayload, ToolCategory, ToolConfig, ToolField } from 'src/libs/tools/type.model';
import SemrushLogo from '@assets/semrush-logo.svg';
import { mutate } from 'swr';
import { TableCategory } from '../../../libs/tables/api/tables.ts';
import { v4 as uuid } from 'uuid';
import { useExecuteTool } from '@/api/tools/execute-tool.ts';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage.ts';
import { SideModalBodyWithHeader } from '@/components/SideModalBodyWithHeader.tsx';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';

export const SemrushConfigurationSidebar = () => {
  const { toolConfigs } = useToolConfigs(DfFeature.WEBSEARCH);
  const semrushToolConfigs = useMemo(
    () => toolConfigs?.filter((tool) => tool.category === ToolCategory.SEMRUSH) ?? [],
    [toolConfigs],
  );
  const [chosenToolConfig, setChosenToolConfig] = useState<ToolConfig | null>(null);
  const { executeTool } = useExecuteTool();
  const { listenerOn } = useWebSocketMessageListener();
  const { showLoader, hideLoader } = useGlobalLoader();

  useEffect(() => {
    if (semrushToolConfigs.length > 0) {
      setChosenToolConfig(semrushToolConfigs[0]);
    }
  }, [semrushToolConfigs]);

  const onSubmit = async (fields: ToolField[]) => {
    if (!chosenToolConfig) return;

    showLoader();

    const wsChannel = `${chosenToolConfig.name}: ${uuid()}`;

    const payload: ExecuteToolPayload = {
      toolName: chosenToolConfig.name,
      fields: fields,
      wsChannel,
    };

    try {
      await executeTool(payload);
    } catch {
      hideLoader();
      return; // error is handled by WebSocketErrorListener
    }

    const { listenerOff } = listenerOn(wsChannel, () => {
      hideLoader();
      closeSideModal();
      mutate(`/table/category/${TableCategory.SEMRUSH_API}`);
      listenerOff();
    });
  };

  return (
    <>
      <SideModalBodyWithHeader
        header={
          <div className="mb-3 flex items-center gap-1">
            <SemrushLogo className="mr-2 rounded-md" />
            Configuration
          </div>
        }
        subheader="Configure research parameters"
      >
        {semrushToolConfigs.length > 0 && chosenToolConfig && (
          <ToolForm
            toolConfig={chosenToolConfig}
            onSubmit={onSubmit}
            extraTopChildren={
              <div className="mb-5">
                <Label htmlFor="select-tool">Select semrush report</Label>
                <Select
                  value={chosenToolConfig?.displayName ?? ''}
                  onChange={(e) => {
                    const toolDisplayName = e.target.value;
                    const toolConfig = semrushToolConfigs.find((tool) => tool.displayName === toolDisplayName)!;
                    setChosenToolConfig(toolConfig);
                  }}
                >
                  {semrushToolConfigs?.map((config: ToolConfig) => (
                    <option key={config.name}>{config.displayName}</option>
                  ))}
                </Select>
              </div>
            }
          />
        )}
      </SideModalBodyWithHeader>
    </>
  );
};

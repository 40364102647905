import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { ConfigureToolNodeSideModalBody } from '../nodes/tools/ConfigureToolNodeSideModalBody';
import { ExecutionEngineNode, NodeData, NodeType } from '../types';

export function useOpenConfiguration(
  nodeId: NodeType['id'],
  nodeData: NodeData,
  executionEngineNode: ExecutionEngineNode,
) {
  function openConfiguration() {
    openSideModal(<ConfigureToolNodeSideModalBody />, {
      reactFlowNodeId: nodeId,
      nodeData,
      executionEngineNode,
    });
  }
  return { openConfiguration };
}

import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { Urls } from '@/consts/urls';
import { buildUrl } from '@/helpers/build-url';
import { WhiteboardTemplate, WhiteboardTemplateCategory, WhiteboardTemplateScope } from '@/models/whiteboard-template';
import { Dropdown } from 'flowbite-react';
import { useDeleteWhiteboardTemplate } from '../../api/templates/delete-whiteboard-template';
// import CopyIcon from '@assets/copy.svg';
import { usePermissions } from '@/api/permission/get-permissions';
import DeleteIcon from '@assets/delete.svg';
import EditIcon from '@assets/edit.svg';
import FactCheckIcon from '@assets/fact-check.svg';
import LinkIcon from '@assets/link.svg';
import MoreIcon from '@assets/more.svg';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { copyTemplateUrl } from '../../helpers/template-helper';

export function TemplateDropdownOptions({
  template,
  children,
}: {
  template: WhiteboardTemplate;
  children?: React.ReactNode;
}) {
  const { deleteWhiteboardTemplate } = useDeleteWhiteboardTemplate();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { openDialog } = useGlobalDialog();
  const { openModal, closeModal } = useGlobalModal();
  const navigate = useNavigate();

  const isMyTemplate = template.category === WhiteboardTemplateCategory.MY;
  const { canDeleteFlowsLibrary, canEditFlowsLibrary } = usePermissions();
  const canEdit = isMyTemplate || canEditFlowsLibrary;
  const canDelete = isMyTemplate || canDeleteFlowsLibrary;
  const canManageTemplate = canEdit || canDelete;
  const canCopy = template.scope === WhiteboardTemplateScope.PUBLIC;

  if (!canManageTemplate) return null;

  function handleEdit() {
    openModal(MODALS.EditWhiteboardTemplate, template);
  }

  function handleEditWhiteboard() {
    navigate(
      buildUrl([Urls.WHITEBOARD], {
        whiteboardId: template.whiteboard.id,
      }),
    );
    closeModal();
  }

  function handleDelete() {
    openDialog(DIALOGS.DeleteWhiteboardTemplate, async () => {
      showLoader();
      await deleteWhiteboardTemplate(template.id);
      hideLoader();
      toast.success('Template deleted successfully');
      closeModal();
    });
  }

  function handleCopyLink() {
    copyTemplateUrl(template);
  }

  return (
    <>
      <Dropdown
        onClick={(e) => e.stopPropagation()}
        renderTrigger={() => (
          <button className="cursor-pointer rounded-full p-1 hover:bg-neutrals-100">
            {children || <MoreIcon className="size-4" />}
          </button>
        )}
        placement="bottom-end"
        className="w-60"
      >
        {canCopy && (
          <Dropdown.Item onClick={handleCopyLink}>
            <LinkIcon className="size-4" />
            Copy URL
          </Dropdown.Item>
        )}
        {canEdit && (
          <>
            <Dropdown.Item onClick={handleEdit}>
              <EditIcon className="size-4" />
              Edit name and info
            </Dropdown.Item>
            <Dropdown.Item onClick={handleEditWhiteboard}>
              <FactCheckIcon className="size-4" />
              Edit template
            </Dropdown.Item>
          </>
        )}
        {canDelete && (
          <Dropdown.Item onClick={handleDelete}>
            <DeleteIcon className="size-4" />
            Delete
          </Dropdown.Item>
        )}
        {/* <Dropdown.Item onClick={handleDuplicate}>
          <CopyIcon className="size-4" />
          Duplicate
        </Dropdown.Item> */}
      </Dropdown>
    </>
  );
}

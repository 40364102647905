import { clearLabel } from '@/helpers/clear-label';
import { useWebSocketMessageListener } from '@/hooks/useOnWebSocketMessage';
import { WhiteboardTemplate } from '@/models/whiteboard-template';
import { AxiosError } from 'axios';
import { FormikProps, useFormik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { FormikDynamicFormItem } from 'src/libs/tools/FormikDynamicFormItem';
import { ToolFieldConfig, ToolOutputType, ToolValue } from 'src/libs/tools/type.model';
import { LoaderIcon } from '../whiteboards/components/ui/LoaderIcon';
import { setPublicWhiteboardUuid } from '../whiteboards/helpers/public-whiteboard-uuid';
import { ExecutionEngineNodeStatus, NodeType, NotifyFrontendParams, ToolExecutedOutput } from '../whiteboards/types';
import { useExecutePublicWhiteboardTemplate } from './api/execute-public-template';

type PublicTemplateField = {
  fieldKey: string;
  name: string;
  config: ToolFieldConfig;
  nodeName: string;
  defaultValue?: ToolFieldConfig['defaultValue'];
};

interface FormValues {
  [key: string]: ToolValue;
}

export type TemplateOutput = {
  nodeId: string;
  nodeName: string;
  output: ToolExecutedOutput | undefined;
  loading: boolean;
};

type PublicTemplateFormProps = {
  template: WhiteboardTemplate;
  onOutput: (output: NodeType) => void;
  onReset: () => void;
};

export function PublicTemplateForm({ template, onOutput, onReset }: PublicTemplateFormProps) {
  const { executePublicWhiteboardTemplate } = useExecutePublicWhiteboardTemplate();
  const { listenerOn } = useWebSocketMessageListener();
  const [isExecuting, setIsExecuting] = useState(false);

  function handleOutput(node: NodeType, output: ToolExecutedOutput | undefined, loading: boolean) {
    onOutput({
      ...node,
      data: {
        ...node.data,
        output,
        loading,
      },
    });
  }

  function handleStreamingMessage(message: NotifyFrontendParams, node: NodeType) {
    let output = '';
    const nodeStreamingWsChannel = `execution-engine-node-output:${message.reactFlowNodeId}`;
    const { listenerOff: nodeStreamingListenerOff } = listenerOn(
      nodeStreamingWsChannel,
      (streamingMessage: ToolOutputType) => {
        if (typeof streamingMessage === 'object' && (streamingMessage as unknown as Record<string, boolean>).done) {
          handleOutput(node, output, false);
          nodeStreamingListenerOff();
        } else {
          output += streamingMessage;
          handleOutput(node, output, true);
        }
      },
    );
  }

  async function handleSubmit(values: FormValues) {
    if (!template.uuid) {
      return;
    }
    setIsExecuting(true);
    onReset();

    try {
      const whiteboard = await executePublicWhiteboardTemplate({
        fields: Object.entries(values).map(([nodeIdAndFieldKey, value]) => ({
          key: nodeIdAndFieldKey.split('/')[1],
          value: value,
          reactFlowNodeId: nodeIdAndFieldKey.split('/')[0],
        })),
        uuid: template.uuid,
      });

      if (!whiteboard) {
        throw new Error('Failed to execute public template');
      }

      setPublicWhiteboardUuid(whiteboard.uuid!);

      let nodesCount = whiteboard.reactFlow.nodes.filter((n) => n.data.publicOutput).length;

      const whiteboardWsChannel = `whiteboard-${whiteboard.id}`;

      const { listenerOff } = listenerOn(whiteboardWsChannel, (message: NotifyFrontendParams) => {
        const node = whiteboard.reactFlow.nodes.find((n) => n.id === message.reactFlowNodeId);
        if (!node) return;
        if (!node.data.publicOutput) return;
        if (message.status === ExecutionEngineNodeStatus.PROCESSING && message.isStreamable) {
          handleStreamingMessage(message, node);
        }
        handleOutput(
          node,
          message.output,
          message.status !== ExecutionEngineNodeStatus.DONE && message.status !== ExecutionEngineNodeStatus.ERROR,
        );
        if (message.status === ExecutionEngineNodeStatus.DONE || message.status === ExecutionEngineNodeStatus.ERROR) {
          nodesCount--;
          if (nodesCount === 0) {
            setIsExecuting(false);
            listenerOff();
          }
        }
      });
    } catch (error: unknown) {
      let errorMessage = 'Something went wrong. Please try again later.';
      if (error instanceof AxiosError) {
        if (error.status === 429) {
          errorMessage = 'Too many requests. Please try again later.';
        } else if (error.response?.data?.message) {
          errorMessage = error.response.data.message;
        }
      }
      setIsExecuting(false);
      toast.error(errorMessage);
    }
  }

  const formFields: PublicTemplateField[] = template.whiteboard.reactFlow.nodes.flatMap((node) => {
    return (
      node.data.templatePublicFields?.map((f) => ({
        fieldKey: `${node.id}/${f}`,
        name: f,
        config: node.data.toolConfig.config.input[f],
        nodeName: node.data.name,
        defaultValue: node.data.toolConfig.config.input[f].defaultValue,
      })) || []
    );
  });

  const initialValues: FormValues = formFields.reduce((acc, field) => {
    acc[field.fieldKey] = field.defaultValue || '';
    return acc;
  }, {} as FormValues);

  const formik = useFormik({
    initialValues,
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      formFields.forEach((field) => {
        const value = values[field.fieldKey];
        if (field.config.validation.required && !value) {
          errors[field.fieldKey] = 'This field is required';
        }
      });
      return errors;
    },
    onSubmit: handleSubmit,
  });

  if (formFields.length === 0) {
    return null;
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="pt-5"
    >
      {formFields.map((field) => (
        <div
          key={field.fieldKey}
          className="pt-2"
        >
          <FormField
            field={field}
            formik={formik}
          />
        </div>
      ))}
      <div className="flex items-center gap-2 pt-3">
        <button
          type="submit"
          disabled={formik.isSubmitting || isExecuting}
          className="rounded-lg bg-primary-600 px-4 py-3 text-button-md text-white transition hover:bg-primary-700"
        >
          Execute
        </button>
        {isExecuting && <LoaderIcon />}
      </div>
    </form>
  );
}

function FormField({ field, formik }: { field: PublicTemplateField; formik: FormikProps<FormValues> }) {
  return (
    <FormikDynamicFormItem
      field={{
        name: field.fieldKey,
        label: `${field.nodeName} - ${clearLabel(field.config.displayName || field.name)}`,
        type: field.config.type,
        options: field.config.validation.valueIn ? (field.config.validation.valueIn as string[]) : undefined,
      }}
      formik={formik}
    />
  );
}

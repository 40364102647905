import { Panel } from '@xyflow/react';
import { UndoPanel } from './UndoPanel';
import { ZoomPanel } from './ZoomPanel';

export function ControlsPanel() {
  return (
    <Panel
      position="top-right"
      className="flex gap-4"
    >
      <UndoPanel />
      <ZoomPanel />
    </Panel>
  );
}

import { ToolCategory, ToolName } from 'src/libs/tools/type.model';
import { getCategoryConfig, getToolsByCategory } from '../../helpers/toolbox-helpers';
import { NodeConfig } from '../../types';
import ToolboxCategory from './ToolboxCategory';
import { ToolboxGroup } from './ToolboxGroup';
import { ToolboxToolItem } from './ToolboxToolItem';
import { ToolboxUtilities } from './ToolboxUtilities';

type ToolboxToolsProps = {
  nodeConfigs: NodeConfig[];
  searchQuery: string;
  open?: boolean;
};

const excludedToolNames = [ToolName.READER_DATA_ROOM, ToolName.READER_CANVAS, ToolName.READER_PERSONA, ToolName.ASSETS];

export function ToolboxTools({ nodeConfigs, searchQuery, open }: ToolboxToolsProps) {
  const filteredNodes = nodeConfigs
    .filter((node) => !excludedToolNames.includes(node.toolConfig.name))
    .filter(
      (node) =>
        node.toolConfig.displayName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        node.toolConfig.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );

  const toolsByCategory = getToolsByCategory(filteredNodes);

  return (
    <ToolboxGroup label="Tools">
      {Object.entries(toolsByCategory).map(([category, nodeConfigs]) => (
        <ToolboxCategory
          key={category}
          categoryConfig={getCategoryConfig(category)}
          open={open || category === ToolCategory.TEXTGEN}
        >
          {nodeConfigs.map((nodeConfig) => (
            <ToolboxToolItem
              key={nodeConfig.name}
              nodeConfig={nodeConfig}
            />
          ))}
        </ToolboxCategory>
      ))}
      <ToolboxUtilities />
    </ToolboxGroup>
  );
}

import { useApi } from '@/api/api';
import { ExecutionEngineNode } from '../types';

export const useClearNodeOutput = () => {
  const { patchFetcher } = useApi();

  const clearNodeOutput = async (reactFlowNodeId: string): Promise<ExecutionEngineNode> => {
    return await patchFetcher(`/execution-engine-node/${reactFlowNodeId}/clear-output`, {
      arg: {},
    });
  };

  return {
    clearNodeOutput,
  };
};

import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';
import { cn } from '@/helpers/cn';
import SearchIcon from '@assets/search-outline.svg';
import { Button } from 'flowbite-react';
import { DfTooltip } from '@/components/DfTooltip/DfTooltip';

type TemplateFormProps = {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
};

export function TemplatesHeader({ searchQuery, setSearchQuery }: TemplateFormProps) {
  return (
    <div className="mb-6 flex items-center gap-7">
      <div className="w-52 xl:w-60">
        <h2 className="text-h3-desktop">Choose template</h2>
      </div>
      <div className="flex grow items-center gap-2">
        <div className="relative min-w-4 grow">
          <SearchIcon
            className={cn(
              'pointer-events-none absolute left-3 top-1/2 size-5 -translate-y-1/2 cursor-pointer',
              'path:fill-neutrals-800',
            )}
          />
          <input
            type="text"
            placeholder="What would you like to create?"
            className={cn('rounded-lg border border-neutrals-300 py-2.5 pl-10 pr-4 text-sm', 'w-full')}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <CreateTemplateButton />
      </div>
    </div>
  );
}

function CreateTemplateButton() {
  const { openModal } = useGlobalModal();
  const openCreateModal = () => {
    openModal(MODALS.CreateWhiteboardTemplate);
  };
  return (
    <div className="z-10">
      <Button
        color="primary"
        size="md"
        onClick={openCreateModal}
        data-tooltip-id="create-template"
      >
        New template
      </Button>
      <DfTooltip
        id="create-template"
        content="New template from current whiteboard"
        place="bottom"
      />
    </div>
  );
}

import { useUpdateMyFlow } from '@/api/flow/my-flow/update-my-flow.ts';
import { useProjects } from '@/api/projects/get-projects.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { Flow } from '@/models/flow.interface.ts';
import { useCurrentWorkspace } from '@/state/app-store.ts';
import { Button } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import { WorkspaceProjectsSelector } from './WorkspaceProjectsSelector';
import { TileRadio } from '@/components/TileRadio.tsx';
import { cn } from '@/helpers/cn';

export function MoveMyFlowToProjectModalBody() {
  const { projectId } = useParams();
  const { closeModal, context } = useGlobalModal<{ myFlow: Flow }>();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { projects, isLoading } = useProjects();
  const { moveMyFlowToProject } = useUpdateMyFlow();
  const currentWorkspace = useCurrentWorkspace();

  const formik = useFormik({
    initialValues: {
      newProjectId: +projectId!,
    },
    onSubmit: async ({ newProjectId }) => {
      closeModal();
      showLoader();
      await moveMyFlowToProject(Number(newProjectId), context.myFlow.id, Number(projectId));
      hideLoader();
    },
  });

  const currentProject = projects?.find((project) => project.id === Number(projectId));
  const otherProjects = projects?.filter((project) => project.id !== Number(projectId));

  if (isLoading || !currentProject) {
    return;
  }

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-8 flex flex-col gap-4">
            <h2 className="text-subtitle-md">Current project</h2>
            <TileRadio
              name="newProjectId"
              value={currentProject.id}
              formik={formik}
            >
              <div className={cn('rounded-lg p-2', currentProject.color ?? 'bg-green-light')}>
                <img
                  src="/business-black.svg"
                  alt=""
                />
              </div>
              <span className="text-caption-md">{currentProject.name}</span>
            </TileRadio>
          </div>

          <WorkspaceProjectsSelector
            currentWorkspace={currentWorkspace}
            projects={otherProjects}
            formik={formik}
            fieldName="newProjectId"
          />

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
            >
              Save changes
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
}

import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import React from 'react';
import { useAddNodes } from '../../hooks/useAddNode';
import { NodeConfig } from '../../types';
import { ToolboxItem } from './ToolboxItem';
import { DATA_TRANSFER_KEYS } from '../../hooks/useDropNodeCreator.ts';
import { useToolGraphics } from '../../../../libs/tools/useToolGraphics.tsx';

export function ToolboxToolItem({ nodeConfig }: { nodeConfig: NodeConfig }) {
  const { addNode } = useAddNodes();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { getToolGraphics } = useToolGraphics();

  const onDragStart = (event: React.DragEvent) => {
    event.dataTransfer.setData(DATA_TRANSFER_KEYS.nodeConfig, JSON.stringify(nodeConfig));
    event.dataTransfer.effectAllowed = 'move';
  };

  const handleAdd = async () => {
    showLoader();
    await addNode({ toolConfig: nodeConfig.toolConfig });
    hideLoader();
  };

  const ToolIcon = getToolGraphics({
    toolName: nodeConfig.toolConfig.name,
  }).icon;

  return (
    <ToolboxItem
      onClick={handleAdd}
      onDragStart={onDragStart}
      icon={ToolIcon}
      label={nodeConfig.toolConfig.displayName}
    />
  );
}

import {
  closeSideModal,
  useGlobalSideModalInternalState,
} from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { cn } from '@/helpers/cn.ts';

export const GlobalSideModal = () => {
  const { open, body } = useGlobalSideModalInternalState();

  return (
    <>
      <div
        className={cn(
          'pointer-events-none absolute top-0 z-[110] h-full w-full bg-gray-dark opacity-0 transition-opacity duration-150',
          {
            'pointer-events-auto opacity-40': open,
          },
        )}
        onClick={() => closeSideModal()}
      />

      <div
        className={cn(
          'fixed bottom-0 right-0 top-0 z-[300] m-3 flex max-h-full w-full max-w-[440px] flex-col overflow-hidden rounded-2xl bg-white transition-all duration-200 ease-in-out',
          {
            'pointer-events-none translate-x-[200px] opacity-0': !open,
            'pointer-events-auto translate-x-0 opacity-100': open,
          },
        )}
      >
        {open && body}
      </div>
    </>
  );
};

import { cn } from '@/helpers/cn';
import { HTMLAttributes } from 'react';

type PanelCardProps = {
  children: React.ReactNode;
  className?: string;
};

export function PanelCard({ children, className }: PanelCardProps) {
  return (
    <div
      className={cn(
        'flex items-center gap-1 rounded-xl border border-neutrals-300 bg-white p-1 shadow-card',
        className,
      )}
    >
      {children}
    </div>
  );
}

type PanelButtonProps = HTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
};

export function PanelButton({ children, disabled, className, ...buttonProps }: PanelButtonProps) {
  return (
    <button
      {...buttonProps}
      className={cn('rounded-lg p-2', disabled ? 'opacity-50' : 'hover:bg-neutrals-100', className)}
    >
      {children}
    </button>
  );
}

import { Button, Dropdown, DropdownDivider } from 'flowbite-react';
import { SubDropdown } from '@/components/SubDropdown.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Link, useNavigate } from 'react-router-dom';
import { useCanvasTemplates, useSaveCanvas } from '@/api/canvases/api.ts';

export function CreateProjectItemDropdown() {
  const { canvasTemplates, isLoading } = useCanvasTemplates();
  const { saveCanvas } = useSaveCanvas();
  const navigate = useNavigate();

  return (
    <Dropdown
      label=""
      className="w-[288px]"
      placement="bottom-end"
      renderTrigger={() => (
        <Button
          size="sm"
          color="primary"
        >
          <img
            src="/add-white.svg"
            alt=""
          />
          New
        </Button>
      )}
    >
      <div className="dropdown-header">Tools</div>

      <SubDropdown
        renderTrigger={() => (
          <Dropdown.Item>
            <div className="rounded-md bg-neutrals-400 p-1.5">
              <img
                src="/data-black.svg"
                alt=""
              />
            </div>
            <div className="flex w-full items-center justify-between">
              <span>Data</span>
              <img
                src="/chevron-right-gray.svg"
                alt=""
              />
            </div>
          </Dropdown.Item>
        )}
        placement="right-start"
      >
        <>
          <div className="dropdown-header">Brainstorm</div>

          {!isLoading &&
            canvasTemplates.slice(0, 2).map((template) => (
              <Dropdown.Item
                key={template.id}
                onClick={async () => {
                  const canvas = await saveCanvas(template.id);
                  navigate(buildUrl([Urls.CANVASES, Urls.CANVAS], { canvasId: canvas.id }));
                }}
              >
                {template.name}
              </Dropdown.Item>
            ))}

          <DropdownDivider />

          <div className="dropdown-header">Train Model</div>

          <Dropdown.Item
            onClick={() => {
              navigate(buildUrl([Urls.DATA_ROOM]));
            }}
          >
            <div className="flex items-center gap-2">
              <div>Connect Data</div>
              <img
                src="/link-blue.svg"
                alt=""
              />
              <img
                src="/recordings-blue.svg"
                alt=""
              />
              <img
                src="/dropbox.svg"
                alt=""
              />
            </div>
          </Dropdown.Item>
        </>
      </SubDropdown>

      <Link to={Urls.FLOWS_LIBRARY}>
        <Dropdown.Item>
          <div className="rounded-md bg-mint-600 p-1.5">
            <img
              src="/data-black.svg"
              alt=""
            />
          </div>
          <div className="flex w-full items-center justify-between">
            <span>Flow</span>
          </div>
        </Dropdown.Item>
      </Link>
    </Dropdown>
  );
}

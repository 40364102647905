import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { Tool } from '../../../libs/tools/type.model.ts';

export const useTool = (toolId?: number) => {
  const { getFetcher } = useApi();

  const { data, error, isLoading } = useSWR<Tool>(toolId ? `/tools/${toolId}` : null, getFetcher);

  return {
    tool: data,
    isLoading,
    error,
  };
};

export const useGetTool = () => {
  const { getFetcher } = useApi();

  const getTool = async (toolId: number): Promise<Tool> => {
    return await getFetcher(`/tools/${toolId}`);
  };

  return {
    getTool,
  };
};

import { useParams } from 'react-router-dom';
import { Page404 } from '../404/404';
import { usePublicWhiteboard } from '../whiteboards/api/get-public-whiteboard';
import { LoaderIcon } from '../whiteboards/components/ui/LoaderIcon';
import { WhiteboardPreview } from '../whiteboards/preview/WhiteboardPreview';
import '../whiteboards/Whiteboard.scss';

export function PublicWhiteboardPage() {
  const { uuid } = useParams<{ uuid: string }>();
  const { whiteboard, isLoading } = usePublicWhiteboard(uuid);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <LoaderIcon className="mr-1" />
        <span>Loading whiteboard...</span>
      </div>
    );
  }

  if (!whiteboard) {
    return <Page404 />;
  }

  return (
    <div>
      <div className="h-screen w-screen p-2">
        <WhiteboardPreview reactFlow={whiteboard.reactFlow} />
      </div>
    </div>
  );
}

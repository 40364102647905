import { AutoSizingTextarea } from '@/components/AutoSizingTextarea/AutoSizingTextarea';
import { cn } from '@/helpers/cn';
import { NodeProps } from '@xyflow/react';
import React, { memo, useEffect, useState } from 'react';
import { STICKY_NOTE_COLORS, STICKY_NOTE_DIMENSIONS, STICKY_NOTE_RESIZE_CONSTRAINTS } from '../consts/whiteboard.const';
import { useSaveReactFlow } from '../hooks/useSaveReactFlow';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { NodeData, NodeType, StickyNoteNodeType } from '../types';
import { NodeResizeWrapper } from './NodeResizeWrapper';
import { NodeSettings } from './NodeSettings';

type StickyNoteNodeProps = {
  data: NodeData;
  id: NodeType['id'];
  selected: boolean;
  width: number;
};

function StickyNoteNode({ data, id, selected, width }: StickyNoteNodeProps) {
  const { saveReactFlow } = useSaveReactFlow();
  const addToHistoryStack = useWhiteboardStore((state) => state.addToHistoryStack);
  const updateNodeData = useWhiteboardStore((state) => state.updateNodeData);

  const [content, setContent] = useState(data.content ?? '');

  useEffect(() => {
    if (data.isNewlyAdded) {
      updateNodeData(id, { isNewlyAdded: false });
    }
  }, [data.isNewlyAdded]);

  function saveContent() {
    if (!content || content === data.content) {
      return;
    }
    updateNodeData(id, { content });
    addToHistoryStack();
    saveReactFlow();
  }

  return (
    <div
      className={cn(
        'h-full overflow-hidden rounded-sm border border-transparent p-2.5 text-subtitle-md italic shadow-[2px_2px_5px_rgba(0,0,0,0.15)]',
        data.backgroundColor ?? STICKY_NOTE_COLORS[0],
      )}
      style={{
        ...STICKY_NOTE_RESIZE_CONSTRAINTS,
      }}
    >
      {selected ? (
        <AutoSizingTextarea
          className="nopan nodrag reset-input resize-none !border-none bg-transparent p-0 text-subtitle-md italic !outline-none !ring-0"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              saveContent();
            }
          }}
          onBlur={() => {
            saveContent();
          }}
          selectAllOnFocus={false}
          autoFocus
          style={{
            width: `${width - 20}px`,
          }}
        />
      ) : (
        <p>
          {content.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < content.split('\n').length - 1 && <br />}
            </React.Fragment>
          ))}
        </p>
      )}

      {selected && (
        <NodeSettings
          id={id}
          data={data}
          colors={STICKY_NOTE_COLORS}
        />
      )}
    </div>
  );
}

const MemoizedStickyNoteNode = memo(StickyNoteNode);

export default function StickyNoteNodeWrapper(props: NodeProps<StickyNoteNodeType>) {
  return (
    <>
      <NodeResizeWrapper
        selected={props.selected}
        resizeConstraints={STICKY_NOTE_RESIZE_CONSTRAINTS}
      />

      <MemoizedStickyNoteNode
        data={props.data}
        id={props.id}
        selected={props.selected}
        width={props.width ?? STICKY_NOTE_DIMENSIONS.width}
      />
    </>
  );
}

import { useCanvases } from '@/api/canvases/api';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items';
import { DataRoomItem } from '@/models/data-room-item.interface';
import FilesIcon from '@assets/files.svg';
import { ToolName } from 'src/libs/tools/type.model';
import { getCategoryConfig } from '../../helpers/toolbox-helpers';
import { useSegmentPersonasForReaderTool } from '../../hooks/useSegmentPersonasForReaderTool';
import { NodeConfig } from '../../types';
import { ToolboxFilesCategory } from './ToolboxFilesCategory';
import { ToolboxGroup } from './ToolboxGroup';
import CanvasesIcon from '@assets/canvases.svg';
import PersonaIcon from '@assets/persona.svg';

type ToolboxFilesProps = {
  nodeConfigs: NodeConfig[];
  searchQuery?: string;
};

export function ToolboxFiles({ searchQuery, nodeConfigs }: ToolboxFilesProps) {
  const { dataRoomItems } = useDataRoomItems();
  const { canvases } = useCanvases();
  const { segmentsWithPersonas } = useSegmentPersonasForReaderTool();

  const dataRoomNodeConfig = nodeConfigs.find((nodeConfig) => nodeConfig.toolConfig.name === ToolName.READER_DATA_ROOM);
  const canvasNodeConfig = nodeConfigs.find((nodeConfig) => nodeConfig.toolConfig.name === ToolName.READER_CANVAS);
  const personaNodeConfig = nodeConfigs.find((nodeConfig) => nodeConfig.toolConfig.name === ToolName.READER_PERSONA);

  const filteredDataRoomItems = dataRoomItems?.filter(
    (item: DataRoomItem) =>
      item.isParsed &&
      !item.isErrored &&
      (searchQuery == null || item.name.toLowerCase().includes(searchQuery.toLowerCase())),
  );
  const filteredCanvases = canvases?.filter(
    (item) => searchQuery == null || item.name.toLowerCase().includes(searchQuery.toLowerCase()),
  );
  const filteredPersonas = segmentsWithPersonas?.filter(
    (segmentWithPersonas) =>
      searchQuery == null ||
      segmentWithPersonas.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      segmentWithPersonas.personas.some((persona) => persona.name.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  if (
    (!dataRoomNodeConfig || !filteredDataRoomItems?.length) &&
    (!canvasNodeConfig || !filteredCanvases?.length) &&
    (!personaNodeConfig || !filteredPersonas?.length)
  ) {
    return null;
  }

  return (
    <ToolboxGroup label="Data Room">
      {dataRoomNodeConfig && filteredDataRoomItems && (
        <ToolboxFilesCategory
          categoryConfig={getCategoryConfig('files')}
          nodeConfig={dataRoomNodeConfig}
          items={filteredDataRoomItems}
          itemIcon={<FilesIcon />}
          searchQuery={searchQuery}
        />
      )}

      {canvasNodeConfig && filteredCanvases && (
        <ToolboxFilesCategory
          categoryConfig={getCategoryConfig('canvases')}
          nodeConfig={canvasNodeConfig}
          items={filteredCanvases}
          itemIcon={<CanvasesIcon />}
          searchQuery={searchQuery}
        />
      )}

      {filteredPersonas &&
        personaNodeConfig &&
        filteredPersonas.map((segmentWithPersonas) => (
          <ToolboxFilesCategory
            key={segmentWithPersonas.id}
            categoryConfig={{ ...getCategoryConfig('personas'), label: segmentWithPersonas.name }}
            nodeConfig={personaNodeConfig}
            items={segmentWithPersonas.personas}
            itemIcon={<PersonaIcon />}
            searchQuery={searchQuery}
          />
        ))}
    </ToolboxGroup>
  );
}

import { useApi } from '@/api/api.ts';
import { ToolOutput } from '../../../libs/tools/type.model.ts';

export const useUpdateToolCurrentOutput = () => {
  const { postFetcher } = useApi();

  const updateCurrentOutput = async (id: number, output: ToolOutput, historyItemId: number) => {
    return await postFetcher(`/tools/change-output`, { arg: { id, output, historyId: historyItemId } });
  };

  return {
    updateCurrentOutput,
  };
};

import { useNavigate, useParams } from 'react-router-dom';
import { LoaderIcon } from '../whiteboards/components/ui/LoaderIcon';
import { useGainAccessToWhiteboard } from './api/gain-access-to-whiteboard';
import toast from 'react-hot-toast';
import { useEffect } from 'react';

export function WhiteboardGainAccessPage() {
  const { uuid, token } = useParams();
  const { whiteboard, error } = useGainAccessToWhiteboard(uuid, token);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      navigate('/');
      return;
    }
    if (whiteboard) {
      toast.success('Access granted');
      navigate(`/whiteboard/${whiteboard.id}`);
    }
  }, [error, whiteboard]);

  if (error || whiteboard) return null;

  return (
    <div className="flex h-screen items-center justify-center">
      <LoaderIcon className="mr-1" />
      <span>Loading whiteboard...</span>
    </div>
  );
}

import { useSegments } from '@/api/segments/get-segments';
import { useNoSegmentPersonas } from '@/api/segments/persona/get-no-segment-personas';
import { Persona } from '@/models/persona.interface';
import { Segment } from '@/models/segment.interface';

export function useSegmentPersonasForReaderTool() {
  const { segments } = useSegments();
  const { noSegmentPersonas } = useNoSegmentPersonas();
  const segmentsWithPersonas = prepareSegmentsPersonas(segments, noSegmentPersonas);
  return { segmentsWithPersonas };
}

type SegmentWithPersonas = {
  id: number;
  name: string;
  personas: {
    id: number;
    name: string;
  }[];
};

function prepareSegmentsPersonas(segments?: Segment[], noSegmentPersonas?: Persona[]): SegmentWithPersonas[] {
  return [
    ...(segments?.map((segment) => ({
      id: segment.id,
      name: segment.name,
      personas: segment.personaCanvases.map((persona) => ({
        id: persona.id,
        name: persona.name,
      })),
    })) ?? []),
    {
      id: 0,
      name: 'No Segment',
      personas:
        noSegmentPersonas?.map((persona) => ({
          id: persona.id,
          name: persona.name,
        })) ?? [],
    },
  ];
}

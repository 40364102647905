import { cn } from '@/helpers/cn';
import { Button, TextInput } from 'flowbite-react';
import { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { ChatMessage } from '@/models/chat-message.interface.ts';
import { llmMarked } from '../../helpers/llmMarked.ts';
import './SideChat.scss';

export interface SideChatProps {
  messages: ChatMessage[];
  title: string;
  isLoading: boolean;
  isVisible: boolean;
  sampleQuestionsHeader: string;
  onHideClick: () => void;
  onSendMessage: (message: string) => void;
  onDeleteMessages: () => void;
  sampleQuestions: string[];
  inputPlaceholder: string;
}

export function SideChat({
  messages,
  title,
  isLoading,
  isVisible,
  onHideClick,
  onSendMessage,
  onDeleteMessages,
  sampleQuestions,
  sampleQuestionsHeader,
  inputPlaceholder,
}: SideChatProps) {
  const [question, setQuestion] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const onExampleQuestionClick = (index: number) => {
    // setQuestion(sampleQuestions[index]);
    onSendMessage(sampleQuestions[index]);
  };

  const onQuestionSend = async () => {
    onSendMessage(question);
    setQuestion('');
  };

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = async (event) => {
    if (event.key === 'Enter') {
      await onQuestionSend();
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div
      className={cn(
        'side-chat-panel fixed right-0 z-[1001] flex w-[420px] transform flex-col border-l border-solid border-gray-divider bg-white shadow-lg transition-transform duration-200 ease-out',
        { 'translate-x-0': isVisible, 'translate-x-full': !isVisible },
      )}
    >
      <div className="flex items-center justify-between border-b border-gray-divider p-3">
        <div className="flex flex-row items-center gap-3">
          <img
            src="/df-logo.svg"
            className="rounded-full border border-gray-divider p-2"
            alt=""
          />
          <h2 className="text-subtitle-md font-semibold">{title}</h2>
        </div>
        <div className="flex flex-row gap-2">
          <img
            src="/trash-gray.svg"
            className="cursor-pointer"
            alt=""
            onClick={onDeleteMessages}
          />
          <img
            src="/close-side-panel.svg"
            className="cursor-pointer"
            alt=""
            onClick={onHideClick}
          />
        </div>
      </div>
      {!isLoading && (
        <>
          {messages?.length > 0 && (
            <div className="side-chat-message-list flex flex-1 flex-col gap-6 overflow-auto p-3">
              {messages.map(({ type, text }, index) => (
                <div
                  key={index}
                  className={cn(
                    'flex max-w-[95%] flex-row-reverse items-start gap-3 p-2 text-sm',
                    { 'self-end rounded-lg rounded-br-none bg-gray-ultra-light': type === 'user' },
                    { 'justify-end': type !== 'user' },
                  )}
                >
                  <div dangerouslySetInnerHTML={{ __html: llmMarked(text) as string }}></div>
                  {type !== 'user' && (
                    <img
                      src="/df-logo-small.svg"
                      className="rounded-full border border-gray-divider p-2"
                      alt=""
                    />
                  )}
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          )}
          {!messages?.length && (
            <div className="flex flex-1 flex-col items-center justify-center gap-8 p-3">
              <div className="flex flex-col items-center justify-center">
                <div className="mb-1 rounded-lg bg-tint-blue p-2">
                  <img
                    src="/chat-blue.svg"
                    alt=""
                  />
                </div>
                <div className="text-base font-medium">{sampleQuestionsHeader}</div>
              </div>
              <div className="flex w-full flex-col gap-3">
                {sampleQuestions.map((question, index) => (
                  <div
                    key={index}
                    onClick={() => onExampleQuestionClick(index)}
                    className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border border-gray-divider p-3 text-sm font-medium transition hover:bg-gray-bg active:bg-gray-bg"
                  >
                    <div>{question}</div>
                    <div className="flex text-primary-default">
                      Ask
                      <img
                        src="/arrow-up-blue.svg"
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <div className="flex flex-row gap-2 p-3">
        <TextInput
          value={question}
          onInput={(e: ChangeEvent<HTMLInputElement>) => setQuestion(e.target.value)}
          onKeyDown={handleKeyPress}
          placeholder={inputPlaceholder}
          className="flex flex-1"
        />
        <Button
          onClick={onQuestionSend}
          size="xs"
          color="primary"
        >
          <img
            src="/send-white.svg"
            alt=""
          />
        </Button>
      </div>
    </div>
  );
}

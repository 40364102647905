import { useApi } from '@/api/api.ts';
import { Tool, ToolName } from '../../../libs/tools/type.model.ts';

export const useUpdateToolType = () => {
  const { patchFetcher } = useApi();

  const updateToolType = async (toolId: number, name: ToolName): Promise<Tool> => {
    return await patchFetcher(`/tools/${toolId}`, { arg: { name } });
  };

  return {
    updateToolType,
  };
};

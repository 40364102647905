import { ReactNode } from 'react';
import { LeftSideNav } from './components/LeftSideNav.tsx';
import { AgentPanel } from '../../components/AgentPanel/AgentPanel.tsx';
import { MemoryLog } from '@/components/MemoryLog/MemoryLog.tsx';
import { useMatches } from 'react-router-dom';

export function MainLayout({ children }: { children: ReactNode }) {
  const matches = useMatches();

  const hideMemoryLog = matches.some((match) => {
    const data = match.handle as { hideMemoryLog?: boolean } | undefined;
    return data?.hideMemoryLog;
  });

  return (
    <>
      <div className="grid h-svh grid-cols-[auto,minmax(0,1fr)]">
        <LeftSideNav />
        <div className="max-w-full">{children}</div>
      </div>
      <AgentPanel />
      {!hideMemoryLog && <MemoryLog />}
    </>
  );
}

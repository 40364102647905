import './MentionAccordionBody.scss';
import { useContext } from 'react';
import { PromptEditorContext } from '@/components/tiptap/extensions/DigitalFirst/editing/prompt-editor.context.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { useProductCanvases } from '@/api/products/get-product-canvases';
import { ProductCanvasDto } from '@/api/products/ProductCanvasDto.ts';

export function ProductCanvasMentionAccordionBody() {
  const { productCanvases } = useProductCanvases();
  const { editor } = useContext(PromptEditorContext);

  const handleAddProductCanvasToPrompt = (productCanvas: ProductCanvasDto) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: MentionReferenceType.PRODUCT_CANVAS,
        attrs: {
          id: productCanvas._id,
          label: `${productCanvas.name}`,
        },
      })
      .insertContent(' ') // add an extra space after the mention
      .run();
  };

  return (
    <>
      <div className="mention-accordion">
        <div className="mention-accordion__header">Add to prompt</div>
        {!!productCanvases?.length && (
          <div className="mention-accordion__list">
            {productCanvases.map((productCanvas) => (
              <button
                key={productCanvas._id}
                onClick={() => {
                  handleAddProductCanvasToPrompt(productCanvas);
                }}
                className="mention-accordion__data-room-item"
                type="button"
              >
                <img
                  src="/add-orange.svg"
                  width={16}
                  height={16}
                  alt=""
                />
                <span className="mention-accordion__data-room-item__name">{productCanvas.name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

import { useApi } from '@/api/api';
import { Whiteboard } from '../types';

type ShareWhiteboardPayload = {
  isPublic: boolean;
  isEditable: boolean;
};

export const useShareWhiteboard = (): {
  shareWhiteboard: (whiteboardId: number, payload: ShareWhiteboardPayload) => Promise<Whiteboard>;
} => {
  const { postFetcher } = useApi();
  const shareWhiteboard = async (whiteboardId: number, payload: ShareWhiteboardPayload): Promise<Whiteboard> => {
    return await postFetcher(`/whiteboard/${whiteboardId}/share`, {
      arg: payload,
    });
  };
  return {
    shareWhiteboard,
  };
};

import { useToolConfigs } from '@/api/tools/get-tool-configs.ts';
import { DfFeature } from '@/enums/df-feature.enum.ts';
import { cn } from '@/helpers/cn.ts';
import { NodeProps } from '@xyflow/react';
import { memo } from 'react';
import { ToolName } from 'src/libs/tools/type.model.ts';
import { NODE_RESIZE_CONSTRAINTS } from '../consts/whiteboard.const.ts';
import { useWhiteboardStore } from '../store/whiteboard.store';
import { ToolNodeType } from '../types';
import { NodeResizeWrapper } from './NodeResizeWrapper.tsx';
import { ToolNodeContent } from './ToolNodeContent.tsx';

type ToolNodeProps = Pick<NodeProps<ToolNodeType>, 'id' | 'data'>;

function ToolNode({ id, data }: ToolNodeProps) {
  const executionEngineNodes = useWhiteboardStore((state) => state.executionEngineNodes);
  const executionEngineNode = executionEngineNodes.find((node) => node.reactFlowNodeId === id);
  const { toolConfigs } = useToolConfigs(DfFeature.WHITEBOARD);

  const canExecute = data.valid && !!executionEngineNode;
  const grayedOut = !canExecute && data.toolConfig.name !== ToolName.READER_TEXT;
  const hasErrors = (data.errors || []).length > 0;

  if (!toolConfigs) return null;

  return (
    <div
      className={cn(
        'relative h-full rounded-xl border-2 text-body-sm shadow-card',
        grayedOut ? 'border-neutrals-400 bg-neutrals-200 *:border-neutrals-400' : 'bg-white',
        hasErrors ? 'border-negative-600' : '',
        'flex flex-col justify-between',
      )}
      style={{
        ...NODE_RESIZE_CONSTRAINTS,
      }}
    >
      <ToolNodeContent
        id={id}
        data={data}
        executionEngineNode={executionEngineNode}
        toolConfigs={toolConfigs}
        canExecute={canExecute}
      />
    </div>
  );
}
const MemoizedToolNode = memo(ToolNode);

export default function ToolNodeWrapper(props: NodeProps<ToolNodeType>) {
  return (
    <>
      <NodeResizeWrapper selected={props.selected} />
      <MemoizedToolNode
        data={props.data}
        id={props.id}
      />
    </>
  );
}

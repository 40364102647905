import { ThemeProps } from 'flowbite-react';

export const dfFlowbiteTheme: ThemeProps = {
  mode: 'light',
  theme: {
    button: {
      base: 'group relative flex items-stretch justify-center text-center font-medium transition-[color,background-color,border-color,text-decoration-color,fill,stroke,box-shadow] focus:z-10 focus:outline-none',
      color: {
        primary: 'bg-primary-default hover:bg-primary-hover active:bg-primary-active text-white',
        secondary:
          'text-text-primary border-gray-divider border-[1px] bg-white hover:bg-gray-bg active:bg-gray-ultra-light',
        yellow: 'bg-yellow-default hover:bg-yellow-hover active:bg-yellow-clicked text-white',
        green: 'bg-green-default hover:bg-green-hover active:bg-green-clicked text-white',
        red: 'bg-red-default hover:bg-red-hover active:bg-red-clicked text-white',
        transparent: 'bg-transparent text-neutrals-700 hover:bg-gray-bg active:bg-gray-ultra-light',
        info: 'bg-primary-default hover:bg-primary-hover active:bg-primary-active text-white',
      },
      size: {
        xs: 'p-2.5 text-button-sm',
        sm: 'flex items-center gap-2 px-4 h-[2.125rem] text-button-md has-[img,svg]:pl-3',
        md: 'flex items-center gap-2 px-5 h-[2.5rem] text-button-md has-[img,svg]:pl-4',
        lg: 'flex items-center gap-2 px-6 h-[3rem] text-button-lg has-[img,svg]:pl-5',
      },
      gradient: {
        yellow:
          'border border-[#edd593] bg-gradient-to-r from-[rgba(255,224,0,0)] to-[rgba(255,204,77,0.3)] text-yellow-default hover:text-yellow-hover active:text-yellow-clicked',
      },
    },
    textInput: {
      field: {
        base: 'flex items-center w-full',
        input: {
          base: 'block w-full border transition !shadow-none disabled:cursor-not-allowed disabled:opacity-50 overflow-ellipsis',
          sizes: {
            md: 'px-4 py-[9.5px] text-sm',
            lg: 'p-[1.1rem] text-sm !rounded-2xl',
          },
          colors: {
            gray: 'border-gray-divider bg-white placeholder-text-tertiary text-text-primary disabled:bg-gray-bg disabled:text-text-secondary disabled:cursor-not-allowed outline-none',
            failure: 'border-red-default active:border-red-default focus:border-red-default',
            readonly: 'border-gray-divider bg-neutrals-200 placeholder-text-tertiary text-text-primary outline-none',
          },
        },
      },
    },
    textarea: {
      base: 'block w-full border transition !shadow-none disabled:cursor-not-allowed disabled:opacity-50 overflow-ellipsis text-sm',
      colors: {
        gray: 'border-gray-divider bg-white placeholder-text-tertiary text-text-primary disabled:bg-gray-bg disabled:text-text-secondary disabled:cursor-not-allowed outline-none',
        failure: 'border-red-default active:border-red-default focus:border-red-default',
        readonly: 'border-gray-divider bg-neutrals-200 placeholder-text-tertiary text-text-primary outline-none',
      },
    },
    select: {
      field: {
        base: 'flex items-center w-full',
        select: {
          base: 'block w-full border transition !shadow-none disabled:cursor-not-allowed disabled:opacity-50',
          sizes: {
            sm: 'px-1.5 py-1 text-xs rounded-lg',
            md: 'px-4 py-[9.5px] text-sm',
            lg: 'p-[1.1rem] text-sm !rounded-2xl',
          },
          colors: {
            gray: 'border-gray-divider bg-white placeholder-text-tertiary text-text-primary disabled:bg-gray-bg disabled:text-text-secondary disabled:cursor-not-allowed outline-none',
            failure: 'border-red-default active:border-red-default focus:border-red-default',
          },
        },
      },
    },
    label: {
      root: {
        base: 'inline-block text-sm font-medium transition-colors mb-2',
        colors: {
          default: 'text-text-primary',
          failure: 'text-red-default',
        },
      },
    },
    helperText: {
      root: {
        base: 'text-sm transition-colors mt-2',
        colors: {
          gray: 'text-text-secondary',
          failure: 'text-red-default',
        },
      },
    },
    dropdown: {
      content: 'text-text-secondary flex flex-col gap-1',
      floating: {
        base: 'z-10 px-2 py-3 w-fit rounded-lg shadow-lg focus:outline-none',
        item: {
          base: 'grid grid-cols-[minmax(1rem,auto),minmax(0,1fr)] h-[40px] w-full gap-2 cursor-pointer items-center justify-items-start px-2 text-sm text-text-primary rounded-lg font-medium bg-interactive-gray focus:outline-none',
        },
        divider: 'relative right-2 w-[calc(100%+1rem)] h-px bg-gray-divider',
      },
    },
    breadcrumb: {
      item: {
        chevron: 'invisible m-0 w-1.5',
        href: {
          off: 'flex items-center text-text-secondary',
          on: 'flex items-center font-medium text-text-primary hover:text-gray-900',
        },
      },
    },
    card: {
      root: {
        base: 'flex transition rounded-xl border border-gray-divider bg-white',
        children: 'flex h-full flex-col items-start p-4',
        horizontal: {
          off: 'flex-col',
          on: 'flex-col md:max-w-xl md:flex-row',
        },
        href: 'has-[button:hover,a:hover]:bg-transparent hover:bg-gray-bg active:bg-gray-ultra-light hover:text-text-primary',
      },
    },
    tooltip: {
      target: 'w-full',
      animation: 'transition-opacity delay-500',
      arrow: {
        base: 'absolute z-10 h-2 w-2 rotate-45',
        style: {
          dark: 'bg-gray-dark',
          light: 'bg-white',
          auto: 'bg-gray-dark',
        },
        placement: '-4px',
      },
      base: 'absolute z-10 inline-block rounded p-2 text-xs shadow-sm text-gray-light',
      hidden: 'invisible opacity-0',
      style: {
        dark: 'bg-gray-dark text-gray-light',
        light: 'border border-gray-200 bg-white text-gray-900',
        auto: 'border border-gray-200 bg-white text-gray-900 dark:border-none dark:bg-gray-700 dark:text-white',
      },
      content: 'relative z-20',
    },
    modal: {
      root: {
        base: 'fixed inset-x-0 top-0 z-[9999] h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full',
      },
      content: {
        base: 'relative h-full w-full p-4 md:h-auto',
        inner: 'relative flex max-h-[90dvh] flex-col rounded-2xl bg-white shadow dark:bg-gray-700',
      },
      body: {
        base: 'flex-1 overflow-x-hidden overflow-y-auto p-6',
        popup: '',
      },
      header: {
        close: {
          base: 'ml-auto transition inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white',
          icon: 'h-5 w-5',
        },
      },
    },
    table: {
      root: {
        base: 'w-full text-left text-sm text-text-secondary table-fixed',
        shadow: 'absolute left-0 top-0 -z-10 h-full w-full rounded-2xl bg-white drop-shadow-md',
        wrapper: 'relative border border-gray-divider rounded-2xl overflow-hidden',
      },
      body: {
        base: 'group/body divide-y border-gray-divider',
        cell: {
          base: 'p-6 pr-3',
        },
      },
      head: {
        base: 'group/head border-b border-gray-divider',
        cell: {
          base: 'bg-gray-ultra-light p-6 pr-3 font-medium',
        },
      },
      row: {
        base: 'group/row bg-white',
        hovered: 'hover:bg-gray-bg',
        striped: 'odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700',
      },
    },
    progress: {
      base: 'w-full overflow-hidden rounded-full bg-primary-200',
      label: 'mb-1 flex justify-between font-medium dark:text-white',
      bar: 'space-x-2 rounded-full text-center font-medium leading-none text-cyan-300 dark:text-cyan-100',
      color: {
        primary: 'bg-primary-default',
      },
      size: {
        sm: 'h-1',
        md: 'h-2',
        lg: 'h-4',
        xl: 'h-6',
      },
    },
    badge: {
      root: {
        base: 'flex h-fit items-center gap-1 rounded-md',
        color: {
          blue: 'bg-primary-100 text-primary-600',
          green: 'bg-tint-green text-green-medium',
          yellow: 'bg-yellow-100 text-yellow-1000',
          mint: 'bg-mint-200 text-mint-1000',
          pink: 'bg-pink-100 text-pink-1000',
          primary: 'bg-primary-100 text-primary-900',
          neutrals: 'bg-neutrals-100 text-text-secondary',
        },
        href: 'group',
        size: {
          circle: '!p-0 !h-5 !w-5 text-xs justify-center !font-medium rounded-full',
          xs: '!px-2 !py-1 text-xs !font-medium',
          lg: '!p-2 text-sm font-medium',
        },
      },
      icon: {
        off: '',
        on: '',
        size: {
          xs: 'h-3 w-3',
          sm: 'h-3.5 w-3.5',
        },
      },
    },
    tabs: {
      base: 'flex flex-col gap-2 max-h-full h-full',
      tablist: {
        base: 'flex justify-center text-center',
        variant: {
          default: 'flex-wrap border-b border-gray-divider',
          underline: '-mb-px flex-wrap border-b border-gray-divider',
          pills: 'flex-wrap space-x-2 text-sm font-medium text-gray-500 dark:text-gray-400',
          fullWidth:
            'grid w-full grid-flow-col divide-x divide-gray-200 rounded-none text-sm font-medium shadow dark:divide-gray-700 dark:text-gray-400',
        },
        tabitem: {
          base: 'flex items-center justify-center rounded-t-lg p-4 text-sm font-medium first:ml-0 focus-visible:outline-none focus-visible:ring-4 focus-visible:ring-cyan-300 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
          variant: {
            default: {
              base: 'rounded-t-lg',
              active: {
                on: 'bg-gray-100 text-primary-600 dark:bg-gray-800 dark:text-cyan-500',
                off: 'text-gray-500 hover:bg-gray-50 hover:text-gray-600 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-gray-300',
              },
            },
            underline: {
              base: 'rounded-t-lg',
              active: {
                on: 'active rounded-t-lg border-b-2 border-primary-600 text-primary-600 dark:border-cyan-500 dark:text-cyan-500',
                off: 'border-b-2 border-transparent text-text-secondary hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300',
              },
            },
            pills: {
              base: '',
              active: {
                on: 'rounded-lg bg-primary-600 text-white',
                off: 'rounded-lg hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-white',
              },
            },
            fullWidth: {
              base: 'ml-0 flex w-full rounded-none first:ml-0',
              active: {
                on: 'active rounded-none bg-gray-100 p-4 text-gray-900 dark:bg-gray-700 dark:text-white',
                off: 'rounded-none bg-white hover:bg-gray-50 hover:text-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white',
              },
            },
          },
          icon: 'mr-2 h-5 w-5',
        },
      },
      tabitemcontainer: {
        base: 'grow min-h-0 overflow-auto',
        variant: {
          default: '',
          underline: '',
          pills: '',
          fullWidth: '',
        },
      },
      tabpanel: 'h-full',
    },
    radio: {
      root: {
        base: 'h-5 w-5 border border-neutrals-400 focus:ring-0 focus:box-shadow-none',
      },
    },
    checkbox: {
      root: {
        base: 'h-5 w-5 rounded border border-neutrals-400 focus:ring-0',
        color: {
          default: 'text-primary-600',
        },
      },
    },
    toggleSwitch: {
      root: {
        base: 'group flex rounded-lg focus:outline-none',
        active: {
          on: 'cursor-pointer',
          off: 'cursor-not-allowed opacity-50',
        },
        label: 'ms-3 mt-0.5 text-start text-sm font-medium text-gray-900 dark:text-gray-300',
      },
      toggle: {
        base: 'relative rounded-full border after:absolute after:rounded-full after:bg-white after:transition-all',
        checked: {
          on: 'after:translate-x-full after:border-white rtl:after:-translate-x-full',
          off: 'border-gray-200 bg-gray-200 dark:border-gray-600 dark:bg-gray-700',
          color: {
            blue: 'border-primary-600 bg-primary-600',
          },
        },
        sizes: {
          md: 'h-7 w-14 min-w-14 after:left-[3px] after:top-[1px] after:h-6 after:w-6',
        },
      },
    },
  },
};

import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { closeSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { ToolField } from '../../../libs/tools/type.model.ts';
import { useUpdateExecutionEngineNode } from '../api/update-execution-engine-node.ts';
import { useWhiteboardStore } from '../store/whiteboard.store.ts';
import { useSaveReactFlow } from './useSaveReactFlow.ts';

export const useWhiteboardFormSubmit = (reactFlowNodeId: string) => {
  const { showLoader, hideLoader } = useGlobalLoader();
  const { updateFields } = useUpdateExecutionEngineNode();
  const updateExecutionEngineNode = useWhiteboardStore((store) => store.updateExecutionEngineNode);
  const updateNodeData = useWhiteboardStore((store) => store.updateNodeData);

  const { saveReactFlow } = useSaveReactFlow();

  return async (fields: ToolField[]) => {
    showLoader();

    const updatedExecutionEngineNode = await updateFields({
      reactFlowNodeId,
      fields,
    });

    updateExecutionEngineNode(reactFlowNodeId, updatedExecutionEngineNode);
    updateNodeData(reactFlowNodeId, { toolInput: updatedExecutionEngineNode.tool.input, valid: true });

    await saveReactFlow();

    closeSideModal();
    hideLoader();
  };
};

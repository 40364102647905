import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';
import ResearchIcon from '@assets/research.svg';
import { ToolName } from 'src/libs/tools/type.model';
import { useAddNodes } from '../../hooks/useAddNode';
import { NodeConfig } from '../../types';
import { ToolboxButton } from './ToolboxButton';

export function ToolboxWebSearcher({ nodeConfigs }: { nodeConfigs: NodeConfig[] }) {
  const { openModal } = useGlobalModal();
  const { addNode } = useAddNodes();

  const handleClick = () => {
    const callback = (data: string, title: string) => {
      const nodeConfig = nodeConfigs.find((nodeConfig) => nodeConfig.toolConfig.name === ToolName.READER_TEXT)!;
      const fields = [
        {
          key: 'content',
          value: data,
        },
      ];

      addNode({ toolConfig: nodeConfig.toolConfig, fields, execute: true, nodeName: title });
    };

    openModal(MODALS.WebsearchModalBody, { callback });
  };

  return (
    <ToolboxButton
      label="Web Searcher"
      onClick={handleClick}
      Icon={ResearchIcon}
      iconClassName="path:fill-blue-800"
    />
  );
}

import { TextInput } from 'flowbite-react';
import { MenuItem } from '@/components/MenuItem.tsx';
import { useParams, useSearchParams } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { CanvasCategory } from '@/enums/canvas-category.enum.ts';
import { useCanvasTemplates } from '@/api/canvases/api.ts';
import { LibraryCanvasCard } from '../../components/LibraryCanvasCard.tsx';
import { useState } from 'react';
import { HiSearch } from 'react-icons/hi';

import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';

export function CanvasLibrary() {
  const { category } = useParams<{ category: CanvasCategory }>();
  const { canvasTemplates, isLoading } = useCanvasTemplates();
  const [searchParams] = useSearchParams();
  const [query, setQuery] = useState(searchParams.get('query') ?? '');

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: [
          { label: 'Strategy canvases', href: buildUrl([Urls.CANVASES]) },
          { label: 'Library', href: '#' },
        ],
        icon: '/canvases-black.svg',
      }}
    >
      <title>Digitalfirst.ai | Canvas library</title>

      <div className="grid grid-cols-[300px,1fr] items-start gap-8">
        <aside className="flex flex-col gap-1">
          <div className="relative mb-3">
            <TextInput
              placeholder="Search..."
              icon={HiSearch}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>

          <MenuItem
            label="All strategy canvases"
            leftIcon="/dashboard-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.ALL })}
          />

          <MenuItem
            label="Marketing"
            leftIcon="/rocket-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.MARKETING })}
          />

          <MenuItem
            label="Communication"
            leftIcon="/notes-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.COMMUNICATION })}
          />

          <MenuItem
            label="Execution"
            leftIcon="/aim-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.EXECUTION })}
          />

          <MenuItem
            label="Documentation"
            leftIcon="/description-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.DOCUMENTATION })}
          />

          <MenuItem
            label="Analysis"
            leftIcon="/analysis-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.ANALYSIS })}
          />

          <MenuItem
            label="Research"
            leftIcon="/research-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.RESEARCH })}
          />

          <MenuItem
            label="Strategy"
            leftIcon="/electric-bolt-black.svg"
            href={buildUrl([Urls.CANVASES, Urls.CANVAS_LIBRARY], { category: CanvasCategory.STRATEGY })}
          />
        </aside>

        {!isLoading && (
          <div className="grid grid-cols-2 gap-6">
            {category === CanvasCategory.ALL
              ? canvasTemplates
                  .filter((canvasTemplate) => canvasTemplate.name.toLowerCase().includes(query.toLowerCase()))
                  .map((canvasTemplate) => (
                    <LibraryCanvasCard
                      key={canvasTemplate.id}
                      canvasTemplate={canvasTemplate}
                    />
                  ))
              : canvasTemplates
                  .filter((canvasTemplate) => canvasTemplate.name.toLowerCase().includes(query.toLowerCase()))
                  .filter((canvasTemplate) => canvasTemplate.category === category)
                  .map((canvasTemplate) => (
                    <LibraryCanvasCard
                      key={canvasTemplate.id}
                      canvasTemplate={canvasTemplate}
                    />
                  ))}
          </div>
        )}
      </div>
    </PageWithHeaderLayout>
  );
}

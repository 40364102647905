import { usePublicApi } from '@/api/public-api';
import { WhiteboardTemplate } from '@/models/whiteboard-template';
import useSWR from 'swr';

export const usePublicWhiteboardTemplates = () => {
  const { publicGetFetcher } = usePublicApi();

  const { data, ...rest } = useSWR<WhiteboardTemplate[]>('/public-template', publicGetFetcher);
  return {
    publicWhiteboardTemplates: data,
    ...rest,
  };
};

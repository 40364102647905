import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { WhiteboardTemplate, WhiteboardTemplateCategory } from '@/models/whiteboard-template';
import CloseIcon from '@assets/close.svg';
import { useMemo, useState } from 'react';
import { useWhiteboardTemplates } from '../../api/templates/get-whiteboard-templates';
import { useAddTemplate } from '../../hooks/useAddTemplate';
import { LoaderIcon } from '../ui/LoaderIcon';
import { TemplateCategories } from './TemplateCategories';
import { TemplateDetails } from './TemplateDetails';
import { TemplatesGrid } from './TemplatesGrid';
import { TemplatesHeader } from './TemplatesHeader';

export function WhiteboardTemplatesModal() {
  const { whiteboardTemplates, isLoading } = useWhiteboardTemplates();
  const [selectedTemplate, setSelectedTemplate] = useState<WhiteboardTemplate | null>(null);
  const [activeCategory, setActiveCategory] = useState<WhiteboardTemplateCategory | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const { addTemplate } = useAddTemplate();
  const { closeModal } = useGlobalModal();

  const filteredTemplates = useMemo(() => {
    if (!whiteboardTemplates) return [];

    let filtered = whiteboardTemplates;

    if (searchQuery) {
      filtered = filtered.filter((template) => template.name.toLowerCase().includes(searchQuery.toLowerCase()));
    }

    if (activeCategory) {
      filtered = filtered.filter((template) => template.category === activeCategory);
    } else {
      filtered = filtered.filter((template) => template.category !== WhiteboardTemplateCategory.MY);
    }

    return filtered;
  }, [whiteboardTemplates, searchQuery, activeCategory]);

  if (isLoading) {
    return (
      <div className="flex h-[calc(100vh-200px)] items-center justify-center overflow-hidden px-6 py-3">
        <LoaderIcon className="mr-1" />
        <span>Loading templates...</span>
      </div>
    );
  }

  return (
    <>
      <button
        className="group absolute right-1 top-1 rounded-full bg-white p-1 hover:bg-neutrals-100 xl:!-right-6 xl:!-top-6"
        onClick={() => closeModal()}
      >
        <CloseIcon className="size-4 path:fill-neutrals-800 group-hover:path:fill-neutrals-1000" />
      </button>
      <div className="h-[calc(100vh-200px)] overflow-hidden px-6 py-3">
        {selectedTemplate ? (
          <TemplateDetails
            template={selectedTemplate}
            onBack={() => setSelectedTemplate(null)}
            onAddTemplate={() => {
              addTemplate(selectedTemplate);
            }}
          />
        ) : (
          <>
            <TemplatesHeader
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
            <div className="flex h-[calc(100%-66px)] gap-7">
              <div className="h-full w-52 shrink-0 overflow-y-auto scrollbar-hide xl:w-60">
                <TemplateCategories
                  handleCategoryChange={(category) => {
                    setActiveCategory(category);
                  }}
                  activeCategory={activeCategory}
                />
              </div>

              <div className="h-full grow overflow-y-auto scrollbar-hide">
                <TemplatesGrid
                  templates={filteredTemplates}
                  onSelect={setSelectedTemplate}
                  onAdd={(template) => addTemplate(template)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
